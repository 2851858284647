import React from 'react';

import { BasePageLayout } from '@src/shared/components/Layout';
import { i18n } from '@src/shared/i18n';
import { AuthService, UserRole } from '@src/shared/services/auth';

import { IDCCoreReportWidget } from './IDCCoreReportWidget';
import { IDCDocUploadWidget } from './IDCDocUploadWidget';
import { IDCEditingReportWidget } from './IDCEditingReportWidget';
import IDCLogoSvg from './assets/IDCLogo.svg?react';
import { IDCDocType } from './enums';

const widgetsByRole = {
  [UserRole.ImportSpecialist]: [
    IDCDocType.idcEditData,
    IDCDocType.idcEditPhoto,
    IDCDocType.hsCodeRequest,
  ],
  [UserRole.SampleCoordinator]: [
    IDCDocType.idcEditData,
    IDCDocType.idcEditPhoto,
    IDCDocType.hsCodeRequest,
  ],
  [UserRole.CertificationSpecialist]: [IDCDocType.idcEditData],
  [UserRole.CustomsGroupSpecialist]: [IDCDocType.idcEditData],
};

export const IDCPage = () => {
  const docUploadWidgets = widgetsByRole[AuthService.getUserIdToken()?.vedRole as string] ?? [];

  return (
    <BasePageLayout title={i18n.idc.pageTitle} logo={<IDCLogoSvg />}>
      <IDCCoreReportWidget />
      <IDCEditingReportWidget />
      {docUploadWidgets.map((docType) => (
        <IDCDocUploadWidget key={docType} docType={docType} />
      ))}
    </BasePageLayout>
  );
};
