// @flow strict
import React from 'react';
import type { FieldContainer } from '../../types';
import styles from './index.css';

type Props = {|
    field: FieldContainer
|};

function NoDropdown({ field }: Props) {
    return (
        <div data-testid="no-dropdown-testid" className={styles.title} style={{ cursor: 'default' }}>
            <span title={field.displayName} className={styles.dotted}>
                {field.displayName}
            </span>
        </div>
    );
}

export { NoDropdown };
