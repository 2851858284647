/* eslint-disable no-param-reassign */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

// Legacy File
// TODO: refactor and fix errors
import React from 'react';

import { Button } from 'mp-ui-components';

import { resolveTask } from '@src/modules/tasks/pages/TasksListPage/services/tasks';
import {
  TextComponent,
  RadioComponent,
  AttachmentScreenshotComponent,
  TextAreaComponent,
  InputComponent,
  DatePickerComponent,
  // eslint-disable-next-line import/namespace
  AttachmentDocComponent,
} from '@src/shared/components/TaskComponents';
import type { TaskComponentContainerField, UploadedFilesType } from '@src/shared/types/domain';

import CrossSvg from '../../../../assets/svg/Cross.svg?react';

import styles from './styles.css';

type LightBoxSection = {
  id: string;
  templateId: string;
  label: string;
  fields: TaskComponentContainerField[];
};

type UpdateFieldType = {
  value?: string | Date;
  selected?: string;
  date?: Date;
  files?: File[] | FileList | null;
  uploading?: boolean;
  uploadedFiles?: UploadedFilesType[] | null;
};

function getTaskComponentContainerResponsibleValue(field: TaskComponentContainerField) {
  switch (field.type) {
    case 'textInput':
      return field.value;

    case 'comment':
      return field.value;

    case 'select':
      return field.selected;

    case 'datePicker':
      return field.value;

    case 'attachmentDoc':
      return field.uploadedFiles;

    case 'attachmentScreenshot':
      return field.uploadedFiles;

    default:
      return '';
  }
}

export const LightBox = ({
  orderId,
  orderNumber,
  taskId,
  data,
  onClose,
}: {
  orderId: string;
  orderNumber: string;
  taskId: string;
  data: LightBoxSection;
  onClose: (event: string) => void;
}) => {
  const [state, setState] = React.useState<LightBoxSection>(data);
  const [requirementsIsOk, setRequirementsIsOk] = React.useState(false);
  const [selectedConditions, setSelectedConditions] = React.useState([]);

  const updateConditions = (prevCondition, nextCondition) => {
    if (nextCondition === null) {
      return;
    }

    const update = selectedConditions.filter((condition) => condition !== prevCondition);

    update.push(nextCondition);
    setSelectedConditions(update);
  };

  function updateField(sectionId: string, componentId: string, update: UpdateFieldType) {
    setState((oldState) => {
      if (oldState.id === sectionId) {
        return {
          ...oldState,
          fields: oldState.fields.map((component) => {
            if (component.id === componentId) {
              if (component.type === 'select' && (component.selected || update.selected)) {
                const prevCondition = component.selected || null;
                const nextCondition = update.selected || null;

                updateConditions(prevCondition, nextCondition);
              }

              for (const fieldKey in update) {
                component[fieldKey] = update[fieldKey];
              }
            }

            return component;
          }),
        };
      }

      return oldState;
    });
  }

  // Check conditions are fine
  React.useEffect(() => {
    const allRequirements = [
      ...state.fields.filter(
        (field) => field.isRequired !== undefined && field.isRequired === true,
      ),
    ];
    const allNeededRequirements = [];

    for (const requirement of allRequirements) {
      if (requirement.availableWhen) {
        let availableWhenCheckIsOkay = false;

        for (const conditionalOption of requirement.availableWhen) {
          if (selectedConditions.includes(conditionalOption) === true) {
            availableWhenCheckIsOkay = true;
          }
        }

        if (availableWhenCheckIsOkay) {
          allNeededRequirements.push(requirement);
        }
      } else {
        allNeededRequirements.push(requirement);
      }
    }

    let allRequirementsIsOkay = true;

    for (const fieldThatRequired of allNeededRequirements) {
      if (
        fieldThatRequired.type === 'select' &&
        fieldThatRequired.selected &&
        fieldThatRequired.options.some((option) => option.isBlocking)
      ) {
        const selectedOption = fieldThatRequired.options.find(
          (option) => option === fieldThatRequired.selected,
        );

        if (selectedOption && selectedOption.isBlocking) {
          allRequirementsIsOkay = false;
        }
      }

      if (!getTaskComponentContainerResponsibleValue(fieldThatRequired)) {
        allRequirementsIsOkay = false;
      }

      if (fieldThatRequired.uploading) {
        allRequirementsIsOkay = false;
      }
    }

    setRequirementsIsOk(allRequirementsIsOkay);
  }, [selectedConditions, state]);

  function updateCommentField(sectionId: string, comoponentId: string, value: string) {
    updateField(sectionId, comoponentId, {
      value,
    });
  }

  function updateDatePickerField(sectionId: string, componentId: string, value) {
    const update = {
      value,
    };

    updateField(sectionId, componentId, update);
  }

  const selectRadio = (sectionId: string, componentId: string, selected: string) => {
    const update = {
      selected,
    };

    updateField(sectionId, componentId, update);
  };

  const fileHandler = (sectionId: string, componentId: string, update) => {
    updateField(sectionId, componentId, update);
  };

  function isAvailable(component: TaskComponentContainerField) {
    const { availableWhen } = component;

    if (availableWhen && availableWhen.length > 0) {
      let availableCount = 0;

      for (const condition of availableWhen) {
        if (selectedConditions.includes(condition)) {
          availableCount++;
        }
      }

      if (availableCount === 0) {
        return false;
      }
    }

    return true;
  }

  function renderByType(component: TaskComponentContainerField, sectionId: string) {
    if (component.type === 'text') {
      return <TextComponent isBold={component.isBold} text={component.text} />;
    }

    if (component.type === 'comment') {
      const textAreaComponentHandler = (e) => {
        updateCommentField(sectionId, component.id, e);
      };

      return (
        <TextAreaComponent
          textAreaHandler={textAreaComponentHandler}
          placeholder={component.placeholder || ''}
          value={component.value}
        />
      );
    }

    if (component.type === 'textInput') {
      const textAreaHandlerComponent = (e) => {
        updateCommentField(sectionId, component.id, e);
      };

      return (
        <InputComponent
          value={component.value}
          placeholder={component.placeholder || ''}
          inputHandler={textAreaHandlerComponent}
        />
      );
    }

    if (component.type === 'select') {
      const selectRadioComponentHandler = (e) => {
        selectRadio(sectionId, component.id, e);
      };

      return (
        <RadioComponent
          options={component.options}
          selected={component.selected}
          selectRadio={selectRadioComponentHandler}
        />
      );
    }

    if (component.type === 'attachmentDoc') {
      const fileHandlerComponent = (update) => {
        fileHandler(sectionId, component.id, update);
      };

      return (
        <AttachmentDocComponent
          doctype={component.doctype}
          orderId={orderId}
          orderNumber={orderNumber}
          isMultiple={component.isMultiple}
          text={component.text || 'Browse...'}
          files={component.files || null}
          isUploading={component.uploading}
          fileHandler={fileHandlerComponent}
        />
      );
    }

    if (component.type === 'attachmentScreenshot') {
      const screenshotHandlerComponent = (update) => {
        fileHandler(sectionId, component.id, update);
      };

      const mappedFiles = [];

      if (component.uploadedFiles) {
        for (const uploadedFile of component.uploadedFiles) {
          mappedFiles.push({
            name: uploadedFile.fileName,
            url: uploadedFile.url,
          });
        }
      }

      return (
        <AttachmentScreenshotComponent
          orderId={orderId}
          orderNumber={orderNumber}
          mappedFiles={mappedFiles}
          screenshotHandler={screenshotHandlerComponent}
          files={component.files || null}
          text={component.text || 'Browse...'}
        />
      );
    }

    if (component.type === 'datePicker') {
      const datePickerComponentHandler = (e) => {
        updateDatePickerField(sectionId, component.id, e);
      };

      return (
        <DatePickerComponent
          disabledTime={component.disabledTime}
          expandRestrictedDays={component.expandRestrictedDays}
          value={component.value}
          datePickerHandler={datePickerComponentHandler}
        />
      );
    }
  }

  function proceedHandler() {
    const resolution = [];
    let isOkay = true;

    for (const containerField of state.fields) {
      const value = getTaskComponentContainerResponsibleValue(containerField);

      if (containerField.type === 'comment') {
        resolution.push({
          id: containerField.id,
          value: value || '',
        });
      }

      if (containerField.type === 'select') {
        resolution.push({
          id: containerField.id,
          value: value || '',
        });
      }

      if (containerField.type === 'datePicker') {
        resolution.push({
          id: containerField.id,
          value: value || '',
        });
      }

      if (
        (containerField.type === 'attachmentDoc' ||
          containerField.type === 'attachmentScreenshot') &&
        containerField.uploading
      ) {
        isOkay = false;
      } else if (
        containerField.type === 'attachmentDoc' ||
        containerField.type === 'attachmentScreenshot'
      ) {
        resolution.push({
          id: containerField.type,
          value: value || [],
        });
      }
    }

    if (!isOkay) {
      return;
    }

    const resolveBody = {
      templateId: state.templateId,
      orderId,
      orderNumber,
      resolution,
    };

    resolveTask(taskId, resolveBody).then((response) => {
      if (response === true) {
        onClose('proceed');
      }
    });
  }

  return (
    <div className={styles.lightBoxContainer}>
      <div
        className={styles.lightBoxBackground}
        onClick={() => {
          onClose('display');
        }}
      />
      <div className={styles.lightBox}>
        <React.Fragment key="0">
          <div className={styles.lightBoxHeader}>
            <span className={styles.lightBoxHeaderTitle}>{state.label}</span>
            <div
              onClick={() => {
                onClose('display');
              }}
            >
              <CrossSvg />
            </div>
          </div>
          <div className={styles.lightBoxContent}>
            {state.fields.map((component) => {
              return (
                isAvailable(component) && (
                  <div key={component.id} className={styles.lightBoxRow}>
                    <div className={styles.lightBoxRowTitle}>{component.label}</div>
                    <div className={styles.lightBoxRowValue}>
                      {renderByType(component, state.id)}
                    </div>
                  </div>
                )
              );
            })}
          </div>
        </React.Fragment>

        <div className={styles.lightBoxFooter}>
          <Button isDisabled={!requirementsIsOk} onClick={proceedHandler} theme="primary">
            PROCEED
          </Button>
        </div>
      </div>
    </div>
  );
};
