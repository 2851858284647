import { useNotificationBanner } from './useNotificationBanner';

const HEADER_WITH_BANNER_HEIGHT = 80;
const DEFAULT_HEADER_HEIGHT = 48;

// TODO: если контент будет динамическим по высоте, следует переписать с использованием Resize Observer Api (в т.ч. можно глянуть в либе react-use)
export const useAppHeaderHeight = (): number => {
  const banner = useNotificationBanner();

  return banner?.message ? HEADER_WITH_BANNER_HEIGHT : DEFAULT_HEADER_HEIGHT;
};
