export const i18n = {
  pageTitle: 'Direct Mode Documents',
  piDocUploadTitle: 'Proforma Invoice',
  arfDocUploadTitle: 'Atlas Russian File',
  invoiceDocUploadTitle: 'Invoice',

  uploadFailedTitle: 'Uploading errors',
  uploadButtonText: 'Upload',
  uploadButtonSucces: 'Files were uploaded',
  uploadButtonFail: 'Files were not uploaded',

  uploadErrorTitleWrongType: 'Wrong file format. Upload xls, xlsx instead:',
  uploadErrorTitleFileTooBig: 'File is too big, should be less than 100 mb:',
  uploadErrorTitleServerError: 'Technical error. Try again:',

  noUploadedFilesText: 'Here will be files',
};
