import { styled } from 'linaria/lib/react';

export const Wrap = styled.div`
  & > div {
    width: 100%;
  }

  /* remove after disabling global styles of old ui kit */
  input:not([disabled]):not([type='hidden']):focus {
    outline: none;
  }
`;
