import React from 'react';

export const SeaIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M.498 23.979a.5.5 0 00.41-.215s1.98-2.783 5.338-2.783c1.998 0 2.933.96 3.758 1.81.594.614 1.159 1.188 1.988 1.188.83 0 1.394-.574 1.998-1.189.816-.85 1.75-1.809 3.748-1.809 3.343 0 5.317 2.758 5.337 2.783a.502.502 0 00.92-.188.498.498 0 00-.1-.381 8.347 8.347 0 00-3.863-2.828l2.454-9.252a.5.5 0 00-.26-.57l-2.738-1.363V4.791a2.798 2.798 0 00-2.794-2.793h-2.203V.499a.5.5 0 00-1 0v1.499h-2.998V.499a.5.5 0 00-1 0v1.499H7.29A2.798 2.798 0 004.496 4.79v4.391l-2.723 1.364a.5.5 0 00-.275.57l2.454 9.25a8.346 8.346 0 00-3.863 2.829.5.5 0 00.41.784zM5.496 4.79c0-.991.803-1.794 1.793-1.794h9.406a1.793 1.793 0 011.793 1.794v3.892l-6.271-3.134a.467.467 0 00-.42 0L5.496 8.682V4.79zm-2.914 6.464l8.91-4.451v13.678a.5.5 0 101 0V6.804l8.909 4.45-2.333 8.853a7.118 7.118 0 00-1.33-.125c-2.418 0-3.608 1.218-4.497 2.113-.514.55-.86.884-1.249.884-.39 0-.735-.334-1.27-.884-.869-.895-2.058-2.113-4.497-2.113a7.1 7.1 0 00-1.328.125l-2.315-8.852z" />
    </svg>
  );
};

export const TrainIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="24" viewBox="0 0 18 24">
      <path d="M8.78.004C6.663.01 4.53.396 2.395 1.146c-.02.007-.04.01-.06.017l-.05.017h-.01c-.429.154-.866.34-1.244.64-.395.314-.716.816-.716 1.414v14.77c0 1.174 1.017 2.105 2.208 2.105h.844L.444 23.067c-.409.409-.065.929.46.929h15.657c.633.004.811-.607.426-.98-1.002-.978-1.995-1.94-2.992-2.906h.938c1.19 0 2.207-.932 2.207-2.106V3.234c0-.6-.33-1.095-.724-1.406-.396-.31-.85-.5-1.287-.664a17.851 17.851 0 00-6.35-1.16zm0 1.083c2.003-.005 3.99.358 5.965 1.1.41.152.775.319.997.493.223.175.307.297.307.554V4.53H1.407V3.234c0-.259.087-.387.307-.562.21-.167.552-.33.937-.469.006-.003.012.003.017 0a.339.339 0 00.035-.017c.017-.006.034-.01.05-.017 2.034-.716 4.04-1.078 6.026-1.082zM1.406 5.62h6.69v5.463h-6.69V5.62zm7.781 0h6.861v5.463H9.19V5.62zm-7.78 6.554h14.641v5.83c0 .541-.484 1.013-1.116 1.013H2.523c-.63 0-1.116-.472-1.116-1.014v-5.83zM4.9 20.109h7.526l.81.784h-9.11l.775-.784zm-1.849 1.875h11.31l.946.92H2.14l.912-.92z" />
      <path d="M4 15c-.548 0-1 .452-1 1s.452 1 1 1 1-.452 1-1-.452-1-1-1zm10.891 1c0 .564-.435 1-1 1-.564 0-1-.436-1-1 0-.564.436-1 1-1 .565 0 1 .436 1 1z" />
    </svg>
  );
};

export const TruckIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="24" viewBox="0 0 21 24">
      <path d="M4.082 0C1.832 0 0 1.839 0 4.099v11.52c0 .745.217 2.159 1.605 3.465v3.158c0 .97.786 1.758 1.752 1.758h1.111c.967 0 1.752-.788 1.752-1.758v-.476h8.56v.476c0 .97.785 1.758 1.752 1.758h1.111c.966 0 1.751-.788 1.751-1.758v-3.158C20.784 17.78 21 16.364 21 15.62V4.1C21 1.839 19.169 0 16.917 0H4.082zm-.725 22.96a.72.72 0 01-.718-.72v-.475h2.546v.476a.72.72 0 01-.717.72H3.357zm-.718-2.233v-6.51l.92-6.622a.942.942 0 01.91-.803h12.063c.443 0 .85.36.908.809l.92 6.617v6.51l-15.72-.001zm13.892 2.234a.72.72 0 01-.718-.72v-.476h2.546v.476c0 .397-.321.72-.717.72h-1.111zm-8.348-7.42c.317 0 .482.26.498.386.01.085 0 1.661 0 2.396 0 .207-.253.391-.498.396-.245.004-.502-.2-.502-.396v-2.33c0-.192.186-.452.502-.452zm2 0c.317 0 .482.26.498.386.01.085 0 1.661 0 2.396 0 .207-.253.391-.498.396-.245.004-.502-.2-.502-.396v-2.33c0-.192.186-.452.502-.452zm2 0c.317 0 .482.26.498.386.01.085 0 1.661 0 2.396 0 .207-.253.391-.498.396-.245.004-.502-.2-.502-.396v-2.33c0-.192.186-.452.502-.452zM16.917 1.04a3.056 3.056 0 013.048 3.06v11.52c0 .417-.098 1.11-.57 1.855V14.18a.568.568 0 00-.006-.072l-.925-6.646a1.979 1.979 0 00-1.933-1.708H4.469c-.963 0-1.811.75-1.933 1.702l-.925 6.652a.409.409 0 00-.006.071v3.293c-.472-.744-.57-1.438-.57-1.854V4.1a3.056 3.056 0 013.046-3.06h12.836zm-.363 11.87c.35 0 .55.167.57.493.02.327-.22.508-.508.508H4.49c-.343 0-.6-.188-.6-.504 0-.317.169-.48.6-.496.28-.011 4.25-.011 12.064-.001z" />
      <path d="M5 18a1.001 1.001 0 010-2 1 1 0 010 2zm11 0a1.001 1.001 0 010-2 1 1 0 010 2z" />
    </svg>
  );
};
