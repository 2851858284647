import { captureException } from '@sentry/react';
import axios from 'axios';

import { TokenRequest } from '@src/modules/auth/types';
import { AuthService } from '@src/shared/services/auth';

import { queryClient } from './query';

// TODO - figure out how to deal with long requests (report downloads mostly):
// Opt 1. Notify user of possible long request (show somewhere in UI "expected load time")
// Opt 2(best). Speed up request on Backend / async download (prepare link, notify frontend and download by link)
export const httpClient = axios.create({
  timeout: 300_000,
});

// TODO Перенести логику с авторизацией ниже в AuthService (данный файлик не должен знать об AuthService, чтобы избежать циклических зависимостей)
export const refreshToken = () =>
  queryClient.fetchQuery({
    queryKey: `/api/auth/token/refresh`,
    queryFn: async ({ queryKey }) => {
      const response = await httpClient.post(queryKey[0], {
        isExternalUser: AuthService.getIsExternalUser(),
        refreshToken: AuthService.getRefreshToken(),
      });

      AuthService.setTokens(response.data);
    },
  });

type ExternalAuthType = {
  username: string;
  password: string;
};

export async function externalAuth(data: ExternalAuthType): Promise<TokenRequest> {
  const response = await httpClient.post('/api/auth/token/external', data);

  AuthService.setIsExternalUser(true);
  AuthService.setTokens(response.data);

  return response.data;
}

const refreshTokenInterceptor = async (config) => {
  const idToken = AuthService.getUserIdToken();

  if (!idToken) {
    return config;
  }

  if (!AuthService.isRefreshTokenUrl(config.url)) {
    const expiration = idToken.exp * 1000;
    const hasExpired = Date.now() > expiration;

    if (hasExpired) {
      try {
        await refreshToken();
      } catch (error) {
        captureException({
          info: 'Auth token refresh failed',
          error,
        });
        AuthService.logout();

        const controller = new AbortController();

        controller.abort();

        return { ...config, signal: controller.signal };
      }
    }
  }

  return {
    ...config,
    headers: { ...config.headers, 'id-token': AuthService.getUserIdTokenRaw() },
  };
};

httpClient.interceptors.request.use(refreshTokenInterceptor);
