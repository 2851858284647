import React from 'react';

import { styled } from 'linaria/lib/react';

import { ActionsPanel } from './ActionsPanel';
import { Header } from './Header';
import { Section } from './Section/Section';
import { FormContext, FormContextValue, FormTarget } from './hooks/useFormContext';

export const Sections = styled.div<{ target: FormTarget }>`
  max-height: ${({ target }) => (target === FormTarget.modal ? 'calc(80vh - 156px)' : 'auto')};
  overflow-x: ${({ target }) => (target === FormTarget.modal ? 'hidden' : 'auto')};
  background: var(--background-primary);
`;

type Props = {
  formContext: FormContextValue;
};

// TODO: Под капотом движка тасок совершаются мутации с исходным DTO - надо такое поведение убрать :(
export const TaskFormTemplate = ({ formContext }: Props) => (
  <FormContext.Provider value={formContext}>
    {formContext.target === FormTarget.modal && <Header />}
    {formContext.state.fields && (
      <Sections target={formContext.target}>
        {formContext.state.fields.map((params) => (
          <Section key={`${params.id}_${params.caption}`} params={params} />
        ))}
      </Sections>
    )}
    <ActionsPanel />
  </FormContext.Provider>
);
