import React from 'react';

import Preloader from '@src/shared/components/Preloader';
import { CommonTable } from '@src/shared/components/Table/_deprecated/Table';
import List from '@src/shared/components/Table/_deprecated/TableList';
import { AuthService } from '@src/shared/services/auth';

import { BlockUnblockActions } from './BlockUnblockActions';
import { EditGKLinkingActions } from './EditGKLinkingActions';
import { GKLinkPicker } from './GKLinkPicker/GKLinkPicker';
import { ArticleActions, getAvailableActions } from '../../entities/orderArticle';
import { useOrderArticles } from '../../hooks/useOrderArticles';

import styles from './styles.css';

type TOrderArticlesListProps = {
  orderId: string;
  ocNumber: string;
  refetchChronology: () => Promise<void>;
};

export const OrderArticlesList = ({
  orderId,
  ocNumber,
  refetchChronology,
}: TOrderArticlesListProps) => {
  const {
    articles,
    fields,
    fetchArticles,
    editGKLinking,
    checkArticle,
    checkAllArticles,
    uncheckArticle,
    uncheckAllArticles,
    isPending,
    error,
    filtersHook,
  } = useOrderArticles(orderId, ocNumber, refetchChronology);

  const { filters, updateFilters, isEmpty: isFiltersEmpty } = filtersHook;

  const actions = getAvailableActions(articles, AuthService.getUserIdToken()?.vedRole);

  const canEditGKLinking = actions.includes(ArticleActions.EditGKLinking);
  const canBlockOrUnblockOrderArticle = actions.includes(ArticleActions.BlockUnblock);

  const handleArticleChange = ({ value, isRoot = false, fieldIndex }) => {
    if (isRoot) {
      if (value) {
        checkAllArticles();
      } else {
        uncheckAllArticles();
      }
    }

    if (fieldIndex !== undefined) {
      if (value) {
        checkArticle(fieldIndex);
      } else {
        uncheckArticle(fieldIndex);
      }
    }
  };

  const handleFiltersChange = (filter) => updateFilters(filter);

  if (isPending && isFiltersEmpty) {
    return <Preloader />;
  }

  if (error.length > 0) {
    return (
      <>
        <h4 className={styles.errorHeading}>Article list was not loaded</h4>
        <div role="alert">{error}</div>
      </>
    );
  }

  if (articles.length === 0 && isFiltersEmpty) {
    return (
      <div className={styles.empty} role="status">
        There are no articles yet
      </div>
    );
  }

  let preparedFields = fields.map((field) => {
    const patchedField = {
      ...field,
      filterType: field.type as any,
      filter: field.isFilterable,
    };

    if (field.name === 'idcId') {
      patchedField.customRenderer = (rowData) => (
        <GKLinkPicker article={rowData as any} editGKLinking={editGKLinking} />
      );
    }

    return patchedField;
  });

  if (!canBlockOrUnblockOrderArticle) {
    preparedFields = preparedFields.filter((field) => field.name !== 'canBlock');
  }

  const tableData: CommonTable = {
    rows: articles,
    fields: preparedFields,
  };

  return (
    <section className={styles.container}>
      <List
        headerCellClassName={styles.headerCell}
        data={tableData.rows}
        fields={tableData.fields}
        filterOptions={filters}
        onFilter={handleFiltersChange}
        onChange={handleArticleChange as any} // TODO: fix typing
      />
      {isPending && <Preloader />}
      {articles.length === 0 && !isPending && (
        <div className={styles.empty}>Articles not found</div>
      )}
      {actions.length > 0 && (
        <footer className={styles.footer}>
          {canBlockOrUnblockOrderArticle && (
            <BlockUnblockActions
              orderId={orderId}
              articles={articles}
              fetchArticles={fetchArticles}
              filters={filters}
            />
          )}
          {canEditGKLinking && <EditGKLinkingActions editGKLinking={editGKLinking} />}
          {editGKLinking.applyModalNode}
        </footer>
      )}
    </section>
  );
};
