import { httpClient } from '@src/shared/services/httpClient';

export function getReportFile(options): Promise {
  return httpClient({
    method: 'POST',
    data: options,
    url: `api/shipmentsTracking/downloadShipmentTracking`,
    responseType: 'blob',
  });
}
