/* eslint-disable @typescript-eslint/no-throw-literal */
/* eslint-disable prefer-promise-reject-errors */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

// Legacy File
// TODO: refactor and fix errors
import { CurrencyCodes, OrderMode } from '@lmru-bpms-ved/core';

import type {
  ListRow,
  FieldContainer,
  ChronologyResponse,
  MappedFilterType,
  PaginationParams,
  RequestFilterFromInputDTO,
  TaskFormResolutionField,
  TaskComponentContainerType,
  TaskTerminalButton,
} from '../../../../../shared/types/domain';

import { httpClient } from '../../../../../shared/services/httpClient';

export type TasksRequestResponse = {
  data: ListRow[];
  fields: FieldContainer[];
};

type ResolveTaskBodyType = {
  resolution: TaskFormResolutionField[];
};

export type OrderInfo = {
  ocData: {
    etd: null | string;
    eta: null | string;
    orderConfirmationNumber: null | string;
    orderConfirmationDate: null | string;
  };
  rmsData: {
    etd: string;
    eta: string;
    orderConfirmationNumber: string;
  };
  number: string;
  mode: OrderMode;
  currency: CurrencyCodes;
  creationDate: string;
  _id: string;
};

// TODO TaskDto !== TaskState (cм. useTaskFormContext)
// Надо доработать тип TaskComponentContainerType, чтобы отвязать типы друг от друга + TaskState надо бы "прорядить", так как не все поля нужны FormContext
// TaskDto мы вообще должны тащить из общего контракта (поместить в core)
// TODO: решить проблему с уникальностью полей с одинаковыми лейблами
// Сейчас поиск поля(field) привязано к label + caption 'Decision' у определейнной секции
// Хотелось бы использовать уникальный id для этой цели
// Вариант 1) Генерить на фронте уникальные ключи в в момент получения формы
// Вариант 2) Убедиться, что на беке поля id действительно заданы и уникальны
// Вариант 3) Использовать комбинированный подход
export type TaskDto = {
  id: string;
  name: string;
  sections: TaskComponentContainerType[];
  terminalButtons: TaskTerminalButton[];
  _id: string;
  orderId: string;
  type: string;
  order: OrderInfo;
  creationDate?: string;
  shipmentId?: string;
  expires: string;
  performer: string;
  state: string;
  role: string;
};

export async function requestTasks(
  filters: MappedFilterType,
  pagination: PaginationParams,
): Promise<TasksRequestResponse> {
  const { limit, offset } = pagination;
  const response = await httpClient({
    method: 'post',
    url: '/api/tasks',
    data: {
      filters,
      limit,
      offset,
      timezoneOffsetMinutes: new Date().getTimezoneOffset() * -1,
    },
  });

  return response.data;
}

export async function requestMyTasks(filters: MappedFilterType): Promise<TasksRequestResponse> {
  const response = await httpClient({
    method: 'post',
    url: '/api/tasks/my',
    data: {
      filters,
      timezoneOffsetMinutes: new Date().getTimezoneOffset() * -1,
    },
  });

  return response.data;
}

export async function requestFilterFromInput(
  filterName: string,
  text: string,
  abortController: AbortController,
) {
  const response = await httpClient.request<RequestFilterFromInputDTO[]>({
    method: 'get',
    url: `/api/filters/${filterName}/search?text=${encodeURIComponent(text)}`,
    signal: abortController.signal,
  });

  return response.data;
}

export async function requestTask(id: string): Promise<TaskDto> {
  const response = await httpClient({
    method: 'get',
    url: `/api/tasks/${id}`,
  });
  const mappedData = response.data;

  mappedData.sections = mappedData.sections.map((section) => {
    return {
      ...section,
      fields: section.fields.map((field) => {
        return {
          ...field,
          availableWhen:
            field.availableWhen && field.availableWhen.length > 0 ? field.availableWhen : null,
        };
      }),
    };
  });

  return mappedData;
}

export async function takeTask(id: string): Promise<boolean> {
  return new Promise((resolve, reject) => {
    httpClient({
      method: 'post',
      url: `/api/tasks/${id}/assign`,
    })
      .then(() => {
        resolve(true);
      })
      .catch(() => {
        reject(false);
      });
  });
}

export async function requestOrderChronology(orderId: string): Promise<ChronologyResponse[]> {
  const response = await httpClient({
    method: 'get',
    url: `/api/orders/${orderId}/chronology`,
  });

  function mapChronologyId(chronologyData: ChronologyResponse[]) {
    return chronologyData.map((chronology) => {
      if (chronology.id === undefined) {
        return { ...chronology, id: String(Math.random()) };
      }

      return chronology;
    });
  }

  return mapChronologyId(response.data);
}

export async function resolveTask(id: string, data: ResolveTaskBodyType): Promise<boolean> {
  try {
    await httpClient({
      method: 'post',
      url: `/api/tasks/${id}/complete`,
      data,
    });

    return true;
  } catch {
    throw false;
  }
}
