import React, { PropsWithChildren } from 'react';

import { ChevronUpIcon, ChevronDownIcon } from '@fronton/icons-react';
import { Typography } from 'fronton-react';
import { styled } from 'linaria/react';

import { DocTitleText } from './DocLayout';

type Props = PropsWithChildren & {
  isActive: boolean;
  isOpened: boolean;
  onToggle: () => void;
};

const Wrap = styled.div<Omit<Props, 'onToggle'>>`
  display: flex;
  align-items: center;
  cursor: ${({ isActive }) => (isActive ? 'pointer' : 'text')};
  pointer-events: ${({ isActive }) => (isActive ? 'auto' : 'none')};

  svg {
    margin-left: var(--space-100);
  }
`;

export const FoldableTitle = (props: Props) => {
  const { children, onToggle, isOpened, isActive } = props;

  return (
    <Wrap onClick={onToggle} isOpened={isOpened} isActive={isActive}>
      <Typography variant="h2">
        <DocTitleText>{children}</DocTitleText>
      </Typography>
      {isActive &&
        (isOpened ? (
          <ChevronUpIcon type="fill" color="success-dark" size="l" />
        ) : (
          <ChevronDownIcon type="fill" color="success-dark" size="l" />
        ))}
    </Wrap>
  );
};
