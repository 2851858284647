import React from 'react';

import { CurrencyCodes, OrderMode } from '@lmru-bpms-ved/core';

import { getMonth } from '@src/app/utilities/dates';

import styles from './index.css';

type Props = {
  mode: OrderMode;
  currency: CurrencyCodes;
  orderNumber: string;
  OCNumber: string;
  orderDate: string;
  orderConfirmationDate: string;
  department: string;
  supplier: string;
  location: string;
  ETD: string;
  ETA: string;
};

const boldText = (text: string) => {
  return <span className={styles.boldText}>{text}</span>;
};

export const OrderInfoBar = ({
  orderNumber,
  mode,
  currency,
  OCNumber,
  orderDate,
  orderConfirmationDate,
  department,
  supplier,
  location,
  ETA,
  ETD,
}: Props) => {
  const orderDateFormated = new Date(orderDate);
  const orderConfirmationDateFormated = new Date(orderConfirmationDate);
  const ETDFormated = new Date(ETD);
  const ETAFormated = new Date(ETA);

  const displayDate = (date: Date) => {
    return `${date.getDate()} ${getMonth(date)} ${date.getFullYear()}`;
  };

  return (
    <section className={styles.container} data-testid="task-status-testid">
      <span className={styles.spanText}>No.</span>
      {boldText(`${orderNumber},`)}
      <span className={styles.spanText}>{displayDate(orderDateFormated)} </span>
      <span className={styles.spanText}>·</span>
      <span className={styles.spanText}>OC/PI</span>
      {OCNumber !== 'unknown' ? (
        boldText(`${OCNumber},`)
      ) : (
        <span style={{ opacity: 0.5 }} className={styles.spanText}>
          is unknown yet
        </span>
      )}
      {orderConfirmationDate !== 'unknown' && (
        <span className={styles.spanText}>{displayDate(orderConfirmationDateFormated)} </span>
      )}
      <span className={styles.spanText}>·</span>
      {boldText(department)}
      <span className={styles.spanText}>department</span>
      <span className={styles.spanText}>·</span>
      <div className={styles.supplier} title={supplier}>
        <span className={styles.spanText}>{supplier}</span>
      </div>
      <span className={styles.spanText}>·</span>
      <span className={styles.warehouse} title={location}>
        {boldText(location)}
      </span>
      <span className={styles.spanText}>·</span>
      {boldText(`${ETDFormated.getDate()} ${getMonth(ETDFormated)} ${ETDFormated.getFullYear()}`)}
      <span className={styles.spanText}>ETD</span>
      <span className={styles.spanText}>/</span>
      {boldText(`${ETAFormated.getDate()} ${getMonth(ETAFormated)} ${ETAFormated.getFullYear()}`)}
      <span className={styles.spanText}>ETA</span>
      <span className={styles.spanText}>·</span>
      <span className={styles.spanText} title={mode}>
        {mode}
      </span>
      <span className={styles.spanText}>·</span>
      <span className={styles.spanText} title={currency}>
        {currency}
      </span>
    </section>
  );
};
