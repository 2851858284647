import React from 'react';

import { XMark } from '@src/shared/components/ImagePreview/icons';

import styles from './index.css';

type Props = {
  appliedOptionsAmount: number;
  onClick: React.MouseEventHandler<HTMLDivElement>;
};

export const AppliedOptionsCount: React.FC<Props> = ({ appliedOptionsAmount, onClick }) => {
  const amountText = `${appliedOptionsAmount} element${
    appliedOptionsAmount > 1 ? 's' : ''
  } selected`;

  return (
    <div className={styles.searchResultFilters} onClick={onClick}>
      <div>{amountText}</div>
      <div>
        <XMark width="12" height="12" />
      </div>
    </div>
  );
};
