import { UserRole } from '@src/shared/services/auth';

import { RootRoute, rootRoutesConfig } from './config';

export const getHasAccessToRoute = (userRole: UserRole | undefined, rootRoute: string): boolean => {
  const routeConfig = rootRoutesConfig[rootRoute as RootRoute];

  if (routeConfig) {
    const { roleAccessWhitelist } = routeConfig;

    return !roleAccessWhitelist || (!!userRole && roleAccessWhitelist.includes(userRole));
  }

  return false;
};

type NavTab = {
  path: string;
  label: string;
};

export const getNavTabsForRole = (userRole: UserRole | undefined): NavTab[] =>
  Object.entries(rootRoutesConfig).reduce((navTabs, [path, { navLabel }]) => {
    if (navLabel && getHasAccessToRoute(userRole, path)) {
      navTabs.push({ path, label: navLabel });
    }

    return navTabs;
  }, [] as NavTab[]);

export const getDefaultRouteForRole = (userRole: UserRole | undefined): RootRoute => {
  if (!userRole) {
    return RootRoute.helpAndSupport;
  }

  const defaultRoute = Object.entries(rootRoutesConfig).find(([path, { defaultForRole }]) =>
    defaultForRole?.includes(userRole),
  )?.[0] as RootRoute | undefined;

  return defaultRoute ?? RootRoute.helpAndSupport;
};
