import { Icon } from 'fronton-react';
import { styled } from 'linaria/lib/react';
import { Link } from 'react-router-dom';

export const Header = styled.header`
  position: fixed;
  z-index: 1000;
  top: 0;
  width: 100%;
  background: var(--background-primary);
  box-shadow: var(--box-shadow-m);
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 48px;
  padding: 0 32px;
`;

export const AppLogo = styled(Icon)`
  margin-right: 24px;
`;

export const NavTab = styled.div`
  padding: 14px 12px;
  color: var(--text-secondary);
  text-transform: capitalize;
  cursor: pointer;
  user-select: none;

  &:active {
    outline: 1px solid var(--control-secondary);
    background-color: var(--control-secondary);
    color: var(--text-primary);
  }

  &:hover {
    color: var(--text-primary);
  }
`;

export const Nav = styled.nav`
  display: flex;
  flex-direction: row;

  a.active ${NavTab} {
    outline: 1px solid var(--control-minor);
    background-color: var(--control-minor);
    color: var(--text-primary);
  }
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;

  > span {
    margin-right: 8px;
  }
`;

export const SignOutButton = styled(Link)`
  width: 24px;
  height: 24px;
  margin-left: 8px;
`;
