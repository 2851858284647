import { sanitizeObject } from '@src/app/utilities/sanitizeObject';

export function getRedirectAuthUrl(token?: string) {
  const authBaseUrl = sanitizeObject(localStorage.getItem('redirectAuthURL'));

  if (authBaseUrl) {
    return {
      hasFetched: true,
      REDIRECT_URL: `${authBaseUrl}/connect/authorize?client_secret=lm-bpms-ved&client_id=lm-bpms-ved&scope=lm.bpms.ved.scope%20openid%20profile%20email%20offline_access&redirect_uri=${sanitizeObject(window.location.origin)}/login&response_type=code`,
      REDIRECT_LOGOUT_URL: `${authBaseUrl}/connect/customendsession?id_token_hint=${
        token || ''
      }&post_logout_redirect_uri=${sanitizeObject(window.location.origin)}/logout`,
    };
  }

  return {
    hasFetched: false,
    REDIRECT_URL: `https://t-legoauth-as02.hq.ru.corp.leroymerlin.com/connect/authorize?client_secret=lm-bpms-ved&client_id=lm-bpms-ved&scope=lm.bpms.ved.scope%20openid%20profile%20email%20offline_access&redirect_uri=${sanitizeObject(window.location.origin)}/login&response_type=code`,
    REDIRECT_LOGOUT_URL: `https://t-legoauth-as02.hq.ru.corp.leroymerlin.com/connect/customendsession?id_token_hint=${
      token || ''
    }&post_logout_redirect_uri=${sanitizeObject(window.location.origin)}`,
  };
}
