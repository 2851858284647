// @flow strict
import React from 'react';
import type { FieldContainer } from '../../types';
import styles from './index.css';

interface INoDropdownComponentProps {
    children: JSX.Element;
    title?: string;
}

function NoDropdownComponent({ children, title }: INoDropdownComponentProps) {
    return (
        <div data-testid="no-dropdown-component-testid" className={styles.title} style={{ cursor: 'default' }}>
            <span title={title} className={styles.dotted}>
                {children}
            </span>
        </div>
    );
}

export { NoDropdownComponent };
