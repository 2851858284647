/* eslint-disable import/no-import-module-exports */
/* eslint-disable unicorn/prefer-module */
import 'mp-ui-components/lib/styles/index.css';
import 'mp-ui-components/lib/styles/fonts.css';

import React from 'react';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import { createRoot } from 'react-dom/client';

import './globals.css';

import { AppRoot } from './AppRoot';
import { APP_ENV } from './env';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

const container = document.querySelector('#root');

if (container) {
  const root = createRoot(container);

  root.render(<AppRoot />);

  if (APP_ENV.isLocalDev && module.hot) {
    module.hot.accept('.', () => {
      // eslint-disable-next-line import/no-self-import, global-require, @typescript-eslint/no-var-requires
      const NextApp = require('.').default;

      root.render(<NextApp />);
    });
  }
} else {
  throw new Error('Cannot find root');
}
