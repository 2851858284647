import { getMinutesBetweenDates, getMinutesFromDeadlineInfo } from '@src/app/utilities/dates';

import {
  TaskComponentContainerType,
  TaskComponentContainerField,
} from '../../../../shared/types/domain';

export function getSelectedConditions(sections: TaskComponentContainerType[]) {
  const initialConditions: string[] = [];

  for (const section of sections) {
    if (section.caption === 'Decision') {
      for (const field of section.fields) {
        if (field.type === 'select' && field.value && !initialConditions.includes(field.value)) {
          initialConditions.push(field.value);
        }
      }
    }
  }

  return initialConditions;
}

export function getTaskComponentContainerResponsibleValue(field: TaskComponentContainerField) {
  switch (field.type) {
    case 'textInput':
      return field.value;

    case 'comment':
      return field.value;

    case 'select':
      return field.selected;

    case 'checkbox':
      return field.selected;

    case 'decisionTable':
      // eslint-disable-next-line array-callback-return
      return field.data.map((item) => {
        if (item.schemeType && item.schemeType.dropdownScheme) {
          return {
            applicationId: item.applicationId,
            value: item.schemeType.dropdownScheme.value,
            isBlocking: item.schemeType.dropdownScheme.isBlocking,
          };
        }
      });

    case 'datePicker':
      return field.value;

    case 'attachmentDoc':
      return field.uploadedFiles;

    case 'attachmentScreenshot':
      return field.uploadedFiles;

    default: {
      return '';
    }
  }
}

export const getMinutesLeft = (expires) => {
  if (!expires) {
    return null;
  }

  let result;

  if (expires.isDelayed) {
    result = getMinutesFromDeadlineInfo(expires, true);
  } else if (expires.deadline) {
    result = getMinutesBetweenDates(new Date(expires.deadline), new Date(), true);
  } else {
    result = getMinutesBetweenDates(new Date(expires), new Date(), true);

    return {
      remaining: result.minutesToLetter,
      type: result.letter,
      isNegative: result.negative,
    };
  }

  return {
    remaining: result.minutesToLetter,
    type: result.letter,
    isNegative: expires.isDelayed,
  };
};
