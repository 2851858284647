// @flow strict
import React from 'react';
import dayjs from 'dayjs';
import { Dropdown, Calendar as CalendarSingle } from 'mp-ui-components';
import type { Period } from 'mp-ui-components';
import styles from './index.css';

const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

type Props = {|
    onSetDate?: Date => void,
    value?: Date,
    disabledTime?: 'past' | 'future',
    disabledPeriods?: Period[],
    expandRestrictedDays?: string
|};

function Calendar({ onSetDate, value, disabledTime, expandRestrictedDays, disabledPeriods }: Props) {
    const [selectedDate, setSelectedDate] = React.useState(value ? value : new Date());
    const [viewedMonth, setViewedMonth] = React.useState(new Date());
    const [localDate, setLocalDate] = React.useState('');
    const [isOpen, setOpen] = React.useState(false);

    React.useEffect(() => {
        if (selectedDate) {
            handleDayClick(selectedDate, true);
        } else {
            handleDayClick(new Date(), true);
        }
    }, []);

    function handleDayClick(date: Date, init?: boolean) {
        if (onSetDate && !init) onSetDate(date);
        setSelectedDate(date);
        setLocalDate(dayjs(date).format('DD.MM.YYYY'));
        if (!init) {
            setOpen(false);
        }
    }

    function handleSwitchMonthClick(inc: number) {
        setViewedMonth(old => {
            return new Date(old.setMonth(old.getMonth() + inc));
        });
    }

    function onHandleDropdownChange(state: boolean) {
        setOpen(state);
    }

    function getDisabledPeriod() {
        if (disabledTime) {
            const expandedDays = Number(expandRestrictedDays || 0);
            if (disabledTime === 'past') {
                const tommorowDate = new Date(new Date().setDate(new Date().getDate() - (1 + expandedDays)));
                return [{ from: null, to: tommorowDate }];
            }
            if (disabledTime === 'future') {
                const yesterdayDate = new Date(new Date().setDate(new Date().getDate() + (1 + expandedDays)));
                return [{ from: yesterdayDate, to: null }];
            }
        }
        return;
    }

    return (
        <div>
            <Dropdown isOpen={isOpen} onChange={onHandleDropdownChange} className={styles.container}>
                <Dropdown.Trigger>
                    <div className={styles.calendarWrap}>
                        <input disabled={true} type="text" className={styles.calendarInput} value={localDate} />
                        <div style={{ marginRight: 5 }}>
                            <CalendarIcon />
                        </div>
                    </div>
                </Dropdown.Trigger>
                <Dropdown.Content ariseDirection="bottom" className={styles.content}>
                    <div className={styles.calednarSwitch}>
                        <span
                            onClick={() => {
                                handleSwitchMonthClick(-1);
                            }}
                            className={styles.calendarSwitchMonth}
                            style={{ left: '1.25rem' }}
                        >
                            prev
                        </span>
                        <div>
                            <div className={styles.calendarYear}>{viewedMonth.getFullYear()}</div>
                            {monthNames[viewedMonth.getMonth()]}
                        </div>
                        <span
                            onClick={() => {
                                handleSwitchMonthClick(1);
                            }}
                            className={styles.calendarSwitchMonth}
                            style={{ right: '1.25rem' }}
                        >
                            next
                        </span>
                    </div>
                    <CalendarSingle
                        disabledPeriods={disabledPeriods ? disabledPeriods : getDisabledPeriod()}
                        showedDate={viewedMonth}
                        from={selectedDate}
                        to={selectedDate}
                        onClick={handleDayClick}
                    />
                </Dropdown.Content>
            </Dropdown>
        </div>
    );
}

function CalendarIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="16"
            height="16"
            viewBox="0 0 16 16"
        >
            <defs>
                <path
                    id="acalendar"
                    d="M3.926.06A.338.338 0 003.59.4v.94h-.47a2.03 2.03 0 00-2.027 2.027v10.546a2.03 2.03 0 002.027 2.027h10.032a2.03 2.03 0 002.027-2.027V3.366a2.03 2.03 0 00-2.027-2.027h-.47V.42a.338.338 0 10-.675 0v.918H4.264v-.94A.338.338 0 003.926.06zm10.576 13.852c0 .745-.606 1.351-1.35 1.351H3.118a1.353 1.353 0 01-1.351-1.351V4.82h12.734v9.092zM12.006 2.014v.918a.338.338 0 10.676 0v-.918h.47c.744 0 1.35.607 1.35 1.352v.778H1.768v-.778c0-.745.606-1.352 1.351-1.352h.47v.896a.338.338 0 10.675 0v-.896h7.742z"
                ></path>
            </defs>
            <g fill="none" fillRule="evenodd">
                <mask id="bcalendar" fill="#fff">
                    <use xlinkHref="#acalendar"></use>
                </mask>
                <g fill="#000" fillOpacity="0.38" fillRule="nonzero" mask="url(#bcalendar)">
                    <path d="M0 0H16V16H0z"></path>
                </g>
            </g>
        </svg>
    );
}

export default Calendar;
