import React from 'react';

import { BasePageLayout } from '@src/shared/components/Layout';

import { STSubReportType } from '../../enums/STSubReportType';
import { STReportDownloadWidget } from '../../widgets/STReportDownloadWidget';
import { STSubReportWidget } from '../../widgets/STSubReportWidget';
import { useSTSubReports } from '../../widgets/STSubReportWidget/hooks/useSTSubReports';
import { i18n } from './i18n';
import { prepareForwarderReportsData } from './utils';

export const ForwarderReportsPage = () => {
  const { data: reports, isLoading, isError } = useSTSubReports();

  return (
    <BasePageLayout
      title={i18n.pageTitle}
      errorTitle={i18n.pageLoadFailedTitle}
      isLoading={isLoading}
      isError={isError}
    >
      <STReportDownloadWidget
        latestDateOfPlanEtaPodInFu={reports?.latestDateOfPlanEtaPodInFu}
        latestDateUpdateShipmentTracking={reports?.latestDateUpdateShipmentTracking}
      />
      <STSubReportWidget
        title={i18n.uploadBlockTitle}
        docType={STSubReportType.ForwarderReports}
        helperInfoText={i18n.uploadBlockHint}
        noDataText={i18n.uploadBlockPlaceholder}
        reports={prepareForwarderReportsData(reports)}
        canUploadReports
      />
    </BasePageLayout>
  );
};
