export const i18n = {
  idc: {
    navLabel: 'IDC',
    pageTitle: 'Import Data Connector',
  },
  fileUploads: {
    errorsTitle: 'Uploading errors',
    errors: {
      fileTooBig: 'File is too big, should be less than 100 mb:',
      fileTypeWrong: 'Wrong file format. Upload xls, xlsx instead:',
      fileNameWrong: 'File name is incorrect.',
      unknownError: 'Technical error. Try again:',
    },
  },
  buttons: {
    download: 'Download',
  },
};
