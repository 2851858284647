import React from 'react';

import { DirectDocWidget } from './DirectDocWidget/DirectDocWidget';
import { DirectDocType } from './enums';
import { i18n } from './i18n';
import { BasePageLayout } from '../../../../shared/components/Layout/PageLayout';

const uploadBlocks = [DirectDocType.pi, DirectDocType.arf, DirectDocType.invoice];

export const DirectModeDocsPage = () => (
  <BasePageLayout title={i18n.pageTitle}>
    {uploadBlocks.map((docType) => (
      <DirectDocWidget key={docType} docType={docType} />
    ))}
  </BasePageLayout>
);
