import React from 'react';

import { TableFieldType } from '@src/shared/components/Table/_deprecated/Table/types';

import { TextFilter, DateFilter } from '../../../Filter';
import { HeaderCellProps } from '../types';
import { renderString } from './renderText/renderText';

// TODO: Rewrite to functions
const renderersMap = {
  [TableFieldType.text]: TextFilter,
  [TableFieldType.parsingState]: TextFilter,
  [TableFieldType.date]: DateFilter,
  [TableFieldType.latestFiles]: DateFilter,
  default: (props) => renderString(props),
};

export const renderFilter = (props: HeaderCellProps) => {
  const Renderer = renderersMap[props.field.type] ?? renderersMap.default;

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Renderer {...props} />;
};
