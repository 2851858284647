import React from 'react';

import { ErrorReloadableComponent } from '@src/shared/components/Error';
import Preloader from '@src/shared/components/Preloader';
import { Table, TableName } from '@src/shared/components/Table/_deprecated/Table';

import { ActionError } from './hooks/useActionButtons/ActionError';
import { usePageControls } from './hooks/usePageControls';
import { useStickyTableHeader } from './hooks/useStickyTableHeader';

import styles from './styles.module.scss';

const TABLE_HEADER_SELECTOR = 'thead';

export const OrdersList: React.FC = () => {
  const {
    tableData,
    isTableDataLoading,
    isTableDataError,
    enabledFilters,
    paginatorNode,
    actionButtonsNode,
    onTableEvent,
  } = usePageControls();

  const { outerContainerRef, dynamicStickyContentRef } =
    useStickyTableHeader(TABLE_HEADER_SELECTOR);

  if (isTableDataLoading) {
    return <Preloader />;
  }

  if (isTableDataError) {
    return (
      <ErrorReloadableComponent
        theme="white"
        width="s"
        margin={30}
        title="Order list can't be loaded"
        status={isTableDataError}
      />
    );
  }

  const isTableLoaded = !isTableDataLoading && tableData;

  return (
    <div ref={outerContainerRef} className={styles.page}>
      <div
        ref={dynamicStickyContentRef}
        className={styles.errorBar}
        id={ActionError.$portalRootId}
      />

      <div className={styles.content}>
        {isTableLoaded && (
          <Table
            className={styles.tableComponent}
            noResultsMessage="No orders"
            tableName={TableName.Orders}
            tableData={tableData}
            allEnabledTableFilters={enabledFilters}
            actionButtonsNode={actionButtonsNode}
            paginatorNode={paginatorNode}
            onTableEvent={onTableEvent}
          />
        )}
      </div>
    </div>
  );
};
