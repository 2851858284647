import React from 'react';

import { useMutation } from 'react-query';

import { OrdersApi } from '@src/modules/orders/api/ordersApi';
import { calculateTableData } from '@src/modules/orders/api/utils/calculateTableData/calculateTableData';
import { BrowserStorage } from '@src/modules/orders/browserStorage';
import { CommonTable } from '@src/shared/components/Table/_deprecated/Table';
import { mapSelectedFilters } from '@src/shared/utils/mappers/mapTableDataResponseDto';
import { scrollToTop } from '@src/shared/utils/scroll/scrollToTop';

import { useTableEvents } from './useTableEvents';
import { useTablePagination, PAGINATION_PAGE_SIZE } from './useTablePagination';

export const useTable = () => {
  const [tableData, setTableData] = React.useState<CommonTable>();

  const [enabledFilters, setEnabledFilters] = React.useState(BrowserStorage.getSavedFilters());

  const { paginatorState, setPaginatorState, changeTablePage } = useTablePagination();

  const triggerTableUpdate = React.useCallback(
    (newTableData?: CommonTable) => {
      if (newTableData) {
        setTableData(calculateTableData(newTableData));

        return;
      }

      if (tableData) {
        setTableData(calculateTableData(tableData));
      }
    },

    [tableData, setTableData],
  );

  const onTableEvent = useTableEvents({
    tableData,
    triggerTableUpdate,
    setEnabledFilters,
    changeTablePage,
  });

  // TODO - Rewrite to PaginatorQuery
  const fetchTableData = useMutation<
    CommonTable,
    { response: { status: string } },
    { useScrollToTop?: boolean }
  >({
    mutationKey: OrdersApi.orders.get.$queryId,

    mutationFn: () => {
      const paginationSettings = {
        // +1 to understand we have next page immediately
        limit: paginatorState.pageSize + 1,
        offset: paginatorState.page * paginatorState.pageSize,
      };

      const filters = mapSelectedFilters(enabledFilters);

      return OrdersApi.orders.get(filters, paginationSettings);
    },

    onSuccess: (receivedTableData, { useScrollToTop }) => {
      const isNextPageAvailable = receivedTableData.rows.length > PAGINATION_PAGE_SIZE;

      for (const field of receivedTableData.fields) {
        field.getFilters = OrdersApi.orders.getFilters;
      }

      setPaginatorState((prevState) => {
        return { ...prevState, isNextPageAvailable };
      });

      triggerTableUpdate(
        isNextPageAvailable
          ? { ...receivedTableData, rows: receivedTableData.rows.slice(0, -1) }
          : receivedTableData,
      );

      if (useScrollToTop) {
        scrollToTop();
      }
    },
  });

  React.useEffect(() => {
    fetchTableData.mutate({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabledFilters, paginatorState.page]);

  return {
    tableData,
    paginatorState,
    enabledFilters,
    fetchTableData,
    changeTablePage,
    triggerTableUpdate,
    onTableEvent,
  };
};
