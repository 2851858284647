// @flow strict
import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import styles from './index.scss';
import dayjs from 'dayjs';
import {
  Input,
  Button,
  RadioGroup,
  FileButton,
  FormRow,
  SuggestSelect,
  Checkbox,
} from 'mp-ui-components';
import { ImageBox } from '../ImageBox';
import CalendarInput from '@src/shared/components/Calendar';

import { uploadFilesForOrder } from '@src/modules/orders/api/methods/uploadFilesForOrder';
import type {
  LinkProp,
  OnUpdateFileFieldType,
  TTaskTableField,
  TTaskTableData,
  TAtlasCodeTable,
  TDecisionTableData,
} from '../../types';
import type { Period } from 'mp-ui-components';
import mapLinks from './enums/links';

import { StaticDocsDownload } from '@src/shared/components/DocDownload';
import { Divider } from 'mp-ui-components';
import { FileService } from '@src/shared/services/files';
import { uploadFilesForShipment } from '@src/modules/shipments/api/uploadFilesForShipment';

import { SetDisplayImageModal } from '../ImageBox/types';
import { DocumentTypes } from '@lmru-bpms-ved/core';

export const TextComponent = ({ text, isBold }: { text: string, isBold?: boolean }) => {
  if (isBold !== undefined && isBold === true)
    return <div style={{ fontWeight: 'bold' }} dangerouslySetInnerHTML={{ __html: text }} />;
  return <div dangerouslySetInnerHTML={{ __html: text }} />;
};

export const DateLabelComponent = ({ text }: { text: string }) => {
  const date = dayjs(text.split('T')[0]);

  return <div>{date.isValid() ? date.format('DD.MM.YYYY') : text}</div>;
};

type LinkComponentProps = {
  links: LinkProp[],
  orderId?: string,
  shipmentId?: string,
};

export const LinkListComponent = ({ links, orderId, shipmentId }: LinkComponentProps) => {
  if (!Array.isArray(links) || links.some((link) => !link.url || !link.text)) return null;

  const getUrl = (url: string, orderId?: string, shipmentId?: string) => {
    const mapResult = mapLinks(url, orderId, shipmentId);
    if (mapResult) return mapResult;
    return url;
  };

  return (
    <div>
      {links.map((link) => (
        <div key={link.url}>
          <a href={getUrl(link.url, orderId, shipmentId)}>
            <span className={styles.linkLabel}>{link.text}</span>
          </a>
        </div>
      ))}
    </div>
  );
};

type CheckupContainerFieldFile = {|
  originalFileName?: string,
  name: string,
  url: string,
|};

export function DocListComponent({
  text,
  zipUrl,
  docs,
}: {
  text: string,
  zipUrl: string,
  docs: CheckupContainerFieldFile[],
}) {
  return (
    <React.Fragment>
      {text && <div className={styles.rowValueHigher}>{text}</div>}
      <StaticDocsDownload files={docs} />
      {docs.length > 1 && (
        <a
          className={styles.buttonDownloadAll}
          href={zipUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button theme="secondary">Download all</Button>
        </a>
      )}
    </React.Fragment>
  );
}

type RadioOption = {|
  id: string,
  text: string,
  isBlocking?: boolean,
|};

export function RadioComponent({
  name,
  selectRadio,
  options,
  selected,
}: {
  name?: string,
  selectRadio: (empty) => typeof undefined,
  options: RadioOption[],
  selected: string,
}) {
  const mapOptions = options.map((option, index) => {
    return {
      id: `${option.id}`,
      name: option.text,
      value: `${option.id}`,
    };
  });
  return (
    <RadioGroup name={name} onChange={(e) => selectRadio(e)} items={mapOptions} value={selected} />
  );
}

export { AttachmentDocComponent } from './AttachmentDocComponent';

type UploadResult = {|
  name: string,
  url?: string,
|};

export function AttachmentScreenshotComponent({
  serviceOfUploading = 'default',
  orderId,
  shipmentId,
  orderNumber,
  isUploading,
  mappedFiles,
  screenshotHandler,
  text,
  files,
  setDisplayModal,
}: {
  serviceOfUploading?: 'shipments' | 'default',
  orderId: string,
  shipmentId?: string,
  orderNumber: string,
  isUploading?: boolean,
  mappedFiles: UploadResult[],
  screenshotHandler: (OnUpdateFileFieldType) => typeof undefined,
  text?: string,
  files: FileList | null,
  setDisplayModal?: SetDisplayImageModal,
}) {
  const [failedStatus, setFailedStatus] = React.useState(null);
  const prepareUpload = (files) => {
    setFailedStatus(null);
    if (files) {
      const update = { files: files, uploading: true };
      screenshotHandler(update);
      if (serviceOfUploading === 'default') {
        uploadFilesForOrder(orderId, orderNumber, files, 'screenshot')
          .then((response) => {
            const update = { files: response, uploadedFiles: response, uploading: false };
            screenshotHandler(update);
          })
          .catch((err) => {
            setFailedStatus(err.message);
            console.error(err);
            const updateFailed = { files: null, uploading: false };
            screenshotHandler(updateFailed);
          });
      } else {
        uploadFilesForShipment(files, shipmentId ?? 'null', 'screenshot')
          .then((response) => {
            const update = { uploadedFiles: response, uploading: false };
            screenshotHandler(update);
          })
          .catch((err) => {
            console.error(err);
            const updateFailed = { files: null, uploading: false };
            screenshotHandler(updateFailed);
            setFailedStatus(err.message);
          });
      }
    }
  };

  function onDragOverHandler(e) {
    e.preventDefault();
  }

  function onDropFileHandler(e) {
    e.preventDefault();
    if (e.dataTransfer.items) {
      const files = [];
      for (let i = 0; i < e.dataTransfer.items.length; i++) {
        files.push(e.dataTransfer.items[i].getAsFile());
      }
      if (files && files.length !== 0) {
        prepareUpload(files);
      }
    }
  }

  const { data: docTypesConfig } = FileService.useDocTypesQuery();

  return (
    <div className={cn(styles.screenshotsContainer, styles.rowValueHigher)}>
      <div className={styles.sceenshotsBtnWithLabel}>
        <div draggable onDrop={onDropFileHandler} onDragOver={onDragOverHandler}>
          <FileButton
            fileTypes={docTypesConfig?.[DocumentTypes.screenshot]?.extensions ?? []}
            isMultiple={true}
            isPending={isUploading}
            theme="secondary"
            onChange={(e) => prepareUpload(e)}
          >
            {text || 'Browse...'}
          </FileButton>
        </div>
        {!files && !failedStatus && <div className={styles.browsedFile}>{'No screenshots'}</div>}
        {failedStatus && <div className={styles.fileErrorLabelScreenshot}>{failedStatus}</div>}
      </div>
      {files && !failedStatus && <ImageBox setDisplayModal={setDisplayModal} files={mappedFiles} />}
    </div>
  );
}

const DEFAULT_TEXTAREA_HEIGHT = 160;
const MAX_TEXTAREA_HEIGHT = 600;

export function TextAreaComponent({
  name,
  minHeight,
  placeholder,
  value,
  textAreaHandler,
}: {
  name?: string,
  minHeight?: number,
  placeholder?: string,
  value: string | typeof undefined,
  textAreaHandler: (string) => void,
}) {
  const textAreaRef = useRef<HTMLDivElement | null>(null);
  const [textAreaHeight, setTextareaHeight] = useState<number>(
    minHeight || DEFAULT_TEXTAREA_HEIGHT,
  );

  useEffect(() => {
    if (textAreaRef.current) {
      setTextareaHeight(textAreaRef.current.scrollHeight);
    }
  }, [value]);

  const onChangeHandler = (e) => {
    textAreaHandler(e.target.value);
  };

  return (
    <>
      <textarea
        name={name}
        placeholder={placeholder || ''}
        className={cn(
          styles.textArea,
          styles.rowValueHigher,
          textAreaHeight >= MAX_TEXTAREA_HEIGHT && styles.textAreaWithScroll,
        )}
        style={{
          height: `${textAreaHeight}px`,
          minHeight: `${minHeight || DEFAULT_TEXTAREA_HEIGHT}px`,
        }}
        onChange={onChangeHandler}
        value={value || ''}
      ></textarea>
      <div
        role="textbox"
        suppressContentEditableWarning
        contentEditable
        ref={textAreaRef}
        placeholder={placeholder || ''}
        className={cn(styles.hiddenTextArea, styles.rowValueHigher)}
        style={minHeight ? { minHeight: `${minHeight}px` } : {}}
      >
        {value || ''}
      </div>
    </>
  );
}

export function InputComponent({
  name,
  placeholder,
  value,
  width,
  inputHandler,
  autoFocus,
  onBlur,
  isInvalid,
}: {
  name?: string,
  placeholder?: string,
  value: string | typeof undefined,
  inputHandler: (string) => void,
  autoFocus?: boolean,
  onBlur?: () => void,
  isInvalid?: boolean,
  width?: number,
}) {
  return (
    <Input
      name={name}
      className={isInvalid && styles.invalidInput}
      onBlur={onBlur}
      autoFocus
      placeholder={placeholder || ''}
      onChange={(e) => inputHandler(e.target.value)}
      value={value}
      style={width && { width: `${width}px` }}
    />
  );
}

export function DatePickerComponent({
  value,
  disabledTime,
  expandRestrictedDays,
  disabledPeriods,
  datePickerHandler,
}: {
  value: Date,
  disabledTime?: 'past' | 'future',
  disabledPeriods?: Period[],
  expandRestrictedDays?: string,
  datePickerHandler: (Date) => void,
}) {
  return (
    <div className={styles.rowValueHigher}>
      <CalendarInput
        expandRestrictedDays={expandRestrictedDays}
        disabledPeriods={disabledPeriods}
        disabledTime={disabledTime}
        value={value}
        onSetDate={(date) => {
          datePickerHandler(date);
        }}
      />
    </div>
  );
}

export function TaskTableComponent({
  width,
  data,
  fields,
  caption,
}: {
  width?: string,
  data: TTaskTableData[],
  fields: TTaskTableField[],
  caption: string,
}) {
  const getShortDescription = (text: string) => {
    if (text.length > 80) {
      return <span title={text}>{text.substring(0, 80) + '...'}</span>;
    } else {
      return <span>{text}</span>;
    }
  };
  const renderCell = (fieldName: string, value: number | string | TAtlasCodeTable[]) => {
    if (fieldName === 'goodsDescription' && typeof value === 'string') {
      return getShortDescription(value);
    }
    if (typeof value === 'string' || typeof value === 'number') {
      return value;
    }

    const addCommaOrEmpty = (index: number) => {
      if (index + 1 === value.length) return '';
      else return ', ';
    };

    if (value.length) {
      let result = [];
      value.forEach((atlasCode, index) => {
        const atlasCodeSplitted = atlasCode.value.split('/');
        if (atlasCodeSplitted.length > 1) {
          if (atlasCode.isBold) {
            result.push(
              <React.Fragment key={atlasCodeSplitted[0]}>
                <span>
                  <b>{atlasCodeSplitted[0]}</b>
                </span>
                <span>
                  /{atlasCodeSplitted[1]}
                  {addCommaOrEmpty(index)}
                </span>
              </React.Fragment>,
            );
          } else {
            result.push(
              <React.Fragment key={atlasCodeSplitted[0]}>
                <span>{atlasCodeSplitted[0]}</span>
                <span>
                  /{atlasCodeSplitted[1]}
                  {addCommaOrEmpty(index)}
                </span>
              </React.Fragment>,
            );
          }
        } else {
          result.push(
            <span key={atlasCodeSplitted[0]}>
              {atlasCodeSplitted[0]}
              {addCommaOrEmpty(index)}
            </span>,
          );
        }
      });
      return result;
    }
  };

  return (
    <div className={styles.taskTableContainer}>
      <div className={styles.tableCaption}>{caption}</div>
      <Divider />
      <table className={styles.tableContainer} width={width}>
        <thead>
          <tr className={styles.trHead}>
            {fields &&
              fields.map((field, index) => (
                <th
                  className={cn(styles.thHeading, styles['th-' + field.name])}
                  key={`table-heading-${field.name}-${index}`}
                >
                  {field.displayName}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, index) => (
              <tr className={styles.tableRow} key={`table-row-${index}`}>
                {fields &&
                  fields.map((field, index) => (
                    <td
                      key={`table-cell-${index}`}
                      className={cn(styles.tableTdCell, styles['td-' + field.name])}
                    >
                      {renderCell(field.name, item[field.name])}
                    </td>
                  ))}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

type SelectOptions = {|
  id: string,
  name: string,
|};

export function DecisionTableComponent({
  width,
  data,
  fields,
  caption,
  onSelect,
}: {
  width?: string,
  data: TDecisionTableData[],
  fields: TTaskTableField[],
  caption: string,
  onSelect: (applicationId: string, value: string) => void,
}) {
  const getShortDescription = (text: string) => {
    if (text.length > 80) {
      return <span title={text}>{text.substring(0, 80) + '...'}</span>;
    } else {
      return <span>{text}</span>;
    }
  };
  const renderCell = (fieldName: string, value: number | string | TAtlasCodeTable[]) => {
    if (fieldName === 'goodsDescription' && typeof value === 'string') {
      return getShortDescription(value);
    }
    if (typeof value === 'string' || typeof value === 'number') {
      return value;
    }

    const addCommaOrEmpty = (index: number) => {
      if (index + 1 === value.length) return '';
      else return ', ';
    };

    if (value.length) {
      let result = [];
      value.forEach((atlasCode, index) => {
        const atlasCodeSplitted = atlasCode.value.split('/');
        if (atlasCodeSplitted.length > 1) {
          if (atlasCode.isBold) {
            result.push(
              <React.Fragment key={atlasCodeSplitted[0]}>
                <span>
                  <b>{atlasCodeSplitted[0]}</b>
                </span>
                <span>
                  /{atlasCodeSplitted[1]}
                  {addCommaOrEmpty(index)}
                </span>
              </React.Fragment>,
            );
          } else {
            result.push(
              <React.Fragment key={atlasCodeSplitted[0]}>
                <span>{atlasCodeSplitted[0]}</span>
                <span>
                  /{atlasCodeSplitted[1]}
                  {addCommaOrEmpty(index)}
                </span>
              </React.Fragment>,
            );
          }
        } else {
          result.push(
            <span key={atlasCodeSplitted[0]}>
              {atlasCodeSplitted[0]}
              {addCommaOrEmpty(index)}
            </span>,
          );
        }
      });
      return result;
    }
  };

  const renderDropdown = ({
    isHeader,
    fieldName,
    fieldDisplayName,
    dataIndex,
    options,
  }: {
    isHeader: boolean,
    fieldName: string,
    fieldDisplayName?: string,
    dataIndex?: number,
    options?: SelectOptions[],
  }) => {
    if (isHeader) {
      return (
        <th className={cn(styles.thHeading, styles['th-' + fieldName])}>
          {fieldDisplayName || ''}
        </th>
      );
    } else {
      if (
        dataIndex !== undefined &&
        data[dataIndex].schemeType &&
        data[dataIndex].schemeType.dropdownScheme
      ) {
        return (
          <td
            className={cn(
              styles.decisionTableTdCell,
              styles.decisionTableTdDropDownCell,
              styles['td-' + fieldName],
            )}
          >
            <FormRow className={styles.tableFormRowContainer}>
              <SuggestSelect
                id="default"
                value={data[dataIndex].schemeType.dropdownScheme.value}
                name="test-name"
                onChange={(e) => {
                  onSelect(data[dataIndex].applicationId, e.value);
                }}
                options={options}
              />
            </FormRow>
          </td>
        );
      }
    }
  };

  return (
    <div className={styles.taskTableContainer}>
      <table className={styles.decisionTableContainer} width={width}>
        <thead>
          <tr className={styles.trHead}>
            {fields &&
              fields.map((field, index) => (
                <React.Fragment key={`table-heading-${field.name}-${index}`}>
                  {field.name !== 'schemeType' && (
                    <th className={cn(styles.thHeading, styles['th-' + field.name])}>
                      {field.displayName}
                    </th>
                  )}
                  {field.name === 'schemeType' &&
                    renderDropdown({
                      fieldName: field.name,
                      fieldDisplayName: field.displayName,
                      isHeader: true,
                    })}
                </React.Fragment>
              ))}
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, dataIndex) => (
              <tr className={styles.tableRow} key={`table-row-${dataIndex}`}>
                {fields &&
                  fields.map((field, index) => (
                    <React.Fragment key={`table-cell-${index}`}>
                      {field.name !== 'schemeType' && (
                        <td className={cn(styles.decisionTableTdCell, styles['td-' + field.name])}>
                          {renderCell(field.name, item[field.name])}
                        </td>
                      )}
                      {field.name === 'schemeType' &&
                        renderDropdown({
                          isHeader: false,
                          fieldName: field.name,
                          dataIndex,
                          options:
                            (item[field.name].dropdownScheme &&
                              item[field.name].dropdownScheme.options) ||
                            [],
                        })}
                    </React.Fragment>
                  ))}
              </tr>
            ))}
        </tbody>
      </table>
      <Divider />
    </div>
  );
}

type CheckboxOption = {|
  id: string,
  text: string,
|};

export function CheckboxComponent({
  onCheck,
  options,
  selected,
}: {
  onCheck: (empty) => typeof undefined,
  options: CheckboxOption[],
  selected: string[],
}) {
  return options.map((option) => {
    const isOptionChecked = selected.some((item) => item.id === option.id);

    return (
      <div className={styles.checkboxOption}>
        <Checkbox
          onChange={() => {
            onCheck(option.id, !isOptionChecked);
          }}
          isChecked={isOptionChecked}
          key={option.id}
        >
          {option.text}
        </Checkbox>
      </div>
    );
  });
}
