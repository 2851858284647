import { Icon } from 'fronton-react';
import { styled } from 'linaria/lib/react';
import { Menu, Dropdown } from 'mp-ui-components';

import { Typography } from '@src/shared/components/Typography';

export const Time = styled.span`
  margin-bottom: var(--space-10);
  margin-left: var(--space-50);
  opacity: 0.5;
`;

export const StyledDropdown = styled(Dropdown)`
  position: static;
`;

export const TriggerContent = styled.div`
  display: flex;
  align-items: center;

  span {
    color: var(--success-dark);
  }
`;

export const IconContainer = styled(Icon)`
  margin-right: var(--space-50);
  color: var(--success-dark);
`;

export const LimitWarning = styled(Typography)`
  padding: 12px 0;
`;

export const Wrap = styled.div`
  margin-top: 6px;
`;

export const StyledMenu = styled(Menu)`
  max-height: 246px;
  overflow-y: auto;
`;

// !important is used to override ui-kit styles
// TODO: try to configure classnames order in webpack
export const MenuItem = styled(Menu.Item)`
  display: flex;
  align-items: center;
  height: 34px;

  &:not(.focused):hover {
    background-color: #66c05e !important;
    color: #fff !important;
  }
`;

export const FileName = styled.span`
  display: inline-block;
  width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Date = styled.span`
  margin-left: 12px;
`;

export const Status = styled.span`
  margin-left: 12px;
  text-transform: lowercase;
`;
