import React from 'react';

import { Button } from 'mp-ui-components';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';

import { BrowserStorage } from '@src/modules/orders/browserStorage';
import { RoutingService } from '@src/shared/services/routing';

import { OrdersApi } from '../../../../api/ordersApi';
import { OrderPriority } from '../../../../api/types';
import { ActionError } from './ActionError';

import styles from './styles.module.scss';

export const getRowsToChangePriorityTo = (newPriority: OrderPriority) => {
  const savedSelectedRows = [...BrowserStorage.getSavedSelectedRows().entries()];

  const currentPriority =
    newPriority === OrderPriority.Regular ? OrderPriority.Priority : OrderPriority.Regular;

  return (
    savedSelectedRows
      .filter(([_, priority]) => priority === currentPriority)
      .map(([rowId]) => rowId) ?? []
  );
};

type RequestPriorityChangeParams = {
  rowsToChange: string[];
  newPriority: OrderPriority;
};

const requestPriorityChange = async ({
  rowsToChange,
  newPriority,
}: RequestPriorityChangeParams) => {
  await OrdersApi.orders.setPriority(rowsToChange, newPriority);
  BrowserStorage.deleteSelectedRowsWithIds(rowsToChange);
};

type Props = {
  onSuccess: () => void;
};

export const ChangePriorityActions: React.FC<Props> = ({ onSuccess }) => {
  const rowsToBecomePriority = getRowsToChangePriorityTo(OrderPriority.Priority);
  const rowsToBecomeRegular = getRowsToChangePriorityTo(OrderPriority.Regular);

  const changePriority = useMutation(requestPriorityChange);

  const rowsToBecomePriorityAmount = rowsToBecomePriority.length;
  const rowsToBecomeRegularAmount = rowsToBecomeRegular.length;

  const assignAsPriority = () =>
    changePriority.mutate(
      {
        rowsToChange: rowsToBecomePriority,
        newPriority: OrderPriority.Priority,
      },
      { onSuccess },
    );

  const removePriority = () =>
    changePriority.mutate(
      {
        rowsToChange: rowsToBecomeRegular,
        newPriority: OrderPriority.Regular,
      },
      { onSuccess },
    );

  const { isError, isLoading } = changePriority;

  return (
    <div className={styles.actionButtons}>
      <Button
        theme="primary"
        isDisabled={rowsToBecomePriorityAmount === 0}
        onClick={assignAsPriority}
        isPending={isLoading}
      >
        <span>Assign as priority</span>
        <span className={styles.amount}>
          {rowsToBecomePriorityAmount > 0 ? ` ${rowsToBecomePriorityAmount}` : ''}
        </span>
      </Button>

      <Button
        theme="danger"
        isDisabled={rowsToBecomeRegularAmount === 0}
        onClick={removePriority}
        isPending={isLoading}
      >
        <span>Remove priority</span>
        <span className={styles.amount}>
          {rowsToBecomeRegularAmount > 0 ? ` ${rowsToBecomeRegularAmount}` : ''}
        </span>
      </Button>

      {isError && (
        <ActionError>
          <span>
            {'Prioritization was not changed due to a technical error. Please try again later or '}

            <Link className={styles.link} to={RoutingService.root.routes.helpAndSupport}>
              <u>consult the technical support</u>
            </Link>

            {' for a solution.'}
          </span>
        </ActionError>
      )}
    </div>
  );
};
