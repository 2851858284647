import React, { ChangeEventHandler, useEffect, useRef } from 'react';

import XMarkSvg from '@src/shared/assets/svg/XMark.svg?react';

import { Filter } from '../../hooks/useFilter';
import { SearchState } from './SearchState';

import styles from './styles.module.scss';

type Props = {
  filter: Filter;
};

export const SearchInput = ({ filter }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const onChange: ChangeEventHandler<HTMLInputElement> = (event) =>
    filter.onInputChange(event.target.value ?? '');

  const onClear = () => filter.onInputChange('');

  useEffect(() => {
    const input = inputRef.current;

    if (input) {
      input.focus();
    }
  }, []);

  const inputValue = filter.input.originalValue;

  return (
    <>
      <div className={styles.inputContainer}>
        <input
          data-testid="filter-menu-search-input"
          ref={inputRef}
          type="text"
          placeholder="Search"
          value={inputValue}
          onChange={onChange}
          onKeyDown={filter.onKeypressSearch}
        />
        <span className={styles.fader} />
        {inputValue && <XMarkSvg className={styles.icon} onClick={onClear} />}
      </div>
      <SearchState filter={filter} />
    </>
  );
};
