import { useCallback } from 'react';

import { useTableContext } from '@src/shared/components/Table/_deprecated/Table/context/TableContext';
import type { FilterItemInputType, FilterItemDateType } from '@src/shared/types/domain';

import { FilterEvent, TableEventType } from '../../../../types';

type DatepickerRangeChangeHandlerProps = {
  from: Date | null;
  to: Date | null;
};

export const useFilter = () => {
  const { tableData, allEnabledTableFilters = [], onTableEvent } = useTableContext();

  const onFilter = useCallback(
    (filterParams) =>
      onTableEvent<FilterEvent>({ type: TableEventType.filter, payload: { filterParams } }),
    [onTableEvent],
  );

  const updateFilter = useCallback(
    (option: FilterItemInputType | FilterItemDateType, filterName: string) => {
      onFilter({ ...allEnabledTableFilters, [filterName]: option });
    },
    [allEnabledTableFilters, onFilter],
  );

  const removeFilter = useCallback(
    (option: FilterItemInputType, filterName: string) => {
      const filtered = allEnabledTableFilters[filterName].filter(
        (filter) => filter.id !== option.id,
      );

      onFilter({ ...allEnabledTableFilters, [filterName]: [...filtered] });
    },
    [allEnabledTableFilters, onFilter],
  );

  const onDatepickerRangeChange = useCallback(
    (changes: DatepickerRangeChangeHandlerProps, dateFilterName) => {
      if (tableData?.fields) {
        let update = {};

        if (changes.from && changes.to) {
          update = {
            type: 'date',
            from: changes.from,
            to: changes.to,
          };
          updateFilter(update, dateFilterName);
        } else if (!changes.from && !changes.to) {
          update = {
            type: 'date',
            from: null,
            to: null,
          };
          updateFilter(update, dateFilterName);
        }
      }
    },
    [tableData?.fields, updateFilter],
  );

  return {
    allEnabledTableFilters,
    updateFilter,
    removeFilter,
    onDatepickerRangeChange,
  };
};
