import React, { useMemo } from 'react';

import cn from 'classnames';

import { Filter } from '../../hooks/useFilter';

import styles from './styles.module.scss';

type Props = {
  filter: Filter;
};

export const SearchState: React.FC<Props> = ({ filter }) => {
  const { input, searchQuery } = filter;

  const message = useMemo<React.ReactNode>(() => {
    if (input.errors.inputLength) {
      return 'At least 3 characters';
    }

    if (searchQuery.isError) {
      return (
        <>
          System error.{' '}
          <span className={styles.buttonTryAgain} onClick={() => searchQuery.refetch()}>
            Try again
          </span>
        </>
      );
    }

    if (searchQuery.isFetching) {
      return 'Searching...';
    }

    const results = searchQuery.data;
    const noResultsFound = results && results.items.length === 0;

    if (noResultsFound) {
      return 'No results';
    }

    if (input.hasMultipleItems && input.isTouched) {
      return 'Press Enter to filter';
    }

    return '';
  }, [input, searchQuery]);

  return message ? (
    <div className={cn(styles.searchState, { [styles.error]: searchQuery.isError })}>{message}</div>
  ) : null;
};
