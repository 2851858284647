import { useQuery } from 'react-query';

import { downloadFile } from './downloadFile';
import { i18n } from './i18n';
import { docTypes, requestDocTypes } from './requestDocTypes';
import { uploadFiles } from './uploadFiles';
import { uploadFilesTaskApi } from './uploadFilesTaskApi';
import * as utils from './utils';
import { defaultQueryOptions } from '../query';

export * from './types';

export { FileExt } from './enums/FileExt';

export { UploadStatus } from './enums/UploadStatus';

export { FileUploadError } from './enums/FileUploadError';

const docTypesQueryKey = ['docTypes'];

export const FileService = {
  docTypes,
  docTypesQueryKey,
  useDocTypesQuery: () =>
    useQuery({ ...defaultQueryOptions, queryKey: docTypesQueryKey, queryFn: requestDocTypes }),
  requestDocTypes,
  downloadFile,
  uploadFiles,
  uploadFilesTaskApi,
  ...utils,
  i18n,
};
