import { httpClient } from '@src/shared/services/httpClient';
import type { MappedFilterType, PaginationParams } from '@src/shared/types/domain';
import { mapTableFieldsFromDTO } from '@src/shared/utils/mappers/mapTableDataResponseDto';

import {
  OrderDocumentsResponse,
  OrderResponseDTO,
  GetOrdersRequesResponse,
  OrderPriority,
} from '../types';
import { calculateTableData } from '../utils/calculateTableData/calculateTableData';
import { mapCertTrackSortedAtlas } from '../utils/mapCertTrackSortedAtlas';

export async function requestOrderDocuments(orderId: string): Promise<OrderDocumentsResponse> {
  const response = await httpClient({
    method: 'get',
    url: `/api/orders/${orderId}/documents`,
  });

  return response.data;
}

export async function requestOrderHeader(orderId: string) {
  const response = await httpClient({
    method: 'get',
    url: `/api/orders/${orderId}`,
  });

  return response.data;
}

export async function requestOrder(id: string): Promise<OrderResponseDTO> {
  const response = await httpClient({
    method: 'get',
    url: `/api/orders/${id}`,
  });

  return response.data;
}

export const getOrders = async (filters: MappedFilterType, pagination: PaginationParams) => {
  const { limit, offset } = pagination;
  const response = await httpClient.request<GetOrdersRequesResponse>({
    method: 'post',
    url: '/api/orders',
    data: {
      filters,
      limit,
      offset,
      timezoneOffsetMinutes: new Date().getTimezoneOffset() * -1,
    },
  });

  const responseData = response.data;

  const fields = mapTableFieldsFromDTO(responseData.fields);
  const rows = responseData.data;

  return calculateTableData({ fields, rows });
};

getOrders.$queryId = 'getOrders';

export async function requestOrderCertTrack(orderId: string) {
  const response = await httpClient({
    method: 'get',
    url: `/api/orders/${orderId}/certTracking`,
  });

  mapCertTrackSortedAtlas(response.data.data);

  return response.data;
}

export const setPriority = (orderIds: string[], priority: OrderPriority) =>
  httpClient.request<GetOrdersRequesResponse>({
    method: 'POST',
    url: '/api/orders/setPriority',
    data: {
      orderIds,
      priority,
    },
  });
