import { ProblemType, QuestionType } from './types/common';

export const requestFormInfoMessage = {
  authorizedPage: `If there is no answer to your question in User Manuals and FAQ, please use application form below. Please check your email in the email box so we could send you a reply to your request. Also it's required to add comment and attach a screen shot or other file which will help us to address the problem.
If you choose 'Navigator' or 'Technical' question as a way point of your request it will be processed by RIES Support team.
If you choose 'Other' question as a way point of your request it will be handled by the team leader of your team or your business coordinator.`,
  publicPage: `If you have problems with authorization or any question about Navigator, please use application form below. Please add your email in the email box so we could send you a reply to your request. Also it's required to add comment and attach a screen shot or other file which will help us to address the problem. Your request will be processed by RIES Support team.
In case you need to create request on any other problem please log in to the system.`,
};

export const requestCreatedMsg = {
  loginCredsRequest: `Thank you for your request. It's registered and sent to RIES Support team. Please check your email within four working hours. Please note the reply will come to the email which you put in the email box of your request.`,
  supportTeamWillCheckRequest: `Thank you for your request. It's registered and sent to RIES Support team. Please note the reply will come to the email which you put in the email box of your request.`,
  keyUserWillCheckRequest: `Thank you for your request. It's registered and sent to the team leader of your team or your business coordinator. Please note the reply will come to the email which you put in the email box of your request.`,
};

type ProblemTypeItem = {
  id: ProblemType;
  text: string;
};

export const technicalProblemTypes: ProblemTypeItem[] = [
  {
    id: ProblemType.AuthorizationIssues,
    text: 'authorization issues',
  },
  {
    id: ProblemType.PageNotLoad,
    text: "a page doesn't load",
  },
  {
    id: ProblemType.TaskNotCreatedNotCompleted,
    text: 'a task is not created or not completed',
  },
  {
    id: ProblemType.CanNotUploadDownloadFile,
    text: "can't upload or download a file",
  },
  {
    id: ProblemType.ReturnTask,
    text: 'please return a task',
  },
  {
    id: ProblemType.ReplaceArf,
    text: 'please replace ARF',
  },
  {
    id: ProblemType.UploadNewShipmentDocument,
    text: 'please upload a new shipment document',
  },
  {
    id: ProblemType.DeleteRowFromShipmentsTracking,
    text: 'please delete row from Shipments tracking report',
  },
  {
    id: ProblemType.IDCEditRequest,
    text: 'issue with IDC Edit',
  },
  {
    id: ProblemType.IDCManualLinkingRequest,
    text: 'issue with manual linking',
  },
  {
    id: ProblemType.NotificationsNotWorksCorrectly,
    text: 'notifications do not work correctly or the mailing matrix needs to be updated',
  },
];

const navigatorProblemTypes: ProblemTypeItem[] = [
  {
    id: ProblemType.NavigatorIncorrectOrMissingData,
    text: 'please check missing or incorrect data',
  },
  {
    id: ProblemType.NavigatorLineDuplication,
    text: 'please check line duplication',
  },
  {
    id: ProblemType.NavigatorUpdateSpecialistsDistribution,
    text: 'please update the distribution of specialists by departments',
  },
  {
    id: ProblemType.NavigatorIncorrectDataFormat,
    text: 'please correct data format',
  },
  {
    id: ProblemType.NavigatorBookmarksIssues,
    text: 'issues with bookmarks',
  },
  {
    id: ProblemType.NavigatorTechnicalIssue,
    text: 'report technical issue',
  },
  {
    id: ProblemType.NavigatorOtherQuestion,
    text: 'other question',
  },
];

export const questionsConfig = {
  authorizedPage: [
    {
      id: QuestionType.Technical,
      text: 'Technical',
      problemTypes: technicalProblemTypes,
    },
    { id: QuestionType.Navigator, text: 'Navigator', problemTypes: navigatorProblemTypes },
    { id: QuestionType.Other, text: 'Other question' },
  ],
  publicPage: [
    {
      id: QuestionType.Technical,
      text: "I can't log in to the system",
      problemTypes: [
        {
          id: ProblemType.CantLogIn,
          text: '',
        },
      ],
    },
    { id: QuestionType.Navigator, text: 'Navigator', problemTypes: navigatorProblemTypes },
  ],
};
