import { RootRoute, rootRoutesConfig } from './config';
import { getDefaultRouteForRole, getHasAccessToRoute, getNavTabsForRole } from './utils';

export const RoutingService = {
  root: {
    routes: RootRoute,
    config: rootRoutesConfig,
  },
  getHasAccessToRoute,
  getNavTabsForRole,
  getDefaultRouteForRole,
};
