import React, { ReactNode } from 'react';

import styles from './styles.module.scss';

type Props = {
  children?: ReactNode;
};

export const ZigZagContainer = ({ children }: Props) => (
  <div className={styles.zigZagContainer}>
    <div className={styles.border} />
    <div className={styles.content}>{children}</div>
  </div>
);
