import React from 'react';

export const useActiveDropdown = () => {
  const [activeDropdown, setActiveDropdown] = React.useState<string>();

  const openDropdown = React.useCallback(
    (fieldName: string) => {
      setActiveDropdown(activeDropdown !== fieldName ? fieldName : undefined);
    },
    [activeDropdown],
  );

  return { activeDropdown, openDropdown };
};
