import React from 'react';

import { WarningIcon } from '@fronton/icons-react';
import { Typography } from 'fronton-react';
import { styled } from 'linaria/lib/react';

import { textEllipsis } from '@src/shared/utils/styles';

import { useNotificationBanner } from './useNotificationBanner';

const Wrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 32px;
  padding: 0 12px;
  background-color: var(--attention-light);
`;

export const NotificationBanner = () => {
  const banner = useNotificationBanner();

  return banner?.message ? (
    <Wrap>
      <WarningIcon size="l" />
      <Typography
        className={textEllipsis}
        as="p"
        variant="s"
        size="body_long"
        title={banner.message}
      >
        {banner.message}
      </Typography>
    </Wrap>
  ) : null;
};
