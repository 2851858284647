import React from 'react';

import debounce from 'lodash/debounce';

import { requestFilterFromInput } from '@src/modules/tasks/pages/TasksListPage/services/tasks';
// eslint-disable-next-line import/namespace
import { RequestFilterFromInputDTO } from '@src/shared/types/domain';

import { AppliedOptionsCount } from './AppliedOptionsCount';
import { OptionsListItem, OptionsList } from './OptionsList';
import { SearchInput } from './SearchInput';

import styles from './index.css';

const filterNonAppliedOptions = (
  options: RequestFilterFromInputDTO[],
  appliedOptions: RequestFilterFromInputDTO[],
) => options.filter((item) => !appliedOptions.map((filter) => filter.id).includes(item.id));

type Props = {
  filterName: string;
  className: string;
  updateFilter: (option: RequestFilterFromInputDTO, filterName: string) => void;
  removeFilter: (option: RequestFilterFromInputDTO, filterName: string) => void;
  removeAllFilters: () => void;
  inputRestrictionDisabled?: boolean;
  filters?: RequestFilterFromInputDTO[];
};

export const FilterMenu: React.FC<Props> = ({
  filterName,
  className,
  filters: appliedOptions = [],
  inputRestrictionDisabled,
  updateFilter,
  removeFilter,
  removeAllFilters,
}) => {
  const [inputValue, setInputValue] = React.useState('');
  const [isPending, setPending] = React.useState(false);
  const [foundOptionsBySearch, setFoundOptionsBySearch] = React.useState<
    RequestFilterFromInputDTO[]
  >([]);

  const searchRequestAbortController = React.useRef(new AbortController());

  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle
  const _searchOptionsMethod = React.useCallback(
    async (text: string) => {
      const prevAbortController = searchRequestAbortController.current;
      const newAbortController = new AbortController();

      prevAbortController.abort();
      searchRequestAbortController.current = newAbortController;

      setPending(true);
      try {
        const results = await requestFilterFromInput(filterName, text, newAbortController);

        setFoundOptionsBySearch(results);
      } catch {
        setFoundOptionsBySearch([]);
      }

      setPending(false);
    },
    [filterName],
  );

  const searchOptions = React.useMemo(
    () => debounce((text: string) => _searchOptionsMethod(text), 500),
    [_searchOptionsMethod],
  );

  React.useEffect(() => {
    if (inputRestrictionDisabled) {
      searchOptions(inputValue);
      searchOptions.flush();
    }

    return () => {
      searchRequestAbortController.current.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inputHandler: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const newText = event?.target?.value ?? '';

    setInputValue(newText);
    setFoundOptionsBySearch([]);

    if (!inputRestrictionDisabled && newText.length < 3) {
      searchOptions.cancel();
      setPending(false);

      return;
    }

    setPending(true);
    searchOptions(newText);
  };

  const searchingStateText = React.useMemo(() => {
    const resultsAreLoaded = foundOptionsBySearch.length > 0;

    if (resultsAreLoaded || appliedOptions.length > 0) {
      return;
    }

    if (isPending) {
      return 'Searching...';
    }

    const minimumOfCharsIsNotProvided = !inputRestrictionDisabled && inputValue.length < 3;

    if (minimumOfCharsIsNotProvided) {
      return 'At least 3 characters';
    }

    const noResultsFound = inputValue.length > 0 && foundOptionsBySearch.length === 0;

    if (noResultsFound) {
      return 'No results';
    }
  }, [inputValue, appliedOptions, isPending, inputRestrictionDisabled, foundOptionsBySearch]);

  const optionsList = React.useMemo<OptionsListItem[]>(() => {
    const nonAppliedOptions = filterNonAppliedOptions(foundOptionsBySearch, appliedOptions);

    const patchedAppliedOptions = appliedOptions.map((item) => ({
      ...item,
      isApplied: true,
    }));

    return [...patchedAppliedOptions, ...nonAppliedOptions];
  }, [appliedOptions, foundOptionsBySearch]);

  return (
    <div className={className}>
      <SearchInput value={inputValue} onChange={inputHandler} />

      {searchingStateText && <div className={styles.inputState}>{searchingStateText}</div>}

      {appliedOptions.length > 0 && (
        <AppliedOptionsCount
          appliedOptionsAmount={appliedOptions.length}
          onClick={removeAllFilters}
        />
      )}

      {foundOptionsBySearch && (
        <OptionsList
          items={optionsList}
          filterName={filterName}
          addFilter={updateFilter}
          removeFilter={removeFilter}
        />
      )}
    </div>
  );
};
