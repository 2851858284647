import { httpClient } from '@src/shared/services/httpClient';

import { SupportRequestFormState, ICreateIncidentDTO } from '../types/common';
import { Instruction } from '../types/instructions';

export async function getInstructionsList(): Promise<Instruction[]> {
  const response = await httpClient({
    method: 'get',
    url: '/api/incidents/instructionsList',
  });

  return response.data;
}

export async function createIncident({
  incidentForm,
}: {
  incidentForm: SupportRequestFormState;
}): Promise {
  const data: ICreateIncidentDTO = new FormData();

  data.append('email', incidentForm.email);
  data.append('comment', incidentForm.comment);
  data.append('question', incidentForm.question);

  if (incidentForm.problemType) {
    data.append('problemType', incidentForm.problemType);
  }

  if (incidentForm.files.length > 0) {
    for (const file of incidentForm.files) {
      data.append('file', file);
    }
  }

  return httpClient({
    method: 'post',
    url: '/api/incidents',
    data,
  });
}
