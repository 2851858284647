import React from 'react';

import cn from 'classnames';
import { Dropdown } from 'mp-ui-components';

import type { FieldContainer, FilterItemInputType } from '../../types';

import { FilterMenu } from './FilterMenu';
import DottedLine from './dotted-line.svg';

import styles from './index.css';

type Props = {
  selectedFilterWindow: null | string;
  selectFilterWindow: (arg0: string) => void;
  updateFilterHandler: (option: FilterItemInputType, filterName: string) => void;
  removeFilterHandler: (option: FilterItemInputType, filterName: string) => void;
  removeColumnFilters: () => void;
  field: FieldContainer;
  filterOptions: any;
};

export const InputDropdown = ({
  selectedFilterWindow,
  selectFilterWindow,
  updateFilterHandler,
  removeFilterHandler,
  removeColumnFilters,
  field,
  filterOptions,
}: Props) => (
  <Dropdown
    isOpen={selectedFilterWindow === field.name}
    onChange={() => selectFilterWindow(field.name)}
  >
    <Dropdown.Trigger>
      <div
        data-testid="input-dropdown-testid"
        className={cn(
          styles.title,
          (selectedFilterWindow === field.name && styles.titleSelected) ||
            (filterOptions[field.name] &&
              filterOptions[field.name].length > 0 &&
              styles.titleSelected),
        )}
      >
        <span
          className={styles.dotted}
          style={{
            backgroundImage: `url(${DottedLine})`,
            filter:
              selectedFilterWindow === field.name ||
              (filterOptions[field.name] && filterOptions[field.name].length > 0)
                ? 'brightness(1)'
                : 'brightness(0)',
          }}
          title={field.displayName}
        >
          {field.displayName}
        </span>
        {filterOptions[field.name] && filterOptions[field.name].length > 0 && (
          <span className={styles.selectedCount}>{filterOptions[field.name].length}</span>
        )}
      </div>
    </Dropdown.Trigger>
    <Dropdown.Content ariseDirection="bottom" className={styles.filterContent}>
      {selectedFilterWindow === field.name && (
        <FilterMenu
          filterName={field.name}
          updateFilter={updateFilterHandler}
          removeFilter={removeFilterHandler}
          removeAllFilters={removeColumnFilters}
          filters={filterOptions[field.name]}
          inputRestrictionDisabled={field.disableMinResctriction || field.disableMinRestriction}
          className={styles.filterWindow}
        />
      )}
    </Dropdown.Content>
  </Dropdown>
);
