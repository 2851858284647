import { FileService } from '@src/shared/services/files';

export const makeUpload = (baseUrl: string) => (files: FileList, docType: string) =>
  FileService.uploadFiles({
    files: [...files].map((file) => ({
      file,
      docType,
      docTypeInfo: FileService.docTypes[docType],
    })),
    url: `${baseUrl}/upload`,
    partialSendMode: true,
  });
