import React from 'react';

import { useNavigate } from 'react-router-dom';

import { BrowserStorage } from '@src/modules/orders/browserStorage';
import { CommonTable } from '@src/shared/components/Table/_deprecated/Table';
import {
  isClearAllEvent,
  isClearAllOnPageEvent,
  isClickRowEvent,
  isFilterEvent,
  isSelectAllOnPageEvent,
  isSelectRowEvent,
  isTableEvent,
} from '@src/shared/components/Table/_deprecated/Table/utils';

import { findRowIndexById } from '../../utils/search';

type UseTableEventsParams = {
  tableData: CommonTable | undefined;
  triggerTableUpdate: (newTableData?: CommonTable) => void;
  changeTablePage: (page: number) => void;
  setEnabledFilters: (enabledFilters: any) => void; // TODO - fix any
};

export const useTableEvents = ({
  tableData,
  triggerTableUpdate,
  setEnabledFilters,
  changeTablePage,
}: UseTableEventsParams) => {
  const navigate = useNavigate();
  const onFiltersChange = React.useCallback(
    (filters) => {
      const notEmptyFilters = {};

      for (const filter in filters) {
        if (filters[filter]) {
          if (!Array.isArray(filters[filter])) {
            if (filters[filter].type && filters[filter].from && filters[filter].to) {
              notEmptyFilters[filter] = filters[filter];
            }
          } else {
            notEmptyFilters[filter] = filters[filter];
          }
        }
      }

      changeTablePage(0);

      setEnabledFilters(notEmptyFilters);

      localStorage.setItem('ordersFilters', JSON.stringify(notEmptyFilters));
    },
    [changeTablePage, setEnabledFilters],
  );

  const onSelectRow = React.useCallback(
    (rowId: string) => {
      if (!tableData) {
        return;
      }

      const allRows = tableData.rows;
      const rowIndex = findRowIndexById(rowId, allRows);
      const selectedRow = allRows[rowIndex];

      const newValue = !selectedRow.$isRowSelected;

      BrowserStorage.saveSelectedRows(
        [selectedRow],
        newValue ? BrowserStorage.StorageOperation.add : BrowserStorage.StorageOperation.delete,
      );

      triggerTableUpdate();
    },
    [tableData, triggerTableUpdate],
  );

  const onSelectAllOnPage = React.useCallback(
    (storageAction: BrowserStorage.StorageOperation) => {
      if (!tableData) {
        return;
      }

      BrowserStorage.saveSelectedRows(tableData.rows, storageAction);

      triggerTableUpdate();
    },
    [tableData, triggerTableUpdate],
  );

  const onClearAll = React.useCallback(() => {
    BrowserStorage.deleteAllSelectedRows();

    triggerTableUpdate();
  }, [triggerTableUpdate]);

  const onTableEvent = React.useCallback(
    (event: unknown) => {
      if (!isTableEvent(event)) {
        return;
      }

      if (isClickRowEvent(event)) {
        navigate(`${event.payload.rowId}`);

        return;
      }

      if (isFilterEvent(event)) {
        onFiltersChange(event.payload.filterParams);

        return;
      }

      if (isSelectRowEvent(event)) {
        onSelectRow(event.payload.rowId);

        return;
      }

      if (isSelectAllOnPageEvent(event)) {
        onSelectAllOnPage(BrowserStorage.StorageOperation.add);

        return;
      }

      if (isClearAllOnPageEvent(event)) {
        onSelectAllOnPage(BrowserStorage.StorageOperation.delete);

        return;
      }

      if (isClearAllEvent(event)) {
        onClearAll();
      }
    },
    [onClearAll, onFiltersChange, onSelectAllOnPage, onSelectRow, navigate],
  );

  return onTableEvent;
};
