import {
  FileDocIcon,
  FileIcon,
  FilePdfIcon,
  FileVideoIcon,
  FileZipIcon,
  ImageIcon,
  MicrosoftExcelLogoIcon,
} from '@fronton/icons-react';

import DocIcon from '@src/shared/assets/svg/FileDocx.svg?react';
import ImageIconLegacy from '@src/shared/assets/svg/FileImage.svg?react';
import VideoIcon from '@src/shared/assets/svg/FileMp4.svg?react';
import UnknownExtIcon from '@src/shared/assets/svg/FileOther.svg?react';
import PdfIcon from '@src/shared/assets/svg/FilePdf.svg?react';
import XlsIcon from '@src/shared/assets/svg/FileXls.svg?react';
import ZipIcon from '@src/shared/assets/svg/FileZip.svg?react';
import { i18n } from '@src/shared/i18n';

import { FileExt } from './enums/FileExt';
import { FileUploadError } from './enums/FileUploadError';
import { DocTypeInfo, docTypes } from './requestDocTypes';
import { FileUploadErrorsInfo } from './types';

export const getFileExtension = (fileName: string): string => {
  if (fileName) {
    const splited = fileName.split('.');

    return splited.slice(-1)[0].toLowerCase();
  }

  return fileName;
};

export const getFileNameWithoutExtension = (fileName: string): string =>
  fileName.replace(/\.[\dA-Za-z]+$/, '');

export const getIsFileBiggerThan = (maxSizeInMbs: number, file: File) => file.size > maxSizeInMbs;

export const validateFile = (
  file: File,
  { extensions, maxUploadSizeInBytes, fileNameRegex }: DocTypeInfo,
): FileUploadError | undefined => {
  const fileExtension = getFileExtension(file.name);

  const isWrongExtension = extensions && !extensions.includes(fileExtension as FileExt);

  if (isWrongExtension) {
    return FileUploadError.fileTypeWrong;
  }

  const isFileTooBig = maxUploadSizeInBytes && getIsFileBiggerThan(maxUploadSizeInBytes, file);

  if (isFileTooBig) {
    return FileUploadError.fileTooBig;
  }

  const isFileNameWrong = !new RegExp(fileNameRegex ?? '.').test(
    getFileNameWithoutExtension(file.name),
  );

  if (isFileNameWrong) {
    return FileUploadError.fileNameWrong;
  }
};

const errorsOrder: { [key in FileUploadError]?: number } = {
  fileTypeWrong: 1,
  fileTooBig: 2,
  fileNameWrong: 3,
};

export const sortErrorKeys = (errors: FileUploadErrorsInfo) =>
  Object.fromEntries(
    Object.entries(errors)
      .sort(([errorType1], [errorType2]) => {
        const error1Order = errorsOrder[errorType1] ?? 10_000;
        const error2Order = errorsOrder[errorType2] ?? 10_000;

        return error1Order - error2Order;
      })
      .map(([errType, errInfo]) => [errType, errInfo]),
  );

const fileIconsLegacy = {
  pdf: PdfIcon,
  xls: XlsIcon,
  xlsx: XlsIcon,
  doc: DocIcon,
  docx: DocIcon,
  zip: ZipIcon,
  rar: ZipIcon,
  png: ImageIconLegacy,
  jpeg: ImageIconLegacy,
  jpg: ImageIconLegacy,
  bmp: ImageIconLegacy,
  gif: ImageIconLegacy,
  mp4: VideoIcon,
  unknown: UnknownExtIcon,
};

const fileIcons = {
  pdf: FilePdfIcon,
  xls: MicrosoftExcelLogoIcon,
  xlsx: MicrosoftExcelLogoIcon,
  doc: FileDocIcon,
  docx: FileDocIcon,
  zip: FileZipIcon,
  rar: FileZipIcon,
  png: ImageIcon,
  jpeg: ImageIcon,
  jpg: ImageIcon,
  bmp: ImageIcon,
  gif: ImageIcon,
  mp4: FileVideoIcon,
  unknown: FileIcon,
};

export const getIconComponentByExtension = (extension: string, frontonIcons = false) => {
  if (frontonIcons) {
    return fileIcons[extension] ?? fileIcons.unknown;
  }

  return fileIconsLegacy[extension] ?? fileIconsLegacy.unknown;
};

export const getIconComponentByFileName = (fileName: string, frontonIcons = false) => {
  const extension = getFileExtension(fileName);

  if (extension === fileName) {
    return frontonIcons ? fileIcons.unknown : fileIconsLegacy.unknown;
  }

  return getIconComponentByExtension(extension, frontonIcons);
};

export const pickUploadErrorMessage = (errorType: string, docType: string) => {
  const docTypeInfo = docTypes[docType];

  const maxUploadSizeInBytes = docTypeInfo?.maxUploadSizeInBytes;

  if (errorType === FileUploadError.fileTooBig && maxUploadSizeInBytes) {
    const maxUploadSizeInMb = maxUploadSizeInBytes / 2 ** 20;

    return `File is too big, should be less than ${maxUploadSizeInMb} mb`;
  }

  const allowedExt = docTypeInfo?.extensions;

  if (errorType === FileUploadError.fileTypeWrong && allowedExt) {
    return `Wrong file format, upload ${allowedExt.join('/')} instead`;
  }

  const fileNameExample = docTypeInfo?.fileNameExample;

  if (errorType === FileUploadError.fileNameWrong && fileNameExample) {
    return `${i18n.fileUploads.errors.fileNameWrong} ${fileNameExample}`;
  }

  return i18n.fileUploads.errors.unknownError;
};
