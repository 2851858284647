import { useQuery } from 'react-query';

import { defaultQueryOptions } from '@src/shared/services/query';

import { DocumentsApi } from '../../../api';

const { getLatestReports: getReports } = DocumentsApi.shipmentsTracking;

type Params = {
  take?: number;
};

const DEFAULT_LAST_REPORTS_AMOUNT = 3;

export const useSTSubReports = (params?: Params) =>
  useQuery({
    queryKey: getReports.getQueryKey({ take: params?.take ?? DEFAULT_LAST_REPORTS_AMOUNT }),
    queryFn: getReports.queryFn,
    ...defaultQueryOptions,
  });
