import React from 'react';

import cn from 'classnames';

import styles from '../styles.module.scss';
import { ClickEvent, CommonTable, TableEventType } from '../types';
import { getFieldTheme } from '../utils';
import { DataCell } from './Cell';

type Props = {
  tableData: CommonTable;
  isTableEmpty: boolean;
  onTableEvent: <EventDto>(e: EventDto) => void;
};

export const Body: React.FC<Props> = ({ tableData, isTableEmpty, onTableEvent }) => {
  const onRowClick: React.MouseEventHandler<HTMLTableRowElement> = (e) => {
    const { rowId } = e.currentTarget.dataset;

    if (rowId) {
      onTableEvent<ClickEvent>({
        type: TableEventType.click,
        payload: { rowId },
      });
    }
  };

  return (
    <tbody>
      <tr className={styles.indentRow} />

      {!isTableEmpty &&
        tableData.rows.map((row) => (
          <tr className={cn(styles.row)} data-row-id={row.id} key={row.id} onClick={onRowClick}>
            {tableData.fields.map((field) => (
              <DataCell
                key={field.id}
                className={cn(styles.cell, styles[getFieldTheme(row, field)])}
                field={field}
                value={row[field.name]}
                rowId={row.id}
                tableData={tableData}
                onTableEvent={onTableEvent}
              />
            ))}
          </tr>
        ))}
    </tbody>
  );
};
