import { httpClient } from '@src/shared/services/httpClient';
import { BaseQueryDef, createQueryDef } from '@src/shared/services/query';

type ResponseData = {
  url: string;
};

type QueryParams = {
  docType: string;
};

type QueryDef = BaseQueryDef<ResponseData, QueryParams>;

export const makeGetTemplateUrl = (baseUrl: string) =>
  createQueryDef<QueryDef>(`${baseUrl}/templates`, async ({ queryKey }) => {
    const [url, { docType }] = queryKey;

    const response = await httpClient.request<ResponseData>({
      method: 'get',
      url: `${url}/${docType}`,
    });

    return response.data;
  });
