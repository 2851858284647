import React from 'react';

import { useResizeDetector } from 'react-resize-detector';

/**
 * Hook, which calculates table header sticky position in the outerContainer if there is a content with dynamic height
 * @param tableHeaderSelector - HTML selector of controlled table header element
 *
 * @returns {object} observer refs
 * @property {object} outerContainerRef - ref for container of table and dynamic sticky content
 * @property {object} dynamicContainerRef - ref for dynamic sticky content, which height we should measure to recalculate sticky table header position
 */
export const useStickyTableHeader = (tableHeaderSelector: string) => {
  const outerContainerRef = React.useRef<HTMLDivElement>(null);

  const onDynamicContentResize = React.useCallback(
    (_, dynamicContentHeight) => {
      const tableHeader =
        outerContainerRef.current?.querySelector<HTMLTableElement>(tableHeaderSelector);

      if (tableHeader) {
        const topOffset = Math.round(dynamicContentHeight ?? 0);

        tableHeader.style.top = `${topOffset}px`;
      }
    },
    [tableHeaderSelector],
  );

  const { ref: dynamicStickyContentRef } = useResizeDetector({
    onResize: onDynamicContentResize,
    skipOnMount: false,
  });

  return {
    outerContainerRef,
    dynamicStickyContentRef,
  };
};
