import { useEffect, useState } from 'react';

import { AuthService, UserIdToken } from '@src/shared/services/auth';
import { FileService } from '@src/shared/services/files';
import { queryClient } from '@src/shared/services/query';

export enum AuthError {
  noRole = 'noRole',
  noResponse = 'noResponse',
  refreshTokenFailed = 'refreshTokenFailed',
}

// TODO: Переименовать в useAppInit и вынести логику по определению роутов в слой роутинга
export const useAuth = () => {
  const [idToken, setIdToken] = useState<UserIdToken>();
  const [error, setError] = useState<AuthError>();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const savedIdToken = AuthService.getUserIdToken();

    if (savedIdToken) {
      setIdToken(savedIdToken);

      if (!savedIdToken.vedRole) {
        setError(AuthError.noRole);
        setLoading(false);

        return;
      }

      FileService.requestDocTypes()
        .then((docTypes) => {
          queryClient.setQueryData(FileService.docTypesQueryKey, docTypes);
        })
        .catch(() => {
          setError(AuthError.noResponse);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  return {
    idToken,
    isLoading,
    error,
  };
};
