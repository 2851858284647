import { currentActivityEnum } from '../../../../enums/enums';
import { TShipmentReport, TShipmentReports, TShipmentReportsBackend } from '../../../../types';
import { STSubReportFilter } from '../../../../widgets/STSubReportWidget/enums';

export function getEmptyState(): TShipmentReports {
  return {
    reports: {},
    docType: '',
    currentActivity: currentActivityEnum.bootstrap,
    filteredBy: STSubReportFilter.allProcessed,
    isOpen: false,
    upload: {
      files: [],
      error: '',
    },
  };
}

export function createReports({
  lastN,
  download,
  lastSuccess,
}: {
  lastN: TShipmentReportsBackend;
  download: Array<TShipmentReportsBackend>;
  lastSuccess: TShipmentReportsBackend;
}): Record<string, TShipmentReport> {
  const reps = {};

  for (const key of Object.keys(lastN)) {
    if (lastN?.[key]?.[0]) {
      reps[key] = {
        ...lastN[key][0],

        downloadFiles: [...download[key]],
        lastSuccessfulUpdated: lastSuccess[key] ? lastSuccess[key].updated : null,
      };
    }
  }

  return reps;
}

export function init(data?: TShipmentReports): TShipmentReports {
  return { ...getEmptyState(), ...data };
}
