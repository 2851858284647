import React, { ReactElement } from 'react';

import { Navigate } from 'react-router-dom';

import { AuthService } from '@src/shared/services/auth';
import { RoutingService } from '@src/shared/services/routing';
import { RootRoute } from '@src/shared/services/routing/config';

type Props = {
  route: RootRoute;
  children: ReactElement;
};

export const RouteGuard = ({ route, children }: Props) => {
  const userRole = AuthService.getUserIdToken()?.vedRole;
  const hasAccess = RoutingService.getHasAccessToRoute(userRole, route);
  const defaultRoute = RoutingService.getDefaultRouteForRole(userRole);

  return hasAccess ? children : <Navigate to={defaultRoute} replace />;
};
