/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable unicorn/consistent-destructuring */

import React from 'react';

import cn from 'classnames';
import HTMLReactParser from 'html-react-parser';

import { TaskComponentContainerField } from '@src/shared/types';

import { DynamicDoc } from './DynamicDoc';
import { hasFullWidth } from './utils';
import {
  AttachmentDocComponent,
  TextComponent,
  DateLabelComponent,
  LinkListComponent,
  DocListComponent,
  RadioComponent,
  CheckboxComponent,
  AttachmentScreenshotComponent,
  TextAreaComponent,
  InputComponent,
  DatePickerComponent,
  TaskTableComponent,
  DecisionTableComponent,
} from '../../../../../../../shared/components/TaskComponents';
import { RequiredFieldMark } from '../Styled';
import { useFormContext } from '../hooks/useFormContext';
import styles from '../index.css';

type Props = {
  container: string;
  label: string;
  field: TaskComponentContainerField;
};

export const FieldContainer = ({ container, label, field }: Props) => {
  const {
    state,
    isShipment,
    selectedConditions,
    shipmentId,
    clearConditions,
    onUpdateField,
    setDisplayModal,
  } = useFormContext();

  const { templateId, order } = state;
  const orderNumber = order?.number;
  // eslint-disable-next-line no-underscore-dangle
  const orderId = order?._id;

  // @ts-ignore
  const { availableWhen, selected } = field;

  if (availableWhen && availableWhen.length > 0) {
    let availableCount = 0;

    for (const condition of availableWhen) {
      if (selectedConditions.includes(condition)) {
        availableCount++;
      }
    }

    if (availableCount === 0) {
      if (field.type === 'select' && selected && clearConditions) {
        // toDo: should be another solution to remove selection from parent components
        setTimeout(() => {
          onUpdateField({
            container,
            label,
            value: field,
            update: {
              selected: '',
            },
          });
          // @ts-ignore
          clearConditions(field.options.map((item) => item.id));
        }, 10);
      }

      return null;
    }
  }

  const selectRadio = (container, label, value, selected) => {
    const update = {
      selected,
    };

    onUpdateField({
      container,
      label,
      value,
      update,
    });
  };

  const textAreaHandler = (container, label, value, text) => {
    const update = {
      value: text,
    };

    onUpdateField({
      container,
      label,
      value,
      update,
    });
  };

  const fileHandler = (container, label, value, update) => {
    onUpdateField({
      container,
      label,
      value,
      update,
    });
  };

  const screenshotHandler = (container, label, value, update) => {
    onUpdateField({
      container,
      label,
      value,
      update,
    });
  };

  function updateDatePickerField(container, label, value, date) {
    onUpdateField({
      container,
      label,
      value,
      update: {
        value: date,
      },
    });
  }

  const checkboxHandler = (
    container,
    label,
    value,
    checked: {
      id: string;
      selected: boolean;
    },
  ) => {
    let update = {};

    update =
      checked.selected === true
        ? {
            selected: [...value.selected, { ...checked }],
          }
        : {
            selected: value.selected.filter((option) => option.id !== checked.id),
          };

    onUpdateField({
      container,
      label,
      value,
      update,
    });
  };

  function updateDecisionTable(applicationId, updateValue) {
    const update = field;

    // @ts-ignore
    update.data = update.data.map((dataItem) => {
      if (
        dataItem.applicationId === applicationId &&
        dataItem.schemeType &&
        dataItem.schemeType.dropdownScheme
      ) {
        const isThisOptionBlockable = dataItem.schemeType.dropdownScheme.options.find(
          (option) => option.id === updateValue,
        ).isBlocking;

        return {
          ...dataItem,
          schemeType: {
            ...dataItem.schemeType,
            dropdownScheme: {
              ...dataItem.schemeType.dropdownScheme,
              value: updateValue,
              isBlocking: isThisOptionBlockable || false,
            },
          },
        };
      }

      return { ...dataItem };
    });
    onUpdateField({
      container,
      value: field,
      // @ts-ignore
      label: field.caption,
      update,
    });
  }

  const renderByType = (fieldParams) => {
    if (fieldParams.type === 'text') {
      return <TextComponent isBold={fieldParams.isBold} text={fieldParams.text} />;
    }

    if (fieldParams.type === 'linkList') {
      return (
        <LinkListComponent orderId={orderId} shipmentId={shipmentId} links={fieldParams.links} />
      );
    }

    if (fieldParams.type === 'dynamicDoc') {
      return <DynamicDoc field={fieldParams} />;
    }

    if (fieldParams.type === 'docList') {
      return (
        <DocListComponent
          text={fieldParams.text}
          zipUrl={fieldParams.zipUrl}
          docs={fieldParams.docs}
        />
      );
    }

    if (fieldParams.type === 'select') {
      const selectHandler = (e: never) => {
        selectRadio(container, label, fieldParams, e);
      };

      return (
        <RadioComponent
          // @ts-ignore
          options={fieldParams.options}
          selected={fieldParams.selected}
          selectRadio={selectHandler}
        />
      );
    }

    if (fieldParams.type === 'checkbox') {
      const checkHandler = (id: string, selected: boolean) => {
        checkboxHandler(container, label, fieldParams, {
          id,
          selected,
        });
      };

      return (
        <CheckboxComponent
          // @ts-ignore
          selected={fieldParams.selected}
          options={fieldParams.options}
          onCheck={checkHandler}
        />
      );
    }

    if (fieldParams.type === 'attachmentDoc') {
      const fileHandlerComponent = (update) => {
        fileHandler(container, label, fieldParams, update);
      };

      return (
        <AttachmentDocComponent
          orderId={orderId}
          shipmentId={shipmentId}
          serviceOfUploading={isShipment ? 'shipments' : 'default'}
          orderNumber={orderNumber}
          templateId={templateId}
          doctype={fieldParams.doctype}
          isMultiple={fieldParams.isMultiple}
          text={fieldParams.text || 'Browse...'}
          files={fieldParams.files || null}
          isUploading={fieldParams.uploading}
          // @ts-ignore
          fileHandler={fileHandlerComponent}
        />
      );
    }

    if (fieldParams.type === 'attachmentScreenshot' && setDisplayModal) {
      const screenshotHandlerComponent = (update) => {
        screenshotHandler(container, label, fieldParams, update);
      };

      const mappedFiles = [];

      if (fieldParams.uploadedFiles) {
        for (const uploadedFile of fieldParams.uploadedFiles) {
          // @ts-ignore
          mappedFiles.push({
            name: uploadedFile.fileName,
            url: uploadedFile.url,
          });
        }
      }

      return (
        <AttachmentScreenshotComponent
          orderId={orderId}
          shipmentId={shipmentId || ''}
          serviceOfUploading={isShipment ? 'shipments' : 'default'}
          orderNumber={orderNumber}
          isUploading={fieldParams.uploading}
          setDisplayModal={setDisplayModal}
          files={fieldParams.files || null}
          text={fieldParams.text || 'Browse...'}
          mappedFiles={mappedFiles}
          // @ts-ignore
          screenshotHandler={screenshotHandlerComponent}
        />
      );
    }

    if (fieldParams.type === 'comment') {
      const textAreaHandlerComponent = (e) => {
        textAreaHandler(container, label, fieldParams, e);
      };

      return (
        <TextAreaComponent
          value={fieldParams.value}
          placeholder={fieldParams.placeholder}
          // @ts-ignore
          textAreaHandler={textAreaHandlerComponent}
        />
      );
    }

    if (fieldParams.type === 'textInput') {
      const textAreaHandlerComponent = (e) => {
        textAreaHandler(container, label, fieldParams, e);
      };

      return (
        <InputComponent
          value={fieldParams.value || ''}
          placeholder={fieldParams.placeholder}
          // @ts-ignore
          inputHandler={textAreaHandlerComponent}
        />
      );
    }

    if (fieldParams.type === 'datePicker') {
      const datePickerComponentHandler = (e) => {
        updateDatePickerField(container, label, fieldParams, e);
      };

      return (
        <DatePickerComponent
          disabledTime={fieldParams.disabledTime}
          expandRestrictedDays={fieldParams.expandRestrictedDays}
          value={fieldParams.value ? new Date(fieldParams.value) : new Date()}
          // @ts-ignore
          datePickerHandler={datePickerComponentHandler}
        />
      );
    }

    if (fieldParams.type === 'dateLabel') {
      return <DateLabelComponent text={fieldParams.text} />;
    }

    if (fieldParams.type === 'taskTable') {
      return (
        <TaskTableComponent
          caption={fieldParams.caption}
          fields={fieldParams.fields}
          data={fieldParams.data}
        />
      );
    }

    if (fieldParams.type === 'decisionTable') {
      return (
        <DecisionTableComponent
          onSelect={(applicationId, selectedValue) => {
            updateDecisionTable(applicationId, selectedValue);
          }}
          caption={fieldParams.caption}
          fields={fieldParams.fields}
          data={fieldParams.data}
        />
      );
    }
  };

  const showLabel = label && field.type !== 'decisionTable';

  return (
    <div className={styles.row}>
      {showLabel && (
        <div className={styles.rowTitle}>
          {field.isRequired ? (
            <>
              {HTMLReactParser(label)} <RequiredFieldMark>*</RequiredFieldMark>
            </>
          ) : (
            HTMLReactParser(label.replace(', optional', ''))
          )}
        </div>
      )}
      <div className={cn(styles.rowValue, hasFullWidth(field.type) && styles.rowValueFull)}>
        {renderByType(field)}
      </div>
    </div>
  );
};
