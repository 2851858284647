import React, { useCallback, useState } from 'react';

import { Tooltip, Typography } from 'fronton-react';
import { useMutation, useQuery } from 'react-query';

import { DocBlock, DocTitle, DocTitleText } from '@src/modules/documents/components/DocLayout';
import { defaultQueryOptions } from '@src/shared/services/query';

import { DownloadSection, StyledButton, StyledMultiPickDropdown, StyledPreloader } from './Styled';
import { downloadCoreReport, getCoreReportFilters } from './api';
import { FormState, validateForm, Filters } from './utils';
import { MultiPickDropdown } from '../../../components/MultiPickDropdown/MultiPickDropdown';

export const IDCCoreReportWidget = () => {
  const [form, setForm] = useState<FormState>({
    [Filters.departments]: [],
    [Filters.statuses]: [],
  });

  const onSelect = useCallback((filterName, values) => {
    setForm((state) => ({ ...state, [filterName]: values }));
  }, []);

  const validationError = validateForm(form);

  const download = useMutation({ mutationFn: () => downloadCoreReport(form) });

  const filtersQuery = useQuery({
    queryKey: getCoreReportFilters.getQueryKey(),
    queryFn: getCoreReportFilters.queryFn,
    ...defaultQueryOptions,
    retry: true,
    onSuccess: (data) => {
      setForm((state) => ({
        ...state,
        [Filters.departments]: data?.[Filters.departments]?.map?.(({ id }) => id) ?? [],
        [Filters.statuses]: data?.[Filters.statuses]?.map?.(({ id }) => id) ?? [],
      }));
    },
  });

  const departmentFilterItems = filtersQuery.data?.[Filters.departments] ?? [];
  const statusFilterItems = filtersQuery.data?.[Filters.statuses] ?? [];

  const areFiltersLoading = !filtersQuery.isSuccess;

  return (
    <DocBlock>
      <DocTitle>
        <Typography variant="h2" as="h2">
          <DocTitleText>Core report</DocTitleText>
        </Typography>
        <DownloadSection>
          {areFiltersLoading ? (
            <StyledPreloader />
          ) : (
            <>
              <MultiPickDropdown
                label="Department"
                placeholder="Choose department"
                name={Filters.departments}
                items={departmentFilterItems}
                selectedValues={form[Filters.departments]}
                onSelect={onSelect}
              />
              <StyledMultiPickDropdown
                label="Status"
                placeholder="Choose status"
                name={Filters.statuses}
                items={statusFilterItems}
                selectedValues={form[Filters.statuses]}
                onSelect={onSelect}
              />
            </>
          )}
          <Tooltip
            title={validationError ?? ''}
            open={!validationError || areFiltersLoading ? false : undefined}
          >
            <StyledButton
              variant="accent"
              size="s"
              disabled={!!validationError || areFiltersLoading}
              loading={download.isLoading}
              onClick={() => download.mutate()}
            >
              Download
            </StyledButton>
          </Tooltip>
        </DownloadSection>
      </DocTitle>
    </DocBlock>
  );
};
