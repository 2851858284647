import React from 'react';

import { DataCellProps } from '../types';

type ValueTypes = string | string[] | undefined;

type TdProps = {
  title: string;
  fieldName: string;
  children: string;
  className?: string;
};

const Td = ({ children, title, className, fieldName }: TdProps) => (
  <td data-field-id={fieldName} className={className} title={title}>
    {children}
  </td>
);

export const renderString = ({ value = '', className, field }: DataCellProps<ValueTypes>) => {
  const EMPTY_VALUE = '. . .';
  const isTextArray = Array.isArray(value);
  const { isTextEnum } = field;

  // TODO: maybe we should use same logic with isTextEnum values?
  // need design and analytics research
  if (isTextArray) {
    const text = value.length > 0 ? value.join(' / ') : EMPTY_VALUE;

    return (
      <Td className={className} title={text} fieldName={field.name}>
        {text}
      </Td>
    );
  }

  if (isTextEnum) {
    let title: string;
    let text: string;

    const values = value.split(', ');

    if (values[0] === '') {
      title = EMPTY_VALUE;
      text = EMPTY_VALUE;
    } else {
      title = value;
      // if more than 1 entry we split into "firstValue (+nRemaining)"
      text = values.length === 1 ? value : `${values[0]} (+${values.length - 1})`;
    }

    return (
      <Td className={className} title={title} fieldName={field.name}>
        {text}
      </Td>
    );
  }

  const text = value ?? EMPTY_VALUE;

  return (
    <Td className={className} title={text} fieldName={field.name}>
      {text}
    </Td>
  );
};
