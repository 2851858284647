import dayjs from 'dayjs';

import type { TPeriod } from '../../../../shared/components/Calendar/types';

const TitleDateFormat = 'DD MMM. YYYY';

const periodToText = (period: TPeriod) => {
  const from =
    (dayjs(period.from).isValid() && dayjs(period.from).format(TitleDateFormat)) || '...';
  const to = (dayjs(period.to).isValid() && dayjs(period.to).format(TitleDateFormat)) || '...';

  return `${from} — ${to}`;
};

export const defaultETAOptionIndex = 0;

export const defaultATAOptionIndex = 0;

export const allDataId = '3';

export const idsOptionWithoutCalendar = ['2', allDataId];

export const ETAFilterOptions = [
  {
    id: '0',
    name: 'period only',
    getTitle: (period: TPeriod) => `${periodToText(period)}`,
  },
  {
    id: '1',
    name: 'period and blank cells',
    getTitle: (period: TPeriod) => `${periodToText(period)} and blank cells`,
  },
  {
    id: '2',
    name: 'blank cells only',
    getTitle: () => `blank cells only`,
  },
  {
    id: allDataId,
    name: 'all data',
    getTitle: () => `all data`,
  },
];

export const ATAFilterOptions = [
  {
    id: '0',
    name: 'period only',
    getTitle: (period: TPeriod) => `${periodToText(period)}`,
  },
  {
    id: '1',
    name: 'period and blank cells',
    getTitle: (period: TPeriod) => `${periodToText(period)} and blank cells`,
  },
  {
    id: '2',
    name: 'blank cells only',
    getTitle: () => `blank cells only`,
  },
  {
    id: allDataId,
    name: 'all data',
    getTitle: () => `all data`,
  },
];
