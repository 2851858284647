import {
  CommonTable,
  TableRow,
  ClearAllEvent,
  ClearAllOnPageEvent,
  ClickEvent,
  FilterEvent,
  SelectAllOnPageEvent,
  SelectEvent,
  TableEventType,
  TableEvent,
  TableFieldName,
  TableColumn,
} from './types';

export const isTableEvent = (event: any): event is TableEvent =>
  typeof event === 'object' && event?.type;

export const isClickRowEvent = (event: TableEvent): event is ClickEvent =>
  event.type === TableEventType.click;

export const isClearAllEvent = (event: TableEvent): event is ClearAllEvent =>
  event.type === TableEventType.clearAll;

export const isClearAllOnPageEvent = (event: TableEvent): event is ClearAllOnPageEvent =>
  event.type === TableEventType.clearAllOnPage;

export const isFilterEvent = (event: TableEvent): event is FilterEvent =>
  event.type === TableEventType.filter;

export const isSelectAllOnPageEvent = (event: TableEvent): event is SelectAllOnPageEvent =>
  event.type === TableEventType.selectAllOnPage;

export const isSelectRowEvent = (event: TableEvent): event is SelectEvent =>
  event.type === TableEventType.select;

export const getFieldTheme = (row: TableRow, field: TableColumn) =>
  row?.metadata?.find((meta) => meta?.property === field.name)?.theme ?? '';

export const getIsSelectRowMode = (tableData: CommonTable) =>
  tableData.fields[0].id === TableFieldName.$isRowSelected;
