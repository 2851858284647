import { TableColumnDef } from '@src/shared/components/Table/types';

import { DirectDocType } from '../../../enums';
import { tableDefDirectInvoice } from './directInvoice';
import { tableDefDirectPi } from './directPi';

const tableDefDirectArf = tableDefDirectPi;

export const tableDefByDocType: Record<DirectDocType, TableColumnDef[]> = {
  [DirectDocType.pi]: tableDefDirectPi,
  [DirectDocType.arf]: tableDefDirectArf,
  [DirectDocType.invoice]: tableDefDirectInvoice,
};
