import { useCallback, useMemo, useRef } from 'react';

import {
  ITEM_FOR_ALL_ITEMS,
  formatInputWithMultipleValues,
  APPROXIMATE_COUNTER_WIDTH_IN_PX,
  APPROXIMATE_FITTING_LETTERS,
  SelectionState,
  getSelectionState,
} from './utils';

type UseMultiPickDropdownArgs = {
  name: string;
  items: { id: string; text: string }[];
  selectedValues: string[];
  onSelect: (filterName: string, values: string[]) => void;
};

export const useMultiPickDropdown = ({
  name,
  items,
  selectedValues,
  onSelect,
}: UseMultiPickDropdownArgs) => {
  // Used for 'text-overflow' css property access in dropdown input
  const wrapRef = useRef<HTMLDivElement>();
  // Used for measuring dropdown input length in px to detect text-overflow and drawing '(+1)' label at the end
  const inputLengthMeterRef = useRef<HTMLSpanElement>();

  const selectionState = useMemo(
    () => getSelectionState(selectedValues.length, items.length),
    [selectedValues.length, items.length],
  );

  const handleSelect = useCallback(
    (values) => {
      if (values.includes(ITEM_FOR_ALL_ITEMS.id)) {
        if (selectionState === SelectionState.all) {
          onSelect(name, []);

          return;
        }

        onSelect(
          name,
          items.map(({ id }) => id),
        );

        return;
      }

      onSelect(name, values);
    },
    [items, name, selectionState, onSelect],
  );

  const inputText = useMemo<string | undefined>(() => {
    if (selectionState === SelectionState.all) {
      return ITEM_FOR_ALL_ITEMS.text;
    }

    if (
      selectionState === SelectionState.none ||
      !inputLengthMeterRef.current ||
      !wrapRef.current
    ) {
      return undefined;
    }

    const inputElement: HTMLSpanElement | null = wrapRef.current.querySelector('button > span');

    if (!inputElement) {
      return undefined;
    }

    const inputPixelWidth = inputElement.clientWidth;

    const selectedItems = items
      .filter(({ id }) => selectedValues.includes(id))
      .map(({ text }) => text);

    // Case 1: only one item selected.
    // Use default dropdown input render logic
    if (selectedItems.length === 1) {
      inputElement.style.textOverflow = 'ellipsis';

      return selectedItems[0];
    }

    // Case 2: multiselect
    // Use custom dropdown input render logic (adding ', (+N)' label)
    // See algorithm explanation below
    inputElement.style.textOverflow = 'unset';
    let inputValue: string | undefined;

    // Iterating through loop of selected items, and trying to render them through invisible inputLengthMeter div HTML node
    for (let ind = 0; ind < selectedItems.length; ind++) {
      inputValue = formatInputWithMultipleValues(selectedItems, selectedItems.slice(0, ind + 1));

      inputLengthMeterRef.current.innerHTML = inputValue;
      const measuredWidth = inputLengthMeterRef.current.clientWidth;

      // If measured inputLengthMeter pixel length exceeded dropdown input real length (detecting by ref) minus some offset value, which could take ', (+N)' label (APPROXIMATE_COUNTER_WIDTH_IN_PX)
      // stopping loop at this point and render (ind - 1) elements in the input (others are hidden in label with hidden numbers)
      // Otherwise we reach end of loop and render all items without hiding
      // P.S. if first item is already bigger than input length - shorten it's size by APPROXIMATE_FITTING_LETTERS and adding counter label
      if (measuredWidth > inputPixelWidth - APPROXIMATE_COUNTER_WIDTH_IN_PX) {
        inputValue =
          ind > 0
            ? formatInputWithMultipleValues(selectedItems, selectedItems.slice(0, ind))
            : formatInputWithMultipleValues(
                selectedItems,
                [selectedItems[0].slice(0, APPROXIMATE_FITTING_LETTERS)],
                true,
              );

        break;
      }
    }

    return inputValue;
  }, [items, selectedValues, selectionState]);

  return { wrapRef, inputLengthMeterRef, inputText, selectionState, handleSelect };
};
