import React from 'react';

export const useInterval = (callback, delay) => {
  const savedCallback = React.useRef<() => void>();

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    if (delay !== null) {
      const id = setInterval(() => {
        savedCallback.current?.();
      }, delay);

      return () => clearInterval(id);
    }
  }, [delay]);
};
