import React from 'react';

import cn from 'classnames';

import { FileService } from '@src/shared/services/files';

import { ChronologyFile } from '../../../types/domain';
import { StaticDocsDownload } from '../../DocDownload';
import { ImageBox } from '../../ImageBox';
import { SetDisplayImageModal } from '../../ImageBox/types';

import styles from './index.css';

const imageTypes = new Set(['png', 'svg', 'gif', 'jpg', 'jpeg']);

type Props = {
  files: ChronologyFile[];
  setDisplayModal?: SetDisplayImageModal;
};

export const ChronologyFiles = ({ files, setDisplayModal }: Props) => {
  const imageFiles = files
    .map((file) => {
      return {
        name: file.name || '',
        url: file.url || '',
      };
    })
    .filter((file) => imageTypes.has(FileService.getFileExtension(file.name)));
  const nonImageFiles = files
    .map((file) => {
      return {
        name: file.name || '',
        url: file.url || '',
      };
    })
    .filter((file) => !imageTypes.has(FileService.getFileExtension(file.name)));

  return (
    <div>
      <div className={styles.dataFilesContainer}>
        <div className={cn(styles.boldText, styles.dataFilesLabel)}>Data files</div>
        {nonImageFiles.length > 0 && <StaticDocsDownload files={nonImageFiles} />}
        {imageFiles.length > 0 && <ImageBox setDisplayModal={setDisplayModal} files={imageFiles} />}
      </div>
    </div>
  );
};
