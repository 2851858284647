const ParsingFileStatusEnum = {
  Idle: 'idle',
  Processing: 'processing',
  HandleWithErrors: 'handleWithErrors',
  HandleWithWarnings: 'handleWithWarnings',
  HandleWithoutErrors: 'handleWithoutErrors',
  InvalidFileColumns: 'invalidFileColumns',
  InvalidFileRows: 'invalidFileRows',
  InternalError: 'internalError',
  FileTooBigError: 'fileTooBigError',
  UnexpectedMimeType: 'unexpectedMimeType',
  UploadingError: 'uploadingError',
};

export const FileStatus = {
  ...ParsingFileStatusEnum,
  WrongFileType: 'wrongFileType',
  RequestError: 'requestError',
};

export const FileStatusTexts = {
  [FileStatus.Idle]: 'File was uploaded. Parsing in progress…',
  [FileStatus.Processing]: 'File was uploaded. Parsing in progress…',
  [FileStatus.HandleWithoutErrors]: 'File was successfully parsed',
  [FileStatus.HandleWithErrors]: 'File was partially parsed',
  [FileStatus.HandleWithWarnings]: 'File was successfully parsed with a warning',
  [FileStatus.InvalidFileColumns]: 'File was not parsed',
  [FileStatus.InvalidFileRows]: 'File was not parsed',
  [FileStatus.FileTooBigError]: 'File was not parsed. The file must be less than 100 mb',
  [FileStatus.InternalError]: 'File was not parsed due to a technical error',
  [FileStatus.UnexpectedMimeType]: 'File was not parsed',
  [FileStatus.UploadingError]: 'Files were not uploaded',
  [FileStatus.RequestError]: 'File was not parsed due to a technical error',
  [FileStatus.WrongFileType]: 'Wrong file format, upload xls, xlsx instead',
};

export const FileStatusTextsShort: Record<keyof typeof FileStatusTexts, string> = {
  [FileStatus.Idle]: 'Parsing…',
  [FileStatus.Processing]: 'Parsing…',
  [FileStatus.HandleWithoutErrors]: 'Successfully parsed',
  [FileStatus.HandleWithErrors]: 'Partially parsed',
  [FileStatus.HandleWithWarnings]: 'Parsed with a warning',
  [FileStatus.InvalidFileColumns]: "Wasn't parsed",
  [FileStatus.InvalidFileRows]: "Wasn't parsed",
  [FileStatus.FileTooBigError]: 'The file must be less than 100 mb',
  [FileStatus.InternalError]: "Wasn't parsed. Technical error",
  [FileStatus.UnexpectedMimeType]: "Wasn't parsed",
  [FileStatus.UploadingError]: "Weren't uploaded",
  [FileStatus.RequestError]: "Wasn't parsed. Technical error",
  [FileStatus.WrongFileType]: 'Wrong file format, upload xls, xlsx instead',
};

export const DownloadFileStatusTexts = {
  [FileStatus.Idle]: 'parsing in progress',
  [FileStatus.Processing]: 'parsing in progress',
  [FileStatus.HandleWithoutErrors]: 'successful parsing',
  [FileStatus.HandleWithErrors]: 'partially parsed',
  [FileStatus.InvalidFileColumns]: 'invalid columns',
  [FileStatus.InvalidFileRows]: 'invalid rows',
  [FileStatus.FileTooBigError]: 'too big',
  [FileStatus.InternalError]: 'internal error',
  [FileStatus.UnexpectedMimeType]: 'unexpected mime type',
  [FileStatus.UploadingError]: 'upload error',
  [FileStatus.RequestError]: 'request error',
};

export const currentActivityEnum = {
  checkFileStatus: 'checkFileStatus',
  uploadFile: 'uploadFile',
  uploading: 'uploading',
  bootstrap: 'bootstrap',
  requestError: 'requestError',
  idle: '',
  undefined: '',
};
