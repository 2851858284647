import { validateEmail } from '@src/shared/services/validation/validateEmail';

export enum QuestionType {
  Technical = 'Technical',
  Navigator = 'Navigator',
  Other = 'Other',
}

export enum ProblemType {
  // RIES
  AuthorizationIssues = 'AuthorizationIssues',
  CantLogIn = 'CantLogIn',
  PageNotLoad = 'PageNotLoad',
  TaskNotCreatedNotCompleted = 'TaskNotCreatedNotCompleted',
  CanNotUploadDownloadFile = 'CanNotUploadDownloadFile',
  ReturnTask = 'ReturnTask',
  ReplaceArf = 'ReplaceArf',
  UploadNewShipmentDocument = 'UploadNewShipmentDocument',
  DeleteRowFromShipmentsTracking = 'DeleteRowFromShipmentsTracking',
  NotificationsNotWorksCorrectly = 'NotificationsNotWorksCorrectly',
  // Navigator
  NavigatorIncorrectOrMissingData = 'NavigatorIncorrectOrMissingData',
  NavigatorLineDuplication = 'NavigatorLineDuplication',
  NavigatorUpdateSpecialistsDistribution = 'NavigatorUpdateSpecialistsDistribution',
  NavigatorIncorrectDataFormat = 'NavigatorIncorrectDataFormat',
  NavigatorBookmarksIssues = 'NavigatorBookmarksIssues',
  NavigatorTechnicalIssue = 'NavigatorTechnicalIssue',
  NavigatorOtherQuestion = 'NavigatorOtherQuestion',
  // IDC
  IDCEditRequest = 'IDCEditRequest',
  IDCManualLinkingRequest = 'IDCManualLinkingRequest',
}

export enum UrlSearchKeys {
  question = 'question',
  problemType = 'problemType',
}

export type SupportRequestFormState = {
  email: string;
  question: QuestionType | null;
  problemType: ProblemType | null;
  comment: string;
  files: File[];
};

export const shouldDisplayAuthorizedForm = (
  isDoneParam: boolean,
  role: string | undefined,
): boolean => {
  if (role && typeof role === 'string' && !isDoneParam) {
    return true;
  }

  return false;
};

export const shouldDisplayAuthorizedSentForm = (
  isDoneParam: boolean,
  role: string | undefined,
): boolean => {
  if (role && typeof role === 'string' && isDoneParam) {
    return true;
  }

  return false;
};

export const shouldDisplayNotAuthorizedForm = (
  isDoneParam: boolean,
  role: string | undefined,
): boolean => {
  if (role === undefined && !isDoneParam) {
    return true;
  }

  return false;
};

export const shouldDisplayNotAuthorizedSentForm = (
  isDoneParam: boolean,
  role: string | undefined,
): boolean => {
  if (role === undefined && isDoneParam) {
    return true;
  }

  return false;
};

export const getFormValidationEmail = (state: SupportRequestFormState): boolean => {
  let isValid = true;

  if (isValid && !state) {
    isValid = false;
  }

  if (isValid && !state.email) {
    isValid = false;
  }

  if (isValid && !validateEmail(state.email)) {
    isValid = false;
  }

  return isValid;
};

export const getFormValidationEmailAndComment = (state: SupportRequestFormState): boolean => {
  let isValid = true;

  if (isValid && !state) {
    isValid = false;
  }

  if (isValid && !state.email) {
    isValid = false;
  }

  if (isValid && !validateEmail(state.email)) {
    isValid = false;
  }

  if (isValid && state.comment.trim().length === 0) {
    isValid = false;
  }

  return isValid;
};

export interface ICreateIncidentDTO {
  file: File[];
  problemType: ProblemType;
  comment: string;
  email: string;
  question: QuestionType;
}
