// @flow strict
import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import type { ListRow, FieldContainer } from '../../../../../../shared/types/domain';
import { getFullDifferenceBetweenDates } from '@src/app/utilities/dates';
import dayjs from 'dayjs';
import cookie from 'react-cookies';
import jwtDecode from 'jwt-decode';

import styles from './index.css';
import { userRoleDisplayMapping } from '@src/shared/enums/userRoleDisplayMapping';

type Props = {
    data: ListRow[] | null,
    fields: FieldContainer[] | null,
    width?: string,
    selected?: number,
    onSelect?: ListRow => void,
    linkTemplate: string => string
};

export default function List({ data, fields, width, onSelect, linkTemplate, selected }: Props) {
    let myRole = jwtDecode(cookie.load('auth').id_token).ved_role;
    myRole = userRoleDisplayMapping[myRole] ?? myRole; 

    function renderByType(value: string | number | string[], type: string) {
        if (type === 'date' && value && typeof value === 'string') {
            const textContent = dayjs(value).format('DD.MM.YYYY');
            return <span title={textContent}>{textContent}</span>;
        }
        if (type === 'estimatedTime' && value && typeof value === 'string') {
            const deadline = getFullDifferenceBetweenDates(new Date(value), new Date());
            if (deadline.negative)
                return (
                    <span title={deadline.withText} style={{ color: '#e30613' }}>
                        {deadline.withText}
                    </span>
                );
            return <span title={deadline.withText}>{deadline.withText}</span>;
        }

        if (Array.isArray(value) && value.length) {
            const textContent = value.join(' / ');
            return <span title={textContent}>{textContent}</span>;
        }
        const textContent = value || '. . .';
        return <span title={textContent}>{textContent}</span>;
    }

    return (
        <div className={styles.container}>
            <table className={styles.tableContainer} width={width}>
                <thead>
                    <tr className={styles.trHead}>
                        {fields &&
                            fields.map((field, index) => (
                                <th className={cn(styles.thHeading)} key={`table-heading-${field.id}-${index}`}>
                                    <span title={field.displayName} className={styles.title}>
                                        {field.displayName}
                                    </span>
                                </th>
                            ))}
                    </tr>
                </thead>
                <tbody>
                    {data &&
                        data.map((item, index) => (
                            <tr
                                className={cn(
                                    styles.row,
                                    selected !== undefined && selected === index && styles.selectedRow
                                )}
                                key={`table-row-${index}`}
                            >
                                {fields &&
                                    fields.map((field, index) => (
                                        <td key={`table-cell-${index}`}>
                                            {item.role && item.role === myRole ? (
                                                <Link to={linkTemplate(item.id)} className={styles.tdBody}>
                                                    {renderByType(item[field.name], field.type)}
                                                </Link>
                                            ) : (
                                                <div className={cn(styles.tdBody, styles.tdBodyDisabled)}>
                                                    {renderByType(item[field.name], field.type)}
                                                </div>
                                            )}
                                        </td>
                                    ))}
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
    );
}
