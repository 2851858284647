import { STSubReportFilter } from '../../../../enums';

export type BookmarkInfo = {
  id: STSubReportFilter;
  upperTitle: string;
  bottomTitle: string;
  placeholder: string;
};

export const bookmarksConfig = [
  {
    id: STSubReportFilter.allProcessed,
    upperTitle: 'All',
    bottomTitle: 'processed broker reports',
    placeholder:
      'This tab contains all Broker reports processed by the system, which have been fully or partially parsed. Next to the file is the result of the last parsing. The 3 latest versions of each uploaded report are also available to download.',
  },
  {
    id: STSubReportFilter.lastProcessing,
    upperTitle: '0',
    bottomTitle: 'in the process of parsing',
    placeholder:
      'This tab contains the downloaded files that are in the process of parsing. After parsing is complete, they will disappear from the page. If the parsing was completed successfully or the files were partially parsed, the parsing results will be available on the «processed broker reports» tab. All unloaded and un-parsed files will go to the «new rejected» tab.',
  },
  {
    id: STSubReportFilter.lastRejected,
    upperTitle: '0',
    bottomTitle: 'last rejected',
    placeholder:
      'This tab contains files that were not uploaded or were unsuccessful parsed. Check out the errors and upload the correct files. The files are automatically deleted 10 work days after they are uploaded.',
  },
];
