import { AxiosError } from 'axios';

import { httpClient } from '@src/shared/services/httpClient';

import { FileUploadError } from './enums/FileUploadError';
import { docTypes } from './requestDocTypes';
import { UploadFilesOptions, UploadFilesResult } from './types';
import { pickUploadErrorMessage, sortErrorKeys, validateFile } from './utils';

export const uploadFiles = async ({
  files,
  url,
  formData = new FormData(),
  partialSendMode = false,
}: UploadFilesOptions): Promise<UploadFilesResult> => {
  const filesInfo: UploadFilesResult['files'] = {
    all: files,
    accepted: [],
    rejected: [],
  };
  let uploadErrors: UploadFilesResult['errors'];
  let request: UploadFilesResult['request'];

  for (const fileDescriptor of files) {
    const { file, docType } = fileDescriptor;

    const docTypeInfo = docTypes[docType];

    const error = docTypeInfo ? validateFile(file, docTypeInfo) : undefined;

    if (error) {
      uploadErrors ??= {};
      uploadErrors[error] ??= { files: [], text: pickUploadErrorMessage(error, docType) };
      uploadErrors[error]?.files.push(fileDescriptor);
      filesInfo.rejected.push(fileDescriptor);
    } else {
      formData.append(docType, file);
      filesInfo.accepted.push(fileDescriptor);
    }
  }

  if (uploadErrors) {
    uploadErrors = sortErrorKeys(uploadErrors);

    // Should abort uploading
    if (!partialSendMode) {
      filesInfo.accepted.length = 0;
    }
  }

  if (filesInfo.accepted.length > 0) {
    try {
      const result = await httpClient({
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        url,
        data: formData,
      });

      request = { result };
    } catch (error) {
      request = { error: error as AxiosError };
      uploadErrors ??= {};
      uploadErrors[FileUploadError.serverError] = {
        files: filesInfo.accepted,
        text: pickUploadErrorMessage(FileUploadError.serverError, filesInfo.accepted[0].docType),
      };
      filesInfo.accepted = [];
      filesInfo.rejected = files;
    }
  }

  return {
    request,
    files: filesInfo,
    errors: uploadErrors,
    status: {
      isSuccess: files.length === filesInfo.accepted.length,
      isPartialSuccess: Boolean(filesInfo.accepted.length > 0 && uploadErrors),
      isFail: filesInfo.accepted.length === 0,
    },
  };
};
