import React from 'react';

import styles from '../../styles.module.scss';
import { CommonTable } from '../../types';
import { HeaderCell } from '../Cell';
import { useActiveDropdown } from './useActiveDropdown';

type HeaderProps = {
  tableData: CommonTable;
  allEnabledTableFilters: any;
  onTableEvent: <EventDto>(e: EventDto) => void;
};

export const Header: React.FC<HeaderProps> = ({
  tableData,
  allEnabledTableFilters,
  onTableEvent,
}) => {
  const { activeDropdown, openDropdown } = useActiveDropdown();

  return (
    <thead>
      <tr className={styles.trHead} data-row-id="thead">
        {tableData.fields.map((field) => (
          <HeaderCell
            key={field.id}
            field={field}
            tableData={tableData}
            allEnabledTableFilters={allEnabledTableFilters}
            activeDropdown={activeDropdown}
            openDropdown={openDropdown}
            onTableEvent={onTableEvent}
          />
        ))}
      </tr>
    </thead>
  );
};
