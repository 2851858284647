import React, { Dispatch } from 'react';

import { FileButton } from 'mp-ui-components';

import { TShipmentTracking } from '@src/modules/documents/types';

import { currentActivityEnum, FileStatusTexts } from '../../../../enums/enums';
import { StatusText } from './Styled';

import styles from './styles.css';

type Props = {
  dispatch: Dispatch<any>;
  docType: string;
  state: any;
};

export const UploadReports = ({ dispatch, docType, state }: Props) => {
  const { upload, currentActivity } = state;
  const { error } = upload;

  return (
    <div className={styles.body}>
      {error && (
        <div>
          <StatusText status="error">Files were not uploaded.</StatusText>
          <StatusText>{FileStatusTexts[error]}</StatusText>
        </div>
      )}

      {currentActivity === currentActivityEnum.checkFileStatus && (
        <div>
          <StatusText>Files were uploaded.</StatusText>
          <StatusText status="parsing">Parsing in progress…</StatusText>
        </div>
      )}

      <FileButton
        id={docType}
        onChange={(files) => {
          if (files) {
            dispatch({
              type: 'uploadFiles',
              payload: {
                files,
              },
            });
          }
        }}
        theme="secondary"
        fileTypes={['xls', 'xlsx']}
        isMultiple
        isPending={currentActivity === currentActivityEnum.uploading}
      >
        Upload...
      </FileButton>
    </div>
  );
};
