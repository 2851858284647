import React from 'react';

import cn from 'classnames';
import { DatepickerRange } from 'mp-ui-components';

import { getDifference } from '@src/app/utilities/dates';
import type { FieldContainer } from '@src/shared/types/domain';

import type { DatepickerRangeChangeHandlerProps } from './types';

import DottedLine from './dotted-line.svg';

import styles from './index.css';

type PeriodFormat = {
  from: Date | null;
  to: Date | null;
};

type SelectedPeriodFormat = {
  from: Date;
  to: Date;
};

function getHasSelectedDate(selectedDate: PeriodFormat): selectedDate is SelectedPeriodFormat {
  return Boolean(selectedDate.from && selectedDate.to);
}

type Props = {
  selectedFilterWindow: string | undefined;
  selectFilterWindow: (arg0: string) => void;
  DatepickerRangeChangeHandler: (
    changes: DatepickerRangeChangeHandlerProps,
    filterName: string,
  ) => void;
  field: FieldContainer;
  filterOptions: any;
};

const DateDropdown: React.FC<Props> = ({
  selectedFilterWindow,
  selectFilterWindow,
  DatepickerRangeChangeHandler,
  field,
  filterOptions,
}) => {
  const tommorowDate = new Date(new Date().setDate(new Date().getDate() + 1));

  const onIsOpenHandler = (state) => {
    if (state) {
      selectFilterWindow(field.name);
    } else if (field.name === selectedFilterWindow) {
      selectFilterWindow('');
    }
  };

  const filterValue = filterOptions[field.name];

  const selectedDate = {
    from: filterValue?.from ? new Date(filterValue?.from) : null,
    to: filterValue?.to ? new Date(filterValue?.to) : null,
  };

  const hasSelectedDate = getHasSelectedDate(selectedDate);

  return (
    <DatepickerRange
      todayText="Today"
      yesterdayText="Yesterday"
      clearDateText="Clear"
      isLookingInPast={field.isLookingPast}
      disabledPeriods={
        field.isLookingPast
          ? [
              {
                from: tommorowDate,
                to: null,
              },
            ]
          : []
      }
      period={selectedDate}
      onChange={(changes) => {
        DatepickerRangeChangeHandler(changes, field.name);
      }}
      onIsOpenChange={onIsOpenHandler}
    >
      <div
        data-testid="datepicker-dropdown-testid"
        className={cn(
          styles.title,
          (selectedFilterWindow === field.name && styles.titleSelected) ||
            (hasSelectedDate && styles.titleSelected),
        )}
      >
        <span
          className={styles.dotted}
          style={{
            backgroundImage: `url(${DottedLine})`,
            filter:
              selectedFilterWindow === field.name || hasSelectedDate
                ? 'brightness(1)'
                : 'brightness(0)',
          }}
          title={field.displayName}
        >
          {field.displayName}
        </span>
        {hasSelectedDate && (
          <span className={styles.selectedCount}>
            {getDifference(selectedDate.from, selectedDate.to).days + 1}
          </span>
        )}
      </div>
    </DatepickerRange>
  );
};

export { DateDropdown };
