// @flow strict
import React from 'react';
import { Button, Tabs, Tab } from 'mp-ui-components';

import { useNavigate, useParams } from 'react-router-dom';
import styles from './index.css';
import type { ChronologyResponse } from '../../../../shared/types/domain';

import { ErrorReloadableComponent } from '../../../../shared/components/Error';
import Preloader from '@src/shared/components/Preloader';
import { ShipmentInfoBar } from '@src/shared/components/InfoBar';
import ChronologyContainer from '../../../../shared/components/ChronologyContainer';
import ImagePreview from '../../../../shared/components/ImagePreview';
import {
  requestShipmentHeader,
  requestShipmentChronology,
} from '../ShipmentsListPage/services/shipments';

import ShipmentTasks from './components/shipment-tasks';
import ShipmentDocuments from './components/shipment-documents';
import { RoutingService } from '@src/shared/services/routing';

type TaskState = {|
  chronology: ChronologyResponse[],
  header: {
    eta: string,
    etd: string,
    isMix: boolean,
    portOfDischarge: string,
    containersOrTrucks: string[],
    departments: string[],
    transport: string,
  } | null,
|};

export const initialState = {
  chronology: [],
  header: null,
};

const getTabIndex = (tabName: string | null | typeof undefined) => {
  if (tabName === 'documents') return 0;
  if (tabName === 'tasks') return 1;
  return 0;
};

function ShipmentView() {
  const [state, setState] = React.useState<TaskState>(initialState);
  const [isPending, setPending] = React.useState(true);
  const [failedStatus, setFailedStatus] = React.useState(null);
  const [displayModal, setDisplayModal] = React.useState(null);
  const navigate = useNavigate();


  const isUnmounted = React.useRef(false);

  const locationParams = useParams();
  const shipmentId = locationParams.id;
  const tabSelected = getTabIndex(locationParams.tab);

  React.useEffect(() => {
    return () => {
      isUnmounted.current = true;
    };
  }, []);

  // Request for shipment
  React.useEffect(() => {
    async function fetchShipment() {
      if (shipmentId) {
        await requestChronology(shipmentId);
        await requestHeader(shipmentId);
        setPending(false);
      }
    }

    fetchShipment();
  }, [locationParams.id]);

  async function requestChronology(id: string) {
    await requestShipmentChronology(id)
      .then((response) => {
        setState((old) => {
          return { ...old, chronology: response };
        });
      })
      .catch((err) => {
        setFailedStatus((old) => {
          return { ...old, chronology: err.response ? err.response.status : 'network' };
        });
        console.error(err);
      });
  }

  async function requestHeader(id: string) {
    await requestShipmentHeader(id)
      .then((response) => {
        setState((old) => {
          return { ...old, header: response };
        });
      })
      .catch((err) => {
        setFailedStatus((old) => {
          return { ...old, header: err.response ? err.response.status : 'network' };
        });
        console.error(err);
      });
  }

  const renderErrors = () => {
    if (failedStatus) {
      if (failedStatus.chronology || failedStatus.header) {
        return (
          <ErrorReloadableComponent
            theme="white"
            width="s"
            margin={30}
            title="The shipment can't be loaded"
            status={failedStatus.chronology || failedStatus.header}
          />
        );
      }
    }
    return null;
  };

  function tabClickHandler(url: string) {
    if (shipmentId) {
      navigate(`${RoutingService.root.routes.shipments}/${shipmentId}/${url}`);
    }
  }

  const renderShipment = () => {
    return (
      <>
        {state.header && (
          <ShipmentInfoBar
            ETA={state.header.eta}
            ETD={state.header.etd}
            departments={state.header.departments}
            transport={state.header.transport}
            containersOrTrucks={state.header.containersOrTrucks}
            portOfDischarge={state.header.portOfDischarge}
            isMix={state.header.isMix}
          />
        )}
        <div className={styles.container}>
          <ChronologyContainer setDisplayModal={setDisplayModal} data={state.chronology} />
          <div className={styles.rightContainer}>
            <Tabs className={styles.tabsContainer} defaultActiveTabIndex={tabSelected}>
              <Tab
                onClick={() => {
                  tabClickHandler('documents');
                }}
                badge={null}
                index={0}
                title="Documents"
              >
                <ShipmentDocuments shipmentId={locationParams.id} />
              </Tab>
              <Tab
                onClick={() => {
                  tabClickHandler('tasks');
                }}
                badge={null}
                index={1}
                title="Active Tasks"
              >
                <ShipmentTasks shipmentId={locationParams.id} />
              </Tab>
            </Tabs>
          </div>
        </div>
        {displayModal && (
          <ImagePreview
            files={displayModal.files}
            index={displayModal.index}
            onClose={() => {
              setDisplayModal(null);
            }}
          />
        )}
      </>
    );
  };

  if (isPending) return <Preloader />;

  if (failedStatus) return renderErrors();

  return renderShipment();
}

export default ShipmentView;
