import dayjs from 'dayjs';

import type { TUploadFileOption } from '../../../../types';

import { STSubReportsTransformed } from '../../../../types';

export function getReportUploadOptions(data: {
  files: File[];
  docType: string;
}): TUploadFileOption {
  return {
    files: data.files,
    day: dayjs().format('YYYY-MM-DD'),
    docType: data.docType,
  };
}

export function hasReports(reports: STSubReportsTransformed): boolean {
  return (
    !!reports.lastRejected?.length ||
    !!reports.lastProcessing?.length ||
    Object.keys(reports.lastN).length > 0
  );
}

export function getEtaDateOptions(latestDateOfPlanEtaPodInFu: string): {
  from: Date;
  to: Date;
} {
  const latest = dayjs(latestDateOfPlanEtaPodInFu || '');
  const today = dayjs();

  if (latest.isAfter(today)) {
    return {
      from: today.toDate(),
      to: latest.toDate(),
    };
  }

  if (latest.isSameOrBefore(today)) {
    return {
      from: latest.toDate(),
      to: latest.toDate(),
    };
  }
}
