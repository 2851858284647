import React from 'react';

import { PaginationController } from '@src/shared/components/PaginationController';

import { useActionButtons } from './useActionButtons/useActionButtons';
import { useTable } from './useTable/useTable';

export const usePageControls = () => {
  const {
    tableData,
    paginatorState,
    fetchTableData,
    enabledFilters,
    onTableEvent,
    changeTablePage,
    triggerTableUpdate,
  } = useTable();

  const isTableDataLoading = fetchTableData.isLoading && !tableData;
  const isTableDataError =
    fetchTableData.isError && (fetchTableData?.error?.response?.status ?? 'network');

  const paginatorNode = (
    <PaginationController
      page={paginatorState.page}
      isPending={isTableDataLoading}
      isNextDisabled={paginatorState.isNextPageAvailable}
      onChange={changeTablePage}
    />
  );

  const actionButtonsNode = useActionButtons({
    onSuccess: () => {
      triggerTableUpdate();
      fetchTableData.mutate({ useScrollToTop: false });
    },
  });

  return {
    isTableDataLoading,
    isTableDataError,
    tableData,
    enabledFilters,
    actionButtonsNode,
    paginatorNode,
    onTableEvent,
  };
};
