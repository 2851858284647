import { DocumentsApi } from '@src/modules/documents/api';
import { CreateColumn } from '@src/shared/components/Table';
import { TableColumnFilterType } from '@src/shared/components/Table/_deprecated/Table/types';
import { AuthService, UserRole } from '@src/shared/services/auth';

import { FieldAccessor } from '../../mappers';

export const INIT_PAGE_INDEX = 0;

export const INIT_PAGE_SIZE = 20;

export const createColumnDef = () => {
  const showPerformerRoleColumn = AuthService.getIsUserRoleMatches([
    UserRole.Forwarder,
    UserRole.CustomsBroker,
  ]);

  const performerColumnAccessor = showPerformerRoleColumn
    ? FieldAccessor.performerRole
    : FieldAccessor.userName;

  return [
    CreateColumn.latestFiles({
      name: FieldAccessor.creationDate,
      displayName: 'Uploaded ↓',
      isFilterable: true,
    }),
    CreateColumn.text({
      name: performerColumnAccessor,
      displayName: showPerformerRoleColumn ? 'Performer role' : 'Performer',
      isFilterable: true,
      disableMinRestriction: true,
      filterType: TableColumnFilterType.dictionary,
      getFilters: DocumentsApi.shipmentsTracking.getArchiveReportsFilters,
    }),
    CreateColumn.parsingState({
      name: FieldAccessor.parsingResult,
      displayName: 'Parsing result',
      isFilterable: true,
      disableMinRestriction: true,
      filterType: TableColumnFilterType.dictionary,
      getFilters: DocumentsApi.shipmentsTracking.getArchiveReportsFilters,
    }),
    CreateColumn.parsingDetails({
      name: FieldAccessor.convertedErrors,
      displayName: 'Details',
    }),
  ];
};
