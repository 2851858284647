import { Typography } from 'fronton-react';
import { styled } from 'linaria/lib/react';

export const Wrap = styled.div`
  width: 256px;
  /* compensating ui-kit forced margin */
  margin-top: -10px;

  button {
    border-radius: var(--rounding-m);
  }

  button > * {
    text-align: left;
  }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
// Fronton typings problem
export const InputLengthMeter = styled(Typography as any)`
  visibility: hidden;
  position: absolute;
  white-space: nowrap;
`;
