// @flow strict
import dayjs from 'dayjs';
import type {
  ListRow,
  FieldContainer,
  FilterType,
  MappedFilterType,
} from '../../../../shared/types/domain';
import { AuthService } from '@src/shared/services/auth';

export type MappedData = {|
  tasks: ListRow[],
  myTasks: ListRow[],
|};

export function mapDataFromDTOWithRoles(response: ListRow[]): MappedData {
  const tasks = [];
  const myTasks = [];

  const me = AuthService.getUserIdToken()?.fullName;

  response.forEach((row) => {
    const mappedRow = {};
    for (let key in row) {
      mappedRow[key] = row[key];
    }
    if (!mappedRow.id) mappedRow.id = '' + Math.random();
    mappedRow.performer = mappedRow.performer === me ? 'me' : mappedRow.performer;
    if (mappedRow.performer && mappedRow.performer === 'me') {
      myTasks.push(mappedRow);
    } else {
      tasks.push(mappedRow);
    }
  });
  return { myTasks, tasks };
}

export function mapDataFromDTO(response: ListRow[]): ListRow[] {
  return response.map((row) => {
    const mappedRow = {};
    for (let key in row) {
      mappedRow[key] = row[key];
    }
    if (!mappedRow.id) mappedRow.id = '' + Math.random();
    return { ...mappedRow };
  });
}

export function mapFieldsFromDTO(response: FieldContainer[]): FieldContainer[] {
  return response.map((field) => {
    if (!field.id) field.id = `${field.name}-id`;
    field.filterType = field.type;
    field.filter = field.isFilterable;
    return field;
  });
}

export function mapSelectedFilters(filters: FilterType): MappedFilterType {
  const mapResult = {};
  for (let filter in filters) {
    if (filters[filter].type === 'date') {
      if (filters[filter].from !== null && filters[filter].to !== null) {
        mapResult[filter] = {
          from: filters[filter].from && dayjs(filters[filter].from).format('YYYY-MM-DD'),
          to: filters[filter].to && dayjs(filters[filter].to).format('YYYY-MM-DD'),
        };
      }
    } else if (Array.isArray(filters[filter]) && filters[filter].length > 0) {
      mapResult[filter] = filters[filter].map((mapFilter) => {
        return mapFilter.id;
      });
    }
  }
  return mapResult;
}
