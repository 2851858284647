import invert from 'lodash/invert';

import { STSubReportType } from '@src/modules/documents/enums/STSubReportType';
import { FileStatusTextsShort } from '@src/modules/documents/enums/enums';
import { TableName } from '@src/shared/components/Table/_deprecated/Table/types';
import { UserRole } from '@src/shared/services/auth';

export const TableNameByDocType = {
  [STSubReportType.BrokerReports]: TableName.BrokerReportsArchive,
  [STSubReportType.ForwarderReports]: TableName.ForwarderReportsArchive,
};

export const DocTypeByTableName = invert(TableNameByDocType);

export enum FieldAccessor {
  creationDate = 'creationDate',
  performerRole = 'performerRole',
  userName = 'userName',
  parsingResult = 'parsingResult',
  convertedErrors = 'convertedErrors',
}

export const remappedUserRoleNames = {
  [UserRole.TransportGroupSpecialist]: 'Transport Group',
  [UserRole.CustomsBroker]: 'Broker',
};

export const remappedUserNames = {
  [UserRole.TransportGroupSpecialist]: 'Transport Group',
};

export const fieldTextMappers = {
  [FieldAccessor.performerRole]: remappedUserRoleNames,
  [FieldAccessor.userName]: remappedUserNames,
  [FieldAccessor.parsingResult]: FileStatusTextsShort,
};
