/* eslint-disable radix */
const monthsCutsForChronology = [
  'Jan.',
  'Feb.',
  'Mar.',
  'Apr.',
  'May',
  'Jun.',
  'Jul.',
  'Aug.',
  'Sep.',
  'Oct.',
  'Nov.',
  'Dec.',
];
const monthsCutsForReports = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

function countValue(single, many, value) {
  if (Math.abs(value) > 1) {
    return many;
  }

  return single;
}

function getMinutesTimeInfo(days, hours, minutes, negative, fullLetters) {
  let letter = fullLetters ? countValue('minute', 'minutes', minutes) : 'min';
  let minutesToLetter = minutes;

  if (days > 0) {
    minutesToLetter = days;
    letter = fullLetters ? countValue('day', 'days', days) : 'd';
  } else if (hours > 0) {
    minutesToLetter = hours;
    letter = fullLetters ? countValue('hour', 'hours', hours) : 'h';
  }

  return {
    minutes,
    letter,
    minutesToLetter,
    negative,
  };
}

export function getMonth(date: Date) {
  if (!date.getMonth) {
    return '';
  }

  return monthsCutsForChronology[date.getMonth()];
}

export function getShortDate(date: string) {
  if (date) {
    const formated = new Date(date);

    if (formated instanceof Date) {
      return {
        day: formated.getDate(),
        month: monthsCutsForReports[formated.getMonth()],
        year: formated.getFullYear(),
        shortTime: `${formated.getHours()}:${formated.getMinutes()}`,
      };
    }
  }

  return {
    day: '',
    month: '',
    year: '',
    shortTime: '',
  };
}

export function getDifference(fromDate: Date, toDate: Date) {
  let difference = Number(fromDate) - Number(toDate);
  let minutes = 0;
  let hours = 0;
  let days = 0;
  let negative = false;

  if (difference < 0) {
    negative = true;
    difference = Math.abs(difference);
  }

  const nMilliseconds = difference;
  const nSeconds = Number.parseInt(String(nMilliseconds / 1000));
  const nMinutes = Number.parseInt(String(nSeconds / 60));
  const nHours = Number.parseInt(String(nMinutes / 60));
  const nDays = Number.parseInt(String(nHours / 24));

  days = nDays;
  hours = (nHours % 24) * 1;
  minutes = (nMinutes % 60) * 1;

  return {
    minutes,
    hours,
    days,
    negative,
  };
}

function getDeadlineInfoParsed(minutesArg: number, isDelayed: boolean) {
  let hours = 0;
  let days = 0;
  let minutes = minutesArg;
  const nHours = Number.parseInt(String(minutes / 60));
  const nDays = Number.parseInt(String(nHours / 24));

  days = nDays;
  hours = (nHours % 24) * 1;
  minutes = (minutes % 60) * 1;
  const negative = isDelayed;

  return {
    days,
    hours,
    minutes,
    negative,
  };
}

function displayIfPosible(countable, value) {
  if (Math.abs(countable) > 0) {
    return value;
  }

  return '';
}

function getFullDifferenceTimeInfo(days, hours, minutes, negative) {
  let withText = '';

  if (days && !hours) {
    withText = displayIfPosible(days, `${Math.abs(days)} ${Math.abs(days) > 1 ? 'days' : 'day'}`);
  } else if (days && hours) {
    withText = `${displayIfPosible(
      days,
      `${Math.abs(days)} ${Math.abs(days) > 1 ? 'days' : 'day'}`,
    )} ${displayIfPosible(hours, `${Math.abs(hours)} ${Math.abs(hours) > 1 ? 'hours' : 'hour'}`)}`;
  } else if (hours && !minutes) {
    withText = displayIfPosible(
      hours,
      `${Math.abs(hours)} ${Math.abs(hours) > 1 ? 'hours' : 'hour'}`,
    );
  } else if (hours && minutes) {
    withText = `${displayIfPosible(
      hours,
      `${Math.abs(hours)} ${Math.abs(hours) > 1 ? 'hours' : 'hours'}`,
    )} ${displayIfPosible(Math.abs(minutes), `${Math.abs(minutes)} min`)}`;
  } else if (minutes) {
    withText = displayIfPosible(minutes, `${Math.abs(minutes)} min`);
  }

  if (negative) {
    withText = `–${withText}`;
  }

  if (minutes === 0 && hours === 0 && days === 0) {
    withText = 'now';
  }

  return {
    minutes,
    hours,
    days,
    negative,
    withText,
  };
}

export function getMinutesBetweenDates(fromDate: Date, toDate: Date, fullLetters?: boolean) {
  if (!fromDate.getTime || !toDate.getTime) {
    return {
      minutes: 0,
      minutesToLetter: 0,
      letter: 'min',
      negative: false,
    };
  }

  const differenceResult = getDifference(fromDate, toDate);
  const { days, hours, minutes, negative } = differenceResult;

  return getMinutesTimeInfo(days, hours, minutes, negative, fullLetters);
}

export function getFullDifferenceBetweenDates(fromDate: Date, toDate: Date) {
  if (!fromDate.getTime || !toDate.getTime) {
    return {
      minutes: 0,
      hours: 0,
      days: 0,
      negative: true,
      withText: '',
    };
  }

  const differenceResult = getDifference(fromDate, toDate);
  const { days, hours, minutes, negative } = differenceResult;

  return getFullDifferenceTimeInfo(days, hours, minutes, negative);
}

export function getFullDifferenceBetweenDatesFromDeadlineInfo(deadlineInfo) {
  const parsedData = getDeadlineInfoParsed(deadlineInfo.delay, deadlineInfo.isDelayed);
  const { days, hours, minutes, negative } = parsedData;

  return getFullDifferenceTimeInfo(days, hours, minutes, negative);
}

export function getMinutesFromDeadlineInfo(deadlineInfo, fullLetters?: boolean) {
  const parsedData = getDeadlineInfoParsed(deadlineInfo.delay, deadlineInfo.isDelayed);
  const { days, hours, minutes, negative } = parsedData;

  return getMinutesTimeInfo(days, hours, minutes, negative, fullLetters);
}
