import { styled } from 'linaria/react';

import { TableTemplate } from '@src/shared/components/Table';

export const Table = styled(TableTemplate)`
  & > thead > tr >,
  & > tbody > tr > {
    td,
    th {
      background-color: transparent;
    }

    td {
      box-sizing: border-box;
      padding: 2px 12px 2px 0;
      overflow: hidden;
      outline: none !important;
      color: inherit;
      font-size: 12px;
      line-height: 18px;
      text-decoration: none;
      text-overflow: ellipsis;
      word-break: break-word;
      white-space: nowrap;
    }

    td {
      &[data-field-id='entityId'],
      &[data-field-id='piNumber'],
      &[data-field-id='direction'] {
        max-width: 74px;
      }

      &[data-field-id='name'] {
        max-width: 200px;
      }

      &[data-field-id='containerOrTruck'] {
        max-width: 96px;
      }

      &[data-field-id='supplier'] {
        max-width: 152px;
      }

      &[data-field-id='performer'] {
        max-width: 117px;
      }

      &[data-field-id='state'] {
        max-width: 65px;
      }

      &[data-field-id='uiErrors'] {
        max-width: 407px;
      }
    }
  }

  &[data-table-name='directInvoice'] > thead > tr >,
  &[data-table-name='directInvoice'] > tbody > tr > {
    td {
      &[data-field-id='uiErrors'] {
        max-width: 215px;
      }
    }
  }

  &[data-table-name='idcEditPhoto'] > thead > tr >,
  &[data-table-name='idcEditPhoto'] > tbody > tr > {
    td {
      &[data-field-id='entityId'] {
        max-width: 105px;
      }
    }
  }
`;
