import { UserRole } from '@src/shared/services/auth';

export type TOrderArticleId = string;

type TOrderArticleCanBlock = {
  isDisabled: boolean;
  isChecked: boolean;
};

export type TOrderArticle = {
  id: string;
  adeoCode: string;
  [key: string]: string;
} & {
  canBlock: TOrderArticleCanBlock;
};

export enum ArticleActions {
  BlockUnblock = 'BlockUnblock',
  EditGKLinking = 'EditGKLinking',
}

export const getAvailableActions = (articles: TOrderArticle[], userRole?: string) => {
  if (userRole === UserRole.AdeoSupplier) {
    return [ArticleActions.BlockUnblock];
  }

  if (
    userRole === UserRole.ImportSpecialist &&
    articles.filter(({ idcId }) => idcId).length === articles.length
  ) {
    return [ArticleActions.EditGKLinking];
  }

  return [];
};
