import { BaseQueryDef } from './types';

export const createGetQueryKey = <TQueryDef extends BaseQueryDef<any, any>>(url: string) =>
  ((options) => [url, options]) as TQueryDef['getQueryKey'];

export const createQueryDef = <TQueryDef extends BaseQueryDef<any, any>>(
  url: string,
  queryFn: TQueryDef['queryFn'],
) => ({
  queryFn,
  getQueryKey: createGetQueryKey<TQueryDef>(url),
});
