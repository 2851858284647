import React from 'react';

export const PAGINATION_PAGE_SIZE = 200;

type PaginatorState = {
  page: number;
  pageSize: number;
  isNextPageAvailable: boolean;
};

export const useTablePagination = () => {
  const [paginatorState, setPaginatorState] = React.useState<PaginatorState>({
    page: 0,
    pageSize: PAGINATION_PAGE_SIZE,
    isNextPageAvailable: false,
  });

  const changeTablePage = React.useCallback(
    (page: number) => {
      if (page !== paginatorState.page) {
        setPaginatorState((prevState) => {
          return { ...prevState, page };
        });
      }
    },
    [paginatorState.page],
  );

  return {
    paginatorState,
    setPaginatorState,
    changeTablePage,
  };
};
