import { styled } from 'linaria/lib/react';

import { FormTarget } from './hooks/useFormContext';

export const Wrap = styled.div<{ disableTopOffset: boolean; target: FormTarget }>`
  margin-top: ${({ disableTopOffset }) => (disableTopOffset ? 0 : '25px')};
  margin-bottom: 25px;
  padding: 0 25px;
  background: #fff;
`;

export const RequiredFieldsHelper = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const RequiredFieldMark = styled.span`
  color: var(--attention-primary);
  font-size: '1.2em';
  font-weight: bold;
`;

export const Fields = styled.div`
  display: flex;
  flex-direction: column;
`;
