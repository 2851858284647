import sanitize from "sanitize-html";

import { isValidUrl } from '@src/app/utilities/isValidUrl';

export const sanitizeObject = (data: any) => {
  if (typeof data !== "object" || data === null) { // for Primitive
    return typeof data === "string" && !isValidUrl(data) ? sanitize(data): data;
  }
  if (Array.isArray(data)) { // for Array
    return data.map((item) => sanitizeObject(item));
  } else if (data instanceof Date) { // for Date
    return data;
  } else { // for Object
    for (const key of Object.keys(data)) {
      data[key] = sanitizeObject(data[key]);
    }
  }
  return data;
};
