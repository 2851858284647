// @flow strict
import React from 'react';
import cn from 'classnames';
import styles from './index.css';
import { LeftArrowIcon, RightArrowIcon, XMark } from './icons';

import type { ImageBoxFile } from '../../../Workspace/components/image-box/types';

type Props = {|
    files: ImageBoxFile[],
    index: number,
    onClose: () => void
|};

const initalControls = {
    left: true,
    right: true,
    zoomIn: true,
    zoomOut: true
};

function ImagePreview({ files, index, onClose }: Props) {
    const [currentIndex, setCurrentIndex] = React.useState(index);
    const [disabledControls, setDisabledControls] = React.useState(initalControls);

    React.useEffect(() => {
        document.addEventListener('keydown', setImageIndexKeyboard);
        let controls = { ...initalControls };
        if (currentIndex !== 0) {
            controls.left = false;
        }
        if (currentIndex !== files.length - 1) {
            controls.right = false;
        }
        setDisabledControls(controls);
        return () => {
            document.removeEventListener('keydown', setImageIndexKeyboard);
        };
    }, [currentIndex]);

    const [scaled, setScaled] = React.useState(1);

    const file = files[currentIndex];
    const { name, url } = file;

    const imageContainerRef = React.createRef();

    document.addEventListener('mouseup', () => {
        document.removeEventListener('mousemove', moveImage);
    });

    function setImageIndexKeyboard(e: KeyboardEvent) {
        if (e.key === 'ArrowLeft') previousImage();
        if (e.key === 'ArrowRight') nextImage();
        if (e.key === 'Escape') onClose();
    }

    function previousImage() {
        if (currentIndex !== 0) setCurrentIndex(currentIndex - 1);
    }

    function nextImage() {
        if (currentIndex !== files.length - 1) setCurrentIndex(currentIndex + 1);
    }

    const startDragImage = () => {
        document.addEventListener('mousemove', moveImage);
    };

    const moveImage = (event: MouseEvent) => {
        if (imageContainerRef.current) {
            if (event.movementY < 0) {
                imageContainerRef.current.scrollTop += -event.movementY;
            } else if (event.movementY > 0) {
                imageContainerRef.current.scrollTop -= event.movementY;
            }

            if (event.movementX < 0) {
                imageContainerRef.current.scrollLeft += -event.movementX;
            } else if (event.movementX > 0) {
                imageContainerRef.current.scrollLeft -= event.movementX;
            }
        }
    };

    const openInNewTab = () => {
        const win = window.open(url, '_blank');
        win.focus();
    };

    return (
        <div className={styles.container}>
            <div className={styles.imageBackground} onClick={onClose} />
            <div className={styles.imageNameContainer}>
                <div className={styles.imageName}>{name}</div>
                <span onClick={openInNewTab} className={styles.openInTab}>
                    Open in new tab
                </span>
            </div>
            <div className={styles.closeIcon} onClick={onClose}>
                <XMark />
            </div>
            <div className={styles.imageContainer} onMouseDown={startDragImage} ref={imageContainerRef}>
                <img
                    style={{ maxWidth: scaled * 100 + '%', maxHeight: scaled * 100 + '%' }}
                    draggable={false}
                    alt={url}
                    className={styles.image}
                    src={url}
                />
            </div>
            {files.length > 1 && (
                <div className={styles.controls}>
                    <div
                        onClick={previousImage}
                        className={cn(styles.control, disabledControls.left && styles.controlDisabled)}
                    >
                        <LeftArrowIcon />
                    </div>
                    <div
                        onClick={nextImage}
                        className={cn(styles.control, disabledControls.right && styles.controlDisabled)}
                    >
                        <RightArrowIcon />
                    </div>
                </div>
            )}
        </div>
    );
}

export default ImagePreview;
