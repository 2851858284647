import { CurrencyCodes, OrderMode } from '@lmru-bpms-ved/core';

import { ListRow, FieldContainer } from '@src/shared/types/domain';

import { OrderDTO } from '../pages/OrderPage/types';

export type OrderDocumentsResponse = {
  data: OrderDTO[];
  zipUrl: string;
};

type OcDTO = {
  eta: string | null;
  etd: string | null;
  orderConfirmationNumber: string | null;
  orderConfirmationDate?: string;
};

type RmsDTO = {
  eta: string | null;
  etd: string | null;
  orderConfirmationNumber: string | null;
};

export type OrderResponseDTO = {
  _id: string;
  number: string;
  creationDate: string;
  rmsData: RmsDTO;
  ocData: OcDTO;
  mode: OrderMode;
  supplier: {
    name: string;
    currency: CurrencyCodes;
  };
  department: {
    shortName: string;
  };
  warehouse: {
    name: string;
  };
};

export type GetOrdersRequesResponse = {
  data: ListRow[];
  fields: FieldContainer[];
};

type AtlasCodeType = {
  value: string;
  isBold?: boolean;
};

export type CertTrackItems = {
  atlasCodes: AtlasCodeType[];
};

export enum OrderPriority {
  Priority = 'Priority',
  Regular = 'Regular',
}
