import React from 'react';

import cn from 'classnames';
import { Button } from 'mp-ui-components';

import { useFormContext } from './hooks/useFormContext';

import styles from './index.css';

export const ActionsPanel = () => {
  const { state, isButtonDisabled, getButtonPendingState, terminalButtonHandler } =
    useFormContext();

  return (
    <div
      className={cn(
        styles.submitContainer,
        state.performer && state.performer !== 'me' && styles.submitContainerTakeFromPerformer,
      )}
    >
      {state.terminalButtons &&
        state.terminalButtons.map((terminalButton) => (
          <Button
            key={terminalButton.id}
            isDisabled={isButtonDisabled(terminalButton.id)}
            isPending={getButtonPendingState(terminalButton.id)}
            onClick={() => {
              terminalButtonHandler(terminalButton.id);
            }}
            theme={terminalButton.type}
          >
            {terminalButton.caption}
          </Button>
        ))}
      {state.performer && state.performer !== 'me' && (
        <div className={styles.submitContainerTakeFromPerformerLabel}>
          Task is being processed by {state.performer}
        </div>
      )}
    </div>
  );
};
