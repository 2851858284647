import React, { useCallback, useEffect, useState } from 'react';

import cn from 'classnames';
import { Button } from 'mp-ui-components';
import { useNavigate, useSearchParams } from 'react-router-dom';

import StaticFileUploaderComponent from '@src/shared/components/Input/StaticFileUploader';
import * as Components from '@src/shared/components/TaskComponents';
import { AuthService, UserRole } from '@src/shared/services/auth';
import { RoutingService } from '@src/shared/services/routing';
import { validateEmail } from '@src/shared/services/validation/validateEmail';

import { questionsConfig, requestFormInfoMessage } from '../../config';
import { createIncident } from '../../services/helpAndSupport';
import {
  getFormValidationEmail,
  ProblemType,
  QuestionType,
  SupportRequestFormState,
  UrlSearchKeys,
} from '../../types/common';
import { FormComponent } from './FormComponent';

import styles from './CreateRequestForm.scss';

// TODO Legacy
// remove after converting from flow to ts
const { RadioComponent, TextAreaComponent, InputComponent } = Components as any;
const StaticFileUploader = StaticFileUploaderComponent as any;

export const CreateRequestForm = () => {
  const userToken = AuthService.getUserIdToken();
  const isLoggedIn = !!userToken;
  const userEmail = userToken?.email ?? '';

  const [searchParams, setSearchParams] = useSearchParams();
  const questionFromUrl = searchParams.get(UrlSearchKeys.question);
  const problemTypeFromUrl = searchParams.get(UrlSearchKeys.problemType);

  const [isSending, setSending] = useState(false);
  const [isFailed, setFailed] = useState(false);
  const [isEmailInvalid, setEmailInvalid] = useState(false);
  const navigate = useNavigate();

  const [formState, setFormState] = useState<SupportRequestFormState>({
    email: userEmail,
    question: questionFromUrl as QuestionType,
    problemType: problemTypeFromUrl as ProblemType,
    comment: '',
    files: [],
  });

  const questionsList = isLoggedIn ? questionsConfig.authorizedPage : questionsConfig.publicPage;

  const questionsRadioOptions = React.useMemo(
    () =>
      questionsList.map(({ id, text }) => ({
        id,
        text,
      })),
    [questionsList],
  );

  const problemTypesRadioOptions = React.useMemo(
    () => questionsList.find(({ id }) => id === questionFromUrl)?.problemTypes ?? [],
    [questionsList, questionFromUrl],
  );

  const shownProblemTypesRadioOptions = React.useMemo(
    () => (problemTypesRadioOptions.length <= 1 ? undefined : problemTypesRadioOptions),
    [problemTypesRadioOptions],
  );

  const onSelectQuestion = useCallback(
    (value: string) => {
      searchParams.set(UrlSearchKeys.question, value);
      searchParams.delete(UrlSearchKeys.problemType);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const onSelectProblemType = useCallback(
    (value: string) => {
      searchParams.set(UrlSearchKeys.problemType, value);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  useEffect(() => {
    if (problemTypesRadioOptions.length === 1) {
      onSelectProblemType(problemTypesRadioOptions[0].id);
    }
  }, [problemTypesRadioOptions, onSelectProblemType]);

  useEffect(() => {
    setFormState((prevState) => ({
      ...prevState,
      question: questionFromUrl as QuestionType,
      problemType: problemTypeFromUrl as ProblemType,
    }));
  }, [questionFromUrl, problemTypeFromUrl]);

  const submitHandler = (e) => {
    if (e) {
      e.preventDefault();
    }

    setSending(true);
    setFailed(false);
    createIncident({
      incidentForm: formState,
    })
      .then(() => {
        navigate(`${RoutingService.root.routes.helpAndSupport}/done?${searchParams}`);
      })
      .catch(() => {
        setFailed(true);
        setSending(false);
      });
  };

  const backToAuthHandler = (e) => {
    if (e) {
      e.preventDefault();
    }

    navigate(RoutingService.root.routes.sign);
  };

  const hasProblemTypes = problemTypesRadioOptions.length > 0;
  const isCommentFilled = formState.comment.trim().length > 0;
  const isEmailFilled = formState.email.trim().length;
  const isEmailValid = validateEmail(formState.email);

  const isFormValid = Boolean(
    formState.question &&
      (formState.problemType || !hasProblemTypes) &&
      isEmailValid &&
      isCommentFilled,
  );

  const infoMessage = isLoggedIn
    ? requestFormInfoMessage.authorizedPage
    : requestFormInfoMessage.publicPage;

  return (
    <article className={styles.container}>
      <div className={styles.title}>Help & Support</div>
      <form className={styles.form} onSubmit={submitHandler}>
        <div className={styles.formDescription}>{infoMessage}</div>

        <div className={styles.divider} />

        <section className={styles.formInput}>
          <FormComponent label="Your email">
            <section className={styles.formContentHigher}>
              <InputComponent
                name="email"
                value={formState.email}
                isInvalid={isEmailInvalid}
                autoFocus
                onBlur={() => {
                  if (isEmailFilled && !getFormValidationEmail(formState)) {
                    setEmailInvalid(true);
                  } else {
                    setEmailInvalid(false);
                  }
                }}
                inputHandler={(value) => {
                  setEmailInvalid(false);
                  setFormState((prevState) => ({ ...prevState, email: value }));
                }}
                width={242}
              />
            </section>
            <section
              className={cn(styles.emailHint, isEmailInvalid && styles.emailHintInvalid)}
              aria-label="Email field is required"
            >
              {isEmailInvalid && `Invalid email`}
              {!isEmailInvalid && !isEmailFilled && (
                <span role="alert">This field is required</span>
              )}
            </section>
          </FormComponent>
        </section>

        <section className={styles.formInput}>
          <FormComponent label="Question">
            <RadioComponent
              name="question"
              selectRadio={onSelectQuestion}
              selected={formState.question}
              options={questionsRadioOptions}
            />
          </FormComponent>
        </section>

        {shownProblemTypesRadioOptions && (
          <section className={cn(styles.formInput, styles.problemTypeSection)}>
            <FormComponent label="Problem type">
              <RadioComponent
                name="problemType"
                selectRadio={onSelectProblemType}
                selected={formState.problemType}
                options={shownProblemTypesRadioOptions}
              />
            </FormComponent>
          </section>
        )}

        <section className={styles.formInput}>
          <FormComponent label="Comment">
            <section className={styles.formInputComment}>
              <TextAreaComponent
                name="comment"
                minHeight={113}
                value={formState.comment}
                textAreaHandler={(value) =>
                  setFormState((prevState) => ({ ...prevState, comment: value }))
                }
                placeholder={
                  AuthService.getIsUserRoleMatches([UserRole.Forwarder, UserRole.CustomsBroker])
                    ? 'Keep here details'
                    : 'Keep here details: OC No., Order No. or another details If necassary and describe the problem'
                }
              />
            </section>

            <section className={styles.commentHint} aria-label="Comment field is required">
              {formState.comment.length === 0 && <span role="alert">This field is required</span>}
            </section>
          </FormComponent>
        </section>

        <section className={styles.formInput}>
          <FormComponent label="Files, optional">
            <section className={styles.formContentHigher}>
              <StaticFileUploader
                name="files"
                filesDisplay="row"
                isMultiple
                files={formState.files}
                text="Browse..."
                onFilesAttached={(files) => {
                  setFormState((prevState) => ({ ...prevState, files }));
                }}
              />
            </section>
          </FormComponent>
        </section>
        <footer className={styles.footer}>
          <Button
            theme="primary"
            onClick={submitHandler}
            type="button"
            isDisabled={!isFormValid}
            isPending={isSending}
          >
            SEND THE MESSAGE
          </Button>
          {!isLoggedIn && (
            <Button
              theme="darkSecondary"
              onClick={backToAuthHandler}
              type="button"
              className={styles.backToAuthButton}
            >
              BACK TO THE AUTHORIZATION
            </Button>
          )}
          {isFailed && (
            <div className={styles.errorHint}>
              {`The message was not sent\u00A0due to\u00A0a\u00A0technical error.\nPlease try again later`}
            </div>
          )}
        </footer>
      </form>
    </article>
  );
};
