import React, { ReactNode } from 'react';

import { SearchResults } from './components/Filter/TextFilter/api/types';

export enum TableEventType {
  click = 'click',
  select = 'select',
  selectAllOnPage = 'selectAllOnPage',
  clearAll = 'clearAll',
  clearAllOnPage = 'clearAllOnPage',
  filter = 'filter',
}

type TableRowMetadataItem = {
  property: string;
  [key: string]: string;
};

export type TableRow = {
  id: string;
  metadata?: TableRowMetadataItem[];

  [key: string]: any;
  // Table service fields added on UI
  $isRowSelected?: boolean;
};

export enum TableFieldName {
  $isRowSelected = '$isRowSelected',
  // TODO - extend
}

export enum RowsSelectionStatus {
  // If selections are made only on current page:
  none = 'none',
  partial = 'partial',
  all = 'all',
  // If selections are made also on other pages:
  multipage = 'mutlipage',
}

export enum TableFieldType {
  text = 'text',
  date = 'date',
  estimateDate = 'estimateDate',
  checkbox = 'checkbox',
  estimatedTime = 'estimatedTime',
  latestFiles = 'latestFiles',
  parsingDetails = 'parsingDetails',
  parsingState = 'parsingState',
}

export enum TableColumnFilterType {
  dictionary = 'dictionary',
}

export type TableColumn = {
  type: TableFieldType;
  name: string;
  displayName: string;
  id?: string;
  filterApiVersion?: number;
  isTextEnum?: boolean;
  isFilterable?: boolean;
  isMultiSearchFilter?: boolean;
  isLookingPast?: boolean;
  disableMinResctriction?: boolean; // TODO: there is misstype on BFF: "disableMinRes_C_triction". Should be fixed for disableMinRestriction and removed from API on both sides
  disableMinRestriction?: boolean;
  modificators?: string[];
  filterType?: TableColumnFilterType;
  filter?: boolean;
  // eslint-disable-next-line no-use-before-define
  getFilters?: GetFiltersFn;
  // State added on UI
  $state?: {
    selectionStatus: RowsSelectionStatus;
    selectionOptions: Array<{ id: TableEventType; displayName: string }> | undefined;
  };
  customRenderer?: (rowData: TableRow) => ReactNode
};

type GetFiltersFn = (params: {
  field: TableColumn;
  searchItems: string[];
  tableName: string;
  signal: AbortSignal;
}) => Promise<SearchResults>;

export type CommonTable = { fields: TableColumn[]; rows: TableRow[] };

export enum TableElementRole {
  rowSelector = 'rowSelector',
  rootRowSelector = 'rootRowSelector',
}

export type TableEvent<EventType = unknown, Payload = unknown> = {
  type: EventType;
  payload: Payload;
};

export type ClickEvent = TableEvent<TableEventType.click, { rowId: string }>;

export type SelectEvent = TableEvent<TableEventType.select, { rowId: string }>;

export type SelectAllOnPageEvent = TableEvent<TableEventType.selectAllOnPage, undefined>;

export type ClearAllEvent = TableEvent<TableEventType.clearAll, undefined>;

export type ClearAllOnPageEvent = TableEvent<TableEventType.clearAllOnPage, undefined>;

export type FilterEvent = TableEvent<TableEventType.filter, { filterParams: any }>;

export enum TableName {
  Orders = 'order',
  Tasks = 'task',
  Shipments = 'shipment',
  DirectPi = 'directPi',
  DirectArf = 'directArf',
  DirectInvoice = 'directInvoice',
  ForwarderReportsArchive = 'forwarderReportsArchive',
  BrokerReportsArchive = 'brokerReportsArchive',
}

export type TableProps = {
  tableName: TableName;
  tableData: CommonTable;
  allEnabledTableFilters: any; // TODO - add type
  noResultsMessage: string;
  actionButtonsNode?: React.ReactNode;
  paginatorNode?: React.ReactNode;
  className?: string;
  onTableEvent: <EventDto>(e: EventDto) => void;
};

export type TableContextValue = Pick<
  TableProps,
  'tableName' | 'tableData' | 'allEnabledTableFilters' | 'onTableEvent'
>;

export type TableMeta = {
  tableName: TableName;
  paginatorNode?: ReactNode;
  activeDropdown?: string | undefined;
  uploadedRowsIds?: string[];
  setUploadedRowsIds?: (ids: string[]) => void;
  openDropdown?: (fieldName: string) => void;
};
