import { AuthService, UserRole } from '@src/shared/services/auth';

import { STSubReportTab } from '../../../widgets/STSubReportWidget';

export const getBrokersAndForwardersReportsTabs = (stReports: undefined): STSubReportTab[] => {
  if (
    !stReports ||
    !AuthService.getIsUserRoleMatches([
      UserRole.CustomsGroupSpecialist,
      UserRole.CustomsDocs,
      UserRole.ApprovisionSpecialist,
      UserRole.ImportSpecialist,
      UserRole.SampleCoordinator,
    ])
  ) {
    return [];
  }

  if (AuthService.getIsUserRoleMatches([UserRole.CustomsGroupSpecialist])) {
    return [STSubReportTab.latest, STSubReportTab.archive];
  }

  return [STSubReportTab.archive];
};
