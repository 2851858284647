import React, { ReactNode } from 'react';

import ReactDOM from 'react-dom';

import { ErrorBar } from '@src/shared/components/Error/ErrorBar';
import { usePortal } from '@src/shared/utils/hooks/useReactPortal';

const portalRootId = 'actionErrorPortalRoot';

type Props = {
  children: ReactNode;
};

type ExtendedRFC = React.FC<Props> & { $portalRootId: string };

export const ActionError: ExtendedRFC = ({ children }: Props) => {
  const portalTarget = usePortal(portalRootId);

  if (!portalTarget) {
    return null;
  }

  return ReactDOM.createPortal(<ErrorBar>{children}</ErrorBar>, portalTarget);
};

ActionError.$portalRootId = portalRootId;
