import React from 'react';

import { ErrorReloadableComponent } from '@src/shared/components/Error';
import Preloader from '@src/shared/components/Preloader';

import { currentActivityEnum } from '../../enums/enums';
import { TShipmentTracking } from '../../types';
import { STReportDownloadWidget } from '../../widgets/STReportDownloadWidget';
import { STSubReportWidget, prepareReports } from '../../widgets/STSubReportWidget';
import { reportsList } from './config';
import { reducer } from './domain/shipmentTracking/reducer';
import { toEntity as init } from './domain/shipmentTracking/shipmentTracking';
import { useGetGrouped } from './hooks/useGetGrouped';

import styles from './styles.module.scss';

export const ShipmentsTrackingPage = () => {
  const [state, dispatch] = React.useReducer(
    reducer,
    { currentActivity: currentActivityEnum.bootstrap } as TShipmentTracking,
    init,
  );

  useGetGrouped(dispatch, state.currentActivity === currentActivityEnum.bootstrap);

  if (state.currentActivity === currentActivityEnum.bootstrap) {
    return <Preloader />;
  }

  if (state.currentActivity === currentActivityEnum.requestError) {
    return (
      <div className={styles.container}>
        <ErrorReloadableComponent
          title={"The page can't be loaded"}
          text="Server respond with errors"
          theme="white"
        />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.reportsLabel}>Generating of the Shipments tracking</div>

      <STReportDownloadWidget
        latestDateOfPlanEtaPodInFu={state.latestDateOfPlanEtaPodInFu}
        latestDateUpdateShipmentTracking={state.latestDateUpdateShipmentTracking}
      />

      {reportsList.map((options) => (
        <STSubReportWidget
          {...options}
          key={options.docType}
          reports={prepareReports(state, options.docType)}
          canUploadReports
        />
      ))}
    </div>
  );
};
