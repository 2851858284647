import React from 'react';

import { Button } from 'mp-ui-components';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { AuthService } from '@src/shared/services/auth';
import { RoutingService } from '@src/shared/services/routing';

import { requestCreatedMsg } from '../../config';
import { QuestionType, UrlSearchKeys } from '../../types/common';

import styles from './RequestCreatedInfo.scss';

export const RequestCreatedInfo = () => {
  const userToken = AuthService.getUserIdToken();
  const isLoggedIn = !!userToken;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const questionFromUrl = searchParams.get(UrlSearchKeys.question);

  const askAnotherQuestionHandler = (e) => {
    if (e) {
      e.preventDefault();
    }

    navigate(RoutingService.root.routes.helpAndSupport);
  };

  const backToAuthHandler = (e) => {
    if (e) {
      e.preventDefault();
    }

    navigate(RoutingService.root.routes.sign);
  };

  const infoMessage = React.useMemo(() => {
    if (!isLoggedIn && questionFromUrl === QuestionType.Technical) {
      return requestCreatedMsg.loginCredsRequest;
    }

    if (questionFromUrl === QuestionType.Other) {
      return requestCreatedMsg.keyUserWillCheckRequest;
    }

    return requestCreatedMsg.supportTeamWillCheckRequest;
  }, [questionFromUrl, isLoggedIn]);

  const buttons = isLoggedIn ? (
    <Button theme="primary" onClick={askAnotherQuestionHandler} type="button">
      ASK ANOTHER QUESTION
    </Button>
  ) : (
    <>
      <Button
        theme="primary"
        onClick={backToAuthHandler}
        type="button"
        className={styles.backToAuthButton}
      >
        BACK TO THE AUTHORIZATION
      </Button>
      <Button theme="darkSecondary" onClick={askAnotherQuestionHandler} type="button">
        ASK ANOTHER QUESTION
      </Button>
    </>
  );

  return (
    <section className={styles.container}>
      <div className={styles.title}>Help & Support</div>
      <div className={styles.form}>
        <div className={styles.formDescription}>{infoMessage}</div>
        <footer className={styles.footer}>{buttons}</footer>
      </div>
    </section>
  );
};
