import React from 'react';

import cn from 'classnames';
import { Dropdown } from 'mp-ui-components';

import { FileService } from '@src/shared/services/files';

import {
  getDateAsFormattedText,
  getDownloadFileStatusText,
  isFileStatusOk,
} from '../../../../../../../../pages/ShipmentsTrackingPage/domain/trackingFile/trackingFileDto';
import { TDownloadTrackingFiles } from '../../../../../../../../types';
import { getPerformerLabel } from '../getPerformerLabel';

import styles from './DownloadDropdown.scss';

export const DownloadDropdown = ({ files }: { files: TDownloadTrackingFiles }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const onRowClick = (file) => {
    FileService.downloadFile(file.url);
    setIsOpen(false);
  };

  const lastThreeFiles = files.slice(0, 3);

  return (
    <Dropdown className={styles.body} isOpen={isOpen} onChange={() => setIsOpen(!isOpen)}>
      <Dropdown.Trigger className={styles.trigger}>
        <span className={styles.downloadBtn}>Download</span>
        <span className={styles.arrowDown} />
      </Dropdown.Trigger>
      <Dropdown.Content className={styles.content} ariseDirection="bottom-start">
        {lastThreeFiles.map((file) => {
          const performer = getPerformerLabel(file);

          return (
            <div key={file.id} className={cn(styles.row)} onClick={() => onRowClick(file)}>
              {getDateAsFormattedText(file.created)}
              {performer ? ` by ${performer}, ` : ', '}
              <span className={isFileStatusOk(file.status) ? styles.statusOk : styles.statusNotOk}>
                {getDownloadFileStatusText(file.status)}
              </span>
            </div>
          );
        })}
      </Dropdown.Content>
    </Dropdown>
  );
};
