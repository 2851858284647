import React from 'react';

import { Typography } from '@src/shared/components/Typography';

import { i18n } from '../../../../pages/DirectModeDocsPage/i18n';
import { useUploader } from '../../hooks/useUploader';

type StatusLabelProps = {
  uploadResult: ReturnType<typeof useUploader>['data'];
};

export const StatusLabel = ({ uploadResult }: StatusLabelProps) => {
  const status = uploadResult?.status;
  const acceptedFilesAmount = uploadResult?.files?.accepted?.length ?? '';
  const totalFilesAmount = uploadResult?.files?.all?.length ?? '';

  if (status?.isSuccess) {
    return <Typography>{i18n.uploadButtonSucces}</Typography>;
  }

  if (status?.isPartialSuccess) {
    return (
      <Typography data-status="error">{`${acceptedFilesAmount} of ${totalFilesAmount} files were uploaded`}</Typography>
    );
  }

  if (status?.isFail) {
    return <Typography data-status="error">{i18n.uploadButtonFail}</Typography>;
  }

  return null;
};
