import React, { ReactNode } from 'react';

import HTMLReactParser from 'html-react-parser';

import { ParsingDetails } from '@src/shared/components/ParsingDetails';
import { ParsingDetail } from '@src/shared/components/Table/columns/parsingDetails/types';

interface IUiAttachmentError {
  description: string;
  subErrors?: ParsingDetail[];
}

interface IUiAttachmentErrorDto {
  message: string;
  details: IUiAttachmentError[];
}

export function parseAttachmentError(err: IUiAttachmentErrorDto): {
  message: ReactNode;
  details: ReactNode;
} {
  const message = HTMLReactParser(err?.message);
  const details = err?.details;

  return {
    message,
    details:
      details && details.length > 0
        ? details.map(({ description, subErrors }) => (
            // TODO: Разобраться c этим правилом. Почему-то иногда косячит в купе с prettier
            // eslint-disable-next-line react/jsx-indent
            <>
              {HTMLReactParser(description)}
              <br />
              {subErrors && (
                <>
                  <ParsingDetails details={subErrors} isOpenByDefault />
                  <br />
                </>
              )}
            </>
          ))
        : '',
  };
}
