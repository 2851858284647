import React from 'react';

import cn from 'classnames';
import { Dropdown } from 'mp-ui-components';

import DottedLine from '@src/shared/assets/svg/DottedLine.svg';

import { useTableContext } from '../../../context/TableContext';
import { HeaderCellProps } from '../../Cell';
import { FilterMenu } from './components/FilterMenu/FilterMenu';

import styles from './styles.module.scss';

export const TextFilter: React.FC<HeaderCellProps> = ({ field, activeDropdown, openDropdown }) => {
  const { allEnabledTableFilters } = useTableContext();

  const isActive = activeDropdown === field.name;
  const enabledOptionsAmount = allEnabledTableFilters[field.name]?.length ?? 0;
  const hasEnabledOptions = enabledOptionsAmount > 0;

  return (
    <Dropdown isOpen={isActive} onChange={() => openDropdown(field.name)}>
      <Dropdown.Trigger>
        <div
          data-testid="dropdown-trigger"
          className={cn(
            styles.title,
            (isActive && styles.titleSelected) || (hasEnabledOptions && styles.titleSelected),
          )}
        >
          <span
            className={styles.dotted}
            style={{
              backgroundImage: `url(${DottedLine})`,
              filter: isActive || hasEnabledOptions ? 'brightness(1)' : 'brightness(0)',
            }}
            title={field.displayName}
          >
            {field.displayName}
          </span>
          {hasEnabledOptions && (
            <span className={styles.selectedCount}>{enabledOptionsAmount}</span>
          )}
        </div>
      </Dropdown.Trigger>
      <Dropdown.Content ariseDirection="bottom" className={styles.filterContent}>
        {isActive && <FilterMenu field={field} />}
      </Dropdown.Content>
    </Dropdown>
  );
};
