import React, { useState } from 'react';

import { Typography } from 'fronton-react';
import HTMLReactParser from 'html-react-parser';
import { styled } from 'linaria/lib/react';

import { FoldableText } from './FoldableText';
import { getTextNode } from './Table/columns/parsingDetails/getTextNode';
import { ParsingDetail } from './Table/columns/parsingDetails/types';

// TODO: fix fronton typings
export const Info = styled(Typography as any)`
  max-width: 440px;
  font-weight: 400;
  white-space: normal;

  span {
    color: #999;

    &.tableDetailsItemTitle {
      color: #000;
      font-weight: 600;
    }
  }
`;

export const StyledFoldableText = styled(FoldableText)`
  font-weight: ${({ isOpened }) => (isOpened ? 600 : 400)};
`;

type Props = {
  details: ParsingDetail[];
  isOpenByDefault?: boolean;
  key?: string | number;
};

export const ParsingDetails = ({ details, key, isOpenByDefault = false }: Props) => {
  const [isOpened, setIsOpened] = useState(isOpenByDefault);

  if (details.length === 0) {
    return <>. . .</>;
  }

  if (details.length === 1 && !details[0].info) {
    return <>{HTMLReactParser(details[0].title)}</>;
  }

  const { title } = details[0];

  const textNode = getTextNode(details);

  const titleSuffix = details.length > 1 && !isOpened ? ' and others' : '';

  return (
    <StyledFoldableText
      key={key} // Must be used to reset folding state
      title={`${title}${titleSuffix}`}
      text={<Info>{textNode}</Info>}
      isOpened={isOpened}
      onOpen={setIsOpened}
    />
  );
};
