import React from 'react';

import { ParsingDetail } from './types';
import { ParsingDetails } from '../../../ParsingDetails';
import { RTCellContext } from '../../types';
import { Td } from '../Td';

export const ParsingDetailsCell = (props: RTCellContext) => {
  const { getValue, table } = props;
  const currentPage = table.getState().pagination.pageIndex;

  const details: ParsingDetail[] = getValue() ?? [];

  return (
    <Td rtContext={props}>
      <ParsingDetails details={details} key={currentPage} />
    </Td>
  );
};
