import React from 'react';

import cn from 'classnames';

import { RequestFilterFromInputDTO } from '@src/shared/types/domain';
import { replaceAdeoText } from '@src/shared/utils/mappers/replaceAdeoText';

import styles from './index.css';

export type OptionsListItem = RequestFilterFromInputDTO & {
  isApplied?: boolean;
};

type Props = {
  items: OptionsListItem[];
  filterName: string;
  addFilter: (option: RequestFilterFromInputDTO, filterName: string) => void;
  removeFilter: (option: RequestFilterFromInputDTO, filterName: string) => void;
};

export const OptionsList: React.FC<Props> = ({ items, filterName, addFilter, removeFilter }) => {
  const needTextReplacer = filterName === 'shipmentState';

  return (
    <div className={styles.filterContainer}>
      {items.map((item) => (
        <div
          key={`filter-menu-option-${item.id}`}
          data-test-id="filter-menu-option"
          onClick={() =>
            item.isApplied ? removeFilter(item, filterName) : addFilter(item, filterName)
          }
          className={cn(styles.searchResult)}
        >
          {item.number && <div className={styles.searchResultNumber}>{item.number}</div>}
          <div className={styles.searchResultText}>
            {needTextReplacer ? replaceAdeoText(item.text) : item.text}
          </div>
          <div
            className={cn(
              styles.searchResultControl,
              item.isApplied ? styles.searchResultRemove : styles.searchResultAdd,
            )}
          >
            {item.isApplied ? '–' : '+'}
          </div>
        </div>
      ))}
    </div>
  );
};
