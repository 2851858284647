// @flow strict
import React from 'react';
import styles from './index.css';
import List from '@src/shared/components/Table/_deprecated/TableList';
import { AddShipmentModal } from './components/AddShipmentModal';
import type { ListRow, FieldContainer, FilterType } from '../../../../shared/types/domain';

import { Button } from 'mp-ui-components';
import { mapFieldsFromDTO, mapSelectedFilters } from './mapper';
import { requestShipments } from './services/shipments';

import { ErrorReloadableComponent } from '../../../../shared/components/Error';
import Preloader from '@src/shared/components/Preloader';
import { PaginationController } from '@src/shared/components/PaginationController';
import jwtDecode from 'jwt-decode';
import cookie from 'react-cookies';
import { RoutingService } from '@src/shared/services/routing';
import { useNavigate } from 'react-router-dom';
import { replaceAdeoText } from '@src/shared/utils/mappers/replaceAdeoText';

const PAGINATION_PAGE_SIZE = 50;

export default function Shipments() {
  const myRole = jwtDecode(cookie.load('auth').id_token).ved_role;
  const getSavedFilters = () => {
    let savedFiltersFromLocalStorage = {};
    const savedFiltersString = localStorage.getItem('shipmentsFilters');
    if (savedFiltersString) {
      savedFiltersFromLocalStorage = JSON.parse(savedFiltersString);
      for (let filter in savedFiltersFromLocalStorage) {
        if (savedFiltersFromLocalStorage[filter].type === 'date') {
          savedFiltersFromLocalStorage[filter].from = new Date(
            savedFiltersFromLocalStorage[filter].from,
          );
          savedFiltersFromLocalStorage[filter].to = new Date(
            savedFiltersFromLocalStorage[filter].to,
          );
        }
      }
      if (Object.keys(savedFiltersFromLocalStorage).length > 0) return savedFiltersFromLocalStorage;
    }
    return {};
  };

  const isUnmounted = React.useRef(false);

  React.useEffect(() => {
    return () => {
      isUnmounted.current = true;
    };
  }, []);

  const [savedFilters, setSavedFilters] = React.useState(getSavedFilters());
  const [isPending, setPending] = React.useState(true);
  const [failedStatus, setFailedStatus] = React.useState(false);
  const [tableData, setTableData] = React.useState<ListRow[] | null>(null);
  const [fields, setFields] = React.useState<FieldContainer[] | null>(null);
  const [paginatorSettings, setPaginatorSettings] = React.useState({
    page: 0,
    pageSize: PAGINATION_PAGE_SIZE,
    isPending: false,
    next: false,
  });
  const [displayModal, setDisplayModal] = React.useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (savedFilters) {
      fetchShipments(savedFilters);
    }
  }, [savedFilters, paginatorSettings.page]);

  function pageHandler(page: number) {
    if (page !== paginatorSettings.page) {
      setPaginatorSettings((old) => {
        return { ...old, page, pending: true };
      });
    }
  }

  const selectShipmentHandler = (shipment) => {
    navigate(`${RoutingService.root.routes.shipments}/${shipment.id}`);
  };

  const linkTemplateHandler = (shipmentId) => {
    return `${RoutingService.root.routes.shipments}/${shipmentId}`;
  };

  function fetchShipments(filters: FilterType) {
    const pagination = {
      // +1 to understand we have next page immediately
      limit: paginatorSettings.pageSize + 1,
      offset: paginatorSettings.page * paginatorSettings.pageSize,
    };

    setPaginatorSettings((old) => {
      return { ...old, isPending: true };
    });
    requestShipments(mapSelectedFilters(filters), pagination)
      .then((response) => {
        if (!isUnmounted.current) {
          const tableData = response.data?.map((fields) => ({
            ...fields,
            shipmentState: replaceAdeoText(fields.shipmentState),
          }));

          if (response.data.length <= PAGINATION_PAGE_SIZE) {
            setPaginatorSettings((old) => {
              if (window) window.scrollTo({ top: 0, behavior: 'smooth' });
              return { ...old, isPending: false, next: false };
            });
            setTableData(tableData);
          } else {
            setPaginatorSettings((old) => {
              if (window) window.scrollTo({ top: 0, behavior: 'smooth' });
              return { ...old, isPending: false, next: true };
            });
            setTableData(tableData.slice(0, -1));
          }
          setFields(mapFieldsFromDTO(response.fields));
          setPending(false);
        }
      })
      .catch((error) => {
        setPending(false);
        setFailedStatus(error.response ? error.response.status : 'network');
      });
  }

  function requestShipmentsWithFilter(filters) {
    const notEmptyFilters = {};
    for (let filter in filters) {
      if (filters[filter]) {
        if (!Array.isArray(filters[filter])) {
          if (filters[filter].type && filters[filter].from && filters[filter].to) {
            notEmptyFilters[filter] = filters[filter];
          }
        } else {
          notEmptyFilters[filter] = filters[filter];
        }
      }
    }
    setPaginatorSettings((old) => {
      return { ...old, page: 0 };
    });
    localStorage.setItem('shipmentsFilters', JSON.stringify(notEmptyFilters));
    setSavedFilters(notEmptyFilters);
  }

  if (isPending) return <Preloader />;

  if (failedStatus)
    return (
      <ErrorReloadableComponent
        theme="white"
        width="s"
        margin={30}
        title="Shipment list can't be loaded"
        status={failedStatus}
      />
    );

  return (
    <div className={styles.container}>
      <List
        filterOptions={savedFilters}
        onFilter={requestShipmentsWithFilter}
        onSelect={selectShipmentHandler}
        linkTemplate={linkTemplateHandler}
        data={tableData}
        fields={fields}
      />
      <div className={styles.addShipment}>
        {myRole === 'ADEOTransport' && (
          <Button theme="primary" onClick={() => setDisplayModal((old) => !old)}>
            Add shipment
          </Button>
        )}
      </div>
      {tableData && tableData.length === 0 && (
        <span style={{ color: '#333', opacity: 0.5 }}>No shipments</span>
      )}
      {tableData && tableData.length > 0 && (
        <div className={styles.paginatorWrapper}>
          <PaginationController
            onChange={pageHandler}
            page={paginatorSettings.page}
            isPending={paginatorSettings.isPending}
            isNextDisabled={paginatorSettings.next}
          />
        </div>
      )}
      {displayModal && (
        <AddShipmentModal
          onClose={() => {
            setDisplayModal(false);
          }}
        />
      )}
    </div>
  );
}
