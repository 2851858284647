import React, { ReactNode, useCallback } from 'react';

import { FileInput } from '@src/shared/components/Input/FileInput';

import { StatusLabel } from './StatusLabelProps';
import { Wrap } from './Styled';
import { i18n } from '../../../../pages/DirectModeDocsPage/i18n';
import { useUploader } from '../../hooks/useUploader';

type Props = {
  upload: ReturnType<typeof useUploader>;
  allowedFileExt: string[];
  hint?: ReactNode;
};

export const UploadButton = ({ upload, allowedFileExt = [], hint }: Props) => {
  const onFileChange = useCallback(
    (files: FileList) => {
      if ([...files].length > 0) {
        upload.mutate(files);
      }
    },
    [upload],
  );

  return (
    <Wrap>
      <StatusLabel uploadResult={upload.data} />
      {hint}
      <FileInput
        extensions={allowedFileExt}
        isMultiple
        isLoading={upload.isLoading}
        onChange={onFileChange}
      >
        {i18n.uploadButtonText}
      </FileInput>
    </Wrap>
  );
};
