// @flow strict
import React from 'react';
import cn from 'classnames';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import type { ListRow, FieldContainer, FilterItemInputType, FilterItemDateType } from '../../../../../../shared/types/domain';
import type { DatepickerRangeChangeHandlerProps } from '@src/shared/components/Input/DropdownInput/types.js.js';
import {
    getFullDifferenceBetweenDatesFromDeadlineInfo,
    getFullDifferenceBetweenDates
} from '@src/app/utilities/dates';
import TimeRelated from '../../../../../../shared/components/TimeRelated';

import { Divider } from 'mp-ui-components';

import { InputDropdown, DateDropdown, NoDropdown } from '@src/shared/components/Input/DropdownInput';
import styles from './index.css';

type FilterOptionRequest = {
    [string]: string[],
    ...
};

type Props = {
    myTasks: ListRow[] | null,
    data: ListRow[] | null,
    fields: FieldContainer[] | null,
    filterOptions: {},
    width?: string,
    linkTemplate: string => string,
    onFilter?: FilterOptionRequest => void
};

export default function List({ myTasks, data, fields, filterOptions, width, onFilter, linkTemplate }: Props) {
    const [selectedFilterWindow, setSelectedFilterWindow] = React.useState(null);

    const filterHandler = props => {
        if (onFilter) {
            onFilter(props);
        }
    };

    const selectFilterWindow = (field, condition) => {
        setSelectedFilterWindow(selectedFilterWindow !== field ? field : null);
    };

    function renderByType(value: Object, type: string) {
        if (type === 'date' && value) {
            const textContent = dayjs(value).format('DD.MM.YYYY');
            return <span title={textContent}>{textContent}</span>;
        }
        if (type === 'estimatedTime' && value) {
            const relateUpdate = () => {
                if (value.isDelayed) {
                    const deadline = getFullDifferenceBetweenDatesFromDeadlineInfo(value);
                    return (
                        <span style={{ color: '#e30613' }} title={deadline.withText}>
                            {deadline.withText}
                        </span>
                    );
                }
                const deadline = getFullDifferenceBetweenDates(new Date(value.deadline), new Date());
                return <span title={deadline.withText}>{deadline.withText}</span>;
            };
            return <TimeRelated relateTo={relateUpdate} />;
        }
        const textContent = value || '. . .';
        return <span title={textContent}>{textContent}</span>;
    }

    function updateFilterHandler(option: FilterItemInputType | FilterItemDateType, filterName: string) {
        if (fields) {
            const filterOption = fields.find(item => item.name === filterName);
            if (filterOption) {
                if (filterOption.filterType === 'text' && option.id) {
                    if (!filterOptions[filterName]) {
                        filterHandler({ ...filterOptions, [filterName]: [option] });
                    } else if (filterOptions[filterName]) {
                        filterHandler({
                            ...filterOptions,
                            [filterName]: [...filterOptions[filterName], option]
                        });
                    }
                } else if (filterOption.filterType === 'date') {
                    filterHandler({ ...filterOptions, [filterName]: option });
                }
            }
        }
    }

    function removeFilterHandler(option: FilterItemInputType, filterName: string) {
        const filtered = filterOptions[filterName].filter(filter => filter.id !== option.id);
        filterHandler({ ...filterOptions, [filterName]: [...filtered] });
    }

    function removeColumnFilters() {
        if (selectedFilterWindow) {
            filterHandler({ ...filterOptions, [selectedFilterWindow]: [] });
        }
    }

    function DatepickerRangeChangeHandler(changes: DatepickerRangeChangeHandlerProps, dateFilterName) {
        if (fields) {
            let update = {};
            if (changes.from && changes.to) {
                update = { type: 'date', from: changes.from, to: changes.to };
                updateFilterHandler(update, dateFilterName);
            } else if (!changes.from && !changes.to) {
                update = { type: 'date', from: null, to: null };
                updateFilterHandler(update, dateFilterName);
            }
        }
    }

    function DatepickerHasFiltered(filterName: string) {
        if (filterOptions[filterName].from && filterOptions[filterName].to) return true;
        return false;
    }

    function getDropdown(field: FieldContainer) {
        if (!field.isFilterable) return <NoDropdown field={field} />;
        if (field.filterType === 'text') {
            return (
                <InputDropdown
                    field={field}
                    filterOptions={filterOptions}
                    removeColumnFilters={removeColumnFilters}
                    selectedFilterWindow={selectedFilterWindow}
                    selectFilterWindow={selectFilterWindow}
                    updateFilterHandler={updateFilterHandler}
                    removeFilterHandler={removeFilterHandler}
                />
            );
        }
        if (field.filterType === 'date') {
            return (
                <DateDropdown
                    selectFilterWindow={selectFilterWindow}
                    selectedFilterWindow={selectedFilterWindow}
                    DatepickerHasFiltered={DatepickerHasFiltered}
                    DatepickerRangeChangeHandler={DatepickerRangeChangeHandler}
                    field={field}
                    filterOptions={filterOptions}
                />
            );
        }
        return <NoDropdown field={field} />;
    }

    return (
        <div className={styles.container}>
            <table className={styles.tableContainer} width={width}>
                <thead>
                    <tr className={styles.trHead}>
                        {fields &&
                            fields.map((field, index) => (
                                <th
                                    className={cn(
                                        styles.thHeading,
                                        field.filter && styles.clickable,
                                        styles[`thCell-${field.name}`]
                                    )}
                                    key={`table-heading-${field.id}-${index}`}
                                >
                                    {getDropdown(field)}
                                </th>
                            ))}
                    </tr>
                </thead>
                <tbody>
                    {myTasks &&
                        myTasks.map((item, index) => (
                            <tr className={styles.row} key={`table-row-${index}`}>
                                {fields &&
                                    fields.map((field, index) => (
                                        <td className={styles[`tdCell-${field.name}`]} key={`table-cell-${index}`}>
                                            <Link to={linkTemplate(item.id)} className={styles.tdBodyLink}>
                                                {renderByType(item[field.name], field.type)}
                                            </Link>
                                        </td>
                                    ))}
                            </tr>
                        ))}
                    {myTasks && myTasks.length > 0 && data && data.length > 0 && (
                        <tr>
                            <td
                                colSpan={fields !== null && fields.length > 0 ? fields.length : 8}
                                className={styles.divider}
                            >
                                <Divider />
                            </td>
                        </tr>
                    )}
                    {data &&
                        data.map((item, index) => (
                            <tr className={styles.row} key={`table-row-${index}`}>
                                {fields &&
                                    fields.map((field, index) => (
                                        <td className={styles[`tdCell-${field.name}`]} key={`table-cell-${index}`}>
                                            <Link
                                                data-testid="test-task-row"
                                                to={linkTemplate(item.id)}
                                                className={styles.tdBodyLink}
                                            >
                                                {renderByType(item[field.name], field.type)}
                                            </Link>
                                        </td>
                                    ))}
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
    );
}
