import React, { useState } from 'react';

import { useQuery } from 'react-query';

import { useTableContext } from '@src/shared/components/Table/_deprecated/Table/context/TableContext';
import { TableColumn } from '@src/shared/components/Table/_deprecated/Table/types';
import { useDebounce } from '@src/shared/utils/hooks/useDebounce';

import { searchFilters } from '../api/searchFilters';
import { SearchResults } from '../api/types';
import { InputState } from './types';
import { useEnabledOptions } from './useEnabledOptions';
import { getIsAutoFetchMode, getIsDictionarySearch as getIsDictionaryMode } from './utils';

const AUTO_FETCH_DELAY = 500;

const emptySearchItems = [''];

export const useSearch = (
  field: TableColumn,
  input: InputState,
  enabledOptions: ReturnType<typeof useEnabledOptions>,
) => {
  const { tableName } = useTableContext();

  const [isAutoFetching, setIsAutoFetching] = useState(false);

  const [dictionary, setDictionary] = useState<SearchResults>();
  const isDictionaryMode = getIsDictionaryMode(field);

  const searchItems = isDictionaryMode ? emptySearchItems : input.items;

  const trimmedInputValue = input.originalValue.trim();

  const queryKey = searchFilters.getQueryKey({
    field,
    searchItems,
    tableName,
  });

  const queryFn =
    isDictionaryMode && dictionary
      ? () => searchFilters.fromDictionary(dictionary, trimmedInputValue)
      : searchFilters.fromService;

  const query = useQuery({
    queryFn,
    queryKey,

    cacheTime: 0,
    retry: false,
    refetchOnWindowFocus: false,

    enabled: false,

    onSettled: () => setIsAutoFetching(false),

    onSuccess: (results) => {
      if (isDictionaryMode && !dictionary) {
        setDictionary(results);

        return;
      }

      const enabledIds = new Set(enabledOptions.items.map((option) => option.id));

      const notEnabledItems = results.items.filter((item) => !enabledIds.has(item.id));

      if (input.hasMultipleItems && notEnabledItems.length > 0) {
        enabledOptions.addItems(notEnabledItems);
      }

      input.setIsTouched(false);
    },
  });

  const debouncedRefetch = useDebounce(query.refetch, isDictionaryMode ? 0 : AUTO_FETCH_DELAY);

  React.useEffect(
    () => {
      const isAutoFetchMode = getIsAutoFetchMode(input);

      if (isAutoFetchMode) {
        debouncedRefetch();
        if (!isDictionaryMode) {
          setIsAutoFetching(true);
        }

        return;
      }

      debouncedRefetch.cancel();
      setIsAutoFetching(false);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [trimmedInputValue],
  );

  return {
    ...query,
    isFetching: query.isFetching || isAutoFetching,
  };
};
