import React, { useCallback } from 'react';

import { XIcon } from '@fronton/icons-react';
import { useSnackbar, Snackbar, Snack, SnackButton, Icon } from 'fronton-react';

import { AddSnackParams } from './types';

export const AppSnackbar = () => {
  const { state, removeSnack } = useSnackbar();

  const renderSnack = useCallback(
    ({
      id,
      header,
      paragraph,
      icon,
      variant,
      closeButton,
      autoHideTimer,
      wideButton,
    }: AddSnackParams) => (
      <Snack
        key={id}
        id={id}
        variant={variant}
        header={header}
        paragraph={paragraph}
        icon={icon}
        wideButton={wideButton}
        autoHideTimer={autoHideTimer}
      >
        {closeButton && (
          <SnackButton onClick={() => removeSnack(id)}>
            <Icon size={20}>
              <XIcon />
            </Icon>
          </SnackButton>
        )}
      </Snack>
    ),
    [removeSnack],
  );

  return (
    <>
      <Snackbar horizontalPosition="end">
        {state
          .filter(({ horizontalPosition }) => ['end', undefined].includes(horizontalPosition))
          .map((params) => renderSnack(params))}
      </Snackbar>
      <Snackbar horizontalPosition="center">
        {state
          .filter(({ horizontalPosition }) => horizontalPosition === 'center')
          .map((params) => renderSnack(params))}
      </Snackbar>
    </>
  );
};
