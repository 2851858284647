import { TableFieldType } from '../../_deprecated/Table/types';
import { CommonHeader } from '../CommonHeader';
import { makeCreateColumnFn } from '../makeCreateColumn';
import { TextCell } from './TextCell';

export const createTextColumn = makeCreateColumnFn({
  type: TableFieldType.text,
  header: CommonHeader,
  cell: TextCell,
});
