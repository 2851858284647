import { TableColumnDef } from '@src/shared/components/Table/types';

import { tableDefHSCodeRequest } from './hsCodeRequest';
import { tableDefIdcDataEdit } from './idcDataEdit';
import { tableDefIdcPhotoEdit } from './idcPhotoEdit';
import { IDCDocType } from '../../../enums';

export const tableDefByDocType: Record<IDCDocType, TableColumnDef[]> = {
  [IDCDocType.hsCodeRequest]: tableDefHSCodeRequest,
  [IDCDocType.idcEditData]: tableDefIdcDataEdit,
  [IDCDocType.idcEditPhoto]: tableDefIdcPhotoEdit,
};
