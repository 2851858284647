import React, { useMemo } from 'react';

import { EnabledOptions, SearchResults } from '../../hooks/useFilter';
import { Item } from './Item';
import { ItemsCount } from './ItemsCount';
import { getDisabledFoundOptions } from './utils';

import styles from './styles.module.scss';

export type Props = {
  enabledOptions: EnabledOptions;
  searchResults: SearchResults;
};

export const OptionsList: React.FC<Props> = ({ enabledOptions, searchResults }) => {
  const { items: enabledItems, removeAllItems, addItems, removeItems } = enabledOptions;

  const enabledItemsAmount = enabledItems.length;

  const disabledItems = useMemo(
    () => getDisabledFoundOptions(searchResults?.items ?? [], enabledItems),

    [enabledItems, searchResults?.items],
  );

  return (
    <>
      {enabledItemsAmount > 0 && (
        <ItemsCount enabledItemsAmount={enabledItemsAmount} onRemoveAllItems={removeAllItems} />
      )}

      <div className={styles.filterContainer}>
        {enabledItems.map((item) => (
          <Item key={item.id} item={item} isEnabled onUpdateItems={removeItems} />
        ))}
        {disabledItems.map((item) => (
          <Item key={item.id} item={item} onUpdateItems={addItems} />
        ))}
      </div>
    </>
  );
};
