import { STSubReportType } from '../../enums/STSubReportType';
import { STSubReportTab } from '../../widgets/STSubReportWidget';

type STSubReportOptions = {
  title: string;
  docType: string;
  helperInfoText: string;
  noInfoPlaceholder: string;
  isFoldable?: boolean;
  latestReportsFiltersEnabled?: boolean;
  tabs?: STSubReportTab[];
};

export const reportsList: STSubReportOptions[] = [
  {
    title: 'Updated shipments tracking',
    docType: STSubReportType.UpdatedShipmentTrackingReport,
    helperInfoText:
      'This file should be updated at least once a week. Must contain only new data: new container lines with the Broker and Forwarder assignment, updated data for existing containers',
    noInfoPlaceholder: 'Here will be Updated shipments tracking report',
    tabs: [STSubReportTab.latest],
  },
  {
    title: 'Forwarders for FOB and FCA',
    docType: STSubReportType.ForwardersDistribution,
    helperInfoText:
      'This file should be updated at least once a week. Must contain only OC on FOB and FCA delivery terms, Forwarder assignment',
    noInfoPlaceholder: 'Here will be Forwarders for FOB and FCA report',
    tabs: [STSubReportTab.latest],
  },
  {
    title: 'Dostavkalm report',
    docType: STSubReportType.DostavkalmReport,
    helperInfoText:
      'This file should be updated daily. It is used for the ATA WH FACT column update',
    noInfoPlaceholder: 'Here will be Dostavkalm report',
    tabs: [STSubReportTab.latest],
  },
  {
    title: 'Warehouse arrival plan',
    docType: STSubReportType.WarehouseArrivalPlan,
    helperInfoText:
      'This file should be updated daily. It is used for the ETA WH PLAN column update ',
    noInfoPlaceholder: 'Here will be Warehouse arrival plan report',
    tabs: [STSubReportTab.latest],
  },
  {
    title: 'Broker reports',
    docType: STSubReportType.BrokerReports,
    helperInfoText: 'This file should be updated daily',
    noInfoPlaceholder: 'Here will be reports from Brokers',
    isFoldable: true,
    latestReportsFiltersEnabled: true,
  },
  {
    title: 'Forwarder reports',
    docType: STSubReportType.ForwarderReports,
    helperInfoText: 'This file should be updated daily',
    noInfoPlaceholder: 'Here will be reports from Forwarders',
    isFoldable: true,
    latestReportsFiltersEnabled: true,
  },
];
