import { FileUploadError } from './enums/FileUploadError';
import { i18n } from './i18n';
import { docTypes } from './requestDocTypes';
import { UploadFilesTaskApiOptions } from './types';
import { uploadFiles } from './uploadFiles';

export const uploadFilesTaskApi = async ({
  files,
  url,
  docType,
  formData = new FormData(),
}: UploadFilesTaskApiOptions) => {
  const {
    status: { isSuccess },
    request,
    errors,
  } = await uploadFiles({
    url,
    formData,
    files: [...files].map((file) => ({ file, docType, docTypeInfo: docTypes[docType] ?? {} })),
  });

  if (isSuccess) {
    const items = request?.result?.data ?? [];

    for (const item of items) {
      item.doctype = docType;
    }

    return items;
  }

  if (errors) {
    const [firstFoundErrorType, firstFoundErrorInfo] = Object.entries(errors)[0];

    if (firstFoundErrorType !== FileUploadError.serverError) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        message: firstFoundErrorInfo.text,
        details: [],
      });
    }
  }

  const serverError = request?.error?.response;
  const serverErrorMsg = serverError?.data?.message;
  const serverErrorDetails = serverError?.data?.details ?? [];
  const serverErrorInfo =
    serverError?.status !== 500 && serverErrorMsg
      ? {
          message: serverErrorMsg,
          details: serverErrorDetails,
        }
      : undefined;

  return Promise.reject(
    serverErrorInfo || {
      message: i18n.baseErrorMessage,
      details: [],
    },
  );
};
