import React from 'react';

import XMarkIcon from '@src/shared/assets/svg/XMark.svg?react';
import { pluralizeNoun } from '@src/shared/utils/transform';

import styles from './styles.module.scss';

type Props = {
  enabledItemsAmount: number;
  onRemoveAllItems: () => void;
};

export const ItemsCount: React.FC<Props> = ({ enabledItemsAmount, onRemoveAllItems }) => {
  const amountText = `${enabledItemsAmount} ${pluralizeNoun(
    'element',
    enabledItemsAmount,
  )} selected`;

  return (
    <div className={styles.searchResultFilters} onClick={onRemoveAllItems}>
      <div>{amountText}</div>
      <XMarkIcon height="16" width="16" />
    </div>
  );
};
