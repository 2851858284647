import React from 'react';

import type { ImageBoxFile, SetDisplayImageModal } from './types';

import styles from './index.css';

type Props = {
  files: ImageBoxFile[];
  setDisplayModal?: SetDisplayImageModal;
};

export const ImageBox = ({ files, setDisplayModal }: Props) => {
  return (
    <div className={styles.container}>
      {files.map((file, index) => (
        <img
          // eslint-disable-next-line react/no-array-index-key
          key={`file-${file.name}-${index}`}
          src={file.url}
          alt={file.name}
          onClick={() => {
            setDisplayModal?.({ files, index });
          }}
          className={styles.image}
          style={{
            cursor: setDisplayModal ? 'pointer' : 'default',
          }}
        />
      ))}
    </div>
  );
};
