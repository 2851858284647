import React from 'react';

import { HeaderCellProps } from '../../types';
import { BaseRowsSelector } from './Base';
import { DropdownRowsSelector } from './Dropdown';

export const renderRowsSelector = (props: HeaderCellProps) => {
  const { field } = props;
  const isMultiPageSelectionMode = field?.$state?.selectionOptions;

  if (isMultiPageSelectionMode) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <DropdownRowsSelector {...props} />;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <BaseRowsSelector {...props} />;
};
