import React, { ReactNode } from 'react';

import cn from 'classnames';
import { FileButton } from 'mp-ui-components';

import { uploadFilesForOrder } from '@src/modules/orders/api/methods/uploadFilesForOrder';
import { uploadFilesForShipment } from '@src/modules/shipments/api/uploadFilesForShipment';
import { FileService } from '@src/shared/services/files';
import { OnUpdateFileFieldType } from '@src/shared/types';
import { parseAttachmentError } from '@src/shared/utils/parse/parseAttachmentError';

import styles from './index.scss';

type Props = {
  doctype?: string;
  shipmentId?: string;
  serviceOfUploading?: 'shipments' | 'default';
  orderId?: string;
  orderNumber?: string;
  isUploading?: boolean;
  text: string;
  fileHandler: (arg0: OnUpdateFileFieldType) => typeof undefined;
  isMultiple?: boolean;
  templateId?: string;
  files: FileList | null;
};

function onDragOverHandler(e) {
  e.preventDefault();
}

export const AttachmentDocComponent = ({
  doctype = '',
  serviceOfUploading = 'default',
  shipmentId,
  orderId,
  orderNumber,
  isUploading,
  text,
  fileHandler,
  isMultiple,
  templateId,
  files,
}: Props) => {
  const [failedStatus, setFailedStatus] = React.useState<ReactNode>();
  const [failedStatusHeader, setFailedStatusHeader] = React.useState<ReactNode>();

  const prepareUpload = (selectedFiles) => {
    const update = {
      files: selectedFiles || null,
      uploading: true,
    };

    setFailedStatus('');
    setFailedStatusHeader('');
    fileHandler(update);

    if (selectedFiles) {
      if (serviceOfUploading === 'default' && orderId && orderNumber) {
        uploadFilesForOrder(orderId, orderNumber, selectedFiles, doctype, templateId)
          .then((response) =>
            fileHandler({
              uploadedFiles: response,
              uploading: false,
            }),
          )
          .catch((error) => {
            const updateFailed = {
              files: null,
              uploadedFiles: null,
              uploading: false,
            };

            fileHandler(updateFailed);

            const parsedError = parseAttachmentError(error);

            setFailedStatus(parsedError.details);
            setFailedStatusHeader(parsedError.message);

            return Promise.reject(error);
          });
      } else {
        uploadFilesForShipment(selectedFiles, shipmentId ?? 'null', doctype)
          .then((response) =>
            fileHandler({
              uploadedFiles: response,
              uploading: false,
            }),
          )
          .catch((error) => {
            const updateFailed = {
              files: null,
              uploadedFiles: null,
              uploading: false,
            };

            fileHandler(updateFailed);

            const parsedError = parseAttachmentError(error);

            setFailedStatus(parsedError.details);
            setFailedStatusHeader(parsedError.message);

            return Promise.reject(error);
          });
      }
    }
  };

  function onDropFileHandler(e) {
    e.preventDefault();

    if (e.dataTransfer.items) {
      const droppedFiles: File[] = [];

      for (let i = 0; i < e.dataTransfer.items.length; i++) {
        droppedFiles.push(e.dataTransfer.items[i].getAsFile());
      }

      if (droppedFiles && droppedFiles.length > 0) {
        if (droppedFiles.length > 1 && !isMultiple) {
          return setFailedStatus('Only one file of this document type can be uploaded');
        }

        prepareUpload(droppedFiles);
      }
    }
  }

  const showUploadedFilesStatus = files && !isUploading && !failedStatus;

  const { data: docTypesConfig } = FileService.useDocTypesQuery();
  const allowedFileExt = docTypesConfig?.[doctype]?.extensions ?? [];

  return (
    <div className={cn(styles.browseFileContainer, styles.rowValueHigher)}>
      <div draggable onDragOver={onDragOverHandler} onDrop={onDropFileHandler}>
        <FileButton
          fileTypes={allowedFileExt}
          isMultiple={isMultiple}
          isPending={isUploading}
          theme="secondary"
          onChange={prepareUpload}
        >
          {text || 'Browse...'}
        </FileButton>
      </div>

      {showUploadedFilesStatus &&
        (isMultiple ? (
          <div className={cn(styles.browsedFile, styles.browsedFileAttached)}>
            All files have been uploaded
          </div>
        ) : (
          <div className={cn(styles.browsedFile, styles.browsedFileAttached)}>{files[0].name}</div>
        ))}

      {!files && !failedStatusHeader && <div className={styles.browsedFile}>No files</div>}
      {failedStatusHeader && (
        <div className={styles.fileErrorLabel}>
          {failedStatusHeader && (
            <div className={styles.fileErrorLabelHeader}>{failedStatusHeader}</div>
          )}
          {failedStatus && <div className={styles.fileErrorLabelStatus}>{failedStatus}</div>}
        </div>
      )}
    </div>
  );
};
