import React from 'react';

import cn from 'classnames';
import { Dropdown, RadioGroup } from 'mp-ui-components';

import type { TPeriod } from '../../../../../shared/components/Calendar/types';
import type { TDownloadOptions } from '../../../pages/ShipmentsTrackingPage/domain/downloadReport/downloadReport';

import CalendarRangeBody from '../../../../../shared/components/Calendar/CalendarBody/CalendarRangeBody';
import CalendarHead from '../../../../../shared/components/Calendar/CalendarHead/Head';
import CalendarIcon from '../../../../../shared/components/Calendar/CalendarIcon';
import { idsOptionWithoutCalendar } from '../enums';

import styles from './styles.scss';

export const DateFilter = ({
  options,
  variants,
  rightBorder,
  onChange,
  colorScheme = 'gray',
}: {
  options: TDownloadOptions;
  variants: Array<{
    id: string;
    name: string;
    getTitle: (arg0: TPeriod) => string;
  }>;
  rightBorder?: Date;
  onChange: () => void;
  colorScheme?: string;
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [showedDate, setShowedDate] = React.useState<Date>(new Date());
  const [period, setPeriod] = React.useState<TPeriod>({
    from: options.from,
    to: options.to,
  });

  const serialize = (): TDownloadOptions => {
    return {
      from: period.from,
      to: period.to,
      typeId: options.typeId,
    };
  };

  const getDropdownTitle = (): string => {
    const variant = variants.find((v) => v.id === options.typeId);

    return (variant && variant.getTitle(period)) || '';
  };

  return (
    <Dropdown
      className={styles.body}
      isOpen={isOpen}
      onChange={() => {
        setIsOpen(!isOpen);
        onChange(serialize());
      }}
    >
      <Dropdown.Trigger>
        <div className={styles.trigger}>
          <input disabled type="text" className={styles.triggerText} value={getDropdownTitle()} />
          <div className={cn(styles.fader, styles[colorScheme])} />
          <CalendarIcon />
        </div>
      </Dropdown.Trigger>
      <Dropdown.Content className={styles.content} ariseDirection="bottom-start">
        <RadioGroup
          onChange={(e) => onChange({ ...options, typeId: e })}
          items={variants}
          value={options.typeId}
        />
        {idsOptionWithoutCalendar.includes(options.typeId) ? null : (
          <>
            <div className={styles.horizontalLine} />
            <div>
              <CalendarHead
                showedDate={showedDate}
                onChange={(x) => setShowedDate(x)}
                isLookingInPast
              />
              <CalendarRangeBody
                showedDate={showedDate}
                period={period}
                onChange={setPeriod}
                disabledPeriods={[
                  {
                    from: rightBorder,
                  },
                ]}
              />
            </div>
          </>
        )}
      </Dropdown.Content>
    </Dropdown>
  );
};
