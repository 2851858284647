// @flow strict
import dayjs from 'dayjs';
import type { FieldContainer, FilterType, MappedFilterType, TaskComponentContainerField } from '../../shared/types/domain';

export function mapFieldsFromDTO(response: FieldContainer[]): FieldContainer[] {
    return response.map(field => {
        if (!field.id) field.id = `${field.name}-id`;
        field.filterType = field.type;
        field.filter = field.isFilterable;
        return field;
    });
}

export function mapWithUniqueIds(fields: TaskComponentContainerField[]): TaskComponentContainerField[] {
    return fields.map(field => {
        if (!field.id) field.id = `storer-${field.label}-id`;
        return field;
    });
}

export function mapSelectedFilters(filters: FilterType): MappedFilterType {
    const mapResult = {};
    for (let filter in filters) {
        if (filters[filter].type === 'date') {
            if (filters[filter].from !== null && filters[filter].to !== null) {
                mapResult[filter] = {
                    from: filters[filter].from && dayjs(filters[filter].from).format('YYYY-MM-DD'),
                    to: filters[filter].to && dayjs(filters[filter].to).format('YYYY-MM-DD')
                };
            }
        } else if (Array.isArray(filters[filter]) && filters[filter].length > 0) {
            mapResult[filter] = filters[filter].map(mapFilter => {
                return mapFilter.id;
            });
        }
    }
    return mapResult;
}
