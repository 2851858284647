import React, { ReactNode, useState } from 'react';

import { STSubReportsTransformed } from '../../../../../types';
import { STSubReportFilter } from '../../../enums';
import { Filters } from './Filters/Filters';
import { LatestReport } from './Report/Report';
import { ReportProcessing } from './Report/ReportProcessing/ReportProcessing';
import { ReportRejected } from './Report/ReportRejected/ReportRejected';

type Props = {
  withFilters: boolean;
  reports: STSubReportsTransformed;
  // eslint-disable-next-line react/no-unused-prop-types
  placeholder: string;
};

type RenderersByFilter = Record<STSubReportFilter, (props: Props) => ReactNode>;

const renderersByFilter: RenderersByFilter = {
  [STSubReportFilter.allProcessed]: ({ reports, placeholder }) =>
    Object.keys(reports.lastN).map((key) => {
      const lastUploadedReport = reports.lastN[key];

      return lastUploadedReport ? (
        <LatestReport key={key} {...{ ...lastUploadedReport, placeholder }} />
      ) : null;
    }),

  [STSubReportFilter.lastProcessing]: ({ reports }) =>
    reports.lastProcessing.map((report) => {
      return <ReportProcessing key={report.id} {...report} />;
    }),

  [STSubReportFilter.lastRejected]: ({ reports }) =>
    reports.lastRejected.map((report) => {
      return <ReportRejected key={report.id} {...report} />;
    }),
};

export const LatestReportsTab = (props: Props) => {
  const { withFilters, reports } = props;
  const [filter, setFilter] = useState<STSubReportFilter>(STSubReportFilter.allProcessed);

  const filesNode: ReactNode = renderersByFilter[filter](props) ?? null;

  const filesNodeMargin = withFilters ? {} : { marginTop: '30px' };

  return (
    <>
      {withFilters && <Filters reports={reports} currentTab={filter} onChange={setFilter} />}
      <div style={filesNodeMargin}>{filesNode}</div>
    </>
  );
};
