import { FileService } from '@src/shared/services/files';

import { httpClient } from '../../../../../shared/services/httpClient';

type KPIResponseDTO = {
  url?: string;
};

export async function requestKPI(): Promise<KPIResponseDTO> {
  const response = await httpClient({
    method: 'post',
    url: '/api/reports/kpi',
    timeout: 600_000, // 5-6 mins in avg
  });

  return response.data;
}

export const uploadCDF = async (files: FileList | File[]) =>
  FileService.uploadFilesTaskApi({ docType: 'cdfReport', url: '/api/reports/cdf', files });

export async function requestCDF(isFull) {
  return httpClient({
    method: 'get',
    url: `api/reports/cdf?isFull=${isFull}`,
    responseType: 'blob',
    timeout: 600_000, // 5-6 mins in avg
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      const headersResponse = response.headers;
      const contentDisposition = headersResponse['content-disposition']; // should be: "attachment; filename={CDF_REPORT_NAME}.xlsx"

      const fileName = contentDisposition.split('=')[1];

      link.href = url;
      link.setAttribute('download', fileName);
      document.body.append(link);
      link.click();
    })
    .catch(() => {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        message: `Something went wrong when tried to download Logistic instruction`,
        details: [],
      });
    });
}
