import React, { useState } from 'react';

import { Tab } from 'mp-ui-components';
import { useSessionStorage } from 'react-use';

import { TabsStyled } from '../components/Styled';
import { ArchiveTab } from '../components/Tabs/ArchiveTab/ArchiveTab';
import { LatestReportsTab } from '../components/Tabs/LatestReportsTab/LatestReportsTab';
import { STSubReportTab } from '../enums';
import { WidgetProps } from '../types';

const defaultTabs = [STSubReportTab.latest, STSubReportTab.archive];

const tabTitles: Record<STSubReportTab, string> = {
  [STSubReportTab.latest]: 'LATEST REPORTS',
  [STSubReportTab.archive]: 'ARCHIVE',
};

export function useTabs(
  {
    helperInfoText,
    latestReportsFiltersEnabled: withFilters = false,
    tabs = defaultTabs,
    docType,
  }: WidgetProps,
  state: any,
) {
  const [activeTab, setActiveTab] = useSessionStorage<number>(`st${docType}ActiveTab`, 0);

  const showTabs = tabs.length > 1;

  const renderContent = () => {
    const currentTab = tabs[activeTab];

    if (currentTab === STSubReportTab.latest) {
      return (
        <LatestReportsTab
          withFilters={withFilters}
          placeholder={helperInfoText}
          reports={state.reports}
        />
      );
    }

    if (currentTab === STSubReportTab.archive) {
      return <ArchiveTab docType={docType} />;
    }
  };

  const tabPickerNode = showTabs ? (
    <TabsStyled defaultActiveTabIndex={activeTab}>
      {tabs.map((tab, ind) => (
        <Tab key={tab} index={ind} title={tabTitles[tab]} onClick={() => setActiveTab(ind)} />
      ))}
    </TabsStyled>
  ) : null;

  return (
    <>
      {tabPickerNode}
      {renderContent()}
    </>
  );
}
