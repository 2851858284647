import { styled } from 'linaria/react';

import { Typography } from '@src/shared/components/Typography';

export const DocBlock = styled.section`
  width: 1035px;
  margin-top: var(--space-250);
  padding: var(--space-200) var(--space-300);
  background: #fff;
`;

export const DocTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DocTitleText = styled.span`
  font-weight: 600;
`;

export const DocNoDataLabel = styled(Typography)`
  margin-top: 20px;
`;
