import { STSubReportType } from '@src/modules/documents/enums/STSubReportType';
import { FileStatusTextsShort } from '@src/modules/documents/enums/enums';
import {
  ArchiveFilesResponseData,
  ArchiveFilesTableData,
} from '@src/modules/documents/widgets/STSubReportWidget/components/Tabs/ArchiveTab/types';
import {
  remappedUserNames,
  remappedUserRoleNames,
} from '@src/modules/documents/widgets/STSubReportWidget/components/Tabs/mappers';
import { httpClient } from '@src/shared/services/httpClient';
import { BaseQueryDef, createQueryDef } from '@src/shared/services/query';
import { FilterItemDateType, FilterItemInputType } from '@src/shared/types';

export type Filters = {
  creationDate?: FilterItemDateType;
  performerRole?: FilterItemInputType;
  userName?: FilterItemInputType;
  parsingResult?: FilterItemInputType;
};

type QueryDef = BaseQueryDef<
  ArchiveFilesTableData,
  {
    skip: number;
    take: number; // amount of last uploaded files
    docType: STSubReportType;
    filters: Filters;
  }
>;

const responseAdapter = (data: ArchiveFilesResponseData): ArchiveFilesTableData => ({
  ...data,
  files: data.files.map((file) => {
    const convertedErrors = file.convertedErrors.map(({ title, errors }) => ({
      title,
      info: errors,
    }));

    const parsingResult = FileStatusTextsShort[file.status];

    const performerRole = remappedUserRoleNames[file.userRole] ?? file.userRole;

    const userName = remappedUserNames[file.userName] ?? file.userName;

    return {
      ...file,
      creationDate: file.created,
      performerRole,
      userName,
      convertedErrors,
      parsingResult,
    };
  }),
});

export const getArchiveReports = createQueryDef<QueryDef>(
  'api/shipmentsTracking/archiveFiles',
  async ({ queryKey }) => {
    const [url, { take, skip, docType, filters }] = queryKey;

    const response = await httpClient.post<ArchiveFilesResponseData>(url, {
      take,
      skip,
      docType,
      filters,
    });

    return responseAdapter(response.data);
  },
);
