import React, { PropsWithChildren } from 'react';

import cn from 'classnames';

import styles from '../../../styles.module.scss';
import { TableColumn } from '../../../types';
import { renderFilter, renderRowsSelector, renderString } from './renderers';
import { HeaderCellProps } from './types';

type ThProps = PropsWithChildren & {
  field: TableColumn;
  isClickable?: boolean;
};

const Th: React.FC<ThProps> = ({ field, isClickable, children }) => (
  <th data-field-id={field.name} className={cn(styles.thHeading, isClickable && styles.clickable)}>
    {children}
  </th>
);

export const HeaderCell: React.FC<HeaderCellProps> = (props) => {
  const { field, tableData } = props;

  if (!tableData.rows) {
    return null;
  }

  const isTableEmpty = tableData.rows.length === 0;

  if (field.type === 'checkbox') {
    if (isTableEmpty) {
      return null;
    }

    return renderRowsSelector(props);
  }

  if (field.isFilterable) {
    return (
      <Th field={field} isClickable>
        {renderFilter(props)}
      </Th>
    );
  }

  return <Th field={field}>{renderString(props)}</Th>;
};
