import React from 'react';

import { StaticDocsDownload } from '@src/shared/components/DocDownload';
import Preloader from '@src/shared/components/Preloader';

import { useInstructions } from '../../hooks/useInstructions';

import styles from './SupportSidebar.scss';

export const SupportSidebar = () => {
  const { data, isLoading, isError } = useInstructions();

  if (isLoading) {
    return (
      <article className={styles.container}>
        <Preloader />
      </article>
    );
  }

  if (isError) {
    return (
      <article className={styles.container}>
        <span className={styles.errorLabel} role="alert">
          {`The files are unavailable due to a technical error.\nPlease contact technical support.`}
        </span>
      </article>
    );
  }

  return (
    <article className={styles.container}>
      {data &&
        data.map((instruction) => (
          <section key={instruction.title}>
            <div className={styles.sectionHeading} role="heading" aria-level="1">
              {instruction.title}
            </div>
            <section className={styles.filelistContainer}>
              <StaticDocsDownload
                fileMargin="12px 0 0 0"
                files={instruction.files.map((file) => ({
                  name: file.fileName,
                  url: file.url,
                }))}
              />
            </section>
          </section>
        ))}
    </article>
  );
};
