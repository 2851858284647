import dayjs from 'dayjs';

import type { TTrackingFile, TUploadFileOption } from '../../../../types';

import { DownloadFileStatusTexts, FileStatus, FileStatusTexts } from '../../../../enums/enums';

export function getUploadOptions(data: TTrackingFile): TUploadFileOption {
  return {
    file: data.uploadFile,
    day: dayjs().format('YYYY-MM-DD'),
    docType: data.docType,
  };
}

export function getStatusText(status: string): string {
  return FileStatusTexts[status] || '';
}

export function getDownloadFileStatusText(status: string): string {
  return DownloadFileStatusTexts[status] || '';
}

export function isFileStatusOk(status: string): boolean {
  const OkStatuses = [FileStatus.Idle, FileStatus.Processing, FileStatus.HandleWithoutErrors];

  return OkStatuses.includes(status);
}

export function getDateAsFormattedText(update: string): string {
  if (!update) {
    return '';
  }

  const d = dayjs(update);

  return (d.isValid() && d.format('D[\u00A0]MMM[\u00A0]YYYY [at\u00A0]HH:mm')) || '';
}

export function getFileNameText(filePath: string): string {
  const splitedFilePath = filePath.split('/');

  return (splitedFilePath.length > 0 && splitedFilePath[splitedFilePath.length - 1]) || '';
}
