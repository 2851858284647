import React, { ReactNode } from 'react';

import { UploadButton } from './components/UploadButton/UploadButton';
import { UploadErrors } from './components/UploadErrors';
import { useTitle } from './hooks/useTitle';
import { useUploader } from './hooks/useUploader';
import { DocumentWidgetContentRenderer } from './types';
import { DocBlock, DocTitle } from '../../components/DocLayout';
import { FoldableTitle } from '../../components/FoldButton';

type Props = {
  title: ReturnType<typeof useTitle>;
  uploader: ReturnType<typeof useUploader>;
  allowedFileExt: string[];
  renderContent: DocumentWidgetContentRenderer;
  hint?: ReactNode;
};

export const DocumentWidget = ({ title, uploader, allowedFileExt, renderContent, hint }: Props) => {
  return (
    <DocBlock>
      <DocTitle>
        <FoldableTitle
          isOpened={!title.isFolded}
          isActive={title.isFoldable}
          onToggle={title.onFold}
        >
          {title.text}
        </FoldableTitle>
        <UploadButton upload={uploader} allowedFileExt={allowedFileExt} hint={hint} />
      </DocTitle>

      <UploadErrors upload={uploader} isWidgetFolded={title.isFolded} />

      {!title.isFolded && renderContent()}
    </DocBlock>
  );
};
