import React from 'react';

import HTMLReactParser, { domToReact } from 'html-react-parser';
import { Link } from 'mp-ui-components';

import { RoutingService } from '@src/shared/services/routing';

import { isDOMTag } from '../../../../../../../../../shared/utils/html/isDOMTag';

export const getErrorMsgNode = (htmlMsg: string) =>
  HTMLReactParser(htmlMsg, {
    replace: (node) => {
      if (!isDOMTag(node)) {
        return;
      }

      if (node.tagName === 'a' && (node.attribs.href ?? '').includes('/support')) {
        const url = new URL(node.attribs.href);
        const searchParams = url.searchParams.toString();

        return (
          <Link
            target="_self"
            href={`${RoutingService.root.routes.helpAndSupport}?${searchParams}`}
          >
            {domToReact(node.children)}
          </Link>
        );
      }
    },
  });
