import React from 'react';

import { InfoIcon } from '@fronton/icons-react';
import { Hint, HintCloseButton, HintContent, RegularButton, useSnackbar } from 'fronton-react';
import { styled } from 'linaria/lib/react';
import { useQuery } from 'react-query';
import { useToggle } from 'react-use';

import { makeDefaultErrorSnack } from '@src/app/components/AppSnackbar/utils';
import { FileService } from '@src/shared/services/files';

import { IDCApi } from '../api';
import { IDCDocType } from '../enums';

const StyledHint = styled(Hint)`
  height: 24px;
  margin-right: var(--space-125);
  cursor: pointer;
`;

const DownloadButton = styled(RegularButton)`
  padding: var(--space-50) var(--space-125) !important; /* TODO: fix fronton/app styles order problem. App styles must be in priority */
`;

type Props = {
  docType: IDCDocType;
};

export const TemplateHint = ({ docType }: Props) => {
  const [isOpened, toggleOpen] = useToggle(false);
  const { addSnack } = useSnackbar();

  const { refetch, isFetching } = useQuery({
    queryKey: IDCApi.documentWidget.getTemplateUrl.getQueryKey({ docType }),
    queryFn: IDCApi.documentWidget.getTemplateUrl.queryFn,
    enabled: false,
    onSuccess: ({ url }) => FileService.downloadFile(url),
    onError: () => addSnack(makeDefaultErrorSnack()),
  });

  if (docType !== IDCDocType.idcEditData) {
    return null;
  }

  return (
    <StyledHint
      open={isOpened}
      actionButton={
        <DownloadButton wide invert onClick={() => refetch()} loading={isFetching}>
          Download template
        </DownloadButton>
      }
      anchor={<InfoIcon size="l" color="text-primary" onClick={() => toggleOpen()} />}
      closeButton={<HintCloseButton onClick={() => toggleOpen()} />}
      placement="top"
    >
      <HintContent paragraph="For upload data update you need to follow a template structure." />
    </StyledHint>
  );
};
