import { css, cx } from 'linaria';

import { TypographyType } from './enums';

const base = css`
  margin: 0;
  color: #333;
  font-family: 'RF Dewi';
  font-style: normal;
`;

export const typographyStyles: Record<TypographyType, string> = {
  pageTitle: cx(
    base,
    css`
      font-size: 36px;
      font-weight: 700;
      line-height: 30px;
    `,
  ),
  frameTitle: cx(
    base,
    css`
      font-size: 28px;
      font-weight: 700;
      line-height: 30px;
    `,
  ),
  paragraphS: cx(
    base,
    css`
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    `,
  ),
  paragraphM: cx(
    base,
    css`
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    `,
  ),
};
