// @flow strict
import { AuthService } from '@src/shared/services/auth';
import React from 'react';
import styles from './index.css';

export default function () {
  return (
    <div className={styles.container}>
      <p>Unfortunately, you have no role in RIES</p>
      <p>Please, contact support</p>
      <a className={styles.logout} onClick={() => AuthService.logout()}>
        Unsign from this account
      </a>
    </div>
  );
}
