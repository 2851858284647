import { currentActivityEnum } from '../../../../enums/enums';
import { TShipmentTracking } from '../../../../types';
import { getEmptyState as getEmptyFileState } from '../trackingFile/trackingFile';

export const getEmptyState = (): TShipmentTracking => ({
  lastN: {
    dostavkalmReport: [],
    updatedShipmentTrackingReport: [],
    warehouseArrivalPlan: [],
    forwarderReports: {},
    brokerReports: {},
  },
  lastSuccess: {
    dostavkalmReport: getEmptyFileState(),
    updatedShipmentTrackingReport: getEmptyFileState(),
    warehouseArrivalPlan: getEmptyFileState(),
    forwarderReports: {},
    brokerReports: {},
  },
  lastRejected: {
    forwarderReports: [],
    brokerReports: [],
  },
  lastProcessing: {
    forwarderReports: [],
    brokerReports: [],
  },
  currentActivity: currentActivityEnum.bootstrap,
});

export const toEntity = (data?: TShipmentTracking): TShipmentTracking => ({
  ...getEmptyState(),
  ...data,
});
