import React from 'react';

export const usePortal = (portalRootId) => {
  const [portalRoot, setPortalRoot] = React.useState<Element | null>(null);
  const [isMounted, setIsMounted] = React.useState(false);

  React.useEffect(() => {
    // Need to wait for first render in order to let portal root to be mounted by React to the DOM
    if (!isMounted) {
      setIsMounted(true);

      return;
    }

    const rootElement = document.querySelector(`#${portalRootId}`);

    setPortalRoot(rootElement);
  }, [isMounted, portalRootId]);

  return portalRoot;
};
