import React from 'react';

import cn from 'classnames';
import { Checkbox } from 'mp-ui-components';

import styles from '../../../../../styles.module.scss';
import {
  ClearAllOnPageEvent,
  RowsSelectionStatus,
  SelectAllOnPageEvent,
  TableElementRole,
  TableEventType,
} from '../../../../../types';
import { HeaderCellProps } from '../../types';

export const BaseRowsSelector: React.FC<HeaderCellProps> = ({ field, onTableEvent }) => {
  const rowsSelectionStatus = field?.$state?.selectionStatus;

  const isAllSelectedOnPage = rowsSelectionStatus === RowsSelectionStatus.all;
  const isPartiallySelectedOnPage = rowsSelectionStatus === RowsSelectionStatus.partial;

  const onClick: React.MouseEventHandler = (e) => {
    e.preventDefault();

    if (isAllSelectedOnPage || isPartiallySelectedOnPage) {
      onTableEvent<ClearAllOnPageEvent>({
        type: TableEventType.clearAllOnPage,
        payload: undefined,
      });

      return;
    }

    onTableEvent<SelectAllOnPageEvent>({
      type: TableEventType.selectAllOnPage,
      payload: undefined,
    });
  };

  return (
    <th
      className={cn(styles.thHeading, styles.clickable, styles.thCheckbox)}
      data-table-role={TableElementRole.rootRowSelector}
      data-partially-selected={isPartiallySelectedOnPage}
      onClick={onClick}
    >
      <div className={styles.checkboxContainer}>
        <Checkbox isChecked={isAllSelectedOnPage} />
      </div>
    </th>
  );
};
