import React from 'react';

import { HeaderCellProps } from '../../types';

import styles from './styles.module.scss';

export const TextCell = ({ field }: Pick<HeaderCellProps, 'field'>) => {
  return (
    <div data-testid="header-cell-text" className={styles.title} style={{ cursor: 'default' }}>
      <span title={field.displayName} className={styles.dotted}>
        {field.displayName}
      </span>
    </div>
  );
};

export const renderString = (props: HeaderCellProps) => <TextCell field={props.field} />;
