import React, { useCallback, useEffect, useState } from 'react';

import { RelinkArticlesDto } from '@lmru-bpms-ved/contract-bff';
import { useSnackbar } from 'fronton-react';
import { isEmpty } from 'lodash';
import { useMutation, useQuery } from 'react-query';
import { useToggle } from 'react-use';

import { AddSnackParams } from '@src/app/components/AppSnackbar';
import { makeDefaultErrorSnack, SnackInfoIcon } from '@src/app/components/AppSnackbar/utils';
import { OrdersApi } from '@src/modules/orders/api/ordersApi';
import { defaultQueryOptions } from '@src/shared/services/query';

import { ApplyModal } from './ApplyModal';
import { TOrderArticle } from '../../entities/orderArticle';

const findArticleByEan = (eanCode: string, articles: TOrderArticle[]) =>
  articles.find((article) => article.eanCode === eanCode);

type LinkChanges = { [eanCode: string]: string };

export function useEditGKLinking(
  ocNumber: string,
  articles: TOrderArticle[],
  refetchPageData: () => Promise<void>,
) {
  const [isEditMode, toggleEditMode] = useToggle(false);
  const [isModalOpened, toggleApplyModal] = useToggle(false);
  const [gkChanges, setGKChanges] = useState<LinkChanges>({});
  const { addSnack } = useSnackbar();

  const isApplyAvailable = !isEmpty(gkChanges);

  useEffect(() => {
    if (!isEditMode) {
      setGKChanges({});
    }
  }, [isEditMode]);

  const updateGKChanges = useCallback(
    (eanCode: string, idcId: string) => {
      const currState = { ...gkChanges };

      const actualLink = findArticleByEan(eanCode, articles)?.idcId;

      if (actualLink === idcId) {
        delete currState[eanCode];
      } else {
        currState[eanCode] = idcId;
      }

      setGKChanges(currState);
    },
    [articles, gkChanges, setGKChanges],
  );

  const getGKQuery = useQuery({
    ...defaultQueryOptions,
    queryKey: OrdersApi.articles.getGK.getQueryKey({
      eanCodes: articles.map((article) => article.eanCode),
    }),
    queryFn: OrdersApi.articles.getGK.queryFn,
    enabled: isEditMode,
    onError: () => {
      toggleEditMode(false);
      addSnack(makeDefaultErrorSnack());
    },
  });

  const getAllGKsByEan = useCallback(
    (eanCode: string): string[] => {
      const foundGKs = getGKQuery.data
        ?.find((keysPack) => keysPack.eanCode === eanCode)
        ?.idcIds?.map(String);

      const actualGK = findArticleByEan(eanCode, articles)?.idcId ?? '';

      return foundGKs ?? [actualGK];
    },
    [articles, getGKQuery],
  );

  const getSelectedGKForEan = useCallback(
    (eanCode: string): string =>
      gkChanges[eanCode] ?? findArticleByEan(eanCode, articles)?.idcId ?? '',
    [articles, gkChanges],
  );

  const relinkArticles = useMutation({
    mutationFn: async () => {
      toggleApplyModal();

      const links: RelinkArticlesDto['links'] = Object.entries(gkChanges).map(
        ([eanCode, idcId]) => ({ eanCode, newIdcId: Number(idcId) }),
      );

      await OrdersApi.articles.relinkGK({ orderConfirmationNumber: ocNumber, links });

      try {
        await refetchPageData();
      } catch {
        // no actions needed
      }
    },
    onSuccess: async () => {
      toggleEditMode();
      addSnack({
        id: String(Date.now()),
        variant: 'success',
        header: 'Links changed',
        autoHideTimer: 1800,
        icon: <SnackInfoIcon size="l" />,
      } as AddSnackParams);
    },
    onError: () => addSnack(makeDefaultErrorSnack()),
  });

  return {
    isEditMode: !getGKQuery.isFetching && isEditMode,
    toggleEditMode,

    getGKQuery,
    getAllGKsByEan,
    getSelectedGKForEan,
    updateGKChanges,

    isApplyAvailable,
    apply: toggleApplyModal,
    applyModalNode: (
      <ApplyModal
        isOpened={isModalOpened}
        isLoading={relinkArticles.isLoading}
        onApply={relinkArticles.mutate}
        onClose={toggleApplyModal}
      />
    ),
  };
}
