import React from 'react';
import cn from 'classnames';
import styles from './index.css';

export const DropdownButton = ({ item, checkIsOpened, toggleDropdown }) => {
  const hasFiles = item?.files && item?.files?.length > 0;
  const hasIdcChanges = item?.idcRelinking && item?.idcRelinking?.length > 0;

  if (!(hasFiles || hasIdcChanges)) {
    return null;
  }

  const onClick = () => {
    toggleDropdown(item.id);
  };

  const isOpened = checkIsOpened(item.id);

  let text = '';

  if (hasFiles) {
    text = `${item.files.length} ${item.files.length === 1 ? 'file' : 'files'}`;
  }

  if (hasIdcChanges) {
    text = `${item.idcRelinking.length} ${
      item.idcRelinking.length === 1 ? 'link' : 'links'
    } changed`;
  }

  return (
    <div className={styles.filesLabel} onClick={onClick}>
      {text}
      <div className={cn(isOpened ? styles.arrowTop : styles.arrowDown)} />
    </div>
  );
};
