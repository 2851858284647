import { FileStatus, FileStatusTextsShort } from '@src/modules/documents/enums/enums';

export const mappedStateNames = {
  Parsing: 'Parsing...',
};

export const colorsByState = {
  error: '#B84D4B',
  warning: '#FF7700',
  default: '#333333',
};

export const colorsByText = {
  Rejected: colorsByState.error,
  [FileStatusTextsShort[FileStatus.HandleWithErrors]]: colorsByState.error,
  [FileStatusTextsShort[FileStatus.HandleWithWarnings]]: colorsByState.error,
  [FileStatusTextsShort[FileStatus.InvalidFileColumns]]: colorsByState.error,
  [FileStatusTextsShort[FileStatus.InvalidFileRows]]: colorsByState.error,
  [FileStatusTextsShort[FileStatus.FileTooBigError]]: colorsByState.error,
  [FileStatusTextsShort[FileStatus.InternalError]]: colorsByState.error,
  [FileStatusTextsShort[FileStatus.UnexpectedMimeType]]: colorsByState.error,
  [FileStatusTextsShort[FileStatus.UploadingError]]: colorsByState.error,
  [FileStatusTextsShort[FileStatus.RequestError]]: colorsByState.error,
  [FileStatusTextsShort[FileStatus.WrongFileType]]: colorsByState.error,

  'Parsing...': colorsByState.warning,
  [FileStatusTextsShort[FileStatus.Idle]]: colorsByState.warning,
  [FileStatusTextsShort[FileStatus.Processing]]: colorsByState.warning,
};
