// Legacy File
// TODO: refactor and fix errors

/* eslint-disable react/no-array-index-key */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';

import cn from 'classnames';
import { Button, Divider } from 'mp-ui-components';

import type { ListRowDocumentField, FieldContainer } from '@src/shared/types/domain';

import { OrderMapped } from '../../types';

import styles from './styles.module.scss';

type Props = {
  data: OrderMapped[] | null;
  fields: FieldContainer[] | null;
  width?: string;
  onAction: (arg0: string) => void;
};

export const DocumentsList: React.FC<Props> = ({ data, fields, width, onAction }) => {
  const listUniqueId = Math.random();
  const [isActionPending, setActionPending] = React.useState(null);

  function requestActionMarkup(action: ListRowDocumentField) {
    if (!isActionPending && action.taskId) {
      setActionPending(null);
      onAction(action.taskId);
    }
  }

  const getColumnStyle = (name: string) => {
    if (name === 'performer') {
      return styles.performerColumn;
    }

    return '';
  };

  const renderColumn = (column: ListRowDocumentField) => {
    if (!column) {
      return '. . .';
    }

    if (column.type === 'text') {
      return <span title={column.value}>{column.value}</span>;
    }

    if (column.type === 'file') {
      if (column.url) {
        return (
          <a className={styles.file} href={column.url}>
            {column.value}
          </a>
        );
      }

      return column.value;
    }

    if (column.type === 'date') {
      return (
        <div>
          <span className={styles.date}>{column.date}</span>
          <span className={styles.time}>{column.time}</span>
        </div>
      );
    }

    if (column.type === 'action') {
      if (column.id) {
        return (
          <Button
            isPending={column.id === isActionPending}
            onClick={() => {
              requestActionMarkup(column);
            }}
            className={styles.smallButton}
            size="xs"
            theme="secondary"
          >
            {column.label}
          </Button>
        );
      }

      return null;
    }
  };

  if (!data || !fields) {
    return null;
  }

  return (
    <div className={styles.container}>
      <table className={styles.tableContainer} width={width}>
        <thead>
          <tr className={styles.trHead}>
            {fields.map((field, index) => (
              <td
                className={cn(styles.tdHeading, getColumnStyle(field.name))}
                key={`table-heading-${listUniqueId}-${index}`}
              >
                <div>
                  <span title={field.displayName} className={cn(styles.title)}>
                    {field.displayName}
                  </span>
                </div>
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((category) => (
            <React.Fragment key={category.type}>
              {category.sections.map((section, sectionIndex) => (
                <tr className={cn(styles.row)} key={`table-row-${category.type}-${sectionIndex}`}>
                  {fields.map((field, fieldIndex) => (
                    <td
                      className={cn(styles.tdBody, getColumnStyle(field.name))}
                      key={`table-cell-${listUniqueId}-${fieldIndex}`}
                    >
                      {renderColumn(section[field.name])}
                    </td>
                  ))}
                </tr>
              ))}
              <tr>
                <td colSpan={5} className={styles.divider}>
                  <Divider />
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};
