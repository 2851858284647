import React from 'react';

import { TableColumn } from '@src/shared/components/Table/_deprecated/Table/types';

import { useFilter } from '../../hooks/useFilter';
import { OptionsList } from '../OptionsList/List';
import { SearchInput } from '../SearchInput/Input';
import { SearchResultsExtraInfo } from '../SearchResultsExtraInfo/SearchResultsExtraInfo';

import styles from './styles.module.scss';

type Props = {
  field: TableColumn;
};

export const FilterMenu: React.FC<Props> = ({ field }) => {
  const filter = useFilter(field);

  const { enabledOptions, searchQuery, input } = filter;

  return (
    <div className={styles.container}>
      <SearchInput filter={filter} />

      <OptionsList enabledOptions={enabledOptions} searchResults={searchQuery.data} />

      <SearchResultsExtraInfo input={input} searchResults={searchQuery.data} />
    </div>
  );
};
