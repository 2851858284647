import {
  DocTypeByTableName,
  fieldTextMappers,
} from '@src/modules/documents/widgets/STSubReportWidget/components/Tabs/mappers';
import { SEARCH_RESULTS_LIMIT } from '@src/shared/components/Table/_deprecated/Table/components/Filter/TextFilter/api/constants';
import { SearchResults } from '@src/shared/components/Table/_deprecated/Table/components/Filter/TextFilter/api/types';
import { TableColumn } from '@src/shared/components/Table/_deprecated/Table/types';
import { httpClient } from '@src/shared/services/httpClient';
import { RequestFilterFromInputDTO } from '@src/shared/types/domain';

import { makeTableFilterItems } from '../../../../shared/components/Table/utils';

export const getArchiveReportsFilters: TableColumn['getFilters'] = async ({
  field,
  searchItems,
  tableName,
  signal,
}): Promise<SearchResults> => {
  const fieldName = field.name;
  const hasInput = searchItems[0] !== '';

  const filters = hasInput ? { [fieldName]: searchItems[0] } : undefined;

  const { data } = await httpClient.request<RequestFilterFromInputDTO[]>({
    method: 'post',
    url: 'api/shipmentsTracking/autocomplete',
    data: {
      targetField: fieldName,
      docType: DocTypeByTableName[tableName],
      filters,
      limit: SEARCH_RESULTS_LIMIT,
    },
    signal,
  });

  const itemTextMapper = fieldTextMappers[fieldName];

  const items = makeTableFilterItems(data, itemTextMapper);

  const formattedData = {
    items,
    missingItems: [],
  };

  return formattedData;
};
