import { i18n } from '@src/shared/i18n';

import { UserRole } from '../auth';

export enum RootRoute {
  sign = '/sign',
  login = '/login',
  logout = '/logout',
  helpAndSupport = '/support',
  orders = '/orders',
  tasks = '/tasks',
  shipments = '/shipments',
  shipmentsTracking = '/shipmentsTracking',
  directModeDocs = '/directMode',
  reports = '/reports',
  forwarderReports = '/forwarderReports',
  brokerReports = '/brokerReports',
  idc = '/idc',
}

type RouteConfig = {
  navLabel?: string;
  roleAccessWhitelist?: UserRole[];
  defaultForRole?: UserRole[];
};

export const rootRoutesConfig: Record<RootRoute, RouteConfig> = {
  [RootRoute.sign]: {},

  [RootRoute.login]: {},

  [RootRoute.logout]: {},

  [RootRoute.orders]: {
    navLabel: 'Orders',
    roleAccessWhitelist: [
      UserRole.AdeoSupplier,
      UserRole.ImportSpecialist,
      UserRole.CustomsDocs,
      UserRole.CustomsGroupSpecialist,
      UserRole.TransportGroupSpecialist,
      UserRole.CertificationSpecialist,
      UserRole.CertBody,
      UserRole.ApprovisionSpecialist,
      UserRole.AdeoTransport,
      UserRole.SampleCoordinator,
      UserRole.AdeoVietnam,
    ],
    defaultForRole: [UserRole.SampleCoordinator],
  },

  [RootRoute.tasks]: {
    navLabel: 'Tasks',
    roleAccessWhitelist: [
      UserRole.AdeoSupplier,
      UserRole.ImportSpecialist,
      UserRole.CustomsDocs,
      UserRole.CertificationSpecialist,
      UserRole.CertBody,
      UserRole.ApprovisionSpecialist,
      UserRole.AdeoTransport,
      UserRole.AdeoVietnam,
    ],
    defaultForRole: [
      UserRole.AdeoSupplier,
      UserRole.ImportSpecialist,
      UserRole.CustomsDocs,
      UserRole.CertBody,
      UserRole.CertificationSpecialist,
      UserRole.ApprovisionSpecialist,
      UserRole.AdeoVietnam,
    ],
  },

  [RootRoute.shipments]: {
    navLabel: 'Shipping docs',
    roleAccessWhitelist: [
      UserRole.AdeoSupplier,
      UserRole.ImportSpecialist,
      UserRole.CustomsDocs,
      UserRole.CustomsGroupSpecialist,
      UserRole.TransportGroupSpecialist,
      UserRole.ApprovisionSpecialist,
      UserRole.AdeoTransport,
      UserRole.SampleCoordinator,
      UserRole.AdeoVietnam,
    ],
    defaultForRole: [
      UserRole.CustomsGroupSpecialist,
      UserRole.TransportGroupSpecialist,
      UserRole.AdeoTransport,
    ],
  },

  [RootRoute.directModeDocs]: {
    navLabel: 'Direct mode',
    roleAccessWhitelist: [UserRole.ImportSpecialist, UserRole.SampleCoordinator],
  },

  [RootRoute.idc]: {
    navLabel: i18n.idc.navLabel,
    roleAccessWhitelist: [
      UserRole.ImportSpecialist,
      UserRole.SampleCoordinator,
      UserRole.CertificationSpecialist,
      UserRole.CustomsGroupSpecialist,
    ],
  },

  [RootRoute.shipmentsTracking]: {
    navLabel: 'Shipments tracking',
    roleAccessWhitelist: [UserRole.TransportGroupSpecialist],
  },

  [RootRoute.reports]: {
    navLabel: 'Reports',
    roleAccessWhitelist: [
      UserRole.AdeoSupplier,
      UserRole.ImportSpecialist,
      UserRole.CustomsDocs,
      UserRole.CustomsGroupSpecialist,
      UserRole.TransportGroupSpecialist,
      UserRole.CertificationSpecialist,
      UserRole.CertBody,
      UserRole.ApprovisionSpecialist,
      UserRole.AdeoTransport,
      UserRole.SampleCoordinator,
    ],
  },

  [RootRoute.forwarderReports]: {
    navLabel: 'Reports',
    roleAccessWhitelist: [UserRole.Forwarder],
    defaultForRole: [UserRole.Forwarder],
  },

  [RootRoute.brokerReports]: {
    navLabel: 'Reports',
    roleAccessWhitelist: [UserRole.CustomsBroker],
    defaultForRole: [UserRole.CustomsBroker],
  },

  [RootRoute.helpAndSupport]: {
    navLabel: 'Help & Support',
  },
};
