import React from 'react';

import { Container } from 'mp-ui-components';

import s from './Preloader.css';

type Props = {
  style?: React.HTMLAttributes<HTMLDivElement>['style'];
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
};

const Preloader = (props: Props) => {
  return (
    <Container {...props}>
      <div className={s.preloader} role="progressbar" />
    </Container>
  );
};

// eslint-disable-next-line import/no-default-export
export default Preloader;
