import { TableFieldType } from '../../_deprecated/Table/types';
import { CommonHeader } from '../CommonHeader';
import { makeCreateColumnFn } from '../makeCreateColumn';
import { ParsingStateCell } from './ParsingStateCell';

export const createParsingStateColumn = makeCreateColumnFn({
  type: TableFieldType.parsingState,
  header: CommonHeader,
  cell: ParsingStateCell,
});
