import React from 'react';

import { Navigate, useRoutes } from 'react-router-dom';

import { Logout } from '@src/modules/auth';
import {
  BrokerReportsPage,
  DirectModeDocsPage,
  ForwarderReportsPage,
  IDCPage,
  ShipmentsTrackingPage,
} from '@src/modules/documents';
import { CommonReportsPage } from '@src/modules/documents/pages/CommonReportsPage';
import { HelpAndSupportPage } from '@src/modules/helpAndSupport';
import { OrdersList, OrderPage } from '@src/modules/orders';
import ShipmentViewPage from '@src/modules/shipments/pages/ShipmentPage';
import ShipmentsPage from '@src/modules/shipments/pages/ShipmentsListPage';
import { TaskPage, TasksListPage } from '@src/modules/tasks';
import { AuthService } from '@src/shared/services/auth';
import { RoutingService } from '@src/shared/services/routing';

import { TechnicalErrorPage } from '../basePages/TechnicalErrorPage';
import { RouteGuard } from './RouteGuard';

export const PrivateRoutes: React.FC = () => {
  const { root, getDefaultRouteForRole } = RoutingService;

  const defaultRoute = getDefaultRouteForRole(AuthService.getUserIdToken()?.vedRole);

  const routes = useRoutes([
    {
      errorElement: <TechnicalErrorPage />,
      children: [
        // Orders module
        {
          path: root.routes.orders,
          children: [
            {
              index: true,
              element: (
                <RouteGuard route={root.routes.orders}>
                  <OrdersList />
                </RouteGuard>
              ),
            },
            {
              path: ':id',
              children: [
                { index: true, element: <Navigate to="documents" replace /> },
                {
                  path: ':tab',
                  element: (
                    <RouteGuard route={root.routes.orders}>
                      <OrderPage />
                    </RouteGuard>
                  ),
                },
              ],
            },
          ],
        },

        // Tasks module
        {
          path: root.routes.tasks,
          children: [
            {
              index: true,
              element: (
                <RouteGuard route={root.routes.tasks}>
                  <TasksListPage />
                </RouteGuard>
              ),
            },
            {
              path: ':id',
              element: (
                <RouteGuard route={root.routes.tasks}>
                  <TaskPage />
                </RouteGuard>
              ),
            },
          ],
        },

        // Shipments module
        {
          path: root.routes.shipments,
          children: [
            {
              index: true,
              element: (
                <RouteGuard route={root.routes.shipments}>
                  <ShipmentsPage />
                </RouteGuard>
              ),
            },
            {
              path: ':id',
              children: [
                { index: true, element: <Navigate to="documents" replace /> },
                {
                  path: ':tab',
                  element: (
                    <RouteGuard route={root.routes.shipments}>
                      <ShipmentViewPage />
                    </RouteGuard>
                  ),
                },
              ],
            },
          ],
        },

        // Documents module
        {
          path: root.routes.brokerReports,
          element: (
            <RouteGuard route={root.routes.brokerReports}>
              <BrokerReportsPage />
            </RouteGuard>
          ),
        },
        {
          path: root.routes.reports,
          element: (
            <RouteGuard route={root.routes.reports}>
              <CommonReportsPage />
            </RouteGuard>
          ),
        },
        {
          path: root.routes.shipmentsTracking,
          element: (
            <RouteGuard route={root.routes.shipmentsTracking}>
              <ShipmentsTrackingPage />
            </RouteGuard>
          ),
        },
        {
          path: root.routes.directModeDocs,
          element: (
            <RouteGuard route={root.routes.directModeDocs}>
              <DirectModeDocsPage />
            </RouteGuard>
          ),
        },
        {
          path: root.routes.idc,
          element: (
            <RouteGuard route={root.routes.idc}>
              <IDCPage />
            </RouteGuard>
          ),
        },
        {
          path: root.routes.forwarderReports,
          element: (
            <RouteGuard route={root.routes.forwarderReports}>
              <ForwarderReportsPage />
            </RouteGuard>
          ),
        },

        // Help And Support module
        {
          path: `${root.routes.helpAndSupport}/*`,
          element: <HelpAndSupportPage />,
        },

        {
          path: root.routes.logout,
          element: <Logout />,
        },
        { path: '*', element: <Navigate to={defaultRoute} /> },
      ],
    },
  ]);

  return routes;
};
