import React, { useCallback } from 'react';

import { useQuery } from 'react-query';

import { TasksApi } from '@src/modules/tasks/api';
import { DynamicDocDownload } from '@src/shared/components/DocDownload';
import { defaultQueryOptions } from '@src/shared/services/query';

import { useFormContext } from '../hooks/useFormContext';

type DynamicDocField = {
  type: string;
  id: string;
  placeholder: string;
};

type Props = {
  field: DynamicDocField;
};

export const DynamicDoc = ({ field }: Props) => {
  const { state: taskState } = useFormContext();

  const { isFetching, isError, refetch } = useQuery({
    ...defaultQueryOptions,
    queryKey: TasksApi.getDynamicDoc.getQueryKey({
      docId: field.id,
      taskId: taskState._id,
    }),
    queryFn: TasksApi.getDynamicDoc.queryFn,
    enabled: false,
  });

  const onDownload = useCallback(() => {
    refetch();
  }, [refetch]);

  const errorText = isError ? 'Technical error. Try again' : undefined;

  return (
    <DynamicDocDownload
      id={field.id}
      docName={field.placeholder}
      isLoading={isFetching}
      errorText={errorText}
      onDownload={onDownload}
    />
  );
};
