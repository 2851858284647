import React from 'react';

import { OrderInfoBar, ShipmentInfoBar } from '@src/shared/components/InfoBar';

import { TaskState } from '../types';

type Props = {
  isShipment: boolean;
  state: TaskState;
};

export const InfoBar = ({ state, isShipment }: Props) => {
  if (!isShipment && state.order) {
    return (
      <OrderInfoBar
        orderNumber={state.order.number}
        OCNumber={
          (state.order.ocData && state.order.ocData.orderConfirmationNumber) ||
          (state.order.rmsData && state.order.rmsData.orderConfirmationNumber) ||
          'unknown'
        }
        orderDate={state.order.creationDate}
        orderConfirmationDate={state.order.ocData.orderConfirmationDate || 'unknown'}
        department={state.department}
        supplier={state.supplier}
        location={state.warehouse}
        ETA={state.order.ocData.eta || state.order.rmsData.eta}
        ETD={state.order.ocData.etd || state.order.rmsData.etd}
        currency={state.order.currency}
        mode={state.order.mode}
      />
    );
  }

  if (state.shipmentHeader) {
    const { eta, etd, transport, departments, containersOrTrucks, isMix, portOfDischarge } =
      state.shipmentHeader;

    return (
      <ShipmentInfoBar
        ETA={eta}
        ETD={etd}
        transport={transport}
        portOfDischarge={portOfDischarge}
        isMix={isMix}
        departments={departments}
        containersOrTrucks={containersOrTrucks}
      />
    );
  }

  return null;
};
