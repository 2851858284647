import { styled } from 'linaria/react';

export const TableStyled = styled.table`
  & > thead > tr >,
  & > tbody > tr > {
    td,
    th {
      background-color: transparent;
    }

    td {
      box-sizing: border-box;
      padding: 2px 12px 2px 0;
      overflow: hidden;
      outline: none !important;
      color: inherit;
      font-size: 12px;
      line-height: 18px;
      text-decoration: none;
      text-overflow: ellipsis;
      word-break: break-word;
      white-space: nowrap;
    }
  }
`;
