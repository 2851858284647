import React from 'react';

import { getIconSrcByFileName } from './utils';

import styles from './index.css';

type DocumentFile = {
  name: string;
  originalFileName?: string;
  url: string;
};

type Props = {
  files: DocumentFile[];
  fileMargin?: string;
};

export const StaticDocsDownload = ({ files, fileMargin }: Props) => {
  return (
    <>
      {files.map((file, index) => (
        <a
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          download
          href={file.url}
          style={{ margin: fileMargin || '0 0 10px', display: 'block' }}
          rel="noopener noreferrer"
          target="_blank"
        >
          <div className={styles.file}>
            <img alt="file-icon" src={getIconSrcByFileName(file.originalFileName || file.name)} />
            <span title={file.name} className={styles.fileLabel}>
              {file.name}
            </span>
          </div>
        </a>
      ))}
    </>
  );
};
