/* eslint-disable no-param-reassign */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

// Legacy File
// TODO: refactor and fix errors
import dayjs from 'dayjs';
import { flatten } from 'lodash';

import { AuthService } from '@src/shared/services/auth';

import type { ListRow, FieldContainer, FilterType, MappedFilterType } from '../../types/domain';

export type MappedData = {
  tasks: ListRow[];
  myTasks: ListRow[];
};

export function mapDataFromDTOWithRoles(response: ListRow[]): MappedData {
  const tasks = [];
  const myTasks = [];
  const me = AuthService.getUserIdToken()?.fullName;

  for (const row of response) {
    const mappedRow = {};

    for (const key in row) {
      mappedRow[key] = row[key];
    }

    if (!mappedRow.id) {
      mappedRow.id = `${Math.random()}`;
    }

    mappedRow.performer = mappedRow.performer === me ? 'me' : mappedRow.performer;

    if (mappedRow.performer && mappedRow.performer === 'me') {
      myTasks.push(mappedRow);
    } else {
      tasks.push(mappedRow);
    }
  }

  return {
    myTasks,
    tasks,
  };
}

export function mapTableRowsDataFromDTO(response: ListRow[]): ListRow[] {
  return response.map((row) => {
    const mappedRow = {};

    for (const key in row) {
      mappedRow[key] = row[key];
    }

    if (!mappedRow.id) {
      mappedRow.id = `${Math.random()}`;
    }

    return { ...mappedRow };
  });
}

export function mapTableFieldsFromDTO(response: FieldContainer[]): FieldContainer[] {
  return response.map((field) => {
    if (!field.id) {
      field.id = `${field.name}-id`;
    }

    field.filterType = field.type;
    field.filter = field.isFilterable;

    return field;
  });
}

export function mapSelectedFilters(filters: FilterType): MappedFilterType {
  const mapResult = {};

  for (const filterKey in filters) {
    const filterValue = filters[filterKey];

    const isDate =
      filterValue.type === 'date' && filterValue.from !== null && filterValue.to !== null;

    if (isDate) {
      mapResult[filterKey] = {
        from: filterValue.from && dayjs(filterValue.from).format('YYYY-MM-DD'),
        to: filterValue.to && dayjs(filterValue.to).format('YYYY-MM-DD'),
      };

      // eslint-disable-next-line no-continue
      continue;
    }

    const isArray = Array.isArray(filterValue) && filterValue.length > 0;

    if (isArray) {
      mapResult[filterKey] = flatten(
        filterValue.map((item) => {
          return item.aliasIds ?? [item.id];
        }),
      );
    }
  }

  return mapResult;
}
