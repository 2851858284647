import React from 'react';

import { ErrorReloadableComponent } from '../../../../shared/components/Error';

export const TechnicalErrorPage = () => (
  <ErrorReloadableComponent
    margin={30}
    buttonLabel="Reload"
    buttonHandler={() => {
      window.location.reload();
    }}
    title="Technical error"
    text="Please reload page and try again"
    theme="white"
  />
);
