import React from 'react';

export default function CalendarIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="18"
            height="18"
            viewBox="0 0 16 16"
        >
            <defs>
                <path
                    id="acalendar"
                    d="M3.926.06A.338.338 0 003.59.4v.94h-.47a2.03 2.03 0 00-2.027 2.027v10.546a2.03 2.03 0 002.027 2.027h10.032a2.03 2.03 0 002.027-2.027V3.366a2.03 2.03 0 00-2.027-2.027h-.47V.42a.338.338 0 10-.675 0v.918H4.264v-.94A.338.338 0 003.926.06zm10.576 13.852c0 .745-.606 1.351-1.35 1.351H3.118a1.353 1.353 0 01-1.351-1.351V4.82h12.734v9.092zM12.006 2.014v.918a.338.338 0 10.676 0v-.918h.47c.744 0 1.35.607 1.35 1.352v.778H1.768v-.778c0-.745.606-1.352 1.351-1.352h.47v.896a.338.338 0 10.675 0v-.896h7.742z"
                ></path>
            </defs>
            <g fill="none" fillRule="evenodd">
                <mask id="bcalendar" fill="#fff">
                    <use xlinkHref="#acalendar"></use>
                </mask>
                <g fill="#000" fillOpacity="0.75" fillRule="nonzero" mask="url(#bcalendar)">
                    <path d="M0 0H16V16H0z"></path>
                </g>
            </g>
        </svg>
    );
}
