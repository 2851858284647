import { CurrencyCodes, OrderMode } from '@lmru-bpms-ved/core';
import dayjs from 'dayjs';

import { userRoleDisplayMapping } from '@src/shared/enums/userRoleDisplayMapping';
import { replaceAfterCommaByMap } from '@src/shared/utils/transform';

import { OrderDTO, OrderMapped } from './types';
import { OrderResponseDTO } from '../../api/types';

export function mapDocumentsFromDTO(response: OrderDTO[]): OrderMapped[] {
  return response.map((section) => {
    return {
      type: section.type,
      sections: section.files.map((file) => {
        return {
          document: {
            type: 'file',
            url: file.url || '',
            value: file.fileName || '. . .',
          },
          status: {
            type: 'text',
            value: file.status || '. . .',
          },
          performer: {
            type: 'text',
            value: file.performer
              ? replaceAfterCommaByMap(file.performer, userRoleDisplayMapping)
              : '. . .',
          },
          version: {
            type: 'date',
            time: file.version
              ? new Date(file.version)
                  .toLocaleTimeString(navigator.language, {
                    hour: '2-digit',
                    minute: '2-digit',
                  })
                  .replace(/\//g, '.')
              : '',
            date: file.version ? dayjs(file.version).format('DD.MM.YYYY') : '. . .',
          },
          action: file.action
            ? {
                type: 'action',
                ...file.action[0],
              }
            : {
                type: 'action',
              },
        };
      }),
    };
  });
}

export type OrderInfo = {
  mode: OrderMode;
  currency: CurrencyCodes;
  orderNumber: string;
  OCNumber: string;
  orderDate: string;
  orderConfirmationDate: string;
  department: string;
  supplier: string;
  location: string;
  eta: string;
  etd: string;
};

export function mapOrderFromDto(response: OrderResponseDTO): OrderInfo {
  return {
    orderNumber: response.number,
    mode: response.mode,
    currency: response?.supplier?.currency ?? '',
    OCNumber:
      (response.ocData &&
        typeof response.ocData.orderConfirmationNumber === 'string' &&
        response.ocData.orderConfirmationNumber) ||
      (response.rmsData &&
        typeof response.rmsData.orderConfirmationNumber === 'string' &&
        response.rmsData.orderConfirmationNumber) ||
      'unknown',
    orderDate: response.creationDate,
    orderConfirmationDate:
      (response.ocData &&
        typeof response.ocData.orderConfirmationDate === 'string' &&
        response.ocData.orderConfirmationDate) ||
      'unknown',
    department: response.department?.shortName ?? 'unknown',
    supplier:
      (response.supplier && typeof response.supplier.name === 'string' && response.supplier.name) ||
      'unknown',
    location:
      (response.warehouse &&
        typeof response.warehouse.name === 'string' &&
        response.warehouse.name) ||
      'unknown',
    eta:
      (response.ocData && typeof response.ocData.eta === 'string' && response.ocData.eta) ||
      (response.rmsData && typeof response.rmsData.eta === 'string' && response.rmsData.eta) ||
      '',
    etd:
      (response.ocData && typeof response.ocData.etd === 'string' && response.ocData.etd) ||
      (response.rmsData && typeof response.rmsData.etd === 'string' && response.rmsData.etd) ||
      '',
  };
}

type AtlasCodeType = {
  value: string;
  isBold: boolean;
};

type CertTrackingData = {
  [key: string]: string;
} & {
  atlasCodes: AtlasCodeType[];
};

type CertTrackingDataMapped = {
  atlasCodes: string;
  [key: string]: string;
};

export function mapCertTrackingData(data: CertTrackingData[]): CertTrackingDataMapped[] {
  return data.map((item) => {
    const atlasCodesParsed = item.atlasCodes
      .map((atlasCode) => {
        const atlasCodeSplited = atlasCode.value.split('/');

        if (atlasCode.isBold) {
          return `<span><b>${atlasCodeSplited[0]}</b>/${atlasCodeSplited[1]}</span>`;
        }

        return `<span>${atlasCode.value}</span>`;
      })
      .join(', ');

    return { ...item, atlasCodes: atlasCodesParsed };
  });
}
