import React from 'react';

import { Divider } from 'mp-ui-components';

import TimeRelated from '../../../../../../../shared/components/TimeRelated';
import { TaskMinLeft } from '../../../types';
import styles from '../index.css';

export const SectionHeader = ({
  caption,
  minLeft,
  getMinutesLeft,
  displayDivider,
}: {
  caption: string;
  minLeft: TaskMinLeft | null;
  getMinutesLeft: () => React.ReactElement<React.ComponentProps<'span'>, 'span'>;
  displayDivider?: boolean;
}) => {
  return (
    <>
      <div className={styles.title}>
        {caption}
        <div className={styles.titleRight}>
          {minLeft && <TimeRelated relateTo={getMinutesLeft} />}
        </div>
      </div>
      {displayDivider && <Divider />}
    </>
  );
};
