import { makeRequestFilters } from '@src/shared/api/makeRequestFilters';
import { RoutingService } from '@src/shared/services/routing';

import { getArticlesGK, relinkArticlesGK } from './methods/articles';
import { getOrders, setPriority } from './methods/orders';

// TODO - expand with other methods and integrate
export const OrdersApi = {
  orders: {
    get: getOrders,
    setPriority,
    getFilters: makeRequestFilters(`/api${RoutingService.root.routes.orders}`, 'autocomplete'),
  },
  articles: {
    getGK: getArticlesGK,
    relinkGK: relinkArticlesGK,
  },
};
