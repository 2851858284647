import React, { ChangeEventHandler, ReactNode, useCallback, useRef, useState } from 'react';

import { RegularButton } from 'fronton-react';
import { styled } from 'linaria/lib/react';
import { uniqueId } from 'lodash';

const Wrap = styled.div`
  input[type='file'] {
    display: none;
  }
`;

type FileEventTarget = EventTarget & { files: FileList };

type Props = {
  isMultiple: boolean;
  isLoading: boolean;
  children: ReactNode;
  extensions?: string[];
  className?: string;
  onChange: (files: FileList) => void;
};

const KEY_PREFIX = 'file_input_';

export const FileInput = ({
  className,
  children,
  extensions = [],
  isLoading,
  isMultiple = false,
  onChange,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  // must reset input key after file change so file with same name could invoke change event again
  const [inputKey, setInputKey] = useState(uniqueId(KEY_PREFIX));

  const onFileChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const files = (e?.target as FileEventTarget)?.files;

      if (files) {
        onChange(files);
      }

      // resetting input state
      setInputKey(uniqueId(KEY_PREFIX));
    },
    [onChange, setInputKey],
  );

  const onButtonClick = useCallback(() => inputRef?.current?.click?.(), [inputRef]);

  const acceptedExtensions = extensions.map((ext: string) => `.${ext}`).join(', ');

  return (
    <Wrap>
      <input
        ref={inputRef}
        key={inputKey}
        accept={acceptedExtensions}
        type="file"
        tabIndex={-1}
        multiple={isMultiple}
        disabled={isLoading}
        onChange={onFileChange}
      />
      <RegularButton
        className={className}
        variant="outline"
        size="s"
        loading={isLoading}
        onClick={onButtonClick}
      >
        {children}
      </RegularButton>
    </Wrap>
  );
};
