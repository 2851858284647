import React from 'react';

import { TaskComponentContainerField } from '@src/shared/types';

import { TaskState } from '../../../types';
import { getTaskComponentContainerResponsibleValue } from '../../../utils';

export function useFormValidation(state: TaskState, selectedConditions: string[]) {
  const [isFormValid, setIsFormValid] = React.useState(true);

  React.useEffect(() => {
    let allRequirements: TaskComponentContainerField[] = [];

    for (const containerField of state.fields) {
      allRequirements = [
        ...containerField.fields.filter((field) => (field as any).isRequired === true),
      ];
    }

    const allNeededRequirements: TaskComponentContainerField[] = [];

    for (const requirement of allRequirements) {
      if (requirement.availableWhen) {
        let availableWhenCheckIsOkay = false;

        for (const conditionalOption of requirement.availableWhen) {
          if (selectedConditions.includes(conditionalOption) === true) {
            availableWhenCheckIsOkay = true;
          }
        }

        if (availableWhenCheckIsOkay) {
          allNeededRequirements.push(requirement);
        }
      } else {
        allNeededRequirements.push(requirement);
      }
    }

    let allRequirementsIsOkay = true;

    for (const fieldThatRequired of allNeededRequirements) {
      const fieldThatRequiredValue = getTaskComponentContainerResponsibleValue(fieldThatRequired);

      if (
        fieldThatRequired.type === 'select' &&
        fieldThatRequired.selected &&
        fieldThatRequired.options.some((option) => option.isBlocking)
      ) {
        const selectedOption = fieldThatRequired.options.find(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore: Unreachable code error
          // TODO [BUG?] - Potential bug if types are correct
          (option) => option === fieldThatRequired.selected,
        );

        if (selectedOption && selectedOption.isBlocking) {
          allRequirementsIsOkay = false;
        }
      } else if (
        fieldThatRequired.type === 'checkbox' &&
        fieldThatRequired.selected &&
        fieldThatRequired.selected.length === 0
      ) {
        allRequirementsIsOkay = false;
      }

      if (!fieldThatRequiredValue) {
        allRequirementsIsOkay = false;
      }

      if (fieldThatRequired.type === 'decisionTable') {
        const areDropdownsFilled = (fieldThatRequiredValue as any).every((item) => {
          return item.value !== null && !item.isBlocking;
        });

        if (!areDropdownsFilled) {
          allRequirementsIsOkay = false;
        }
      }

      if ((fieldThatRequired as any).uploading) {
        allRequirementsIsOkay = false;
      }
    }

    setIsFormValid(allRequirementsIsOkay);
  }, [state, selectedConditions]);

  return { isFormValid };
}
