import React from 'react';

import { BasePageLayout } from '../../../../shared/components/Layout/PageLayout';
import { STSubReportType } from '../../enums/STSubReportType';
import { STReportDownloadWidget } from '../../widgets/STReportDownloadWidget';
import { prepareReports, STSubReportWidget } from '../../widgets/STSubReportWidget';
import { useSTSubReports } from '../../widgets/STSubReportWidget/hooks/useSTSubReports';
import { toEntity } from '../ShipmentsTrackingPage/domain/shipmentTracking/shipmentTracking';
import { i18n } from './i18n';

export const BrokerReportsPage = () => {
  const { data: reports, isLoading, isError } = useSTSubReports();

  return (
    <BasePageLayout
      title={i18n.pageTitle}
      errorTitle={i18n.pageLoadFailedTitle}
      isLoading={isLoading}
      isError={isError}
    >
      <STReportDownloadWidget
        latestDateOfPlanEtaPodInFu={reports?.latestDateOfPlanEtaPodInFu}
        latestDateUpdateShipmentTracking={reports?.latestDateUpdateShipmentTracking}
      />
      <STSubReportWidget
        reports={prepareReports(toEntity(reports), STSubReportType.BrokerReports)}
        title={i18n.uploadBlockTitle}
        docType={STSubReportType.BrokerReports}
        helperInfoText={i18n.uploadBlockHint}
        noDataText={i18n.uploadBlockNoDataPlaceholder}
        canUploadReports
      />
    </BasePageLayout>
  );
};
