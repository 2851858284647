import React from 'react';
import { styled } from 'linaria/lib/react';
import Preloader from './Preloader';

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 10000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &::before {
    content: '';
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: var(--opacity-6);
    background: var(--background-primary);
  }

  > div {
    position: fixed;
  }
`;

export const ViewportPreloader = () => {
  return (
    <Wrapper>
      <Preloader />
    </Wrapper>
  );
};
