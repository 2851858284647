import { makeDocumentWidgetApi } from '../widgets/DocumentWidget';
import { getArchiveReports } from './shipmentsTracking/getArchiveReports';
import { getArchiveReportsFilters } from './shipmentsTracking/getArchiveReportsFilters';
import { getLatestReports } from './shipmentsTracking/getLatestReports';

export const DocumentsApi = {
  directMode: makeDocumentWidgetApi('/api/direct/docs'),
  shipmentsTracking: {
    getLatestReports,
    getArchiveReports,
    getArchiveReportsFilters,
  },
};
