import { useCallback } from 'react';

import dayjs from 'dayjs';

import { TaskFormResolutionField } from '@src/shared/types';

import { TaskState } from '../../../types';
import { getTaskComponentContainerResponsibleValue } from '../../../utils';

export const useFormSubmit = (state: TaskState, selectedConditions: string[]) =>
  useCallback(() => {
    const resolution: TaskFormResolutionField[] = [];
    let isValid = true;

    for (const container of state.fields) {
      for (const containerField of container.fields) {
        let skip = false;
        const availableConditions = containerField.availableWhen;

        if (availableConditions) {
          for (const availableCondition of availableConditions) {
            if (selectedConditions.includes(availableCondition)) {
              skip = false;
              break;
            } else {
              skip = true;
            }
          }
        }

        const value = getTaskComponentContainerResponsibleValue(containerField);

        if (
          containerField.type === 'select' &&
          containerField.selected &&
          containerField.options.some((option) => option.isBlocking)
        ) {
          const selectedOption = containerField.options.find(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // TODO [BUG?] - Potential bug if types are correct
            (option) => option === containerField.selected,
          );

          if (selectedOption && selectedOption.isBlocking) {
            isValid = false;
          }
        }

        if (containerField.type === 'checkbox' && containerField.selected.length === 0) {
          isValid = false;
        }

        if (
          (containerField.type === 'attachmentDoc' ||
            containerField.type === 'attachmentScreenshot') &&
          containerField.uploading
        ) {
          isValid = false;
        } else if (!skip && value) {
          switch (containerField.type) {
            case 'attachmentDoc':

            case 'attachmentScreenshot': {
              resolution.push({
                id: containerField.type,
                value: value || '',
              });

              break;
            }

            case 'checkbox': {
              resolution.push({
                id: containerField.id,
                value: containerField.selected.map((item: any) => item.id), // Смотри тип TaskComponentContainerField
              });

              break;
            }

            case 'datePicker': {
              resolution.push({
                id: containerField.id,
                value: dayjs(value as string).format('YYYY-MM-DD'),
                timezoneOffsetMinutes: new Date().getTimezoneOffset() * -1,
              });

              break;
            }

            default:
              resolution.push({
                id: containerField.id,
                value: value || '',
              });
          }
        }
      }
    }

    return { resolution, isValid };
  }, [state, selectedConditions]);
