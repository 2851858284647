import React from 'react';

import XlsIcon from '@src/shared/components/DocDownload/assets/icon-file-xls.svg';

import { DownloadDropdown } from './DownloadDropdown';
import { FileDescription } from './FileDescription';
import { getPerformerLabel } from './getPerformerLabel';
import { STReport } from '../../../../../../../types';

import styles from './styles.css';

export const FileDownloadOnly = (report: STReport) => {
  const { created, filePath, fileName, downloadFiles } = report;

  const performer = getPerformerLabel(report);

  return (
    <div className={styles.filePart}>
      <div className={styles.file}>
        <img src={XlsIcon} className={styles.icon} alt="xls-icon" />
        <div className={styles.description}>
          {filePath ? (
            <FileDescription
              filePath={filePath}
              fileName={fileName}
              created={created}
              performer={performer}
            />
          ) : (
            <b>No uploaded file</b>
          )}

          <div className={styles.actions}>
            {downloadFiles?.length ? <DownloadDropdown files={downloadFiles} /> : null}
          </div>
        </div>
      </div>
    </div>
  );
};
