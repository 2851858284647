export enum STSubReportFilter {
  lastProcessing = 'lastProcessing',
  lastRejected = 'lastRejected',
  allProcessed = 'lastSuccess',
}

export enum STSubReportTab {
  latest = 'latest',
  archive = 'archive',
}
