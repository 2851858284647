// @flow strict
import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { Checkbox } from 'mp-ui-components';
import { getFullDifferenceBetweenDates } from '@src/app/utilities/dates';
import type { ListRow, FieldContainer, FilterItemInputType, FilterItemDateType } from '../../types';
import {
  InputDropdown,
  DateDropdown,
  NoDropdown,
  NoDropdownComponent,
} from '../../../Input/DropdownInput';
import styles from './index.css';

export type CheckBoxValue = {
  isChecked: boolean,
  isDisabled: boolean,
};

type FilterOptionRequest = {
  [string]: string[],
  ...
};

type DatepickerRangeChangeHandlerProps = {|
  from: Date | null,
  to: Date | null,
|};

type Props = {
  headerCellClassName?: string,
  data: ListRow[] | null,
  fields: FieldContainer[] | null,
  filterOptions: {},
  width?: string,
  onSelect?: (ListRow, rowIndex: number) => void,
  selected?: number,
  linkTemplate?: (string) => string,
  onFilter?: (FilterOptionRequest) => void,
  onChange?: (fieldName: string, value: boolean, isRoot: boolean) => void,
};

export default function List({
  headerCellClassName,
  data,
  fields,
  filterOptions,
  width,
  selected,
  onSelect,
  linkTemplate,
  onFilter,
  onChange,
}: Props) {
  const [selectedFilterWindow, setSelectedFilterWindow] = React.useState(null);

  const filterHandler = (props) => {
    if (onFilter) {
      onFilter(props);
    }
  };
  const selectFilterWindow = (field) => {
    setSelectedFilterWindow(selectedFilterWindow !== field ? field : null);
  };

  function renderByType(
    value: string | number | string[] | CheckBoxValue,
    field: any,
    fieldIndex?: number,
    item: ListRow,
  ) {
    const { type, name: fieldName } = field;

    let className = null;

    if (item.metadata && item.metadata.length > 0) {
      const meta = item.metadata.find((md) => md.property === fieldName);

      if (meta && meta.theme) {
        className = styles[meta.theme];
      }
    }

    if (type === 'date' && value && typeof value === 'string') {
      const textContent = dayjs(value).format('DD.MM.YYYY');

      return (
        <span className={className} title={textContent}>
          {textContent}
        </span>
      );
    }

    if (type === 'estimatedTime' && value && typeof value === 'string') {
      const deadline = getFullDifferenceBetweenDates(new Date(value), new Date());

      if (deadline.negative) {
        return (
          <span title={deadline.withText} style={{ color: '#e30613' }}>
            {deadline.withText}
          </span>
        );
      }

      return (
        <span className={className} title={deadline.withText}>
          {deadline.withText}
        </span>
      );
    }

    if (type === 'checkBox' && value.isChecked !== undefined && value.isDisabled !== undefined) {
      return (
        <Checkbox
          onChange={(e) => {
            if (onChange) {
              onChange({ fieldName, fieldIndex: fieldIndex, value: !value.isChecked });
            }
          }}
          isDisabled={value.isDisabled}
          isChecked={value.isChecked}
        />
      );
    }

    if (Array.isArray(value) && value.length) {
      const textContent = value.join(' / ');

      return (
        <span className={className} title={textContent}>
          {textContent}
        </span>
      );
    }

    if (field.customRenderer) {
      return field.customRenderer(item);
    }

    const textContent = value || '. . .';

    return (
      <span className={className} title={textContent}>
        {textContent}
      </span>
    );
  }

  function updateFilterHandler(
    option: FilterItemInputType | FilterItemDateType,
    filterName: string,
  ) {
    if (fields) {
      const filterOption = fields.find((item) => item.name === filterName);
      if (filterOption) {
        if (filterOption.filterType === 'text' && option.id) {
          if (!filterOptions[filterName]) {
            filterHandler({ ...filterOptions, [filterName]: [option] });
          } else if (filterOptions[filterName]) {
            filterHandler({
              ...filterOptions,
              [filterName]: [...filterOptions[filterName], option],
            });
          }
        } else if (filterOption.filterType === 'date') {
          filterHandler({ ...filterOptions, [filterName]: option });
        }
      }
    }
  }

  function removeFilterHandler(option: FilterItemInputType, filterName: string) {
    const filtered = filterOptions[filterName].filter((filter) => filter.id !== option.id);
    filterHandler({ ...filterOptions, [filterName]: [...filtered] });
  }

  function removeColumnFilters() {
    if (selectedFilterWindow) {
      filterHandler({
        ...filterOptions,
        [selectedFilterWindow]: [],
      });
    }
  }

  function DatepickerRangeChangeHandler(
    changes: DatepickerRangeChangeHandlerProps,
    dateFilterName,
  ) {
    if (fields) {
      let update = {};

      if (changes.from && changes.to) {
        update = { type: 'date', from: changes.from, to: changes.to };
        updateFilterHandler(update, dateFilterName);
      } else if (!changes.from && !changes.to) {
        update = { type: 'date', from: null, to: null };
        updateFilterHandler(update, dateFilterName);
      }
    }
  }

  function DatepickerHasFiltered(filterName: string) {
    return !!(filterOptions[filterName].from && filterOptions[filterName].to);
  }

  const selectRow = (index: number, data: ListRow) => {
    if (onSelect) {
      onSelect(data, index);
    }
  };

  function getDropdown(field: FieldContainer) {
    if (!field.isFilterable && field.type === 'checkBox') {
      const isColumnChecked = data
        .filter((item) => item[field.name].isDisabled === false)
        .every((item) => item[field.name].isChecked === true);

      return (
        <NoDropdownComponent title="check all articles">
          <Checkbox
            onChange={(e) => {
              if (onChange) {
                onChange({ fieldName: field.name, value: !isColumnChecked, isRoot: true });
              }
            }}
            isChecked={isColumnChecked}
          >
            {field.displayName}
          </Checkbox>
        </NoDropdownComponent>
      );
    }

    if (!field.isFilterable) {
      return <NoDropdown field={field} />;
    }

    if (field.filterType === 'text') {
      return (
        <InputDropdown
          field={field}
          filterOptions={filterOptions}
          removeColumnFilters={removeColumnFilters}
          selectedFilterWindow={selectedFilterWindow}
          selectFilterWindow={selectFilterWindow}
          updateFilterHandler={updateFilterHandler}
          removeFilterHandler={removeFilterHandler}
        />
      );
    }

    if (field.filterType === 'date') {
      return (
        <DateDropdown
          selectFilterWindow={selectFilterWindow}
          selectedFilterWindow={selectedFilterWindow}
          DatepickerHasFiltered={DatepickerHasFiltered}
          DatepickerRangeChangeHandler={DatepickerRangeChangeHandler}
          field={field}
          filterOptions={filterOptions}
        />
      );
    }

    return <NoDropdown field={field} />;
  }

  return (
    <div className={styles.container}>
      <table className={styles.tableContainer} width={width}>
        <thead>
          <tr className={styles.trHead}>
            {fields &&
              fields.map((field, index) => (
                <th
                  className={cn(
                    styles.thHeading,
                    headerCellClassName,
                    field.filter && styles.clickable,
                  )}
                  key={`table-heading-${field.id}-${index}`}
                >
                  {getDropdown(field)}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, index) => (
              <tr
                className={cn(
                  styles.row,
                  selected !== undefined && selected === index && styles.selectedRow,
                )}
                key={`table-row-${index}`}
                onClick={() => {
                  selectRow(index, item);
                }}
              >
                {fields &&
                  fields.map((field, fieldIndex) => (
                    <td key={`table-cell-${fieldIndex}`}>
                      {linkTemplate ? (
                        <Link
                          data-testid="test-order-row"
                          className={styles.tdBody}
                          to={linkTemplate(item.id)}
                        >
                          {renderByType(item[field.name], field, index, item)}
                        </Link>
                      ) : (
                        <div className={styles.tdBody}>
                          {renderByType(item[field.name], field, index, item)}
                        </div>
                      )}
                    </td>
                  ))}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}
