import {
  DocumentTypes,
  IUserTaskTemplate,
  TaskChronologyOutcome,
  TaskFieldType,
  TaskId,
  TaskNames,
  TaskSectionCaption,
  TaskSectionId,
  TaskTerminalButtonCaption,
  TaskTerminalButtonId,
  TaskTerminalButtonType,
  TaskType,
  TransportTypes,
  UserRoles,
} from '@lmru-bpms-ved/core';

const allTransports = Object.values(TransportTypes);

const createShipmentTaskId = 'Create a shipment 5.1' as TaskId; // тут ок привести к общему типу, так как форма кастомная

export const createShipmentTaskName = 'Create a shipment' as TaskNames; // тут ок привести к общему типу, так как форма кастомная,

export const makeCreateShipmentTaskTemplate = (): IUserTaskTemplate => ({
  id: createShipmentTaskId,
  name: createShipmentTaskName,
  type: TaskType.Task,
  role: UserRoles.AdeoTransport,
  roleName: '',
  defaultChronologyOutcome: TaskChronologyOutcome.TaskFinished,
  sla: [],
  sections: [
    {
      id: TaskSectionId.Task,
      caption: TaskSectionCaption.Task,
      invisibleCaption: true,
      fields: [
        {
          id: 'taskName',
          type: TaskFieldType.Text,
          label: 'Name',
          text: 'Attach drafts of shipping documents',
          isBold: true,
        },
        {
          id: 'taskDescription',
          type: TaskFieldType.Text,
          label: 'Description',
          text: 'Please choose the way the shipment was sent and attach the following shipping documents as separate files',
        },
      ],
    },
    {
      id: TaskSectionId.Decision,
      caption: TaskSectionCaption.Decision, // именно Decision! есть завязки на это поле. См. определение типа TaskFormDto
      invisibleCaption: true,
      fields: [
        {
          id: 'transport',
          type: TaskFieldType.Select,
          label: 'Shipped by',
          options: [
            {
              text: TransportTypes.Truck,
              id: TransportTypes.Truck,
            },
            {
              text: TransportTypes.Train,
              id: TransportTypes.Train,
            },
            {
              text: TransportTypes.Sea,
              id: TransportTypes.Sea,
            },
          ],
          isRequired: true,
        },
        {
          id: 'etd',
          type: TaskFieldType.DatePicker,
          availableWhen: [TransportTypes.Truck, TransportTypes.Train],
          label: 'ETD',
          isRequired: true,
        },
        {
          id: 'eta',
          type: TaskFieldType.DatePicker,
          availableWhen: [TransportTypes.Sea],
          label: 'ETA',
          isRequired: true,
        },
        {
          type: TaskFieldType.AttachmentDoc,
          id: DocumentTypes.invoiceEuropeDraft,
          doctype: DocumentTypes.invoiceEuropeDraft,
          label: 'Invoice, draft',
          isRequired: true,
          isMultiple: true,
          availableWhen: [TransportTypes.Truck],
        },
        {
          type: TaskFieldType.AttachmentDoc,
          id: DocumentTypes.invoiceEurope,
          doctype: DocumentTypes.invoiceEurope,
          label: 'Invoice, original',
          isRequired: false,
          isMultiple: true,
          availableWhen: [TransportTypes.Truck],
        },
        {
          type: TaskFieldType.AttachmentDoc,
          id: DocumentTypes.invoiceChinaDraft,
          doctype: DocumentTypes.invoiceChinaDraft,
          label: 'Invoice, draft\u200B', // Хак для уникальности. См. определение типа TaskFormDto
          isRequired: true,
          isMultiple: true,
          availableWhen: [TransportTypes.Train],
        },
        {
          type: TaskFieldType.AttachmentDoc,
          id: DocumentTypes.invoiceChina,
          doctype: DocumentTypes.invoiceChina,
          label: 'Invoice, original\u200B', // Хак для уникальности. См. определение типа TaskFormDto
          isRequired: false,
          isMultiple: true,
          availableWhen: [TransportTypes.Train],
        },
        {
          type: TaskFieldType.AttachmentDoc,
          id: DocumentTypes.invoiceChinaDraft,
          doctype: DocumentTypes.invoiceChinaDraft,
          label: 'Invoice, draft\u00A0', // Хак для уникальности. См. определение типа TaskFormDto
          isRequired: true,
          isMultiple: true,
          availableWhen: [TransportTypes.Sea],
        },
        {
          type: TaskFieldType.AttachmentDoc,
          id: DocumentTypes.invoiceChina,
          doctype: DocumentTypes.invoiceChina,
          label: 'Invoice, original\u00A0', // Хак для уникальности. См. определение типа TaskFormDto
          isRequired: false,
          isMultiple: true,
          availableWhen: [TransportTypes.Sea],
        },
        {
          type: TaskFieldType.AttachmentDoc,
          id: DocumentTypes.swbDraft,
          doctype: DocumentTypes.swbDraft,
          label: 'SWB, draft',
          isRequired: true,
          isMultiple: true,
          availableWhen: [TransportTypes.Sea],
        },
        {
          type: TaskFieldType.AttachmentDoc,
          id: DocumentTypes.swb,
          doctype: DocumentTypes.swb,
          label: 'SWB, original',
          isRequired: false,
          isMultiple: true,
          availableWhen: [TransportTypes.Sea],
        },
        {
          type: TaskFieldType.AttachmentDoc,
          id: DocumentTypes.cmrDraft,
          doctype: DocumentTypes.cmrDraft,
          label: 'CMR, draft',
          isRequired: true,
          isMultiple: true,
          availableWhen: [TransportTypes.Truck],
        },
        {
          type: TaskFieldType.AttachmentDoc,
          id: DocumentTypes.cmr,
          doctype: DocumentTypes.cmr,
          label: 'CMR, original',
          isRequired: false,
          isMultiple: true,
          availableWhen: [TransportTypes.Truck],
        },
        {
          type: TaskFieldType.AttachmentDoc,
          id: DocumentTypes.railwaySpecificationDraft,
          doctype: DocumentTypes.railwaySpecificationDraft,
          label: 'Railway Specification, draft',
          isRequired: true,
          isMultiple: true,
          availableWhen: [TransportTypes.Train],
        },
        {
          type: TaskFieldType.AttachmentDoc,
          id: DocumentTypes.railwaySpecification,
          doctype: DocumentTypes.railwaySpecification,
          label: 'Railway Specification, original',
          isRequired: false,
          isMultiple: true,
          availableWhen: [TransportTypes.Train],
        },
        {
          type: TaskFieldType.AttachmentDoc,
          id: DocumentTypes.smgs,
          doctype: DocumentTypes.smgs,
          label: 'SMGS, original',
          isRequired: false,
          isMultiple: true,
          availableWhen: [TransportTypes.Train],
        },
        {
          type: TaskFieldType.AttachmentDoc,
          id: DocumentTypes.priceListDraft,
          doctype: DocumentTypes.priceListDraft,
          label: 'Price list, draft',
          isRequired: false,
          isMultiple: true,
          availableWhen: allTransports,
        },
        {
          type: TaskFieldType.AttachmentDoc,
          id: DocumentTypes.priceList,
          doctype: DocumentTypes.priceList,
          label: 'Price list, original',
          isRequired: false,
          isMultiple: true,
          availableWhen: allTransports,
        },
        {
          type: TaskFieldType.AttachmentDoc,
          id: DocumentTypes.exportDeclarationDraft,
          doctype: DocumentTypes.exportDeclarationDraft,
          label: 'Export Declaration, draft',
          isRequired: false,
          isMultiple: true,
          availableWhen: allTransports,
        },
        {
          type: TaskFieldType.AttachmentDoc,
          id: DocumentTypes.exportDeclaration,
          doctype: DocumentTypes.exportDeclaration,
          label: 'Export Declaration, original',
          isRequired: false,
          isMultiple: true,
          availableWhen: allTransports,
        },
        {
          type: TaskFieldType.AttachmentDoc,
          id: DocumentTypes.certificateOfOriginDraft,
          doctype: DocumentTypes.certificateOfOriginDraft,
          label: 'Certificates of origin, draft',
          isRequired: false,
          isMultiple: true,
          availableWhen: allTransports,
        },
        {
          type: TaskFieldType.AttachmentDoc,
          id: DocumentTypes.certificateOfOrigin,
          doctype: DocumentTypes.certificateOfOrigin,
          label: 'Certificates of origin, original',
          isRequired: false,
          isMultiple: true,
          availableWhen: allTransports,
        },
        {
          type: TaskFieldType.AttachmentDoc,
          id: DocumentTypes.phytosanitaryCertificateDraft,
          doctype: DocumentTypes.phytosanitaryCertificateDraft,
          label: 'Phytosanitary certificates, draft',
          isRequired: false,
          isMultiple: true,
          availableWhen: allTransports,
        },
        {
          type: TaskFieldType.AttachmentDoc,
          id: DocumentTypes.phytosanitaryCertificate,
          doctype: DocumentTypes.phytosanitaryCertificate,
          label: 'Phytosanitary certificates, original',
          isRequired: false,
          isMultiple: true,
          availableWhen: allTransports,
        },
        {
          type: TaskFieldType.AttachmentDoc,
          id: DocumentTypes.packinglistDraft,
          doctype: DocumentTypes.packinglistDraft,
          label: 'Packing list, draft',
          isRequired: false,
          isMultiple: true,
          availableWhen: allTransports,
        },
        {
          type: TaskFieldType.AttachmentDoc,
          id: DocumentTypes.packinglist,
          doctype: DocumentTypes.packinglist,
          label: 'Packing list, original',
          isRequired: false,
          isMultiple: true,
          availableWhen: allTransports,
        },
        {
          type: TaskFieldType.AttachmentDoc,
          id: DocumentTypes.letterOfDeclarationDraft,
          doctype: DocumentTypes.letterOfDeclarationDraft,
          label: 'Letter of Declaration, draft',
          isRequired: false,
          isMultiple: true,
          availableWhen: allTransports,
        },
        {
          type: TaskFieldType.AttachmentDoc,
          id: DocumentTypes.letterOfDeclaration,
          doctype: DocumentTypes.letterOfDeclaration,
          label: 'Letter of Declaration, original',
          isRequired: false,
          isMultiple: true,
          availableWhen: allTransports,
        },
        {
          type: TaskFieldType.Comment,
          id: 'comment',
          label: 'Comment',
          placeholder: 'Describe some details if necessary',
          isRequired: false,
          availableWhen: allTransports,
        },
      ],
    },
  ],
  terminalButtons: [
    {
      caption: TaskTerminalButtonCaption.Proceed,
      type: TaskTerminalButtonType.Primary,
      id: TaskTerminalButtonId.Submit,
    },
  ],
});
