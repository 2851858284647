import { CreateColumn } from '@src/shared/components/Table';
import { TableColumnDef } from '@src/shared/components/Table/types';

import { IDCApi } from '../../../api';

export const tableDefIdcPhotoEdit: TableColumnDef[] = [
  CreateColumn.latestFiles({
    name: 'createdAt',
    displayName: 'Uploaded ↓',
    isFilterable: true,
    isLookingPast: true,
    showOnlyOne: false,
    getLatestVersions: IDCApi.documentWidget.getUploadsHistoryByEntityId,
  }),

  CreateColumn.text({
    name: 'entityId',
    displayName: 'File name',
    isFilterable: true,
    isMultiSearchFilter: true,
    getFilters: IDCApi.documentWidget.requestFilters,
  }),

  CreateColumn.text({
    name: 'performer',
    displayName: 'Performer',
    isFilterable: true,
    disableMinRestriction: true,
    getFilters: IDCApi.documentWidget.requestFilters,
  }),

  CreateColumn.parsingState({
    name: 'state',
    displayName: 'Status',
    isFilterable: true,
    disableMinRestriction: true,
    getFilters: IDCApi.documentWidget.requestFilters,
  }),

  CreateColumn.parsingDetails({
    name: 'uiErrors',
    displayName: 'Details',
    isFilterable: false,
  }),
];
