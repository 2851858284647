import React, { useCallback } from 'react';

import { Loader, Typography } from 'fronton-react';
import { styled } from 'linaria/lib/react';

import { FileService } from '@src/shared/services/files';

import styles from './index.css';

type Props = {
  id: string;
  docName: string;
  isLoading: boolean;
  onDownload: (id: string) => void;
  extension?: string;
  errorText?: string;
};

// TODO: fix fronton typings
const ErrorCaption = styled(Typography as any)`
  margin-top: var(--space-100);
  /* TODO(fronton): Using an old UI error color override for consistency, cause we not fully support fronton yet in Tasks */
  color: #e30613;
`;

export const DynamicDocDownload = ({
  id,
  docName,
  extension,
  errorText,
  isLoading,
  onDownload,
}: Props) => {
  const FileExtIcon = extension
    ? FileService.getIconComponentByExtension(extension)
    : FileService.getIconComponentByFileName(docName);

  const onClick = useCallback(() => {
    if (!isLoading) {
      onDownload(id);
    }
  }, [id, isLoading, onDownload]);

  return (
    <>
      <div className={styles.file} onClick={onClick}>
        {isLoading ? <Loader size="s" /> : <FileExtIcon />}
        <span title={docName} className={styles.fileLabel}>
          {docName}
        </span>
      </div>
      {errorText && (
        <ErrorCaption variant="s" size="body_long">
          {errorText}
        </ErrorCaption>
      )}
    </>
  );
};
