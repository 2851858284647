import React from 'react';

import cn from 'classnames';
import { Dropdown, Checkbox } from 'mp-ui-components';

import DropdownIcon from '@src/shared/assets/svg/TriangleUp.svg?react';

import styles from '../../../../../styles.module.scss';
import { RowsSelectionStatus, TableElementRole } from '../../../../../types';
import { HeaderCellProps } from '../../types';

export const DropdownRowsSelector: React.FC<HeaderCellProps> = ({ field, onTableEvent }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const dropdownOptions = field.$state?.selectionOptions;
  const selectionStatus = field.$state?.selectionStatus;

  const isPartiallySelected = selectionStatus === RowsSelectionStatus.partial;

  const onClickOption: React.MouseEventHandler<HTMLDivElement> = (e) => {
    const actionType = e.currentTarget?.dataset?.action;

    if (actionType) {
      onTableEvent<unknown>({
        type: actionType,
        payload: undefined,
      });
    }

    setIsOpen(false);
  };

  return (
    <th
      className={cn(
        styles.thHeading,
        styles.clickable,
        styles.thCheckbox,
        styles.thCheckboxDropdown,
      )}
      data-table-role={TableElementRole.rootRowSelector}
      data-opened={isOpen}
      data-partially-selected={isPartiallySelected}
    >
      <Dropdown isOpen={isOpen} onChange={() => setIsOpen(!isOpen)}>
        <Dropdown.Trigger>
          <div className={styles.checkboxContainer} onClick={(e) => e.preventDefault()}>
            <Checkbox isChecked={false} />
            <DropdownIcon />
          </div>
        </Dropdown.Trigger>
        <Dropdown.Content className={styles.dropdownContent} ariseDirection="bottom-start">
          {dropdownOptions?.map(({ id, displayName }) => (
            <div
              className={styles.dropdownOption}
              key={id}
              data-action={id}
              onClick={onClickOption}
            >
              {displayName}
            </div>
          ))}
        </Dropdown.Content>
      </Dropdown>
    </th>
  );
};
