import React from 'react';

import { SignOutIcon } from '@fronton/icons-react';
import { Avatar, Typography } from 'fronton-react';
import { Link } from 'react-router-dom';

import AppLogoSvg from '@src/shared/assets/svg/RiesLogo.svg?react';
import { userRoleDisplayMapping } from '@src/shared/enums/userRoleDisplayMapping';
import { AuthService } from '@src/shared/services/auth';
import { RoutingService } from '@src/shared/services/routing';

import { NavBar } from './NavBar';
import { NotificationBanner } from './NotificationBanner';
import { Header, AppLogo, UserInfo, SignOutButton, MainContent } from './Styled';

export const AppHeader = () => {
  const userToken = AuthService.getUserIdToken();

  const userName = userToken?.fullName ?? '';
  const userRole = userRoleDisplayMapping[userToken?.vedRole ?? ''] ?? '';

  const showUserInfo = userName && userRole;

  return (
    <Header>
      <NotificationBanner />

      <MainContent>
        <Link to="/">
          <AppLogo size="auto">
            <AppLogoSvg />
          </AppLogo>
        </Link>

        <NavBar userToken={userToken} />

        {showUserInfo && (
          <UserInfo>
            <Typography variant="s" size="body_long" color="text-secondary">
              {userRole}
            </Typography>
            <Typography variant="s" size="body_long">
              {userName}
            </Typography>
            <Avatar name={userName} hideInfo color="blue" size="s" />
            <SignOutButton to={RoutingService.root.routes.logout}>
              <SignOutIcon type="outline" size="l" color="text-primary" />
            </SignOutButton>
          </UserInfo>
        )}
      </MainContent>
    </Header>
  );
};
