import React, { ReactNode, useState } from 'react';

import { styled } from 'linaria/lib/react';

import DropdownIcon from '@src/shared/assets/svg/TriangleUp.svg?react';

type WrapProps = {
  isOpened: boolean;
};

const Wrap = styled.div<WrapProps>`
  .title {
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      margin-left: 3px;
      transform: rotate(${({ isOpened }) => (isOpened ? '0deg' : '180deg')});

      & g {
        fill: #9da8b3;
      }
    }
  }
`;

type FoldableTextProps = {
  title: ReactNode;
  text: ReactNode;
  isOpened?: boolean;
  onOpen?: (isOpened: boolean) => void;
  className?: string;
  style?: React.HTMLAttributes<HTMLDivElement>;
};

export const FoldableText = ({
  style,
  className,
  title,
  text,
  isOpened: isOpenedFromProps,
  onOpen = () => {},
}: FoldableTextProps) => {
  const [isOpenedLocal, setIsOpen] = useState(false);

  const isOpened = isOpenedFromProps ?? isOpenedLocal;

  const onTitleClick = () => {
    setIsOpen(!isOpened);
    onOpen(!isOpened);
  };

  return (
    <Wrap isOpened={isOpened} style={style} className={className}>
      <div className="title" onClick={onTitleClick}>
        {title}
        <DropdownIcon height="14px" width="14px" viewBox="0 0 16 16" />
      </div>
      {isOpened && text}
    </Wrap>
  );
};
