import React, { useRef } from 'react';
import { useClickAway, useToggle } from 'react-use';
import { useEditGKLinking } from '../../../hooks/useEditGKLinking/useEditGKLinking';
import { TOrderArticle } from '../../../entities/orderArticle';
import { Menu, MenuItem } from './Menu';
import { Link } from 'mp-ui-components';

type Props = {
  article: TOrderArticle;
  editGKLinking?: ReturnType<typeof useEditGKLinking>;
};

export const GKLinkPicker = ({ article, editGKLinking }: Props) => {
  const [isMenuOpened, toggleMenuOpen] = useToggle(false);

  const ref = useRef(null);

  useClickAway(ref, () => {
    toggleMenuOpen(false);
  });

  const actualIdcId = article.idcId;

  if (!editGKLinking?.isEditMode) {
    return <>{actualIdcId ?? '. . .'}</>;
  }

  const selectedIdcId = editGKLinking?.getSelectedGKForEan(article.eanCode);

  const onItemPick = (idcId: string) => {
    if (selectedIdcId !== idcId) {
      editGKLinking?.updateGKChanges(article.eanCode, idcId);
    }
    toggleMenuOpen();
  };

  const hasChanges = selectedIdcId !== actualIdcId;

  return (
    <div ref={ref}>
      <Link tag="span" isDotted={true} onClick={() => toggleMenuOpen()}>
        {hasChanges ? <b>{selectedIdcId}</b> : selectedIdcId}
      </Link>
      {isMenuOpened && (
        <Menu>
          {editGKLinking.getAllGKsByEan(article.eanCode).map((idcId) => (
            <MenuItem
              key={idcId}
              value={idcId}
              isSelected={idcId === selectedIdcId}
              onSelect={onItemPick}
            />
          ))}
        </Menu>
      )}
    </div>
  );
};
