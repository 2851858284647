import React, { useContext } from 'react';

import { SetDisplayImageModal } from '@src/shared/components/ImageBox/types';

import { TaskState } from '../../../types';

export enum FormTarget {
  inline = 'inline',
  modal = 'modal',
}

export type FormContextValue = {
  state: TaskState;
  target: FormTarget;
  isShipment: boolean;
  selectedConditions: string[];
  shipmentId?: string;
  header?: string;
  onUpdateField: (field: any) => void;
  clearConditions: (toRemove: string[]) => void;
  isButtonDisabled: (buttonId: string) => boolean;
  getButtonPendingState: (buttonId: string) => boolean;
  terminalButtonHandler: (buttonId: string) => void;
  // по факту используется для картинок только
  setDisplayModal?: SetDisplayImageModal;
  onClose?: () => void;
  getMinutesLeft?: () => { remaining: any; type: any; isNegative: any } | null;
};

export const FormContext = React.createContext<FormContextValue>({} as never);

export const useFormContext = () => useContext(FormContext);
