// @flow strict
import React from 'react';
import cn from 'classnames';
import styles from './index.css';
import { Button } from 'mp-ui-components';
import sadSmile from './assets/pic-not-found.svg';

type Props = {|
    title: string,
    text?: string,
    theme: 'red' | 'white',
    width?: 'xs' | 's' | 'm' | 'l',
    margin?: string | number,
    status?: string,
    buttonLabel?: string,
    buttonHandler?: () => void
|};

const parseWidth = value => {
    switch (value) {
        case 'xs':
            return '320px';
        case 's':
            return '480px';
        case 'm':
            return '620px';
        case 'l':
            return '760px';
        default:
            return 'auto';
    }
};

function ErrorComponent({ title, text, theme, width, margin }: Props) {
    const resolveThemeStyle = (theme: string) => {
        if (theme === 'red') return styles.containerDefault;
        if (theme === 'white') return styles.containerWhite;
        return styles.containerDefault;
    };

    return (
        <div className={cn(styles.container, resolveThemeStyle(theme))} style={{ margin, width: parseWidth(width) }}>
            <div className={styles.textContainer}>
                <div className={styles.title}>{title}</div>
                <div className={styles.text}>{text}</div>
            </div>
            <div className={styles.smileContainer}>
                <img alt="sad_smile" src={sadSmile} />
            </div>
        </div>
    );
}

function ErrorReloadableComponent({ title, text, theme, width, margin, status, buttonLabel, buttonHandler }: Props) {
    const errors = {
        default: "Server doesn't respond",
        '400': "Server doesn't respond",
        '404': "Request doesn't found",
        '500': "Server doesn't respond"
    };

    const resolveErrorText = () => {
        if (status && errors[status]) {
            return errors[status];
        } else if (status) return errors.default;

        return text;
    };

    const reloadPage = () => {
        if (window) {
            window.location.reload();
        }
    };

    return (
        <div
            data-testid="error-component"
            className={styles.containerReloadable}
            style={{ margin, width: parseWidth(width) }}
        >
            <div className={styles.titleReloadable}>{title}</div>
            <div className={styles.textReloadable}>{resolveErrorText()}</div>
            <div className={styles.btnReloadableContainer}>
                <Button onClick={buttonHandler || reloadPage} theme="primary">
                    {buttonLabel || 'Reload page'}
                </Button>
            </div>
        </div>
    );
}

export { ErrorComponent, ErrorReloadableComponent };
