import { styled } from 'linaria/lib/react';

export const Wrap = styled.div`
  display: flex;
  align-items: center;

  > p:first-of-type {
    margin-right: 12px;
  }

  > p[data-status='error'] {
    color: var(--attention-dark);
  }
`;
