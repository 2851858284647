// toDo: replace most of the state logic from TaskView => to this reducer
export type TAction =
  | {
      type: 'resetTerminalButtonsPending';
    }
  | {
      type: 'setProceeding';
      value: boolean;
    }
  | {
      type: 'setTakingFromPerformer';
      value: boolean;
    }
  | {
      type: 'setProcessing';
      value: boolean;
    };

export type IState = {
  isProceedingTask: boolean;
  isTakingFromPerformer: boolean;
  isProcessingTask: boolean;
};

export const initialState: IState = {
  isProceedingTask: false,
  isTakingFromPerformer: false,
  isProcessingTask: false,
};

export function reducer(state: IState, action: TAction) {
  switch (action.type) {
    case 'resetTerminalButtonsPending': {
      return {
        ...state,
        isProceedingTask: false,
        isTakingFromPerformer: false,
        isProcessingTask: false,
      };
    }

    case 'setProceeding': {
      return { ...state, isProceedingTask: action.value };
    }

    case 'setTakingFromPerformer': {
      return { ...state, isTakingFromPerformer: action.value };
    }

    case 'setProcessing': {
      return { ...state, isProcessingTask: action.value };
    }

    default:
      return { ...state };
  }
}
