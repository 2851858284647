// @flow strict
import React from 'react';
import styles from './index.css';
import List from '../list-active-tasks';

import { ErrorReloadableComponent } from '../../../../../../shared/components/Error';
import Preloader from '@src/shared/components/Preloader';
import { requestShipmentTasks } from '../../../ShipmentsListPage/services/shipments';
import { RoutingService } from '@src/shared/services/routing';
import { userRoleDisplayMapping } from '@src/shared/enums/userRoleDisplayMapping';

function ShipmentTasks({ shipmentId }: { shipmentId: string | null | typeof undefined }) {
  const [tableData, setTableData] = React.useState([]);
  const [fields, setFields] = React.useState([]);
  const [isPending, setPending] = React.useState(true);
  const [failedStatus, setFailedStatus] = React.useState(null);

  const isUnmounted = React.useRef(false);

  React.useEffect(() => {
    if (shipmentId) {
      requestShipmentTasks(shipmentId)
        .then((response) => {
          if (!isUnmounted.current) {
            setTableData(
              response.data?.map((fields) => ({
                ...fields,
                role: userRoleDisplayMapping[fields.role] ?? fields.role,
              })),
            );
            setFields(response.fields);
            setPending(false);
          }
        })
        .catch((err) => {
          if (!isUnmounted.current) {
            setFailedStatus(err.response ? err.response.status : 'network');
            setPending(false);
            console.error(err);
          }
        });
    }
    return () => {
      isUnmounted.current = true;
    };
  }, []);

  const linkTemplateHandler = (taskId) => {
    return `${RoutingService.root.routes.tasks}/${taskId}`;
  };

  if (failedStatus)
    return (
      <ErrorReloadableComponent
        theme="white"
        width="s"
        margin="30px 0"
        title="Active tasks can't be loaded"
        status={failedStatus}
      />
    );

  if (!isPending && tableData.length === 0) {
    return <div className={styles.noTasks}>No tasks</div>;
  }

  if (isPending) return <Preloader />;

  return (
    <div>
      <List linkTemplate={linkTemplateHandler} data={tableData} fields={fields} />
    </div>
  );
}

export default ShipmentTasks;
