import React from 'react';

import { debounce } from 'lodash';

/**
 * Lodash.debounce react hook
 * @param fn Function which we want to debounce. IMPORTANT: Must be memoized outside hook!
 * @param delay Debounce delay
 * @returns Lodash debounced function
 */
export const useDebounce = <Fn extends (...args: any) => any>(fn: Fn, delay: number) => {
  return React.useMemo(() => debounce(fn, delay), [fn, delay]);
};
