import { IdcCoreReportRequest } from '@lmru-bpms-ved/contract-bff';

export enum Filters {
  departments = 'departments',
  statuses = 'statuses',
}

export type FormState = Required<IdcCoreReportRequest>;

export const validateForm = (form: FormState) => {
  const departmentFilled = form[Filters.departments].length > 0;
  const statusFilled = form[Filters.statuses].length > 0;

  if (departmentFilled && statusFilled) {
    return;
  }

  if (departmentFilled) {
    return `Choose status`;
  }

  if (statusFilled) {
    return `Choose department`;
  }

  return `Choose department and status`;
};
