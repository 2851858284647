import React from 'react';

import { InfoIcon } from '@fronton/icons-react';
import { styled } from 'linaria/lib/react';
import { uniqueId } from 'lodash';

import { AddSnackParams } from './types';

// Fix fronton icon rendering in snackbar
export const SnackInfoIcon = styled(InfoIcon)`
  display: block;
`;

export const makeDefaultErrorSnack = () =>
  ({
    id: uniqueId(),
    variant: 'error',
    header: 'Technical error',
    paragraph: 'Please try again later',
    autoHideTimer: 1800,
    icon: <SnackInfoIcon size="l" />,
  } as AddSnackParams);
