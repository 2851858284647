import { CreateColumn, CreateColumnParams } from '@src/shared/components/Table';
import { httpClient } from '@src/shared/services/httpClient';
import { createQueryDef } from '@src/shared/services/query';

type QueryDef = NonNullable<
  CreateColumnParams<typeof CreateColumn.latestFiles>['getLatestVersions']
>;

export const makeGetUploadsHistoryByEntityId = (baseUrl: string) =>
  createQueryDef<QueryDef>(`${baseUrl}/history/byEntityId`, async ({ queryKey }) => {
    const [url, params] = queryKey;

    const response = await httpClient.post(url, params);

    return response.data;
  });
