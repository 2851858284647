import * as Sentry from '@sentry/react';

import { TableRow } from '@src/shared/components/Table/_deprecated/Table';

export enum StorageOperation {
  add = 'add',
  delete = 'delete',
}

export const ORDERS_BROWSER_STORAGE_KEYS = {
  local: { appliedFilters: 'ordersFilters' },
  session: { selectedRows: 'ordersSelectedRows' },
};

export const getSavedFilters = () => {
  let savedFiltersFromLocalStorage = {};
  const savedFiltersString = localStorage.getItem(ORDERS_BROWSER_STORAGE_KEYS.local.appliedFilters);

  if (savedFiltersString) {
    savedFiltersFromLocalStorage = JSON.parse(savedFiltersString);

    for (const filter in savedFiltersFromLocalStorage) {
      if (savedFiltersFromLocalStorage[filter].type === 'date') {
        savedFiltersFromLocalStorage[filter].from = new Date(
          savedFiltersFromLocalStorage[filter].from,
        );
        savedFiltersFromLocalStorage[filter].to = new Date(savedFiltersFromLocalStorage[filter].to);
      }
    }

    if (Object.keys(savedFiltersFromLocalStorage).length > 0) {
      return savedFiltersFromLocalStorage;
    }
  }

  return {};
};

export const getSavedSelectedRows = () => {
  const selectedRowsJSON = sessionStorage.getItem(ORDERS_BROWSER_STORAGE_KEYS.session.selectedRows);
  let selectedRows = new Map<string, string>();

  if (selectedRowsJSON) {
    try {
      selectedRows = new Map(JSON.parse(selectedRowsJSON));
    } catch (error) {
      Sentry.captureException({
        info: 'OrdersPage - failed to parse selected rows data from session storage',
        error,
      });
    }
  }

  return selectedRows;
};

export const saveSelectedRows = (rows: TableRow[], operation = StorageOperation.add) => {
  const savedSelectedRows = getSavedSelectedRows();

  for (const row of rows) {
    switch (operation) {
      case StorageOperation.add: {
        savedSelectedRows.set(row.id, row.priority);
        break;
      }

      case StorageOperation.delete: {
        savedSelectedRows.delete(row.id);
        break;
      }

      default: {
        return;
      }
    }
  }

  sessionStorage.setItem(
    ORDERS_BROWSER_STORAGE_KEYS.session.selectedRows,
    JSON.stringify([...savedSelectedRows.entries()]), // Spread is used here, because Map.entries() return an iterator rather than an Array
  );
};

export const deleteSelectedRowsWithIds = (ids: string[]) => {
  const savedSelectedRows = getSavedSelectedRows();

  for (const id of ids) {
    savedSelectedRows.delete(id);
  }

  sessionStorage.setItem(
    ORDERS_BROWSER_STORAGE_KEYS.session.selectedRows,
    JSON.stringify([...savedSelectedRows.entries()]), // Spread is used here, because Map.entries() return an iterator rather than an Array
  );
};

export const deleteAllSelectedRows = () => {
  sessionStorage.removeItem(ORDERS_BROWSER_STORAGE_KEYS.session.selectedRows);
};
