import React from 'react';

import XlsIcon from '@src/shared/components/DocDownload/assets/icon-file-xls.svg';

import { FileDescription } from './FileDescription';

import styles from './styles.css';

export const FileDownloadOnlyOne = ({
  created,
  filePath,
  fileName,
  url,
}: {
  filePath: string;
  created: string;
  fileName: string;
  url: string;
}) => {
  return (
    <div className={styles.filePart}>
      <div className={styles.file}>
        <img src={XlsIcon} className={styles.icon} alt="xls-icon" />
        <div className={styles.description}>
          {filePath ? (
            <FileDescription fileName={fileName} created={created} />
          ) : (
            <b>No uploaded file</b>
          )}

          <div>
            <a className={styles.href} href={url} target="_blank" rel="noopener noreferrer">
              Download
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
