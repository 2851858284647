import React from 'react';

import { Dayjs } from 'dayjs';
import { Caption, MaskedInput } from 'fronton-react';

import { Wrap } from './Styled';

type Props = {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  error?: string;
  className?: string;
};

export const DATE_RANGE_DELIMITER = ' — ';

export const INPUT_DATE_FORMAT = 'DD.MM.YYYY';

export const prepareDefaultDateInput = (startDate: Dayjs, endDate: Dayjs) =>
  `${startDate.format(INPUT_DATE_FORMAT)}${DATE_RANGE_DELIMITER}${endDate.format(
    INPUT_DATE_FORMAT,
  )}`;

export const DatePickerRangeInput = ({ className, error, value, onChange }: Props) => (
  <Wrap className={className}>
    <MaskedInput
      label="Date"
      inputSize="s"
      mask="99.99.9999 — 99.99.9999"
      alwaysShowMask
      maskPlaceholder={`${INPUT_DATE_FORMAT}${DATE_RANGE_DELIMITER}${INPUT_DATE_FORMAT}`}
      error={!!error}
      caption={error ? <Caption error message={error} /> : undefined}
      onChange={onChange}
      value={value}
    />
  </Wrap>
);
