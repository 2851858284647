import { STSubReportType } from '@src/modules/documents/enums/STSubReportType';
import { AuthService, UserRole } from '@src/shared/services/auth';

import { STReport } from '../../../../../../../types';
import { remappedUserNames, remappedUserRoleNames } from '../../../mappers';

export const getPerformerLabel = ({ docType, userRole, userName }: STReport) => {
  if (
    ![STSubReportType.BrokerReports, STSubReportType.ForwarderReports].includes(
      docType as STSubReportType,
    )
  ) {
    return;
  }

  if (AuthService.getIsUserRoleMatches([UserRole.Forwarder, UserRole.CustomsBroker])) {
    return remappedUserRoleNames[userRole] ?? userRole;
  }

  return remappedUserNames[userName] ?? userName;
};
