import { FileService } from '@src/shared/services/files';
import { httpClient } from '@src/shared/services/httpClient';

import { FileStatus } from '../../enums/enums';
import { TUploadFileOption } from '../../types';

// TODO Files Service - rewrite to common upload API
// BFF upgrade is needed
export function uploadFiles({ files = [], docType, day }: TUploadFileOption) {
  const formData = new FormData();

  for (const file of files) {
    const fileExtension = FileService.getFileExtension(file.name);

    if (fileExtension !== 'xls' && fileExtension !== 'xlsx') {
      return Promise.reject(FileStatus.WrongFileType);
    }

    formData.append(`${docType}`, file);
  }

  return httpClient({
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: `api/shipmentsTracking/files?filesFolder=${docType}/${day}`,
    data: formData,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response?.status === 413) {
        return Promise.reject(FileStatus.FileTooBigError);
      }

      return Promise.reject(FileStatus.UploadingError);
    });
}
