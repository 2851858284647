import React from 'react';

import { currentActivityEnum } from '../../../enums/enums';
import { reducer } from '../../../pages/ShipmentsTrackingPage/domain/shipmentReports/reducer';
import { init } from '../../../pages/ShipmentsTrackingPage/domain/shipmentReports/shipmentReports';
import {
  getReportUploadOptions,
  hasReports,
} from '../../../pages/ShipmentsTrackingPage/domain/shipmentReports/shipmentReportsDto';
import { useUploadFiles } from '../../../pages/ShipmentsTrackingPage/hooks/useUploadFiles';
import { WidgetProps } from '../types';
import { useGetReports } from './useGetReports';

export const useWidgetState = ({ reports, docType }: WidgetProps) => {
  const [state, dispatch] = React.useReducer(reducer, { reports, docType }, init);

  useUploadFiles(
    dispatch,
    getReportUploadOptions({ ...state.upload, docType }),
    state.currentActivity === currentActivityEnum.uploading,
  );

  useGetReports(
    dispatch,
    {
      docType,
    },
    state.currentActivity === currentActivityEnum.checkFileStatus,
  );

  const hasData = hasReports(state.reports);

  return {
    state,
    dispatch,
    hasData,
  };
};
