import {
  CoreReportFilters,
  IdcCoreReportResponse,
  IdcCoreReportRequest,
} from '@lmru-bpms-ved/contract-bff';

import { FileService } from '@src/shared/services/files';
import { httpClient } from '@src/shared/services/httpClient';
import { BaseQueryDef, createQueryDef } from '@src/shared/services/query';

export const getCoreReportFilters = createQueryDef<BaseQueryDef<CoreReportFilters>>(
  `/api/idc/reports/core/filters`,
  async ({ queryKey }) => {
    const [url] = queryKey;
    const response = await httpClient.get<CoreReportFilters>(url);

    return response.data;
  },
);

export const downloadCoreReport = async (dto: IdcCoreReportRequest) => {
  const response = await httpClient.post<IdcCoreReportResponse>(`/api/idc/reports/core`, dto);

  const fileUrl = response.data.s3Key;

  if (fileUrl) {
    FileService.downloadFile(fileUrl);
  }
};
