import React, { useState, useEffect } from 'react';

import { getInstructionsList } from '../services/helpAndSupport';
import { Instruction } from '../types/instructions';

export interface IUseInstructions {
  data: Instruction[];
  isLoading: boolean;
  isError: boolean;
}

export const useInstructions = () => {
  const [data, setData] = useState<Instruction[]>();
  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);

  useEffect(() => {
    setLoading(true);
    getInstructionsList()
      .then((responseData) => {
        setData(responseData);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return {
    data,
    isLoading,
    isError,
  };
};
