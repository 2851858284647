import React from 'react';

import { userRoleDisplayMapping } from '@src/shared/enums/userRoleDisplayMapping';
import { UserRole } from '@src/shared/services/auth';

import type { ChronologyItem, ChronologyResponse } from '../../types';
import type { SetDisplayImageModal } from '../ImageBox/types';

import Chronology from './Chronology';

import styles from './index.css';

const ChronologyContainer = ({
  data,
  setDisplayModal,
}: {
  data: ChronologyResponse[];
  setDisplayModal?: SetDisplayImageModal;
}) => {
  if (data.length === 0) {
    return null;
  }

  const chronologyMapped = data.map((chronologyItem) => {
    const enrichedItem = { ...chronologyItem } as ChronologyItem;

    if (chronologyItem.sla) {
      enrichedItem.sla = {
        start: chronologyItem.sla.start ? new Date(chronologyItem.sla.start) : null,
        estimated: chronologyItem.sla.estimated ? new Date(chronologyItem.sla.estimated) : null,
        finish: chronologyItem.sla.finish ? new Date(chronologyItem.sla.finish) : null,
      };
    }

    enrichedItem.information = chronologyItem.information.map((item) => ({
      ...item,
      role: userRoleDisplayMapping[item.role as UserRole] ?? item.role,
    }));

    return enrichedItem;
  });

  return (
    <div className={styles.container}>
      <Chronology setDisplayModal={setDisplayModal} data={chronologyMapped} />
    </div>
  );
};

export default ChronologyContainer;
