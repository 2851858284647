import React from 'react';

import { FileService } from '@src/shared/services/files';

import { TemplateHint } from './TemplateHint';
import { titleText } from './config/title';
import { tableDefByDocType } from './config/uploadsHistoryTable/tableDefByDocType';
import {
  DocumentWidget,
  useTitle,
  useUploader,
  useUploadsHistoryTable,
} from '../../../widgets/DocumentWidget';
import { IDCApi } from '../api';
import { IDCDocType } from '../enums';

type Props = { docType: IDCDocType };

export const IDCDocUploadWidget = ({ docType }: Props) => {
  const title = useTitle({
    docType,
    text: titleText[docType] ?? '',
    isFoldable: true,
  });

  const historyContent = useUploadsHistoryTable({
    isActive: !title.isFolded,
    docType,
    dataQueryDef: IDCApi.documentWidget.getAllUploadsHistory,
    tableDef: tableDefByDocType[docType] ?? [],
  });

  const uploader = useUploader({
    apiMethod: (files) => IDCApi.documentWidget.upload(files, docType),
    onUploaded: historyContent.update,
  });

  const { data: docTypesConfig } = FileService.useDocTypesQuery();

  const allowedFileExt = docTypesConfig?.[docType]?.extensions ?? [];

  return (
    <DocumentWidget
      title={title}
      hint={<TemplateHint docType={docType} />}
      uploader={uploader}
      renderContent={historyContent.render}
      allowedFileExt={allowedFileExt}
    />
  );
};
