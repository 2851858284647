// Legacy File
// TODO: refactor and fix errors

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { SyntheticEvent, useCallback } from 'react';

import { Tab, TabList } from 'fronton-react';
import { styled } from 'linaria/lib/react';
import { useNavigate, useParams } from 'react-router-dom';

import { mapWithUniqueIds } from '@src/modules/certTracking/mapper';
import {
  requestOrderChronology,
  requestTask,
} from '@src/modules/tasks/pages/TasksListPage/services/tasks';
import ChronologyContainer from '@src/shared/components/ChronologyContainer';
import { ErrorReloadableComponent } from '@src/shared/components/Error';
import ImagePreview from '@src/shared/components/ImagePreview';
import { OrderInfoBar } from '@src/shared/components/InfoBar';
import Preloader from '@src/shared/components/Preloader';
import { RoutingService } from '@src/shared/services/routing';

import { LightBox } from './components/Lightbox/Lightbox';
import { OrderArticlesList } from './components/OrderArticlesList/OrderArticlesList';
import { OrderDocuments } from './components/OrderDocuments/OrderDocuments';
import { mapOrderFromDto } from './mapper';
import { requestOrder } from '../../api/methods/orders';

import styles from './styles.module.scss';
import { ImageModalParams } from '@src/shared/components/ImageBox/types';

enum TabId {
  documents = 'documents',
  articles = 'articles',
}

const tabs = [
  { id: TabId.documents, displayText: 'Documents' },
  { id: TabId.articles, displayText: 'Articles' },
];

const getTabIndex = (tabId?: string) => {
  const index = tabs.findIndex(({ id }) => id === tabId);

  return index >= 0 ? index : 0;
};

const TabContent = styled.div`
  margin-top: var(--space-200);
`;

const StyledTabList = styled(TabList)`
  /* Dirty hack, cause table component in Articles tab has insane padding top 
     TODO: use new table component and rewrite inner styles
  */
  position: relative;
  z-index: 2;
  margin-top: -7px;
  margin-left: -10px;
`;

export const OrderPage: React.FC = () => {
  const [chronology, setChronology] = React.useState();
  const [headerData, setHeaderData] = React.useState({});
  const [lightBoxOpened, setLightBoxOpened] = React.useState(null);
  const [displayModal, setDisplayModal] = React.useState<ImageModalParams>(null);
  const [isPending, setPending] = React.useState(true);
  const [failedStatus, setFailedStatus] = React.useState(null);
  const [lightBoxData, setLightBoxData] = React.useState({});
  const [selectedTaskId, setSelectedTaskId] = React.useState(null);
  const locationParams = useParams();
  const orderId = locationParams.id;
  const tabSelected = getTabIndex(locationParams.tab);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (orderId) {
      requestOrder(orderId)
        .then((response) => {
          setHeaderData(mapOrderFromDto(response));
          requestOrderChronology(orderId)
            .then((responseChronology) => {
              setPending(false);
              setChronology(responseChronology);
            })
            .catch((error) => {
              setPending(false);
              setFailedStatus((old) => {
                return { ...old, chronology: error.response ? error.response.status : 'network' };
              });
            });
        })
        .catch((error) => {
          setPending(false);
          setFailedStatus((old) => {
            return { ...old, order: error.response ? error.response.status : 'network' };
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationParams.id]);

  function actionHandler(taskId: string) {
    requestTask(taskId).then((response) => {
      setSelectedTaskId(taskId);
      setLightBoxData({
        id: response.id,
        templateId: response.templateId,
        label: response.name,
        fields: mapWithUniqueIds(response.sections[0].fields),
      });
      setLightBoxOpened(true);
    });
  }

  function tabClickHandler(event: SyntheticEvent<Element, Event>, tab: number) {
    if (orderId) {
      navigate(`${RoutingService.root.routes.orders}/${orderId}/${tabs[tab]?.id}`);
    }
  }

  const refetchChronology = useCallback(async () => {
    try {
      const newChronology = await requestOrderChronology(orderId);

      setChronology(newChronology);
    } catch (error) {
      setFailedStatus((old) => {
        return { ...old, chronology: error.response ? error.response.status : 'network' };
      });
    }
  }, [orderId, setChronology, setFailedStatus]);

  if (isPending) {
    return <Preloader />;
  }

  if (failedStatus) {
    return (
      <ErrorReloadableComponent
        theme="white"
        width="s"
        margin={30}
        title="The order can't be loaded"
        status={failedStatus.chronology || failedStatus.order}
      />
    );
  }

  const selectedTabId = tabs[tabSelected].id;

  return (
    <>
      <OrderInfoBar
        currency={headerData.currency}
        mode={headerData.mode}
        orderNumber={headerData.orderNumber}
        OCNumber={headerData.OCNumber}
        orderDate={headerData.orderDate}
        orderConfirmationDate={headerData.orderConfirmationDate}
        department={headerData.department}
        supplier={headerData.supplier}
        location={headerData.location}
        ETA={headerData.eta}
        ETD={headerData.etd}
      />
      <div className={styles.container}>
        {lightBoxOpened && orderId && selectedTaskId && (
          <LightBox
            orderId={orderId}
            orderNumber={headerData.orderNumber}
            taskId={selectedTaskId}
            data={lightBoxData}
            onClose={(lightBoxCallback) => {
              if (lightBoxCallback === 'proceed' && window) {
                window.location.reload();
              }

              setLightBoxOpened(false);
            }}
          />
        )}
        {displayModal && (
          <ImagePreview
            files={displayModal.files}
            index={displayModal.index}
            onClose={() => {
              setDisplayModal(null);
            }}
          />
        )}
        <ChronologyContainer setDisplayModal={setDisplayModal} data={chronology || []} />
        <div className={styles.content}>
          <div className={styles.wrap}>
            <StyledTabList active={tabSelected} onChangeTab={tabClickHandler} size="s">
              {tabs.map((tab) => (
                <Tab key={tab.displayText}>{tab.displayText}</Tab>
              ))}
            </StyledTabList>
            <TabContent>
              {orderId && (
                <>
                  {selectedTabId === TabId.documents && (
                    <OrderDocuments onAction={actionHandler} orderId={orderId} />
                  )}
                  {selectedTabId === TabId.articles && (
                    <OrderArticlesList
                      orderId={orderId}
                      ocNumber={headerData.OCNumber}
                      refetchChronology={refetchChronology}
                    />
                  )}
                </>
              )}
            </TabContent>
          </div>
        </div>
      </div>
    </>
  );
};
