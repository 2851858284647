import { TasksGetDynamicDocResponse } from '@lmru-bpms-ved/contract-bff';

import { FileService } from '@src/shared/services/files';
import { httpClient } from '@src/shared/services/httpClient';
import { BaseQueryDef, createQueryDef } from '@src/shared/services/query';

type QueryDef = BaseQueryDef<
  void,
  {
    taskId: string;
    docId: string;
  }
>;

export const getDynamicDoc = createQueryDef<QueryDef>('/api/tasks', async ({ queryKey }) => {
  const [url, { docId, taskId }] = queryKey;

  const response = await httpClient.get<TasksGetDynamicDocResponse>(`${url}/${taskId}/dynamicDoc`, {
    params: {
      docId,
    },
  });

  FileService.downloadFile(response.data.url);
});
