import React, { useCallback, useRef, useState } from 'react';

import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { isEqual } from 'lodash';
import { useQuery } from 'react-query';

import { PaginationController } from '@src/shared/components/PaginationController';
import Preloader from '@src/shared/components/Preloader';
import { usePagination } from '@src/shared/components/Table';
import { useActiveDropdown } from '@src/shared/components/Table/_deprecated/Table/components/Header/useActiveDropdown';
import {
  CommonTable,
  TableMeta,
  TableRow,
} from '@src/shared/components/Table/_deprecated/Table/types';
import { BaseQueryDef } from '@src/shared/services/query';
import { FilterType } from '@src/shared/types';

import { DocNoDataLabel } from '../../../components/DocLayout';
import { i18n } from '../../../i18n';
import { Table } from '../components/Table';
import { INIT_PAGE_INDEX, INIT_PAGE_SIZE } from '../config';
import { DocumentWidgetContentRenderer, OnUploadedCb } from '../types';
import { getHasUploads } from '../utils';

type DataQueryDef = BaseQueryDef<
  {
    hasNext: boolean;
    data: CommonTable['rows'];
  },
  {
    pageNumber: number;
    pageSize: number;
    fileType: string;
    filters: FilterType;
  }
>;

type Params = {
  docType: string;
  dataQueryDef: DataQueryDef;
  tableDef: ColumnDef<TableRow>[];
  isActive: boolean;
};

const REFRESH_TIME = {
  max: 20_000,
  min: 2000,
};

export const useUploadsHistoryTable = ({ docType, dataQueryDef, tableDef, isActive }: Params) => {
  const pagination = usePagination(INIT_PAGE_INDEX, INIT_PAGE_SIZE);
  const [globalFilter, setGlobalFilter] = useState({});
  const fastRefreshAttempt = useRef(0);

  const setFilterWithPagination = useCallback(
    (newFilters) => {
      if (!isEqual(globalFilter, newFilters)) {
        pagination.reset();
      }

      setGlobalFilter(newFilters);
    },
    [globalFilter, pagination, setGlobalFilter],
  );

  const query = useQuery({
    queryKey: dataQueryDef.getQueryKey({
      pageNumber: pagination.state.pageIndex + 1,
      pageSize: pagination.state.pageSize,
      fileType: docType,
      filters: globalFilter,
    }),
    queryFn: dataQueryDef.queryFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    cacheTime: 0,
    retry: false,
    enabled: isActive,
    refetchInterval: (queryData) => {
      const hasPendingData = queryData?.data?.some?.(({ state }) => state === 'Parsing');

      if (hasPendingData) {
        if (fastRefreshAttempt.current * REFRESH_TIME.min >= REFRESH_TIME.max) {
          return REFRESH_TIME.max;
        }

        fastRefreshAttempt.current += 1;

        return fastRefreshAttempt.current * REFRESH_TIME.min;
      }

      if (fastRefreshAttempt.current) {
        fastRefreshAttempt.current = 0;
      }

      return REFRESH_TIME.max;
    },
  });

  const { activeDropdown, openDropdown } = useActiveDropdown();

  const paginatorNode = (
    <PaginationController
      isPending={query.isFetching}
      isNextDisabled={query.data?.hasNext ?? false}
      page={pagination.state.pageIndex}
      onChange={(nextPage) => pagination.setState((state) => ({ ...state, pageIndex: nextPage }))}
    />
  );

  const [uploadedRowsIds, setUploadedRowsIds] = useState<string[]>([]);

  const table = useReactTable({
    data: query.data?.data ?? [],
    columns: tableDef,
    pageCount: -1,
    state: {
      pagination: pagination.state,
      globalFilter,
    },
    meta: {
      tableName: docType as unknown,
      paginatorNode,
      activeDropdown,
      openDropdown,
      uploadedRowsIds,
      setUploadedRowsIds,
    } as TableMeta,
    manualPagination: true,
    enableFilters: true,
    manualFiltering: true,
    onGlobalFilterChange: setFilterWithPagination,
    onPaginationChange: pagination.setState,
    getCoreRowModel: getCoreRowModel(),
  });

  const hasData = getHasUploads(table);

  const render = useCallback<DocumentWidgetContentRenderer>(() => {
    if (query.isLoading) {
      return <Preloader />;
    }

    return hasData ? (
      <Table table={table} />
    ) : (
      <DocNoDataLabel>{i18n.noUploadedFilesText}</DocNoDataLabel>
    );
  }, [hasData, query.isLoading, table]);

  const update: OnUploadedCb = async (documentIds) => {
    await query.refetch();

    const tableMeta = table.options.meta as TableMeta;

    tableMeta?.setUploadedRowsIds?.(documentIds);
  };

  return { render, update, hasData };
};
