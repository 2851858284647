import { RegularButton } from 'fronton-react';
import { styled } from 'linaria/lib/react';

import { DatePickerRangeInput } from '@src/modules/documents/components/DatePickerRangeInput';
import { DocTitle } from '@src/modules/documents/components/DocLayout';
import Preloader from '@src/shared/components/Preloader';

export const DownloadSection = styled.div`
  display: flex;
`;

export const StyledButton = styled(RegularButton)`
  margin-left: var(--space-300);
`;

export const StyledPreloader = styled(Preloader)`
  align-self: center;
  width: calc(256px + 256px + 16px); /* filter inputs + margin */

  & > div {
    width: 100%;
    margin: 0;
  }
`;

export const StyledDatePickerRangeInput = styled(DatePickerRangeInput)`
  width: 256px;
  margin-left: var(--space-200);
`;

export const StyledDocTitle = styled(DocTitle)`
  align-items: flex-start;
`;
