export const pluralizeWord = (
  wordForms: [none: string, single: string, many: string],
  count: number,
) => {
  const [noneForm, singleForm, manyForm] = wordForms;

  if (count === 0) {
    return noneForm;
  }

  if (count === 1) {
    return singleForm;
  }

  return manyForm;
};
