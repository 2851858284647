import { createReports } from '../../../pages/ShipmentsTrackingPage/domain/shipmentReports/shipmentReports';
import { LatestSTSubReports } from '../../../types';
import { WidgetProps } from '../types';

// TODO: rewrite components to get rid of adapter
// 1. use simplier contract for widget props
// 2. rewrite inner requests logic to single useQuery with data-provider component (mb consume single query func as props ?)
export const prepareReports = (
  reportsData: LatestSTSubReports,
  docType: WidgetProps['docType'],
): WidgetProps['reports'] => ({
  lastN: Array.isArray(reportsData.lastN[docType])
    ? {
        [docType]: {
          ...reportsData.lastN[docType]?.[0],
          downloadFiles: reportsData.lastN[docType],
          lastSuccessfulUpdated: reportsData.lastSuccess[docType]?.updated ?? null,
        },
      }
    : createReports({
        lastN: reportsData.lastN[docType],
        download: reportsData.lastN[docType],
        lastSuccess: reportsData.lastSuccess[docType],
      }),

  lastSuccess: reportsData.lastSuccess[docType],
  lastRejected: reportsData.lastRejected[docType],
  lastProcessing: reportsData.lastProcessing[docType],
});
