import React from 'react';

import cn from 'classnames';
import { Checkbox } from 'mp-ui-components';

import styles from '../../../../styles.module.scss';
import { SelectEvent, TableEventType } from '../../../../types';
import { DataCellProps } from '../types';

export const renderCheckbox = ({
  value,
  className,
  rowId,
  onTableEvent,
}: DataCellProps<boolean>) => {
  const onClick: React.MouseEventHandler<HTMLElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();

    return onTableEvent<SelectEvent>({
      type: TableEventType.select,
      payload: { rowId },
    });
  };

  return (
    <td className={cn(className, styles.tdCheckbox)} onClick={onClick}>
      <Checkbox isChecked={value} />
    </td>
  );
};
