import { styled } from 'linaria/lib/react';

export const TBody = styled.tbody`
  vertical-align: top;

  tr[data-updated='true'] {
    position: relative;

    td:first-child::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 10px;
      left: -12px;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #979797;
    }
  }
`;
