import { TableColumn } from '@src/shared/components/Table/_deprecated/Table/types';
import { KeyboardKeys, useKeypressHandler } from '@src/shared/utils/hooks/useKeypressHandler';

import { useEnabledOptions } from './useEnabledOptions';
import { useInput } from './useInput';
import { useSearch } from './useSearch';

export const useFilter = (field: TableColumn) => {
  const { input, onInputChange } = useInput(field);

  const enabledOptions = useEnabledOptions(field);

  const searchQuery = useSearch(field, input, enabledOptions);

  const onKeypressSearch = useKeypressHandler([KeyboardKeys.Enter], () => {
    if (input.hasMultipleItems && !searchQuery.isFetching) {
      searchQuery.refetch();
    }
  });

  return {
    input,
    enabledOptions,
    searchQuery,
    onInputChange,
    onKeypressSearch,
  };
};

export type Filter = ReturnType<typeof useFilter>;

export type SearchResults = Filter['searchQuery']['data'];

export type EnabledOptions = Filter['enabledOptions'];
