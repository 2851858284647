import React from 'react';

import cn from 'classnames';

import { STSubReportsTransformed } from '@src/modules/documents/types';

import { STSubReportFilter } from '../../../../enums';
import { bookmarksConfig } from './config';

import styles from './styles.css';

type Props = {
  reports: STSubReportsTransformed;
  currentTab: string;
  onChange: (id: STSubReportFilter) => void;
};

export const Filters = ({ onChange, reports, currentTab }: Props) => (
  <>
    <div className={styles.wrap}>
      {bookmarksConfig.map((bookmark, index) => (
        <React.Fragment key={bookmark.id}>
          <div
            className={cn(styles.place, bookmark.id === currentTab && styles.active)}
            onClick={() => onChange(bookmark.id)}
          >
            <div className={styles.upperTitle}>
              {reports[bookmark.id]?.length ?? bookmark.upperTitle}
            </div>
            <div>{bookmark.bottomTitle}</div>
          </div>
          {index === 0 ? <div className={styles.divider} /> : null}
        </React.Fragment>
      ))}
    </div>
    <div className={styles.placeholder}>
      {bookmarksConfig.find((t) => t.id === currentTab)?.placeholder}
    </div>
  </>
);
