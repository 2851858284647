import React, { ComponentType } from 'react';

import { AuthError, useAuth } from '@src/app/hooks/auth';
import { Logout } from '@src/modules/auth';
import NoRole from '@src/shared/components/Error/NoRole';

import { AppContent } from './AppContent/AppContent';
import { TechnicalErrorPage } from './AppContent/basePages/TechnicalErrorPage';
import { AppHeader } from './AppHeader/AppHeader';
import { WithSnackbar } from './AppSnackbar/WithSnackbar';

const ErrorComponents: Record<AuthError, ComponentType> = {
  [AuthError.refreshTokenFailed]: Logout,
  [AuthError.noResponse]: TechnicalErrorPage,
  [AuthError.noRole]: NoRole,
};

export const App = () => {
  const auth = useAuth();

  const authError = auth.error;
  const isSignedIn = !!auth.idToken;

  if (authError) {
    const ErrorComponent = ErrorComponents[authError];

    return <ErrorComponent />;
  }

  return (
    <WithSnackbar>
      <AppHeader />
      {!auth.isLoading && <AppContent isSignedIn={isSignedIn} />}
    </WithSnackbar>
  );
};
