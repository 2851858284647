import React from 'react';

import cn from 'classnames';

import { FilterItemInputType } from '@src/shared/types/domain';

import styles from './styles.module.scss';

type Props = {
  item: FilterItemInputType;
  isEnabled?: boolean;
  onUpdateItems: (enabledOptions: FilterItemInputType[]) => void;
};

export const Item: React.FC<Props> = ({ item, isEnabled, onUpdateItems }) => (
  <div
    className={cn(styles.searchResult)}
    data-test-id={`filter-menu-option-${item.id}`}
    onClick={() => onUpdateItems([item])}
  >
    {item.number && <div className={styles.searchResultNumber}>{item.number}</div>}
    <div className={styles.searchResultText}>{item.text}</div>
    <div
      className={cn(
        styles.searchResultControl,
        isEnabled ? styles.searchResultRemove : styles.searchResultAdd,
      )}
    >
      {isEnabled ? '–' : '+'}
    </div>
  </div>
);
