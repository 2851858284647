import React from 'react';

import { styled } from 'linaria/lib/react';

import { RTCellContext } from '../../types';
import { Td } from '../Td';
import { mappedStateNames, colorsByText, colorsByState } from './config';

const getValue = (context: RTCellContext) => {
  const originalValue = context.getValue() ?? '';
  const mappedValue = mappedStateNames[originalValue] ?? originalValue;

  return mappedValue;
};

type StateTextProps = {
  children: string;
};

const StateText = styled.div<StateTextProps>`
  max-width: 110px;
  overflow: hidden;
  color: ${({ children: text }) => colorsByText[text] ?? colorsByState.default};
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ParsingStateCell = (props: RTCellContext) => (
  <Td rtContext={props}>
    <StateText>{getValue(props)}</StateText>
  </Td>
);
