import { QueryFunction } from 'react-query';

import { TableColumn, TableName } from '@src/shared/components/Table/_deprecated/Table/types';

import { requestFiltersLegacyMethod } from './requestFiltersLegacyMethod';
import { SearchResults } from './types';

type QueryKey = [
  key: string,
  params: {
    field: TableColumn;
    searchItems: string[];
    tableName: TableName;
  },
];

type GetQueryKey = (options: QueryKey[1]) => QueryKey;

const getQueryKey: GetQueryKey = (options) => ['searchFilterOptions', options];

const fromService: QueryFunction<SearchResults, QueryKey> = async ({
  queryKey,
  signal = new AbortController().signal,
}) => {
  const [, { field, searchItems, tableName }] = queryKey;
  const fieldName = field.name;

  // getFilters must be provided through useTable common helper hook outside TableTemplate component
  if (field.getFilters) {
    return field.getFilters({
      field,
      searchItems,
      tableName,
      signal,
    });
  }

  // TODO: transform all tables to TableTemplate component and provide this method outside
  return requestFiltersLegacyMethod(fieldName, searchItems[0], signal);
};

const fromDictionary = async (dictionary: SearchResults, trimmedInputValue: string) => ({
  ...dictionary,
  items: trimmedInputValue
    ? dictionary.items.filter(({ text = '' }) => text.toLowerCase().includes(trimmedInputValue))
    : dictionary.items,
});

export const searchFilters = {
  fromService,
  getQueryKey,
  fromDictionary,
};
