// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

// Legacy File
// TODO: refactor and fix errors
import React from 'react';

import { Button } from 'mp-ui-components';

import { ErrorReloadableComponent } from '@src/shared/components/Error';
import Preloader from '@src/shared/components/Preloader';

import { requestOrderDocuments } from '../../../../api/methods/orders';
import { mapDocumentsFromDTO } from '../../mapper';
import { DocumentsList } from '../DocumentsList/DocumentList';

import styles from './styles.css';

const OrderDocumentFields = [
  {
    id: '123',
    name: 'document',
    displayName: 'Document',
    type: 'text',
  },
  {
    id: '1234',
    name: 'status',
    displayName: 'Status',
    type: 'text',
  },
  {
    id: '1253',
    name: 'performer',
    displayName: 'Performer',
    type: 'text',
  },
  {
    id: '1263',
    name: 'version',
    displayName: 'Version',
    type: 'text',
  },
  {
    id: '1237',
    name: 'action',
    displayName: '',
    type: 'text',
  },
];

type Props = {
  orderId: string;
  onAction: (action: string) => void;
};

export const OrderDocuments: React.FC<Props> = ({ orderId, onAction }) => {
  const [isPending, setPending] = React.useState(true);
  const [failedStatus, setFailedStatus] = React.useState(null);
  const [documents, setDocuments] = React.useState([]);
  const [zipUrl, setZipUrl] = React.useState('');
  const isUnmounted = React.useRef(false);

  React.useEffect(() => {
    if (orderId) {
      requestOrderDocuments(orderId)
        .then((orderDocumentsResponse) => {
          if (!isUnmounted.current) {
            setDocuments(mapDocumentsFromDTO(orderDocumentsResponse.data));
            setZipUrl(orderDocumentsResponse.zipUrl);
            setPending(false);
          }
        })
        .catch((error) => {
          setPending(false);
          setFailedStatus(error.response ? error.response.status : 'network');
        });
    }

    return () => {
      isUnmounted.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (isPending) {
    return <Preloader />;
  }

  if (failedStatus) {
    return (
      <ErrorReloadableComponent
        theme="white"
        width="s"
        margin="30px 0"
        title="Documents can't be loaded"
        status={failedStatus}
      />
    );
  }

  return (
    <div className={styles.container}>
      <DocumentsList onAction={onAction} data={documents} fields={OrderDocumentFields} />
      {zipUrl && (
        <a href={zipUrl} target="_blank" rel="noopener noreferrer">
          <Button className={styles.downloadButton} theme="secondary">
            Download All
          </Button>
        </a>
      )}
    </div>
  );
};
