import { Table } from '@tanstack/react-table';
import isEmpty from 'lodash/isEmpty';

import { TableRow } from '@src/shared/components/Table/_deprecated/Table/types';

export const getHasUploads = (table: Table<TableRow>) => {
  const hasData = table.options.data.length > 0;
  const hasActiveFilters = !isEmpty(table.getState().globalFilter);

  return hasData || hasActiveFilters;
};
