import type {
  MappedFilterType,
  PaginationParams,
  ListRow,
  FieldContainer,
  ChronologyResponse,
  TaskFormResolutionField,
} from '../../../../../shared/types/domain';

import { httpClient } from '../../../../../shared/services/httpClient';

export type ShipmentsRequestResponse = {
  data: ListRow[];
  fields: FieldContainer[];
};

export async function requestShipments(
  filters: MappedFilterType,
  pagination: PaginationParams,
): Promise<ShipmentsRequestResponse> {
  const { limit, offset } = pagination;
  const response = await httpClient({
    method: 'post',
    url: '/api/shipments/filter',
    data: {
      filters,
      limit,
      offset,
      timezoneOffsetMinutes: new Date().getTimezoneOffset() * -1,
    },
  });

  return response.data;
}

export async function requestShipment(id: string) {
  const response = await httpClient({
    method: 'post',
    url: `/api/shipment/${id}`,
  });

  return response.data;
}

type ShipmentAttachedFile = {
  fileName: string;
  path: string;
  type: string;
};

export type ShipmentResolution = {
  transport: string;
  eta?: string;
  etd?: string;
  attachedFiles: ShipmentAttachedFile[];
  comment?: string;
};

export async function addShipment(resolution: TaskFormResolutionField[]) {
  const response = await httpClient({
    method: 'post',
    url: `/api/shipments`,
    data: resolution,
  });

  return response.data;
}

export async function requestShipmentDocuments(shipmentId: string) {
  const response = await httpClient({
    method: 'get',
    url: `/api/shipments/${shipmentId}/documents`,
  });

  return response.data;
}

export async function requestShipmentTasks(shipmentId: string) {
  const response = await httpClient({
    method: 'get',
    url: `/api/shipments/${shipmentId}/tasks`,
  });

  return response.data;
}

export async function requestShipmentHeader(shipmentId: string) {
  const response = await httpClient({
    method: 'get',
    url: `/api/shipments/${shipmentId}`,
  });

  return response.data;
}

function mapChronologyId(chronologyData: ChronologyResponse[]): ChronologyResponse[] {
  return chronologyData.map((chronology) => {
    if (chronology.id === undefined) {
      return { ...chronology, id: `${Math.random()}` };
    }

    return chronology;
  });
}

export async function requestShipmentChronology(shipmentId: string) {
  const response = await httpClient({
    method: 'get',
    url: `/api/shipments/${shipmentId}/chronology`,
  });

  return mapChronologyId(response.data);
}
