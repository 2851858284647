import { TableFieldType } from '../../_deprecated/Table/types';
import { CommonHeader } from '../CommonHeader';
import { makeCreateColumnFn } from '../makeCreateColumn';
import { LatestFilesCell } from './LatestFilesCell';
import { LatestFilesColumn } from './types';

export const createLatestFilesColumn = makeCreateColumnFn<LatestFilesColumn>({
  type: TableFieldType.latestFiles,
  header: CommonHeader,
  cell: LatestFilesCell,
});
