import React, { FC } from 'react';
import cn from 'classnames';
import { FileButton } from 'mp-ui-components';
import styles from './StaticFileUploader.scss';

interface IStaticFileUploaderProps {}

const StaticFileUploader: FC<IStaticFileUploaderProps> = ({
    name,
    text,
    onFilesAttached,
    isMultiple,
    files,
    filesDisplay = 'row'
}: {
    name?: string,
    text: string,
    onFilesAttached: (File[]) => void,
    isMultiple?: boolean,
    files: File[] | null,
    filesDisplay?: 'row' | 'column'
}) => {
    const [failedStatus, setFailedStatus] = React.useState(null);

    const prepareUpload = files => {
        if (files && onFilesAttached) {
            const filesAsArray = [...files];
            onFilesAttached(filesAsArray);

            if (failedStatus) {
                setFailedStatus(null);
            }
        }
    };

    function onDragOverHandler(e) {
        e.preventDefault();
    }

    function onDropFileHandler(e) {
        e.preventDefault();
        if (e.dataTransfer.items) {
            const files = [];
            for (let i = 0; i < e.dataTransfer.items.length; i++) {
                files.push(e.dataTransfer.items[i].getAsFile());
            }
            if (files && files.length !== 0) {
                if (files.length > 1 && !isMultiple)
                    return setFailedStatus('Only one file of this document type can be uploaded');
                prepareUpload(files);
            }
        }
    }

    return (
        <div className={cn(styles.browseFileContainer, styles.rowValueHigher)} style={{ flexDirection: filesDisplay }}>
            <div draggable onDragOver={onDragOverHandler} onDrop={onDropFileHandler}>
                <FileButton
                    /* since it is not possible to set name (only data-testid) */
                    data-testid={name}
                    fileTypes={[]}
                    isMultiple={isMultiple}
                    theme="secondary"
                    onChange={prepareUpload}
                >
                    {text || 'Browse...'}
                </FileButton>
            </div>
            {files && files.length > 0 && !failedStatus && (
                <div
                    className={cn(styles.browsedFile, styles.browsedFileAttached)}
                    style={{ marginTop: filesDisplay === 'row' ? (files.length === 1 ? '8px' : '0px') : '8px' }}
                >
                    {files &&
                        files.length > 0 &&
                        filesDisplay === 'row' &&
                        files.map(file => (
                            <div key={file.name} className={styles.fileName} title={file.name}>
                                {file.name}
                            </div>
                        ))}
                    {files &&
                        files.length > 0 &&
                        filesDisplay === 'column' &&
                        files.map(file => file.name).join(filesDisplay === 'column' ? ',' : '\n')}
                </div>
            )}
            {!files && !failedStatus && <div className={styles.browsedFile}>No files</div>}
            {failedStatus && (
                <div className={styles.fileErrorLabel}>
                    {failedStatus && <div className={styles.fileErrorLabelStatus}>{failedStatus}</div>}
                </div>
            )}
        </div>
    );
};

export default StaticFileUploader;
