import React from 'react';

import styles from './index.css';

type Props = {
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};

export const SearchInput: React.FC<Props> = ({ value, onChange }) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <input
      data-testid="filter-menu-search-input"
      ref={inputRef}
      onChange={onChange}
      value={value}
      placeholder="Search"
      type="text"
      className={styles.inputFilter}
    />
  );
};
