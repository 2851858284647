import { ComponentProps } from 'react';

import { styled } from 'linaria/lib/react';

import { Typography } from '@src/shared/components/Typography';

const statusTextColor = {
  parsing: '#FF7700',
  error: '#E30613',
  default: '#333333',
};

type StatusTextProps = ComponentProps<typeof Typography> & {
  status?: keyof typeof statusTextColor;
};

export const StatusText = styled<StatusTextProps>(Typography)`
  margin-right: 15px;
  color: ${({ status = '' }) => statusTextColor[status] ?? statusTextColor.default};
  text-align: right;
`;
