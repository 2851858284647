import { CommonTable } from '@src/shared/components/Table/_deprecated/Table';
import { httpClient } from '@src/shared/services/httpClient';
import { BaseQueryDef, createQueryDef } from '@src/shared/services/query';
import { FilterType } from '@src/shared/types/domain';
import { mapSelectedFilters } from '@src/shared/utils/mappers/mapTableDataResponseDto';

type ResponseData = {
  hasNext: boolean;
  data: CommonTable['rows'];
  fields: CommonTable['fields'];
};

type QueryParams = {
  pageNumber: number;
  pageSize: number;
  fileType: string;
  filters: FilterType;
};

type QueryDef = BaseQueryDef<ResponseData, QueryParams>;

export const makeGetAllUploadsHistory = (baseUrl: string) =>
  createQueryDef<QueryDef>(`${baseUrl}/history`, async ({ queryKey }) => {
    const [url, { pageNumber, pageSize, fileType, filters }] = queryKey;

    const response = await httpClient.request<ResponseData>({
      method: 'post',
      url,
      data: {
        pageNumber,
        pageSize,
        fileType,
        filters: mapSelectedFilters(filters),
      },
    });

    return response.data;
  });
