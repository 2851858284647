import React from 'react';
import { SnackbarProvider } from 'fronton-react';
import { AppSnackbar } from './AppSnackbar';

export const WithSnackbar = (props: React.PropsWithChildren) => (
  <SnackbarProvider>
    {props.children}
    <AppSnackbar />
  </SnackbarProvider>
);
