import React from 'react';
import { sanitizeObject } from '@src/app/utilities/sanitizeObject';

import qs from 'qs';

import { getRedirectAuthUrl } from '../../utilities';
import { CodeValidationPage } from './CodeValidation';

export const RedirectAuthPage = () => {
  const location = qs.parse(window.location.search.slice(1));

  if (location) {
    if (location.code !== undefined) {
      const code = String(location.code);

      return <CodeValidationPage code={code} />;
    }

    window.location.href = sanitizeObject(getRedirectAuthUrl().REDIRECT_URL);

    return null;
  }

  return null;
};
