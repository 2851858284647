import { FoundIdcKeysDto, RelinkArticlesDto } from '@lmru-bpms-ved/contract-bff';
import { httpClient } from '@src/shared/services/httpClient';
import { BaseQueryDef, createQueryDef } from '@src/shared/services/query';

import type { TOrderArticleId } from '../../pages/OrderPage/entities/orderArticle';

export const getOrderArticles = (orderId: string, filters = {}) =>
  httpClient
    .post(`/api/orders/${orderId}/articles`, {
      filters,
    })
    .then((response) => response.data);

export const blockOrderArticles = (
  orderId: string,
  articleIds: TOrderArticleId[],
  message: string,
) =>
  httpClient.post(`/api/orders/${orderId}/blockArticles`, {
    articleIds,
    message,
  });

export const unblockOrderArticles = (
  orderId: string,
  articleIds: TOrderArticleId[],
  message: string,
) =>
  httpClient.post(`/api/orders/${orderId}/unblockArticles`, {
    articleIds,
    message,
  });

type GetArticlesGKQueryDef = BaseQueryDef<
  FoundIdcKeysDto[],
  {
    eanCodes: string[];
  }
>;

export const getArticlesGK = createQueryDef<GetArticlesGKQueryDef>(
  '/api/orders/articles/getIdcKeys',
  async ({ queryKey }) => {
    const [url, { eanCodes }] = queryKey;

    const params = new URLSearchParams();

    for (let eanCode of eanCodes) {
      params.append('eanCode', eanCode);
    }

    const response = await httpClient.get<FoundIdcKeysDto[]>(url, {
      params,
    });

    return response.data;
  },
);

export const relinkArticlesGK = (dto: RelinkArticlesDto) =>
  httpClient.post<void>('/api/orders/articles/relink', dto);
