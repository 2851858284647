import { UiNotificationBannerDto } from '@lmru-bpms-ved/contract-bff';
import { useQuery } from 'react-query';

import { AuthService } from '@src/shared/services/auth';
import { httpClient } from '@src/shared/services/httpClient';
import { BaseQueryDef, createQueryDef } from '@src/shared/services/query';

const initialBannerDto: UiNotificationBannerDto = { message: '' };

export const getNotificationBanner = createQueryDef<BaseQueryDef<UiNotificationBannerDto>>(
  `/api/incidents/notificationBanner`,
  async ({ queryKey }) => {
    const [url] = queryKey;

    try {
      const response = await httpClient.get<UiNotificationBannerDto>(url);

      return response.data;
    } catch {
      return initialBannerDto;
    }
  },
);

export const useNotificationBanner = (): UiNotificationBannerDto | undefined => {
  const { data } = useQuery({
    queryKey: getNotificationBanner.getQueryKey(),
    queryFn: getNotificationBanner.queryFn,
    refetchInterval: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    // Пока что только для авторизованных пользователей
    enabled: Boolean(AuthService.getUserIdToken()),
  });

  return data;
};
