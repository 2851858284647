import React from 'react';

import { RegularButton, Tooltip } from 'fronton-react';
import { styled } from 'linaria/lib/react';

import { TUseOrderArticles } from '../../hooks/useOrderArticles';

type Props = { editGKLinking: TUseOrderArticles['editGKLinking'] };

const ButtonsPanel = styled.div`
  display: flex;

  button:first-child {
    margin-right: var(--space-150);
  }
`;

export const EditGKLinkingActions = ({ editGKLinking }: Props) => {
  if (editGKLinking.isEditMode) {
    return (
      <ButtonsPanel>
        <RegularButton variant="pseudo" size="s" onClick={editGKLinking.toggleEditMode}>
          Cancel
        </RegularButton>

        <Tooltip
          title={'You need to change at least one Golden key ID'}
          open={editGKLinking.isApplyAvailable ? false : undefined}
        >
          <RegularButton
            variant="accent"
            size="s"
            disabled={!editGKLinking.isApplyAvailable}
            onClick={editGKLinking.apply}
          >
            Apply
          </RegularButton>
        </Tooltip>
      </ButtonsPanel>
    );
  }

  return (
    <RegularButton
      variant="accent"
      size="s"
      onClick={editGKLinking.toggleEditMode}
      loading={editGKLinking.getGKQuery.isFetching}
    >
      Edit GK linking
    </RegularButton>
  );
};
