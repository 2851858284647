import React from 'react';

import axios from 'axios';
import dayjs from 'dayjs';
import { Button, Input } from 'mp-ui-components';
import { sanitizeObject } from '@src/app/utilities/sanitizeObject';

import { AuthService } from '@src/shared/services/auth';
import { RoutingService } from '@src/shared/services/routing';

import { externalAuth } from '../../../../shared/services/httpClient';
import { getRedirectAuthUrl } from '../../utilities';

import styles from './index.css';

let invalidCredsAttempts = 0;

const getAuthErrorMessage = ({ error: errorType, lockout, endLockoutDate }) => {
  if (lockout) {
    const date = dayjs(endLockoutDate);

    return endLockoutDate && date.isValid()
      ? `Ohh too many attempts.\nPlease try again after ${date.add(1, 'm').format('HH:mm')}.`
      : 'Account is not found. Please use Help&Support page to request your credentials.';
  }

  if (errorType === 'invalid_grant') {
    const invalidCredsMsg = `Email or password is incorrect.${
      invalidCredsAttempts > 0
        ? `\nIf you don’t remember it, please use Help&Support page to request your credentials.`
        : ''
    }`;

    invalidCredsAttempts++;

    return invalidCredsMsg;
  }

  return 'Technical error. Please try again later.';
};

export const SignInPage = () => {
  const [leroyAuthURL, setLeroyAuthURL] = React.useState(
    getRedirectAuthUrl().hasFetched ? getRedirectAuthUrl().REDIRECT_URL : undefined,
  );
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [failureText, setFailureText] = React.useState('');
  const [isPending, setPending] = React.useState(false);

  React.useEffect(() => {
    if (!leroyAuthURL) {
      setPending(true);
      setFailureText('');
      axios
        .get('/api/auth/baseurl')
        .then((response) => {
          setPending(false);
          setLeroyAuthURL(
            `${response.data}/connect/authorize?client_secret=lm-bpms-ved&client_id=lm-bpms-ved&scope=lm.bpms.ved.scope%20openid%20profile%20email%20offline_access&redirect_uri=${window.location.origin}/login&response_type=code`,
          );
          localStorage.setItem('redirectAuthURL', response.data);
        })
        .catch(() => {
          setPending(false);
          setFailureText('Technical error. Please refresh the page and try again later.');
          // eslint-disable-next-line unicorn/no-useless-undefined
          setLeroyAuthURL(undefined);
        });
    }
  }, []);

  const submitHandler = (e: React.SyntheticEvent) => {
    e.preventDefault();
    externalAuth({
      username,
      password,
    })
      .then(() => {
        const defaultRoute = RoutingService.getDefaultRouteForRole(
          AuthService.getUserIdToken()?.vedRole,
        );

        window.location.href = defaultRoute;
      })
      .catch((error) => {
        const errorData = error?.response?.data ?? {};

        setFailureText(getAuthErrorMessage(errorData));
      });
  };

  return (
    <div>
      {!isPending && (
        <div className={styles.container}>
          <span className={styles.formLabel}>Login form for external users</span>
          <form className={styles.form} onSubmit={submitHandler}>
            <div className={styles.inputLabel}>Email</div>

            <Input
              onChange={(e) => {
                setUsername(e.target.value);
              }}
              value={username}
              placeholder="username@mail.us"
              className={styles.input}
            />
            <div className={styles.inputLabel}>Password</div>
            <Input
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              value={password}
              className={styles.input}
              type="password"
            />
            <div className={styles.formControls}>
              <Button>LOG IN</Button>
              <a href={sanitizeObject(leroyAuthURL)} className={styles.employLink}>
                {`I'm an employee of Leroy Merlin`}
              </a>
            </div>
          </form>
          {failureText && <div className={styles.errorBlock}>{failureText}</div>}
        </div>
      )}
    </div>
  );
};
