import dayjs from 'dayjs';

import { renderString } from './renderString';
import { DataCellProps } from '../types';

export const renderDate = (props: DataCellProps<string>) => {
  const dateString = props.value;

  return renderString({
    ...props,
    value: dateString ? dayjs(dateString).format('DD.MM.YYYY') : '. . .',
  });
};
