import { useMutation } from 'react-query';

import { UploadFilesResult } from '@src/shared/services/files';

import { OnUploadedCb } from '../types';

type Params = {
  apiMethod: (files: FileList) => Promise<UploadFilesResult>;
  onUploaded: OnUploadedCb;
};

export const useUploader = ({ apiMethod, onUploaded }: Params) =>
  useMutation(apiMethod, {
    onSettled: async (uploadResult) => {
      const uploadResponse: { id: string }[] = uploadResult?.request?.result?.data ?? [];
      const uploadedRowsIds = uploadResponse.map(({ id }) => id);

      if (uploadedRowsIds.length > 0) {
        onUploaded(uploadedRowsIds);
      }
    },
  });
