import type { TTrackingFile, STReport } from '../../../../types';

import { FileStatus, currentActivityEnum } from '../../../../enums/enums';

export function getEmptyState(): TTrackingFile {
  return {
    id: '',
    uploadId: '',
    status: '',
    url: '',
    filePath: '',
    docType: '',
    created: '',
    updated: '',
    version: '',
    convertedErrors: [],
    currentActivity: '',
    fileName: '',
    uploadFile: null,
    downloadFiles: [],
  };
}

export function getCurrentActivity(status: string): string {
  return status === FileStatus.Idle || status === FileStatus.Processing
    ? currentActivityEnum.checkFileStatus
    : currentActivityEnum.idle;
}

export function toEntity(data: STReport): TTrackingFile {
  return { ...getEmptyState(), ...data };
}

export function init(initial?: TTrackingFile): TTrackingFile {
  return { ...getEmptyState(), ...initial };
}
