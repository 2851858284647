import React, { FC, PropsWithChildren } from 'react';

import styles from './FormComponent.scss';

type Props = PropsWithChildren & {
  label: string;
};

export const FormComponent: FC<Props> = ({ label, children }) => {
  return (
    <article className={styles.container}>
      <section className={styles.label}>{label}</section>
      {children}
    </article>
  );
};
