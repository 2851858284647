import React, { useCallback, useMemo } from 'react';

import noop from 'lodash/noop';

import { HeaderCell } from '@src/shared/components/Table/_deprecated/Table/components/Cell';
import { TableContext } from '@src/shared/components/Table/_deprecated/Table/context/TableContext';
import {
  CommonTable,
  TableColumn,
  TableMeta,
} from '@src/shared/components/Table/_deprecated/Table/types';

import { isFilterEvent, isTableEvent } from '../_deprecated/Table/utils';
import { RTHeaderContext } from '../types';

// now we use an adapter to old Table component

export const CommonHeader = ({ table, column }: RTHeaderContext) => {
  const field = column.columnDef.meta as TableColumn;

  const tableData: CommonTable = useMemo(
    () => ({
      rows: table.options.data,
      fields: table.options.columns.map(({ meta }) => meta) as CommonTable['fields'],
    }),
    [table.options.columns, table.options.data],
  );

  const { tableName, activeDropdown, openDropdown = noop } = table.options.meta as TableMeta;

  const onTableEvent = useCallback(
    (event: unknown) => {
      if (!isTableEvent(event)) {
        return;
      }

      if (isFilterEvent(event)) {
        table.setGlobalFilter(event.payload.filterParams);
      }
    },
    [table],
  );

  const context = useMemo(
    () => ({
      tableName,
      tableData,
      onTableEvent,
      allEnabledTableFilters: table.getState().globalFilter,
    }),
    [tableData, tableName, table, onTableEvent],
  );

  if (!field) {
    return null;
  }

  return (
    <TableContext.Provider value={context}>
      <HeaderCell
        activeDropdown={activeDropdown}
        openDropdown={openDropdown}
        field={field}
        onTableEvent={onTableEvent}
        allEnabledTableFilters={table.getState().globalFilter}
        tableData={tableData}
      />
    </TableContext.Provider>
  );
};
