import React, { ReactElement, ReactNode } from 'react';

import { Grid, GridItem, Icon, Typography } from 'fronton-react';
import { styled } from 'linaria/lib/react';

import { ErrorReloadableComponent } from '@src/shared/components/Error';
import Preloader from '@src/shared/components/Preloader';

export const PageContent = styled(Grid)`
  padding: 0 var(--space-525);
`;

export const PageTitle = styled(GridItem)`
  display: flex;
  align-items: center;
  margin: var(--space-525) 0;
`;

export const PageLogo = styled(Icon)`
  margin-right: var(--space-150);
`;

type Props = {
  title: ReactNode;
  children: ReactNode;
  logo?: ReactElement;
  isLoading?: boolean;
  isError?: boolean;
  errorTitle?: ReactNode;
};

export const BasePageLayout = ({
  title,
  logo,
  children,
  isLoading = false,
  isError = false,
  errorTitle = '',
}: Props) => {
  if (isLoading) {
    return <Preloader />;
  }

  if (isError) {
    return <ErrorReloadableComponent theme="white" width="s" margin={30} title={errorTitle} />;
  }

  return (
    <PageContent as="section">
      <PageTitle as="h1">
        {logo && <PageLogo size="auto">{logo}</PageLogo>}
        <Typography variant="h1" as="span">
          {title}
        </Typography>
      </PageTitle>
      {children}
    </PageContent>
  );
};
