import React from 'react';

import { TableFieldType } from '../../../types';
import { renderCheckbox } from './renderers/renderCheckbox';
import { renderDate } from './renderers/renderDate';
import { renderEstimatedTime } from './renderers/renderEstimatedTime';
import { renderString } from './renderers/renderString';
import { DataCellProps } from './types';

const renderersMap = {
  [TableFieldType.checkbox]: renderCheckbox,
  [TableFieldType.date]: renderDate,
  [TableFieldType.estimatedTime]: renderEstimatedTime,
  default: renderString,
};

export const DataCell: React.FC<DataCellProps<any>> = (props) => {
  const renderer = renderersMap[props.field.type] ?? renderersMap.default;

  return renderer(props);
};
