import React from 'react';

import dayjs from 'dayjs';

import { ZigZagContainer } from '@src/shared/components/Table/_deprecated/Table/components/Filter/TextFilter/components/SearchResultsExtraInfo/ZigZagContainer';
import { FileService } from '@src/shared/services/files';

import { RTCellContext } from '../../types';
import { Wrap, StyledMenu, MenuItem, FileName, Date, Status, LimitWarning } from './Styled';
import { MAX_HISTORY_SIZE, useHistory } from './useHistory';

type Props = {
  rtContext: RTCellContext;
};

export const UploadsList = ({ rtContext }: Props) => {
  const uploadsHistory = useHistory(rtContext);

  const isLimitReached = uploadsHistory.uploadsList.length >= MAX_HISTORY_SIZE;

  // Add failed and loading status
  return (
    <Wrap>
      <StyledMenu>
        {uploadsHistory.uploadsList.map(({ id, name, createdAt, url, state }) => {
          const date = dayjs(createdAt);

          const day = date.format('DD.MM.YYYY');
          const time = date.format('HH:mm');

          const downloadFile = () => FileService.downloadFile(url, name);

          return (
            <MenuItem key={id} title={name} onClick={downloadFile}>
              <FileName>{name}</FileName>
              <Date>
                {day} {time}
              </Date>
              <Status>{state}</Status>
            </MenuItem>
          );
        })}
      </StyledMenu>

      {isLimitReached && (
        <ZigZagContainer>
          <LimitWarning>{`The first ${MAX_HISTORY_SIZE} files are visible`}</LimitWarning>
        </ZigZagContainer>
      )}
    </Wrap>
  );
};
