import React from 'react';

import { Divider } from 'mp-ui-components';

import { InputState } from '../../hooks/types';
import { SearchResults } from '../../hooks/useFilter';
import { ZigZagContainer } from './ZigZagContainer';
import { buildMissingItemsMessage, buildSearchLimitMessage } from './buildMessage';

import styles from './styles.module.scss';

type Props = {
  searchResults: SearchResults;
  input: InputState;
};

export const SearchResultsExtraInfo: React.FC<Props> = ({
  searchResults = { items: [], missingItems: [] },
  input,
}) => {
  const messages = [
    buildSearchLimitMessage({ searchResults, input }),
    buildMissingItemsMessage({ searchResults, input }),
  ].filter(Boolean);

  if (messages.length === 0) {
    return null;
  }

  return (
    <ZigZagContainer>
      {messages.map((msg, ind) => {
        const hasDivider = messages.length > 1 && ind !== messages.length - 1;

        return (
          <>
            {msg}
            {hasDivider && <Divider className={styles.divider} />}
          </>
        );
      })}
    </ZigZagContainer>
  );
};
