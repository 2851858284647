import React from 'react';
import { ViewportPreloader } from '@src/shared/components/Preloader/ViewportPreloader';
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Portal,
  RegularButton,
} from 'fronton-react';
import { styled } from 'linaria/lib/react';

const StyledModalFooter = styled(ModalFooter)`
  display: flex;

  button:first-child {
    margin-right: var(--space-200);
  }
`;

type Props = {
  isLoading: boolean;
  isOpened: boolean;
  onApply: () => void;
  onClose: () => void;
};

export const ApplyModal = ({ isLoading, isOpened, onApply, onClose }: Props) => {
  return (
    <Portal>
      {isLoading ? (
        <ViewportPreloader />
      ) : (
        <Modal
          show={isOpened}
          modalID="GKLinkingConfirmation"
          onClose={onClose}
          size="s"
          overlayType="medium"
        >
          <ModalHeader title="Change GK linking?" />

          <ModalContent>
            Please consider the possible impact on ARF and shipping documents.
          </ModalContent>

          <StyledModalFooter>
            <RegularButton wide size="s" variant="secondary" onClick={onClose}>
              Back to edit
            </RegularButton>

            <RegularButton wide size="s" variant="accent" onClick={onApply}>
              Confirm changes
            </RegularButton>
          </StyledModalFooter>
        </Modal>
      )}
    </Portal>
  );
};
