import { currentActivityEnum } from '../../../../enums/enums';
import { TShipmentReports } from '../../../../types';

export const reducer = (
  state: TShipmentReports,
  action: {
    type: string;
    payload: unknown;
  },
): TShipmentReports => {
  switch (action.type) {
    case 'changeShipmentTrackingReports':
      return (({ nextReports }: { nextReports: TShipmentTracking }) => {
        return { ...state, reports: nextReports };
      })(action.payload);

    case 'changeCurrentActivity':
      return (({ nextActivity }: { nextActivity: string }) => {
        return { ...state, currentActivity: nextActivity };
      })(action.payload);

    case 'uploadFiles':
      return ((nextUploadFiles: { files: FileList | File[] }) => {
        return {
          ...state,
          upload: {
            files: nextUploadFiles.files,
            error: '',
          },
          currentActivity: currentActivityEnum.uploading,
        };
      })(action.payload);

    case 'changeUpload':
      return (({
        nextStatus,
        nextError = '',
      }: {
        nextStatus: string;
        nextErrors: Array<string>;
      }) => {
        return { ...state, upload: { ...state.upload, status: nextStatus, error: nextError } };
      })(action.payload);

    default:
      throw new Error('No action found');
  }
};
