import React from 'react';

import { STSubReportType } from '@src/modules/documents/enums/STSubReportType';
import Preloader from '@src/shared/components/Preloader';
import { TableTemplate } from '@src/shared/components/Table';

import { useTable } from './useTable/useTable';

type Props = {
  docType: STSubReportType;
};

export const ArchiveTab = ({ docType }: Props) => {
  const table = useTable(docType);

  if (table.query.isLoading) {
    return (
      <div style={{ marginTop: '40px' }}>
        <Preloader />
      </div>
    );
  }

  return <TableTemplate table={table.instance} />;
};
