import React from 'react';

import { TShipmentReport } from '../../../../../../types';
import { FileDownloadOnly } from './base/FileDownloadOnly';
import { FileStatus } from './base/FileStatus';

import styles from './styles.css';

export const LatestReport = (props: TShipmentReport) => {
  const { updated, placeholder, lastSuccessfulUpdated, status, convertedErrors } = props;

  return (
    <div className={styles.body}>
      <div className={styles.fileInfo}>
        <FileDownloadOnly {...props} />

        <div className={styles.verticalDivider} />

        <FileStatus
          status={status}
          lastSuccessfulUpdated={lastSuccessfulUpdated}
          placeholder={placeholder}
          convertedErrors={convertedErrors}
          updated={updated}
        />
      </div>
    </div>
  );
};
