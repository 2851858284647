import React from 'react';

import { getMonth } from '@src/app/utilities/dates';

import { SeaIcon, TrainIcon, TruckIcon } from './icons';

import styles from './index.css';

type Props = {
  departments: string[];
  ETD?: string;
  ETA?: string;
  transport: string;
  portOfDischarge: string;
  containersOrTrucks: string[];
  isMix: boolean;
};

const boldText = (text: string) => {
  return <span className={styles.boldText}>{text}</span>;
};

export const ShipmentInfoBar = ({
  departments,
  ETA,
  ETD,
  transport,
  portOfDischarge,
  containersOrTrucks,
  isMix,
}: Props) => {
  const ETDFormated = ETD ? new Date(ETD) : undefined;
  const ETAFormated = ETA ? new Date(ETA) : undefined;

  const displayDate = (date: Date) => {
    return `${date.getDate()} ${getMonth(date)} ${date.getFullYear()}`;
  };

  const displayIconStatus = (transportName: string) => {
    const lowerCasedTransport = transportName.toLocaleLowerCase();

    if (lowerCasedTransport === 'sea') {
      return <SeaIcon />;
    }

    if (lowerCasedTransport === 'train') {
      return <TrainIcon />;
    }

    if (lowerCasedTransport === 'truck') {
      return <TruckIcon />;
    }

    return <div />;
  };

  return (
    <section className={styles.container}>
      <span className={styles.spanText}>{displayIconStatus(transport)}</span>
      <span className={styles.transportLabel}>{boldText(transport)}</span>
      <span className={styles.spanText}>·</span>
      {containersOrTrucks && (
        <>
          <span className={styles.spanText}>Containers{isMix ? ' (mix)' : ''}:</span>
          <span className={styles.spanText}>{boldText(containersOrTrucks.join(', '))}</span>
          <span className={styles.spanText}>·</span>
        </>
      )}
      {portOfDischarge && (
        <>
          <span className={styles.spanText}>{boldText(portOfDischarge)}</span>
          <span className={styles.spanText}>·</span>{' '}
        </>
      )}

      {ETAFormated && (
        <>
          {boldText(displayDate(ETAFormated))}
          <span className={styles.spanText}>ETA</span>
        </>
      )}
      {ETDFormated && (
        <>
          {boldText(displayDate(ETDFormated))}
          <span className={styles.spanText}>ETD</span>
        </>
      )}
      {departments && (
        <>
          <span className={styles.spanText}>·</span>
          {boldText('Departments:')}
          <span className={styles.spanText}>{departments && departments.join(' / ')}</span>
        </>
      )}
    </section>
  );
};
