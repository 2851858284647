import React from 'react';

import { TaskFormTemplate } from '@src/modules/tasks/pages/TaskPage/components/TaskFormTemplate/TaskFormTemplate';

import { useTaskForm } from './useTaskForm';

import styles from './index.css';

type Props = { onClose: () => void };

export const AddShipmentModal = ({ onClose }: Props) => {
  const formContext = useTaskForm(onClose);

  return (
    <div className={styles.modal}>
      <div className={styles.overlayModal} onClick={onClose} />
      <div className={styles.modalContent}>
        <TaskFormTemplate formContext={formContext} />
      </div>
    </div>
  );
};
