import { ChronologyIdcRelinkingItem } from '@src/shared/types';
import { styled } from 'linaria/lib/react';
import React from 'react';

type Props = {
  items: ChronologyIdcRelinkingItem[];
};

const Table = styled.table`
  margin-top: var(--space-150);
  margin-left: -8px;
  border-spacing: var(--space-100) var(--space-25);
  white-space: nowrap;

  th {
    padding-bottom: var(--space-100);
  }

  th,
  td {
    text-align: start;
  }
`;

export const IdcLinkingChanges = ({ items }: Props) => {
  return (
    <Table>
      <tr>
        <th>EAN</th>
        <th>Golden key ID (old → new)</th>
      </tr>
      {items.map((item) => (
        <tr key={item.eanCode}>
          <td>{item.eanCode}</td>
          <td>
            {item.oldIdcId} → <b>{item.newIdcId}</b>
          </td>
        </tr>
      ))}
    </Table>
  );
};
