import React from 'react';

import { DateDropdown } from '@src/shared/components/Input/DropdownInput';

import { HeaderCellProps } from '../../Cell';
import { useFilter } from './hooks/useFilter';

export const DateFilter = ({ field, activeDropdown, openDropdown }: HeaderCellProps) => {
  const filter = useFilter();

  return (
    <DateDropdown
      field={field as any}
      selectFilterWindow={openDropdown}
      selectedFilterWindow={activeDropdown}
      DatepickerRangeChangeHandler={filter.onDatepickerRangeChange}
      filterOptions={filter.allEnabledTableFilters}
    />
  );
};
