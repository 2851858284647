import { RegularButton } from 'fronton-react';
import { styled } from 'linaria/lib/react';

import { MultiPickDropdown } from '@src/modules/documents/components/MultiPickDropdown/MultiPickDropdown';
import Preloader from '@src/shared/components/Preloader';

export const DownloadSection = styled.div`
  display: flex;
  align-items: center;
  height: var(--space-400);
`;

export const StyledButton = styled(RegularButton)`
  margin-left: var(--space-300);
`;

export const StyledPreloader = styled(Preloader)`
  width: calc(256px + 256px + 16px); /* filter inputs + margin */
  & > div {
    width: 100%;
  }
`;

export const StyledMultiPickDropdown = styled(MultiPickDropdown)`
  margin-left: var(--space-200);
`;
