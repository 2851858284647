import { QueryClient, UseQueryOptions } from 'react-query';

export const queryClient = new QueryClient();

export const defaultQueryOptions: UseQueryOptions<any, any, any, any> = {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  keepPreviousData: true,
  cacheTime: 0,
  retry: false,
};

export * from './types';

export { createGetQueryKey, createQueryDef } from './utils';
