import { currentActivityEnum } from '../../../../enums/enums';
import { TShipmentTracking } from '../../../../types';

export const reducer = (
  state: TShipmentTracking,
  action: {
    type: string;
    payload: unknown;
  },
): TShipmentTracking => {
  switch (action.type) {
    case 'changeShipmentTracking':
      return ((nextShipmentTracking: TShipmentTracking) => {
        return { ...state, ...nextShipmentTracking, currentActivity: currentActivityEnum.idle };
      })(action.payload);

    case 'changeCurrentActivity':
      return ((nextActivity: string) => {
        return { ...state, currentActivity: nextActivity };
      })(action.payload);

    default:
      throw new Error('no action type provided');
  }
};
