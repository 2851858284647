import React from 'react';

import { styled } from 'linaria/react';
import { Divider } from 'mp-ui-components';

import { Typography } from '@src/shared/components/Typography';
import { i18n } from '@src/shared/i18n';

import { useUploader } from '../hooks/useUploader';

const Wrap = styled.div`
  margin: 20px 0;
`;

const ErrorBlock = styled.div`
  margin-bottom: 9px;

  &:last-of-type {
    margin-bottom: 38px;
  }
`;

const Title = styled(Typography)`
  margin-bottom: 9px;
  color: var(--attention-dark);
  font-weight: 700;
`;

const FileName = styled(Typography)`
  color: #808080;
  word-break: break-all;
`;

type Props = {
  upload: ReturnType<typeof useUploader>;
  isWidgetFolded: boolean;
};

export const UploadErrors = ({ upload, isWidgetFolded }: Props) => {
  const errors = upload.data?.errors;

  if (!errors) {
    return null;
  }

  return (
    <Wrap>
      <Title>{i18n.fileUploads.errorsTitle}</Title>
      {Object.entries(errors).map(([errorType, errorInfo]) => (
        <ErrorBlock key={errorType}>
          <Typography>{errorInfo.text}</Typography>
          <>
            {errorInfo.files.map(({ file }, ind) => (
              // eslint-disable-next-line react/no-array-index-key
              <FileName key={ind}>{file.name}</FileName>
            ))}
          </>
        </ErrorBlock>
      ))}
      {!isWidgetFolded && <Divider />}
    </Wrap>
  );
};
