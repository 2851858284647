import { TableFieldType } from '../../_deprecated/Table/types';
import { CommonHeader } from '../CommonHeader';
import { makeCreateColumnFn } from '../makeCreateColumn';
import { ParsingDetailsCell } from './ParsingDetailsCell';

export const createParsingDetailsColumn = makeCreateColumnFn({
  type: TableFieldType.parsingDetails,
  header: CommonHeader,
  cell: ParsingDetailsCell,
});
