import React from 'react';

import { Navigate, useRoutes } from 'react-router-dom';

import { RedirectAuthPage, SignInPage } from '@src/modules/auth';
import { HelpAndSupportPage } from '@src/modules/helpAndSupport';
import { RoutingService } from '@src/shared/services/routing';

import { TechnicalErrorPage } from '../basePages/TechnicalErrorPage';

export const PublicRoutes = () => {
  const { root } = RoutingService;

  const routes = useRoutes([
    {
      errorElement: <TechnicalErrorPage />,
      children: [
        {
          path: root.routes.sign,
          element: <SignInPage />,
        },
        {
          path: root.routes.login,
          element: <RedirectAuthPage />,
        },
        {
          path: `${root.routes.helpAndSupport}/*`,
          element: <HelpAndSupportPage />,
        },
        {
          path: '*',
          element: <Navigate to={root.routes.sign} />,
        },
      ],
    },
  ]);

  return routes;
};
