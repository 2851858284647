import React, { useState } from 'react';

import { TableColumn } from '@src/shared/components/Table/_deprecated/Table/types';

import { InputState } from './types';
import { splitByMultivalueSeparators } from './utils';

export const useInput = (field: TableColumn) => {
  const [value, setValue] = React.useState('');
  const [isTouched, setIsTouched] = useState(false);

  const onInputChange = React.useCallback((newValue: string) => {
    setValue(newValue);
    setIsTouched(true);
  }, []);

  const input = React.useMemo<InputState>(() => {
    const isMultiValueInputSupported = field.isMultiSearchFilter;

    const parsedItems = isMultiValueInputSupported ? splitByMultivalueSeparators(value) : [value];

    const hasMultipleItems = parsedItems.length > 1;

    const isInputLengthEnough =
      hasMultipleItems ||
      field.disableMinResctriction ||
      field.disableMinRestriction ||
      parsedItems[0].length >= 3;

    return {
      originalValue: value,
      items: parsedItems,
      hasMultipleItems,
      isTouched,
      errors: { inputLength: !isInputLengthEnough },
      setIsTouched,
    };
    // Input calculated state should depend only on inner useState values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, isTouched]);

  return {
    input,
    onInputChange,
  };
};
