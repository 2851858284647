import React, { MutableRefObject } from 'react';

import { AuthService, UserRole } from '@src/shared/services/auth';

import type { LatestSTSubReports, STSubReportsTransformed } from '../../../types';

import { getLatestSTReportsRequest } from '../../../api/shipmentsTracking/getLatestReports';
import { currentActivityEnum } from '../../../enums/enums';
import { prepareForwarderReportsData } from '../../../pages/ForwarderReportsPage/utils';
import { useInterval } from '../../../pages/ShipmentsTrackingPage/hooks/useInterval';
import { prepareReports } from '../adapters/prepareReports';

const NumberLastReports = 30;
const StatusRefreshInterval = 20_000;

export const useGetReports = (
  dispatch: (action: object) => void,
  options: {
    docType: string;
  },
  isActive: boolean,
): void => {
  const requestRef = React.useRef(null) as MutableRefObject<ReturnType<
    typeof getLatestSTReportsRequest
  > | null>;

  useInterval(
    () => {
      if (requestRef.current?.then) {
        return;
      }

      requestRef.current = getLatestSTReportsRequest({
        take: NumberLastReports,
      });

      requestRef.current
        .then(({ data }: { data: LatestSTSubReports }) => {
          const nextReports: STSubReportsTransformed = AuthService.getIsUserRoleMatches([
            UserRole.Forwarder,
          ])
            ? prepareForwarderReportsData(data)
            : prepareReports(data, options.docType);

          dispatch({
            type: 'changeShipmentTrackingReports',
            payload: {
              nextReports,
            },
          });

          const processingReportsAmount = nextReports?.lastProcessing?.length ?? 0;

          const shouldRefetch = processingReportsAmount > 0;

          if (!shouldRefetch) {
            dispatch({
              type: 'changeCurrentActivity',
              payload: {
                nextActivity: currentActivityEnum.idle,
              },
            });
          }
        })
        .catch(() => {
          dispatch({
            type: 'changeCurrentActivity',
            payload: currentActivityEnum.requestError,
          });
        })
        .finally(() => {
          requestRef.current = null;
        });
    },
    isActive ? StatusRefreshInterval : null,
  );
};
