export enum SelectionState {
  all,
  none,
  partial,
}

export function formatInputWithMultipleValues(
  selectedItems: string[],
  showedItems: string[],
  isHugeLastShownItem?: boolean,
) {
  const hiddenItemsAmount = selectedItems.length - showedItems.length;
  const lastItemSeparator = isHugeLastShownItem ? '..., ' : ', ';
  const hiddenItemsLabel =
    hiddenItemsAmount > 0 ? `${lastItemSeparator}(+${hiddenItemsAmount})` : '';

  return `${showedItems.join(', ')}${hiddenItemsLabel}`;
}

export const getSelectionState = (selectedAmount: number, totalAmount: number): SelectionState => {
  if (selectedAmount === totalAmount) {
    return SelectionState.all;
  }

  if (selectedAmount === 0) {
    return SelectionState.none;
  }

  return SelectionState.partial;
};

export const ITEM_FOR_ALL_ITEMS = { id: '$All', text: 'All' };

export const APPROXIMATE_FITTING_LETTERS = 25;

export const APPROXIMATE_COUNTER_WIDTH_IN_PX = 30;
