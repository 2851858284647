import jwtDecode from 'jwt-decode';
import camelCase from 'lodash/camelCase';
import mapKeys from 'lodash/mapKeys';
import cookie from 'react-cookies';

import { getRedirectAuthUrl } from '@src/modules/auth/utilities';

import { CertBodyName } from './enums/certBodyName';
import { UserRole } from './enums/userRole';
import { UserIdToken, UserIdTokenRaw } from './types';

export const setIsExternalUser = (isExternalUser: boolean) =>
  cookie.save('isExternalUser', isExternalUser, {
    path: '/',
  });

export const setTokens = (data) =>
  cookie.save('auth', data, {
    path: '/',
  });

export const getIsExternalUser = () => {
  const value = cookie.load('isExternalUser');

  return Boolean(value && value !== 'false');
};

export const getRefreshToken = () => cookie.load('auth')?.refresh_token;

export const getUserIdTokenRaw = () => cookie.load('auth')?.id_token;

export const getUserIdToken = () => {
  const rawIdToken = getUserIdTokenRaw();

  if (rawIdToken) {
    const userIdToken = jwtDecode<UserIdTokenRaw>(rawIdToken);

    // Some token property names are in snake_case.
    // Convert them to camelCase as project standard:
    return mapKeys(userIdToken, (value, key) => camelCase(key)) as UserIdToken;
  }
};

export const getIsUserRoleMatches = (roles: UserRole[]) => {
  const userIdToken = getUserIdToken();

  if (!userIdToken) {
    return false;
  }

  return roles.includes(userIdToken.vedRole);
};

export const getIsUserWithCertBodyName = (name: CertBodyName) => {
  const userIdToken = getUserIdToken();

  if (!userIdToken) {
    return false;
  }

  return userIdToken.certBodyName === name;
};

// 'title' is modern and actual field for user profile display name, but old users' creds use 'fullName'
// This getter is used for backward compatibility
export const getUserDisplayName = () => {
  const userIdToken = getUserIdToken();

  return userIdToken?.title ?? userIdToken?.fullName ?? null;
};

export const logout = () => {
  const authCookie = cookie.load('auth');
  const isExternalUser = cookie.load('isExternalUser');
  let redirectLogoutUrl = '/sign';

  if (authCookie) {
    redirectLogoutUrl = isExternalUser
      ? '/sign'
      : getRedirectAuthUrl(authCookie.id_token).REDIRECT_LOGOUT_URL;

    cookie.remove('auth', {
      path: '/',
    });
    cookie.remove('isExternalUser', {
      path: '/',
    });

    sessionStorage.clear();
    localStorage.clear();
  }

  window.location.href = redirectLogoutUrl;
};

export const isRefreshTokenUrl = (url = '') => /^\/api\/auth\/token\/refresh/.test(url);
