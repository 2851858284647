export enum UserRole {
  AdeoSupplier = 'ADEOSupplier',
  ImportSpecialist = 'ImportSpecialist',
  CustomsDocs = 'CustomsDocs',
  CustomsGroupSpecialist = 'CustomsGroupSpecialist',
  TransportGroupSpecialist = 'TransportGroupSpecialist',
  CertificationSpecialist = 'CertificationSpecialist',
  CertBody = 'CertBody',
  ApprovisionSpecialist = 'ApprovisionSpecialist',
  AdeoTransport = 'ADEOTransport',
  Forwarder = 'Forwarder',
  CustomsBroker = 'CustomsBroker',
  SampleCoordinator = 'SampleCoordinator',
  AdeoVietnam = 'ADEOVietnam',
}
