import React from 'react';

import { RTCellContext } from '../../types';
import { Td } from '../Td';

type ValueTypes = string | string[] | undefined;

const getIsStringArray = (value: ValueTypes): value is string[] => {
  return Array.isArray(value) && typeof value?.[0] === 'string';
};

export const TextCell = (props: RTCellContext) => {
  const { getValue } = props;
  const value = getValue() ?? '';

  let text: string;

  const isStringArray = getIsStringArray(value);

  if (isStringArray) {
    text = value.join(' / ');
  } else {
    text = value ? String(value) : '. . .';
  }

  return (
    <Td rtContext={props} title={text}>
      {text}
    </Td>
  );
};
