import React, { ChangeEventHandler, useCallback, useState } from 'react';

import dayjs from 'dayjs';
import { Tooltip, Typography, useSnackbar } from 'fronton-react';
import { uniqueId } from 'lodash';
import { useMutation, useQuery } from 'react-query';

import { AddSnackParams } from '@src/app/components/AppSnackbar';
import { makeDefaultErrorSnack } from '@src/app/components/AppSnackbar/utils';
import {
  DATE_RANGE_DELIMITER,
  INPUT_DATE_FORMAT,
  prepareDefaultDateInput,
} from '@src/modules/documents/components/DatePickerRangeInput';
import { DocBlock, DocTitleText } from '@src/modules/documents/components/DocLayout';
import { defaultQueryOptions } from '@src/shared/services/query';

import {
  DownloadSection,
  StyledButton,
  StyledDatePickerRangeInput,
  StyledDocTitle,
  StyledPreloader,
} from './Styled';
import { downloadEditingReport, getEditingReportFilters } from './api';
import { FormState } from './types';
import { validateForm } from './utils';
import { MultiPickDropdown } from '../../../components/MultiPickDropdown/MultiPickDropdown';

export const IDCEditingReportWidget = () => {
  const { addSnack } = useSnackbar();
  const [form, setForm] = useState<FormState>({
    departments: [],
    startDate: dayjs(),
    endDate: dayjs(),
    minDate: dayjs(),
    dateInput: '',
  });

  const onDropdownSelect = useCallback((filterName, values) => {
    setForm((state) => ({ ...state, [filterName]: values }));
  }, []);

  const onDateInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    const inputValue = event.target.value ?? '';

    const [startDate, endDate] = inputValue.split(DATE_RANGE_DELIMITER);

    setForm((state) => ({
      ...state,
      dateInput: inputValue,
      startDate: dayjs.utc(startDate, INPUT_DATE_FORMAT, true).startOf('day'),
      endDate: dayjs.utc(endDate, INPUT_DATE_FORMAT, true).endOf('day'),
    }));
  }, []);

  const download = useMutation({
    mutationFn: () => downloadEditingReport(form),
    onError: (err) => {
      let status;

      try {
        status = JSON.parse(JSON.stringify(err)).status;
      } finally {
        if (status === 404) {
          addSnack({
            id: uniqueId(),
            variant: 'neutral',
            header: 'Empty editing report',
            paragraph: 'There is no data in the IDC for these filters',
            autoHideTimer: 4000,
            closeButton: true,
            horizontalPosition: 'center',
          } as AddSnackParams);
        } else {
          addSnack(makeDefaultErrorSnack());
        }
      }
    },
  });

  const filtersQuery = useQuery({
    queryKey: getEditingReportFilters.getQueryKey(),
    queryFn: getEditingReportFilters.queryFn,
    ...defaultQueryOptions,
    retry: true,
    onSuccess: (data) => {
      // form uses Moscow daytime. Converting UTC+0 to UTC+3 and working with this format
      const defaultStartDate = dayjs.utc(data.startDate).add(3, 'hours').startOf('day');
      const defaultEndDate = dayjs.utc().add(3, 'hours').endOf('day');

      setForm((state) => ({
        ...state,
        departments: data?.departments?.map?.(({ id }) => id) ?? [],
        dateInput: prepareDefaultDateInput(defaultStartDate, defaultEndDate),
        startDate: defaultStartDate,
        endDate: defaultEndDate,
        minDate: defaultStartDate,
      }));
    },
  });

  const departmentFilterItems = filtersQuery.data?.departments ?? [];

  const areFiltersLoading = !filtersQuery.isSuccess;

  const validation = validateForm(form);

  const tooltipTitle = validation.missingFieldsError ?? '';
  const isTooltipOpened = !validation.missingFieldsError || areFiltersLoading ? false : undefined;

  return (
    <DocBlock>
      <StyledDocTitle>
        <Typography variant="h2" as="h2">
          <DocTitleText>Editing report</DocTitleText>
        </Typography>
        <DownloadSection>
          {areFiltersLoading ? (
            <StyledPreloader />
          ) : (
            <>
              <MultiPickDropdown
                label="Department"
                placeholder="Choose department"
                name="departments"
                items={departmentFilterItems}
                selectedValues={form.departments}
                onSelect={onDropdownSelect}
              />
              <StyledDatePickerRangeInput
                onChange={onDateInputChange}
                value={form.dateInput}
                error={validation.wrongDatesError}
              />
            </>
          )}
          <Tooltip title={tooltipTitle} open={isTooltipOpened}>
            <StyledButton
              variant="accent"
              size="s"
              disabled={validation.hasErrors || areFiltersLoading}
              loading={download.isLoading}
              onClick={() => download.mutate()}
            >
              Download
            </StyledButton>
          </Tooltip>
        </DownloadSection>
      </StyledDocTitle>
    </DocBlock>
  );
};
