import React from 'react';

import XlsIcon from '@src/shared/components/DocDownload/assets/icon-file-xls.svg';

import { getDateAsFormattedText } from '../../../../../../../pages/ShipmentsTrackingPage/domain/trackingFile/trackingFileDto';
import { TShipmentReport } from '../../../../../../../types';

import styles from './styles.css';

export const ReportProcessing = ({ fileName, url, created }: TShipmentReport) => {
  return (
    <div className={styles.body}>
      <img src={XlsIcon} className={styles.icon} alt="xls-icon" />
      <div className={styles.divider} />
      <a className={styles.href} href={url} target="_blank" rel="noopener noreferrer">
        <div className={styles.fileName}>{fileName},</div>
      </a>
      &nbsp;
      <div className={styles.timeUpload}>uploaded {getDateAsFormattedText(created)}</div>
    </div>
  );
};
