import React from 'react';

import { useLocalStorage } from 'react-use';

import { UploadReports } from './components/UploadReports';
import { useTabs } from './hooks/useTabs';
import { useWidgetState } from './hooks/useWidgetState';
import { WidgetProps } from './types';
import { DocBlock, DocNoDataLabel, DocTitle } from '../../components/DocLayout';
import { FoldableTitle } from '../../components/FoldButton';

export const STSubReportWidget = (props: WidgetProps) => {
  const { title, docType, noDataText, isFoldable = false, canUploadReports = false } = props;

  const [isFolded, setIsFolded] = useLocalStorage<boolean>(
    `documentWidget_${docType}_isFolded`,
    true,
  );

  const isOpened = isFoldable ? !isFolded : true;

  const { state, dispatch, hasData } = useWidgetState(props);

  const tabsNode = useTabs(props, state);

  const content = isOpened && tabsNode;

  return (
    <DocBlock>
      <DocTitle>
        <FoldableTitle
          isActive={isFoldable && hasData}
          isOpened={isOpened}
          onToggle={() => setIsFolded(!isFolded)}
        >
          {title}
        </FoldableTitle>

        {canUploadReports && <UploadReports docType={docType} dispatch={dispatch} state={state} />}
      </DocTitle>

      {hasData ? content : <DocNoDataLabel>{noDataText}</DocNoDataLabel>}
    </DocBlock>
  );
};
