import { FilterItemInputType } from '@src/shared/types/domain';

export const getDisabledFoundOptions = (
  foundOptions: FilterItemInputType[],
  enabledOptions: FilterItemInputType[],
) => {
  const enabledIds = new Set(enabledOptions.map((item) => item.id));

  return foundOptions.filter((foundItem) => !enabledIds.has(foundItem.id));
};
