import { FileService } from '@src/shared/services/files';

import DocIcon from './assets/icon-file-docx.svg';
import VideoIcon from './assets/icon-file-mp4.svg';
import UnknownFormatIcon from './assets/icon-file-other-files.svg';
import PdfIcon from './assets/icon-file-pdf-2.svg';
import XlsIcon from './assets/icon-file-xls.svg';
import ZipIcon from './assets/icon-file-zip.svg';
import ImageIcon from './assets/icon-image-for-doctype.svg';

const displayIcon = {
  pdf: PdfIcon,
  xls: XlsIcon,
  xlsx: XlsIcon,
  doc: DocIcon,
  docx: DocIcon,
  zip: ZipIcon,
  rar: ZipIcon,
  png: ImageIcon,
  jpeg: ImageIcon,
  jpg: ImageIcon,
  bmp: ImageIcon,
  gif: ImageIcon,
  mp4: VideoIcon,
};

export const getIconSrcByFileName = (fileName: string) => {
  const extension = FileService.getFileExtension(fileName);

  // extension variable is going to be returned as fileName if method getFileExtension will not able to find extension
  if (extension !== fileName && displayIcon[extension]) {
    return displayIcon[extension];
  }

  return UnknownFormatIcon;
};
