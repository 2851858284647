import { TableRow } from '@src/shared/components/Table/_deprecated/Table';
import {
  TableEventType,
  RowsSelectionStatus,
  CommonTable,
  TableFieldName,
  TableFieldType,
} from '@src/shared/components/Table/_deprecated/Table/types';

import { BrowserStorage } from '../../../browserStorage';

const getIsAllSelectedOnPage = (currentPageRows: TableRow[]) => {
  return currentPageRows.every((row) => row.$isRowSelected === true);
};

const getIsNoneSelectedOnPage = (currentPageRows: TableRow[]) => {
  return currentPageRows.every((row) => row.$isRowSelected === false);
};

const getIsPartiallySelectedOnPage = (currentPageRows: TableRow[]) => {
  return (
    currentPageRows.some((row) => row.$isRowSelected === true) &&
    currentPageRows.some((row) => row.$isRowSelected === false)
  );
};

const getRowsSelectionStatus = (
  currentPageRows: TableRow[],
  savedSelectedRows: Map<string, string>,
) => {
  const currentPageRowIds = new Set(currentPageRows.map((row) => row.id));

  const isMultipageSelection = [...savedSelectedRows.keys()].some(
    (savedSelectedRowId) => !currentPageRowIds.has(savedSelectedRowId),
  );

  if (isMultipageSelection) {
    return RowsSelectionStatus.multipage;
  }

  const isPartiallySelectedOnPage = getIsPartiallySelectedOnPage(currentPageRows);

  if (isPartiallySelectedOnPage) {
    return RowsSelectionStatus.partial;
  }

  const isAllSelectedOnPage = getIsAllSelectedOnPage(currentPageRows);

  if (isAllSelectedOnPage) {
    return RowsSelectionStatus.all;
  }

  return RowsSelectionStatus.none;
};

const MULTI_ROW_SELECT_OPTIONS = [
  { id: TableEventType.selectAllOnPage, displayName: 'Select all on this page' },
  { id: TableEventType.clearAllOnPage, displayName: 'Clear all on this page' },
  { id: TableEventType.clearAll, displayName: 'Clear all' },
];

const getMultiRowSelectionOptions = (rows: TableRow[]) => {
  const isAllSelectedOnPage = getIsAllSelectedOnPage(rows);

  if (isAllSelectedOnPage) {
    return MULTI_ROW_SELECT_OPTIONS.filter(
      (option) => option.id !== TableEventType.selectAllOnPage,
    );
  }

  const isNoneSelectedOnPage = getIsNoneSelectedOnPage(rows);

  if (isNoneSelectedOnPage) {
    return MULTI_ROW_SELECT_OPTIONS.filter((option) => option.id !== TableEventType.clearAllOnPage);
  }

  return MULTI_ROW_SELECT_OPTIONS;
};

const getRowSelectFieldState = (rows: TableRow[], savedSelectedRows: Map<string, string>) => {
  const rowsSelectionStatus = getRowsSelectionStatus(rows, savedSelectedRows);

  return {
    selectionStatus: rowsSelectionStatus,
    selectionOptions:
      rowsSelectionStatus === RowsSelectionStatus.multipage
        ? getMultiRowSelectionOptions(rows)
        : undefined,
  };
};

export const addChangePriority = ({ fields, rows }: CommonTable): CommonTable => {
  const customizedFields = [...fields];

  const savedSelectedRows = BrowserStorage.getSavedSelectedRows();

  const customizedRows = rows.map((row) => {
    const wasSelected = savedSelectedRows.has(row.id);

    const patchedRow = { ...row, $isRowSelected: wasSelected };

    return patchedRow;
  });

  const hasRowSelectField = fields?.[0]?.id === TableFieldName.$isRowSelected;

  const rowSelectFieldState = getRowSelectFieldState(customizedRows, savedSelectedRows);

  if (!hasRowSelectField) {
    customizedFields.unshift({
      id: TableFieldName.$isRowSelected,
      name: TableFieldName.$isRowSelected,
      type: TableFieldType.checkbox,
      displayName: '',
      $state: rowSelectFieldState,
    });
  } else {
    const rowSelectField = fields[0];

    rowSelectField.$state = rowSelectFieldState;
  }

  return { rows: customizedRows, fields: customizedFields };
};
