import cn from 'classnames';

import { getFullDifferenceBetweenDates } from '@src/app/utilities/dates';

import styles from '../../../../styles.module.scss';
import { DataCellProps } from '../types';
import { renderString } from './renderString';

export const renderEstimatedTime = (props: DataCellProps<string>) => {
  const { value, className } = props;

  const deadline = getFullDifferenceBetweenDates(new Date(value), new Date());

  return renderString({
    ...props,
    value: deadline.withText,
    className: cn(className, { [styles.danger]: deadline.negative }),
  });
};
