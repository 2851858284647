import React, { useCallback, useMemo } from 'react';

import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { isEqual } from 'lodash';
import { useQuery } from 'react-query';
import { useSessionStorage } from 'react-use';

import { DocumentsApi } from '@src/modules/documents/api';
import { STSubReportType } from '@src/modules/documents/enums/STSubReportType';
import { PaginationController } from '@src/shared/components/PaginationController';
import { usePagination } from '@src/shared/components/Table';
import { CommonTable, TableRow } from '@src/shared/components/Table/_deprecated/Table';
import { useActiveDropdown } from '@src/shared/components/Table/_deprecated/Table/components/Header/useActiveDropdown';
import { TableMeta } from '@src/shared/components/Table/_deprecated/Table/types';
import { defaultQueryOptions } from '@src/shared/services/query';
import { mapSelectedFilters } from '@src/shared/utils/mappers/mapTableDataResponseDto';

import { TableNameByDocType } from '../../mappers';
import { createColumnDef, INIT_PAGE_INDEX, INIT_PAGE_SIZE } from './config';

export const useTable = (docType: STSubReportType) => {
  const pagination = usePagination(INIT_PAGE_INDEX, INIT_PAGE_SIZE);

  const [globalFilter, setGlobalFilter] = useSessionStorage<Record<string, unknown>>(
    `st${docType}ArchiveFilters`,
    {},
  );

  const setFilterWithPagination = useCallback(
    (newFilters) => {
      if (!isEqual(globalFilter, newFilters)) {
        pagination.reset();
      }

      setGlobalFilter(newFilters);
    },
    [globalFilter, pagination, setGlobalFilter],
  );

  const query = useQuery({
    queryKey: DocumentsApi.shipmentsTracking.getArchiveReports.getQueryKey({
      take: INIT_PAGE_SIZE,
      skip: pagination.state.pageIndex * INIT_PAGE_SIZE,
      docType,
      filters: mapSelectedFilters(globalFilter),
    }),
    queryFn: DocumentsApi.shipmentsTracking.getArchiveReports.queryFn,
    ...defaultQueryOptions,
  });

  const { data: tableData, isFetching } = query;

  const { activeDropdown, openDropdown } = useActiveDropdown();

  const paginatorNode = (
    <PaginationController
      isPending={isFetching}
      isNextDisabled={tableData?.hasNext ?? false}
      page={pagination.state.pageIndex}
      onChange={(nextPage) => pagination.setState((state) => ({ ...state, pageIndex: nextPage }))}
    />
  );

  const columns = useMemo<ColumnDef<TableRow>[]>(
    () => (tableData ? createColumnDef() : []),
    [tableData],
  );

  const table = useReactTable({
    data: (tableData?.files as CommonTable['rows']) ?? [],
    columns,
    pageCount: -1,
    state: {
      pagination: pagination.state,
      globalFilter,
    },
    meta: {
      tableName: TableNameByDocType[docType],
      tableData: tableData ? { rows: tableData.files, fields: columns } : undefined,
      paginatorNode,
      activeDropdown,
      openDropdown,
    } as TableMeta,
    manualPagination: true,
    enableFilters: true,
    manualFiltering: true,
    onGlobalFilterChange: setFilterWithPagination,
    onPaginationChange: pagination.setState,
    getCoreRowModel: getCoreRowModel(),
  });

  return {
    query,
    instance: table,
  };
};
