import React, { ReactNode } from 'react';

import cn from 'classnames';
import dayjs from 'dayjs';
import { Button, FileButton } from 'mp-ui-components';

import { getShortDate } from '@src/app/utilities/dates';
import { BasePageLayout } from '@src/shared/components/Layout';
import Preloader from '@src/shared/components/Preloader';
import { AuthService } from '@src/shared/services/auth';
import { FileService } from '@src/shared/services/files';

import { ReportBlock } from './components/ReportBlock';
import { ReportBlockWithAction } from './components/ReportBlock/ReportBlockWithAction';
import { requestKPI, requestCDF, uploadCDF } from './services';
import { getBrokersAndForwardersReportsTabs } from './services/getBrokersAndForwardersReportsTabs';
import { ErrorReloadableComponent } from '../../../../shared/components/Error';
import { parseAttachmentError } from '../../../../shared/utils/parse/parseAttachmentError';
import { getLatestSTReportsRequest } from '../../api/shipmentsTracking/getLatestReports';
import { STSubReportType } from '../../enums/STSubReportType';
import { STReportDownloadAction } from '../../widgets/STReportDownloadWidget';
import { prepareReports, STSubReportWidget } from '../../widgets/STSubReportWidget';
import { toEntity } from '../ShipmentsTrackingPage/domain/shipmentTracking/shipmentTracking';

import styles from './index.css';

function parseShortDate(date) {
  if (date) {
    const shortDate = getShortDate(date);

    return `${shortDate.day} ${shortDate.month} ${shortDate.year}, ${shortDate.shortTime}`;
  }

  return '';
}

// ToDo: refactor this page
export const CommonReportsPage = () => {
  const userRole = AuthService.getUserIdToken()?.vedRole;
  const [kpiData, setKpiData] = React.useState({
    isPending: false,
    isUploading: false,
    dateOfUpload: null,
    url: null,
  });
  const [samplesData, setSamplesData] = React.useState({
    isPending: true,
    isUploading: false,
    dateOfUpload: null,
    url: null,
  });
  const [cdfData, setCDFData] = React.useState({
    isPending: false,
    isUploading: false,
  });
  const [fullCdfData, setFullCDFData] = React.useState({
    isPending: false,
    isUploading: false,
  });

  const isCertBody = () => {
    return userRole === 'CertBody';
  };

  const isADEOSupplier = () => {
    return userRole === 'ADEOSupplier';
  };

  const isImportSpecialist = () => {
    return userRole === 'ImportSpecialist';
  };

  const isCustomsDocs = () => {
    return userRole === 'CustomsDocs';
  };

  const isCustomsGroupSpecialist = () => {
    return userRole === 'CustomsGroupSpecialist';
  };

  const isTransportGroupSpecialist = () => {
    return userRole === 'TransportGroupSpecialist';
  };

  const isCertificationSpecialist = () => {
    return userRole === 'CertificationSpecialist';
  };

  const isApprovisionSpecialist = () => {
    return userRole === 'ApprovisionSpecialist';
  };

  const isSampleCoordinator = () => {
    return userRole === 'SampleCoordinator';
  };

  const isADEOTransport = () => {
    return userRole === 'ADEOTransport';
  };

  const getKpiVisibility = () => {
    if (isCertBody()) {
      return false;
    }

    return true;
  };

  const getCDFVisibility = () => {
    if (
      isCertBody() ||
      isADEOSupplier() ||
      isImportSpecialist() ||
      isCustomsDocs() ||
      isCustomsGroupSpecialist() ||
      isTransportGroupSpecialist() ||
      isCertificationSpecialist() ||
      isApprovisionSpecialist() ||
      isSampleCoordinator() ||
      isADEOTransport()
    ) {
      return true;
    }

    return false;
  };

  const isKpiVisible = getKpiVisibility();
  const isCDFVisible = getCDFVisibility();
  const [failureTitleText, setFailureTitleText] = React.useState(null);
  const [failureText, setFailureText] = React.useState(null);
  const [cdfUploadFailedText, setCDFUploadFailedText] = React.useState<ReactNode>('');
  const [cdfUploadFailedTextHeader, setCDFUploadFailedTextHeader] = React.useState<ReactNode>('');
  const [stReports, setSTReports] = React.useState();
  const isUnmounted = React.useRef(false);

  React.useEffect(() => {
    setSamplesData((old) => {
      return { ...old, isPending: true };
    });
    Promise.all([
      getLatestSTReportsRequest({
        take: 3,
      }),
    ])
      .then((responses) => {
        if (!isUnmounted.current) {
          setSamplesData((old) => {
            return {
              ...old,
              isPending: false,
              url: responses[0].url,
              dateOfUpload: responses[0].dateOfUpload
                ? `, attached ${parseShortDate(responses[0].dateOfUpload)}`
                : '',
            };
          });
        }

        setSTReports(responses[0]?.data);
      })
      .catch((error) => {
        if (error.response && error.response.status) {
          if (error.response.status === 500 || error.response.status === 400) {
            setFailureText("Server doesn't respond");
          } else if (error.response.status === 404) {
            setFailureText("Request doesn't found");
          }
        }

        setFailureTitleText("The page can't be loaded");

        if (!isUnmounted.current) {
          setSamplesData((old) => {
            return { ...old, isPending: false };
          });
          console.error(error);
        }
      });

    return () => {
      isUnmounted.current = true;
    };
  }, []);

  function kpiUploadHandler() {
    if (kpiData.url) {
      window.open(kpiData.url, '_blank');
    } else {
      setKpiData((old) => {
        return { ...old, isPending: true };
      });
      requestKPI()
        .then((response) => {
          if (!isUnmounted.current) {
            window.open(response.url, '_blank');
            setKpiData((old) => {
              return { ...old, isPending: false, url: response.url };
            });
          }
        })
        .catch((error) => {
          if (error.response && error.response.status) {
            if (error.response.status === 500 || error.response.status === 400) {
              setFailureText("Server doesn't respond");
            } else if (error.response.status === 404) {
              setFailureText("Request doesn't found");
            }
          }

          setFailureTitleText("KPI can't be loaded");

          if (!isUnmounted.current) {
            setKpiData((old) => {
              return { ...old, isPending: false };
            });
            console.error(error);
          }
        });
    }
  }

  function renderDownloadButton({
    downloadUrl,
    isPending,
    onDownload,
    isButton = true,
  }: {
    downloadUrl?: string | null;
    isPending: boolean;
    onDownload?: () => void;
    isButton?: boolean;
  }) {
    const DownloadFromUrl = () => {
      if (!onDownload && downloadUrl) {
        return window.open(downloadUrl, '_blank');
      }

      if (onDownload && !downloadUrl) {
        return onDownload();
      }
    };

    if (isButton) {
      return (
        <Button onClick={onDownload || DownloadFromUrl} isPending={isPending} theme="secondary">
          Download
        </Button>
      );
    }

    if (downloadUrl && !onDownload) {
      return (
        <a
          href={downloadUrl}
          className={cn(styles.link, isPending && styles.linkDisabled)}
          rel="noopener noreferrer"
          target="_blank"
        >
          Download
        </a>
      );
    }

    return (
      <button
        type="button"
        onClick={onDownload}
        aria-label="download LI"
        className={cn(styles.linkButton, isPending && styles.linkDisabled)}
      >
        Download
      </button>
    );
  }

  const cdfFileUploadHandler = (
    files: FileList | null,
    setUploadingCallback: (state: boolean) => void,
  ) => {
    if (files) {
      setUploadingCallback(true);
      setCDFUploadFailedTextHeader('');
      uploadCDF(files)
        .then(() => {
          setUploadingCallback(false);
          if (window) {
            window.location.reload();
          }
        })
        .catch((error) => {
          if (error && error.message) {
            const parsedError = parseAttachmentError(error);

            setCDFUploadFailedTextHeader(parsedError.message);
            setCDFUploadFailedText(parsedError.details);
          } else {
            setCDFUploadFailedText('File was not uploaded, please try again');
          }

          setUploadingCallback(false);
        });
    }
  };

  function renderDownloadAndUploadButtons({
    downloadUrl,
    isUploading,
    isPending,
    fileUploadHandler,
    setUploadingCallback,
    onDownload,
  }: {
    downloadUrl?: string | null;
    isUploading: boolean;
    isPending?: boolean;
    fileUploadHandler: () => void;
    setUploadingCallback: (state: boolean) => void;
    onDownload?: () => void;
  }) {
    return (
      <>
        {renderDownloadButton({
          downloadUrl,
          isPending,
          onDownload,
          isButton: false,
        })}
        <FileButton
          isPending={isUploading}
          onChange={(e) => fileUploadHandler(e, setUploadingCallback)}
          theme="secondary"
          fileTypes={FileService.docTypes.samplesReport?.extensions ?? []}
        >
          Upload...
        </FileButton>
      </>
    );
  }

  const setUploadingHandler = (block: string, state: boolean) => {
    if (block === 'samples') {
      setSamplesData((old) => {
        return { ...old, isUploading: state };
      });
    } else if (block === 'cdf') {
      setCDFData((old) => {
        return { ...old, isUploading: state };
      });
    }
  };

  const downloadCDF = () => {
    setCDFData((prevState) => {
      return { ...prevState, isPending: true };
    });
    requestCDF(false).then(() => {
      setCDFData((prevState) => {
        return { ...prevState, isPending: false };
      });
    });
  };

  const downloadFullCDF = () => {
    setFullCDFData((prevState) => {
      return { ...prevState, isPending: true };
    });
    requestCDF(true).then(() => {
      setFullCDFData((prevState) => {
        return { ...prevState, isPending: false };
      });
    });
  };

  const displayFullCDFButtons = () => {
    return renderDownloadButton({
      downloadUrl: '',
      isPending: fullCdfData.isPending,
      onDownload: downloadFullCDF,
    });
  };

  const displayCDFButtonsWithDownload = () => {
    return renderDownloadAndUploadButtons({
      downloadUrl: cdfData.url,
      isPending: cdfData.isPending,
      isUploading: cdfData.isUploading,
      fileUploadHandler: cdfFileUploadHandler,
      setUploadingCallback: (e) => setUploadingHandler('cdf', e),
      onDownload: downloadCDF,
    });
  };

  if (samplesData.isPending) {
    return <Preloader />;
  }

  if (failureTitleText) {
    return (
      <div className={styles.container}>
        <ErrorReloadableComponent
          title={failureTitleText}
          text={failureText || "Server doesnt' respond"}
          theme="white"
        />
      </div>
    );
  }

  const brokersAndForwarderReportsTabs = getBrokersAndForwardersReportsTabs(stReports);

  return (
    <BasePageLayout title="Reports">
      {isKpiVisible && (
        <ReportBlock
          placeholder="There will be KPI report"
          title="KPI report"
          fileName="KPI report.xlsx"
          dateLabel=", latest version"
          displayPlaceholder={false}
          isPending={kpiData.isPending}
          isUploading={kpiData.isUploading}
        >
          {renderDownloadButton({
            downloadUrl: kpiData.url,
            isPending: kpiData.isPending,
            onDownload: kpiUploadHandler,
          })}
        </ReportBlock>
      )}

      {isCDFVisible && (
        <ReportBlock
          placeholder="There will be Logistic instruction"
          title="Logistic instruction"
          fileName="Logistic instruction.xlsx"
          displayPlaceholder={false}
          dateLabel=", latest version"
          isPending={fullCdfData.isPending}
        >
          {displayFullCDFButtons()}
        </ReportBlock>
      )}
      {isCDFVisible && isSampleCoordinator() && (
        <ReportBlock
          placeholder="There will be Logistic instruction update"
          title="Updating LI"
          fileName="Article list for Logistic instruction update.xlsx"
          displayPlaceholder={false}
          dateLabel=", latest version"
          isPending={cdfData.isPending}
          isUploading={cdfData.isUploading}
          uploadFailed={cdfUploadFailedText}
          uploadFailedHeader={cdfUploadFailedTextHeader}
        >
          {displayCDFButtonsWithDownload()}
        </ReportBlock>
      )}

      <ReportBlockWithAction
        title="Shipments tracking"
        fileName="Shipments tracking.xlsx"
        dateLabel={dayjs(stReports?.latestDateUpdateShipmentTracking).format(
          'D MMM. YYYY [at] HH:mm',
        )}
      >
        <STReportDownloadAction
          key={stReports?.latestDateOfPlanEtaPodInFu}
          colorScheme="white"
          downloadButtonTheme="secondary"
          {...stReports}
        />
      </ReportBlockWithAction>
      {brokersAndForwarderReportsTabs.length > 0 && (
        <>
          <STSubReportWidget
            title="Broker reports"
            noDataText="Here will be reports from Brokers"
            helperInfoText="This file should be updated daily"
            reports={prepareReports(toEntity(stReports), STSubReportType.BrokerReports)}
            docType={STSubReportType.BrokerReports}
            tabs={brokersAndForwarderReportsTabs}
            isFoldable
          />
          <STSubReportWidget
            title="Forwarder reports"
            noDataText="Here will be reports from Forwarders"
            helperInfoText="This file should be updated daily"
            reports={prepareReports(toEntity(stReports), STSubReportType.ForwarderReports)}
            docType={STSubReportType.ForwarderReports}
            tabs={brokersAndForwarderReportsTabs}
            isFoldable
          />
        </>
      )}
    </BasePageLayout>
  );
};
