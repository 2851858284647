import { useCallback, useMemo } from 'react';

import { useTableContext } from '@src/shared/components/Table/_deprecated/Table/context/TableContext';
import type { FilterItemInputType } from '@src/shared/types/domain';

import { FilterEvent, TableEventType, TableColumn } from '../../../../types';

export const useEnabledOptions = (field: TableColumn) => {
  const fieldName = field.name;

  const { allEnabledTableFilters = {}, onTableEvent } = useTableContext();

  const onFilter = useCallback(
    (filterParams) =>
      onTableEvent<FilterEvent>({ type: TableEventType.filter, payload: { filterParams } }),
    [onTableEvent],
  );

  const addItems = useCallback(
    (items: FilterItemInputType[]) => {
      const prevOptionsForField = allEnabledTableFilters[fieldName] ?? [];

      onFilter({
        ...allEnabledTableFilters,
        [fieldName]: [...prevOptionsForField, ...items],
      });
    },
    [allEnabledTableFilters, fieldName, onFilter],
  );

  const removeItems = useCallback(
    (items: FilterItemInputType[]) => {
      const removedIds = new Set(items.map((item) => item.id));

      const filteredOptions = allEnabledTableFilters[fieldName].filter(
        (filter) => !removedIds.has(filter.id),
      );

      onFilter({ ...allEnabledTableFilters, [fieldName]: [...filteredOptions] });
    },
    [allEnabledTableFilters, fieldName, onFilter],
  );

  const removeAllItems = useCallback(() => {
    onFilter({ ...allEnabledTableFilters, [fieldName]: [] });
  }, [fieldName, allEnabledTableFilters, onFilter]);

  const items = useMemo<FilterItemInputType[]>(
    () => allEnabledTableFilters[fieldName] ?? [],
    [allEnabledTableFilters, fieldName],
  );

  return {
    items,
    addItems,
    removeItems,
    removeAllItems,
  };
};
