import React, { useCallback } from 'react';

export const useFormConditions = () => {
  const [selectedConditions, setSelectedConditions] = React.useState<string[]>([]);

  const updateConditions = useCallback(
    (prevCondition, nextCondition) => {
      if (nextCondition === null) {
        return;
      }

      const update = selectedConditions.filter((condition) => condition !== prevCondition);

      update.push(nextCondition);
      setSelectedConditions(update);
    },
    [selectedConditions],
  );

  const updateCheckboxConditions = useCallback(
    (prevConditions, nextConditions) => {
      const nextSelectedConditions = selectedConditions.filter(
        (condition) => !prevConditions.includes(condition),
      );

      for (const condition of nextConditions) {
        nextSelectedConditions.push(condition);
      }

      setSelectedConditions(nextSelectedConditions);
    },
    [selectedConditions],
  );

  const clearConditions = useCallback(
    (toRemove: string[]) => {
      setSelectedConditions(selectedConditions.filter((item) => !toRemove.includes(item)));
    },
    [selectedConditions],
  );

  return {
    selectedConditions,
    setSelectedConditions,
    updateConditions,
    updateCheckboxConditions,
    clearConditions,
  };
};
