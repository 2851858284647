import { TableColumn } from '../_deprecated/Table';
import { TableColumnDef } from '../types';

type FactoryParams = {
  type: TableColumn['type'];
  header: TableColumnDef['header'];
  cell: TableColumnDef['cell'];
};

export const makeCreateColumnFn =
  <TColumnParams extends TableColumn>({ type, header, cell }: FactoryParams) =>
  (columnParams: Omit<TColumnParams, 'type'>): TableColumnDef => ({
    accessorKey: columnParams.name,
    meta: { type, ...columnParams },
    header,
    cell,
  });
