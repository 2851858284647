// @flow strict
import React from 'react';
import styles from './index.css';
import { mapDataFromDTOWithRoles, mapTableFieldsFromDTO, mapSelectedFilters } from '@src/shared/utils/mappers/mapTableDataResponseDto';
import List from './components/list-tasks';
import { requestTasks, requestMyTasks } from './services/tasks';
import type { ListRow, FieldContainer, FilterType } from '../../../../shared/types/domain';
import { ErrorReloadableComponent } from '../../../../shared/components/Error';
import Preloader from '@src/shared/components/Preloader';
import { PaginationController } from '@src/shared/components/PaginationController';
import { RoutingService } from '@src/shared/services/routing';

const PAGINATION_PAGE_SIZE = 50;

export function TasksListPage() {
    const getSavedFilters = () => {
        let savedFiltersFromLocalStorage = {};
        const savedFiltersString = localStorage.getItem('tasksFilters');
        if (savedFiltersString) {
            savedFiltersFromLocalStorage = JSON.parse(savedFiltersString);
            for (let filter in savedFiltersFromLocalStorage) {
                if (savedFiltersFromLocalStorage[filter].type === 'date') {
                    savedFiltersFromLocalStorage[filter].from = new Date(savedFiltersFromLocalStorage[filter].from);
                    savedFiltersFromLocalStorage[filter].to = new Date(savedFiltersFromLocalStorage[filter].to);
                }
            }
            if (Object.keys(savedFiltersFromLocalStorage).length > 0) return savedFiltersFromLocalStorage;
        }
        return {};
    };

    const isUnmounted = React.useRef(false);

    React.useEffect(() => {
        return () => {
            isUnmounted.current = true;
        };
    }, []);

    const [savedFilters, setSavedFilters] = React.useState(getSavedFilters());
    const [isPending, setPending] = React.useState({ myTasks: true, tasks: true });
    const [failedStatus, setFailedStatus] = React.useState(null);
    const [tableData, setTableData] = React.useState<ListRow[] | null>(null);
    const [myTasks, setMyTasks] = React.useState<ListRow[] | null>(null);
    const [fields, setFields] = React.useState<FieldContainer[] | null>(null);
    const [paginatorSettings, setPaginatorSettings] = React.useState({
        page: 0,
        pageSize: PAGINATION_PAGE_SIZE,
        isPending: false,
        next: false
    });

    function pageHandler(page: number) {
        if (page !== paginatorSettings.page) {
            setPaginatorSettings(old => {
                return { ...old, page, pending: true };
            });
        }
    }

    React.useEffect(() => {
        if (savedFilters) fetchTasks(savedFilters);
    }, [savedFilters, paginatorSettings.page]);

    const linkTemplateHandler = taskId => {
        return `${RoutingService.root.routes.tasks}/${taskId}`;
    };

    function fetchTasks(filters: FilterType) {
        const pagination = {
            // +1 to understand we have next page immediately
            limit: paginatorSettings.pageSize + 1,
            offset: paginatorSettings.page * paginatorSettings.pageSize
        };
        if (isUnmounted.current) return;
        setPaginatorSettings(old => {
            return { ...old, isPending: true };
        });
        requestTasks(mapSelectedFilters(filters), pagination)
            .then(response => {
                if (isUnmounted.current) return;
                setFields(mapTableFieldsFromDTO(response.fields));
                setPending(old => {
                    return { ...old, tasks: false };
                });
                const mappedResponseWithRoles = mapDataFromDTOWithRoles(response.data);
                if (mappedResponseWithRoles.tasks.length <= PAGINATION_PAGE_SIZE) {
                    setTableData(mappedResponseWithRoles.tasks);
                    setPaginatorSettings(old => {
                        if (window) window.scrollTo({ top: 0, behavior: 'smooth' });
                        return { ...old, isPending: false, next: false };
                    });
                } else {
                    setTableData(mappedResponseWithRoles.tasks.slice(0, -1));
                    setPaginatorSettings(old => {
                        if (window) window.scrollTo({ top: 0, behavior: 'smooth' });
                        return { ...old, isPending: false, next: true };
                    });
                }
            })
            .catch(err => {
                if (isUnmounted.current) return;
                setPending(old => {
                    return { ...old, tasks: false };
                });
                setFailedStatus(err.response ? err.response.status : 'network');
            });
        requestMyTasks(mapSelectedFilters(filters))
            .then(myTasksResponse => {
                if (isUnmounted.current) return;
                setPending(old => {
                    return { ...old, myTasks: false };
                });
                setMyTasks(mapDataFromDTOWithRoles(myTasksResponse.data).myTasks);
            })
            .catch(err => {
                if (isUnmounted.current) return;
                setPending(old => {
                    return { ...old, myTasks: false };
                });
                setFailedStatus(err.response ? err.response.status : 'network');
            });
    }

    function requestTasksWithFilter(filters) {
        if (isUnmounted.current) return;
        const notEmptyFilters = {};
        for (let filter in filters) {
            if (filters[filter]) {
                if (!Array.isArray(filters[filter])) {
                    if (filters[filter].type && filters[filter].from && filters[filter].to) {
                        notEmptyFilters[filter] = filters[filter];
                    }
                } else {
                    notEmptyFilters[filter] = filters[filter];
                }
            }
        }
        setPaginatorSettings(old => {
            return { ...old, page: 0 };
        });

        localStorage.setItem('tasksFilters', JSON.stringify(notEmptyFilters));
        setSavedFilters(notEmptyFilters);
    }

    if (isPending.myTasks || isPending.tasks) return <Preloader />;

    if (failedStatus)
        return (
            <ErrorReloadableComponent
                theme="white"
                width="s"
                margin={30}
                title="Task list can't be loaded"
                status={failedStatus}
            />
        );

    return (
        <div className={styles.container}>
            <List
                filterOptions={savedFilters}
                myTasks={myTasks}
                onFilter={requestTasksWithFilter}
                linkTemplate={linkTemplateHandler}
                data={tableData}
                fields={fields}
            />
            {tableData && tableData.length !== 0 && (
                <div className={styles.paginatorWrapper}>
                    <PaginationController
                        zeroPageLabel="Important"
                        onChange={pageHandler}
                        page={paginatorSettings.page}
                        isPending={paginatorSettings.isPending}
                        isNextDisabled={paginatorSettings.next}
                    />
                </div>
            )}
            {tableData && tableData.length === 0 && myTasks && myTasks.length === 0 && (
                <span style={{ color: '#333', opacity: 0.5 }}>No tasks</span>
            )}
        </div>
    );
}
