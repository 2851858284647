import React from 'react';

import { TaskSectionCaption } from '@lmru-bpms-ved/core';
import cn from 'classnames';

import { TaskComponentContainerType } from '@src/shared/types';

import { SectionHeader } from './SectionHeader';
import { ErrorComponent } from '../../../../../../../shared/components/Error';
import { FieldContainer } from '../Field/FieldContainer';
import { Wrap, RequiredFieldsHelper, RequiredFieldMark, Fields } from '../Styled';
import { useFormContext } from '../hooks/useFormContext';
import styles from '../index.css';

type Props = {
  params: TaskComponentContainerType;
};

export const Section = ({ params }: Props) => {
  const { getMinutesLeft, target } = useFormContext();

  const { fields, sectionIssue, caption } = params;
  const invisibleCaption = params.invisibleCaption || false;
  const minLeft = caption === 'Task' && getMinutesLeft ? getMinutesLeft() : null;

  const renderMinutesLeft = () => {
    if (!minLeft) {
      return <span />;
    }

    return (
      <span className={cn(minLeft.isNegative && styles.elapsedSLA, styles.taskSlaContainer)}>
        <span>
          {minLeft.isNegative && '–'}
          {minLeft.remaining}
        </span>
        <span className={cn(styles.titleRightMinutes, minLeft.isNegative && styles.elapsedSLA)}>
          {minLeft.type} left
        </span>
      </span>
    );
  };

  const showRequiredFieldsHelper =
    caption === TaskSectionCaption.Decision && fields.some((field) => field.isRequired);

  return (
    <Wrap disableTopOffset={invisibleCaption} target={target}>
      {!invisibleCaption && (
        <SectionHeader
          caption={caption}
          getMinutesLeft={renderMinutesLeft}
          minLeft={minLeft}
          displayDivider={!sectionIssue}
        />
      )}
      {sectionIssue && (
        <ErrorComponent theme="red" title={sectionIssue.title} text={sectionIssue.text} />
      )}
      {showRequiredFieldsHelper && (
        <RequiredFieldsHelper>
          <RequiredFieldMark>*</RequiredFieldMark> — <b>required fields</b>
        </RequiredFieldsHelper>
      )}
      <Fields>
        {fields &&
          fields.map((field) => {
            return (
              <FieldContainer
                key={`${field.id}_${field.label}`}
                field={field}
                container={caption}
                label={field.label}
              />
            );
          })}
      </Fields>
    </Wrap>
  );
};
