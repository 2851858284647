// @flow strict
import React from 'react';

export const XMark = ({ width, height }: { width?: string, height?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width || '36'}
      height={height || '36'}
      viewBox="0 0 36 36"
    >
      <defs>
        <path
          id="axmark"
          d="M17.808 2.112L11.04 8.928l6.768 6.768-2.112 2.112-6.768-6.768-6.816 6.768L0 15.696l6.816-6.768L0 2.112 2.112 0l6.816 6.816L15.696 0z"
        ></path>
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(9 9)">
        <mask id="bxmark" fill="#fff">
          <use xlinkHref="#axmark"></use>
        </mask>
        <g fill="#FFF" fillRule="nonzero" mask="url(#bxmark)">
          <path d="M-9-9h36v36H-9z"></path>
        </g>
      </g>
    </svg>
  );
};
export const LeftArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <defs>
        <path id="aLeftArrow" d="M21.44.44l2.12 2.12L12 14.122.44 2.561 2.56.439 12 9.88z"></path>
      </defs>
      <g fill="none" fillRule="evenodd" transform="rotate(90 9 15)">
        <mask id="bLeftArrow" fill="#fff">
          <use xlinkHref="#aLeftArrow"></use>
        </mask>
        <g fill="#FFF" fillRule="nonzero" mask="url(#bLeftArrow)">
          <path d="M0-6h24v24H0z"></path>
        </g>
      </g>
    </svg>
  );
};

export const RightArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="36"
      height="37"
      viewBox="0 0 36 37"
    >
      <defs>
        <path id="aRightArrow" d="M21.44.44l2.12 2.12L12 14.122.44 2.561 2.56.439 12 9.88z"></path>
      </defs>
      <g fill="none" fillRule="evenodd" transform="matrix(0 1 1 0 12 7)">
        <mask id="bRightArrow" fill="#fff">
          <use xlinkHref="#aRightArrow"></use>
        </mask>
        <g fill="#FFF" fillRule="nonzero" mask="url(#bRightArrow)">
          <path d="M0-6h24v24H0z"></path>
        </g>
      </g>
    </svg>
  );
};
