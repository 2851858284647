export enum TypographyType {
  pageTitle = 'pageTitle',
  frameTitle = 'frameTitle',
  paragraphS = 'paragraphS',
  paragraphM = 'paragraphM',
}

export enum TypographyTag {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
  p = 'p',
  span = 'span',
}
