import { SEARCH_RESULTS_LIMIT } from '@src/shared/components/Table/_deprecated/Table/components/Filter/TextFilter/api/constants';
import { TableColumn } from '@src/shared/components/Table/_deprecated/Table/types';
import { httpClient } from '@src/shared/services/httpClient';
import { RequestFilterFromInputDTO } from '@src/shared/types/domain';

type SubUrl = 'autocomplete' | 'filters';

export const makeRequestFilters =
  (baseUrl: string, subUrl: SubUrl = 'filters'): TableColumn['getFilters'] =>
  async ({ field, searchItems, tableName, signal }) => {
    const fieldName = field.name;
    const hasInput = searchItems[0] !== '';

    const filters = hasInput
      ? { [fieldName]: searchItems.slice(0, SEARCH_RESULTS_LIMIT) }
      : undefined;

    const { data } = await httpClient.request<RequestFilterFromInputDTO[]>({
      method: 'post',
      url: `${baseUrl}/${subUrl}`,
      data: {
        context: tableName,
        targetField: fieldName,
        filters,
        limit: SEARCH_RESULTS_LIMIT,
      },
      signal,
    });

    let missingItems: string[] = [];

    const isMultiSearchFilter = searchItems.length > 1;

    if (isMultiSearchFilter) {
      const foundValues = new Set(data.map((item) => item.text));

      missingItems = searchItems.filter((value) => !foundValues.has(value));
    }

    const formattedData = { items: data, missingItems };

    return formattedData;
  };
