import React from 'react';

import cn from 'classnames';

import {
  getMonth,
  getMinutesBetweenDates,
  getMinutesFromDeadlineInfo,
} from '@src/app/utilities/dates';

import type { ChronologyItem, ChronologySLAMapped } from '../../../types/domain';

import { ChronologyFiles } from './ChronologyFiles';
import { DropdownButton } from './DropdownButton';
import { IdcLinkingChanges } from './IdcLinkingChanges';
import { TickSVG } from './icons';
import { SetDisplayImageModal } from '../../ImageBox/types';
import TimeRelated from '../../TimeRelated';

import styles from './index.css';

type Props = {
  data: ChronologyItem[];
  setDisplayModal?: SetDisplayImageModal;
};

const MAX_DECISION_DESCRIPTION_LENGTH = 380;

export default function Chronology({ data, setDisplayModal }: Props) {
  const [filesOpened, setFilesOpened] = React.useState<string[]>([]);
  let previousYear: number | null = null;

  const displaySla = (index: number) => {
    return <div className={styles.slaContainer}>{index === 0 ? 'SLA' : ''}</div>;
  };

  const displayYear = (year: number) => {
    if (previousYear !== year) {
      previousYear = year;

      return <div className={cn(styles.yearLabel, styles.boldText)}>{year}</div>;
    }

    return <div className={cn(styles.yearLabel, styles.boldText)} />;
  };

  const countSLA = (sla: ChronologySLAMapped) => {
    const { estimated, finish } = sla;

    if (estimated === null) {
      return '...';
    }

    if (estimated && finish) {
      if (estimated < finish) {
        return 'expired';
      }

      if (estimated >= finish) {
        return 'ok';
      }
    }

    if (estimated <= new Date()) {
      return 'expired';
    }

    if (finish === null) {
      return 'work';
    }

    return '...';
  };

  const displaySLAWorthBar = (sla: ChronologySLAMapped, isLast: boolean) => {
    if (countSLA(sla) === 'expired' && sla.estimated && sla.start) {
      // @ts-ignore todo: discover what type is correct
      const secondsToResolve = (sla.estimated - sla.start) / 1000;
      // @ts-ignore
      const secondsElapsed = ((sla.finish || new Date()) - sla.estimated) / 1000;
      const secondsToBecomeHardWorth = secondsToResolve * 2;

      if (secondsElapsed > secondsToBecomeHardWorth) {
        return (
          <div
            className={cn(styles.slaWorth, styles.slaWorthHard, isLast && styles.slaWorthLast)}
          />
        );
      }

      return (
        <div
          className={cn(styles.slaWorth, styles.slaWorthMedium, isLast && styles.slaWorthLast)}
        />
      );
    }

    return <div className={cn(styles.slaWorth, isLast && styles.slaWorthLast)} />;
  };

  const displaySLAStatus = (sla: ChronologySLAMapped) => {
    const status = countSLA(sla);

    if (status === 'ok') {
      return (
        <span>
          <TickSVG />
        </span>
      );
    }

    if (status === 'expired' && sla.estimated) {
      const fromDate = sla.finish || new Date();
      const minutesLeft = getMinutesBetweenDates(fromDate, sla.estimated);

      return (
        <span className={styles.expiredMinutes}>
          {minutesLeft.minutesToLetter} {minutesLeft.letter}
        </span>
      );
    }

    if (status === 'work' && sla.estimated) {
      const minutesLeft = getMinutesBetweenDates(sla.estimated, new Date());

      if (minutesLeft.negative) {
        return (
          <span className={styles.expiredMinutes}>
            {minutesLeft.minutesToLetter} {minutesLeft.letter}
          </span>
        );
      }

      return (
        <span className={styles.remainingTime}>
          {minutesLeft.minutesToLetter} {minutesLeft.letter}
          <div>left</div>
        </span>
      );
    }

    return <span>...</span>;
  };

  const displaySLAStatusWithDeadlineInfo = (deadlineInfo, sla) => {
    const minutesLeft = getMinutesFromDeadlineInfo(deadlineInfo);

    if (minutesLeft.negative) {
      return (
        <span className={styles.expiredMinutes}>
          {minutesLeft.minutesToLetter} {minutesLeft.letter}
        </span>
      );
    }

    return (
      <span className={styles.remainingTime}>
        {minutesLeft.minutesToLetter} {minutesLeft.letter}
        <div>left</div>
      </span>
    );
  };

  const displayStartSLA = (sla: Date | null) => {
    if (sla === null) {
      return <div className={styles.stepStatusLabel}>...</div>;
    }

    return (
      <>
        <div>{`${sla.getDate()} ${getMonth(sla)}`}</div>
        <div className={styles.timeLabel}>
          {sla.toLocaleTimeString(navigator.language, {
            hour: '2-digit',
            minute: '2-digit',
          })}
        </div>
      </>
    );
  };

  const openFiles = (id) => {
    if (filesOpened.find((fileItemId) => fileItemId === id) === undefined) {
      setFilesOpened((old) => {
        return [...old, id];
      });
    } else {
      setFilesOpened((old) => {
        return old.filter((fileItemId) => fileItemId !== id);
      });
    }
  };

  const isFileOpened = (id) => {
    return filesOpened.find((fileItemId) => fileItemId === id) !== undefined;
  };

  return (
    <div className={styles.container}>
      <div className={styles.stepContainer}>
        <div className={styles.chronologyState} />
        {data.map((item, index) => {
          const isDropdownActive = !!filesOpened.find(
            (chronologyItemId) => chronologyItemId === item.id,
          );

          return (
            <div
              key={`step-${index}`}
              className={cn(styles.step, item.isFake && styles.taskInProgress)}
            >
              {displaySLAWorthBar(item.sla, index === data.length - 1)}
              <div className={styles.stepHeader}>
                {displaySla(index)}
                {item.sla.start && displayYear(item.sla.start.getFullYear())}
              </div>
              <div className={styles.stepContent}>
                <div className={cn(styles.column, styles.columnSLA)}>
                  <div className={styles.stepStatusLabel}>
                    <TimeRelated
                      relateTo={() => {
                        return item.deadlineInfo?.isDelayed
                          ? displaySLAStatusWithDeadlineInfo(item.deadlineInfo, item.sla)
                          : displaySLAStatus(item.sla);
                      }}
                    />
                  </div>
                </div>

                <div className={cn(styles.column, styles.stepDate)}>
                  {displayStartSLA(item.sla.start)}
                </div>
                <div className={styles.taskInfo}>
                  <div className={cn(styles.taskInfoTitle, !item.sla.start && styles.boldText)}>
                    {item.title}
                  </div>
                  {item.information.map((info, infoIndex) => (
                    <div key={`info-${infoIndex}`} className={styles.info}>
                      <div>{info.actor}</div>
                      <div>{info.role && info.role}</div>
                    </div>
                  ))}
                </div>
                <div className={cn(styles.column, styles.columnDecision)}>
                  <div className={cn(styles.boldText, styles.decisionTitle)}>{item.decision}</div>
                  {item.message && item.message.length <= MAX_DECISION_DESCRIPTION_LENGTH && (
                    <div className={styles.decisionMessage}>{item.message}</div>
                  )}
                  {isDropdownActive && item.files && item.sla.start && (
                    <ChronologyFiles setDisplayModal={setDisplayModal} files={item.files || []} />
                  )}
                  {isDropdownActive && item.idcRelinking && item.idcRelinking.length > 0 && (
                    <IdcLinkingChanges items={item.idcRelinking} />
                  )}
                </div>
                <div className={cn(styles.column, styles.columnFiles)}>
                  <DropdownButton
                    item={item}
                    checkIsOpened={isFileOpened}
                    toggleDropdown={openFiles}
                  />
                </div>
              </div>
              {item.message && item.message.length > MAX_DECISION_DESCRIPTION_LENGTH && (
                <div className={styles.longDecisionMessage}>{item.message}</div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
