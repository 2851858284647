import { KeyboardEventHandler } from 'react';

export enum KeyboardKeys {
  Enter = 'Enter',
}

const KeyboardKeysTokens: Record<KeyboardKeys, { key: string; code: string }> = {
  [KeyboardKeys.Enter]: { key: KeyboardKeys.Enter, code: '13' },
};

/**
 * React hook to sipmlify subscription to specific keys of keyboard events
 * @param observedKeys - Array of KeyboardKeys enum items for which press we want to subscribe to
 * @param handler - Keyboard event subscription callback
 * @returns @param React.KeyboardEventHandler - pass it directly to observed component
 */
export const useKeypressHandler =
  (observedKeys: KeyboardKeys[], handler: KeyboardEventHandler): KeyboardEventHandler =>
  (...args) => {
    const { key: pressedKey, code: pressedKeyCode } = args[0];

    const isObservedKey = observedKeys.some((observedKey) => {
      const { key, code } = KeyboardKeysTokens[observedKey];

      return pressedKey === key || pressedKeyCode === code;
    });

    if (isObservedKey) {
      handler(...args);
    }
  };
