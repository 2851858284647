import { getLatestSTReportsRequest } from '../../../api/shipmentsTracking/getLatestReports';
import { currentActivityEnum } from '../../../enums/enums';
import { toEntity } from '../domain/shipmentTracking/shipmentTracking';

const NumberLastReports = 30;

export const useGetGrouped = (dispatch, isActive) => {
  if (isActive) {
    getLatestSTReportsRequest({
      take: NumberLastReports,
    })
      .then(({ data }) => {
        dispatch({
          type: 'changeShipmentTracking',
          payload: toEntity(data),
        });
      })
      .catch(() => {
        dispatch({
          type: 'changeCurrentActivity',
          payload: {
            nextActivity: currentActivityEnum.requestError,
          },
        });
      });
  }
};
