/* eslint-disable no-underscore-dangle */
import React, { useCallback, useMemo } from 'react';

import { CurrencyCodes, OrderMode } from '@lmru-bpms-ved/core';
import * as Sentry from '@sentry/react';
import { useNavigate, useParams } from 'react-router-dom';

import { requestOrderHeader } from '@src/modules/orders/api/methods/orders';
import Preloader from '@src/shared/components/Preloader';
import { AuthService } from '@src/shared/services/auth';
import { RoutingService } from '@src/shared/services/routing';
import { ChronologyResponse } from '@src/shared/types';

import { InfoBar } from './components/InfoBar';
import { TaskFormTemplate } from './components/TaskFormTemplate/TaskFormTemplate';
import { useFormConditions } from './components/TaskFormTemplate/hooks/useFormConditions';
import { FormContextValue, FormTarget } from './components/TaskFormTemplate/hooks/useFormContext';
import { useOnUpdateField } from './components/TaskFormTemplate/hooks/useFormFields';
import { useFormSubmit } from './components/TaskFormTemplate/hooks/useFormSubmit';
import { useFormValidation } from './components/TaskFormTemplate/hooks/useFormValidation';
import { mapButtons, mapSections, mapPerformer } from './mapper';
import { reducer, initialState as initialReducerState } from './reducer';
import { TaskState } from './types';
import { getSelectedConditions, getMinutesLeft } from './utils';
import ChronologyContainer from '../../../../shared/components/ChronologyContainer';
import { ErrorReloadableComponent } from '../../../../shared/components/Error';
import ImagePreview from '../../../../shared/components/ImagePreview';
import {
  requestShipmentChronology,
  requestShipmentHeader,
} from '../../../shipments/pages/ShipmentsListPage/services/shipments';
import {
  requestTask,
  takeTask,
  requestOrderChronology,
  resolveTask,
} from '../TasksListPage/services/tasks';

import styles from './index.css';
import { ImageModalParams } from '@src/shared/components/ImageBox/types';

export const initialState: TaskState = {
  templateId: '',
  fields: [],
  order: {
    ocData: {
      eta: '',
      etd: '',
      orderConfirmationNumber: '',
      orderConfirmationDate: '',
    },
    rmsData: {
      eta: '',
      etd: '',
      orderConfirmationNumber: '',
    },
    number: '',
    creationDate: '',
    _id: '',
    mode: '' as OrderMode,
    currency: '' as CurrencyCodes,
  },
  shipmentHeader: null,
  terminalButtons: [],
  warehouse: '',
  supplier: '',
  department: '',
  task: {
    type: '',
  },
  _id: '',
  orderId: '',
  chronology: [],
  expires: '',
  performer: '',
};

// TODO: написать тесты и порефачить логику
export const TaskPage = () => {
  const [reducerState, dispatch] = React.useReducer(reducer, initialReducerState);
  const [state, setState] = React.useState(initialState);
  const [isPending, setPending] = React.useState(true);
  const [displayModal, setDisplayModal] = React.useState<ImageModalParams>(null);
  const [isShipment, setShipment] = React.useState(false);
  const [shipmentId, setShipmentId] = React.useState<string | null>(null);
  const [failedStatus, setFailedStatus] = React.useState({
    chronology: null,
    task: null,
    header: null,
  });
  const navigate = useNavigate();
  const isUnmounted = React.useRef(false);

  const {
    selectedConditions,
    setSelectedConditions,
    clearConditions,
    updateConditions,
    updateCheckboxConditions,
  } = useFormConditions();

  React.useEffect(() => {
    return () => {
      isUnmounted.current = true;
    };
  }, []);
  const locationParams = useParams();
  const taskIdFromUrl = locationParams?.id;

  const { isFormValid } = useFormValidation(state, selectedConditions);

  const requestTaskData = React.useCallback(
    (taskId: string) => {
      setPending(true);
      setSelectedConditions([]);
      setState(initialState);
      setDisplayModal(null);
      dispatch({
        type: 'resetTerminalButtonsPending',
      });
      requestTask(taskId)
        .then((response) => {
          if (!response) {
            return;
          }

          if (response.state === 'complete') {
            return navigate('/');
          }

          if (response.role !== AuthService.getUserIdToken()?.vedRole) {
            return navigate('..');
          }

          if (!isUnmounted.current) {
            setPending(true);
            const performer = mapPerformer(response.performer);

            if (response.shipmentId) {
              const shipmentIdResponse = response.shipmentId;

              setShipment(true);
              setShipmentId(shipmentIdResponse);
              requestShipmentChronology(shipmentIdResponse)
                .then((responseChronology) => {
                  const fakeChronologyItem = {
                    isFake: true,
                    deadlineInfo: response.expires,
                    id: 'inprogress',
                    title: response.name,
                    information: [],
                    decision: 'Task in progress...',
                    message: '',
                    sla: {
                      start: response.creationDate || '',
                      finish: '',
                      estimated: (response.expires as any)?.deadline || response.expires || '',
                    },
                    files: [],
                  };

                  requestShipmentHeader(shipmentIdResponse)
                    .then((shipmentHeaderResponse) => {
                      setSelectedConditions(getSelectedConditions(response.sections));
                      setState((old) => ({
                        ...old,
                        templateId: '',
                        _id: response._id,
                        fields: mapSections(response.sections, performer),
                        task: {
                          type: response.type,
                        },
                        terminalButtons: mapButtons(response.terminalButtons, performer),
                        orderId: response.orderId,
                        expires: response.expires,
                        performer,
                        chronology: [...responseChronology, fakeChronologyItem as any],
                        shipmentHeader: shipmentHeaderResponse,
                      }));
                      setPending(false);
                    })
                    .catch((error) => {
                      setPending(false);
                      setFailedStatus((old) => {
                        return {
                          ...old,
                          header: error.response ? error.response.status : 'network',
                        };
                      });
                    });
                })
                .catch((error) => {
                  setPending(false);
                  setFailedStatus((old) => {
                    return {
                      ...old,
                      chronology: error.response ? error.response.status : 'network',
                    };
                  });
                });
            } else if (!response.shipmentId) {
              requestOrderChronology(response.orderId)
                .then((responseChronology) => {
                  const fakeChronologyItem = {
                    isFake: true,
                    deadlineInfo: response.expires,
                    id: 'inprogress',
                    title: response.name,
                    information: [],
                    decision: 'Task in progress...',
                    message: '',
                    sla: {
                      start: response.creationDate || '',
                      finish: '',
                      estimated: (response.expires as any)?.deadline || response.expires || '',
                    },
                    files: [],
                  };

                  requestOrderHeader(response.orderId)
                    .then((orderHeaderResponse) => {
                      if (!orderHeaderResponse) {
                        return;
                      }

                      setSelectedConditions(getSelectedConditions(response.sections));
                      setState((old) => {
                        const order = {
                          number: orderHeaderResponse.number,
                          creationDate: orderHeaderResponse.creationDate,
                          ocData: orderHeaderResponse.ocData || {},
                          rmsData: orderHeaderResponse.rmsData,
                          _id: orderHeaderResponse._id,
                          mode: orderHeaderResponse.mode,
                          currency: orderHeaderResponse.supplier?.currency,
                        };
                        const newState = {
                          ...old,
                          _id: response._id,
                          templateId: (response as any).templateId || '',
                          fields: mapSections(response.sections, performer),
                          task: {
                            type: response.type,
                          },
                          terminalButtons: mapButtons(response.terminalButtons, performer),
                          orderId: response.orderId,
                          expires: response.expires,
                          performer,
                          chronology: [...responseChronology, fakeChronologyItem as any],
                          warehouse:
                            orderHeaderResponse.warehouse && orderHeaderResponse.warehouse.name
                              ? orderHeaderResponse.warehouse.name
                              : 'unknown',
                          supplier:
                            orderHeaderResponse.supplier && orderHeaderResponse.supplier.name
                              ? orderHeaderResponse.supplier.name
                              : 'unknown',
                          department: orderHeaderResponse.department?.shortName ?? 'unknown',
                          order,
                        };

                        return newState;
                      });
                      setPending(false);
                    })
                    .catch((error) => {
                      setPending(false);
                      setFailedStatus((old) => {
                        return {
                          ...old,
                          header: error.response ? error.response.status : 'network',
                        };
                      });
                    });
                })
                .catch((error) => {
                  setFailedStatus((old) => {
                    return {
                      ...old,
                      chronology: error.response ? error.response.status : 'network',
                    };
                  });
                  setPending(false);
                });
            }
          }
        })
        .catch((error) => {
          setPending(false);
          setFailedStatus((old) => {
            return { ...old, task: error.response ? error.response.status : 'network' };
          });
        });
    },
    [navigate],
  );

  // Начальный запрос за данными по таске
  React.useEffect(() => {
    if (taskIdFromUrl) {
      requestTaskData(taskIdFromUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskIdFromUrl]);

  const onUpdateField = useOnUpdateField(setState, updateConditions, updateCheckboxConditions);

  const startProcessingHandler = useCallback(() => {
    dispatch({
      type: 'setProcessing',
      value: true,
    });
    takeTask(state._id ?? '')
      .then(() => {
        requestTaskData(state._id ?? '');
      })
      .catch(() => {
        dispatch({
          type: 'setProcessing',
          value: false,
        });
        requestTaskData(state._id ?? '');
      });
  }, [requestTaskData, state._id]);

  const takeFromPerformer = useCallback(() => {
    dispatch({
      type: 'setTakingFromPerformer',
      value: true,
    });
    takeTask(state._id ?? '')
      .then(() => {
        requestTaskData(state._id ?? '');
      })
      .catch(() => {
        dispatch({
          type: 'setTakingFromPerformer',
          value: false,
        });
        requestTaskData(state._id ?? '');
      });
  }, [requestTaskData, state._id]);

  const onSubmit = useFormSubmit(state, selectedConditions);

  const proceedHandler = useCallback(() => {
    const { resolution, isValid } = onSubmit();

    const resolveBody = {
      resolution,
    };

    if (isValid) {
      dispatch({
        type: 'setProceeding',
        value: true,
      });
      resolveTask(state._id ?? '', resolveBody)
        .then((response) => {
          if (response === true) {
            navigate(RoutingService.root.routes.tasks);
          }
        })
        .catch(() => {
          // ToDo: Display error in future
          dispatch({
            type: 'resetTerminalButtonsPending',
          });
        });
    }
  }, [navigate, state._id, onSubmit]);

  const terminalButtonHandler = useCallback(
    (buttonId: string) => {
      if (buttonId === 'proceedButton' && reducerState.isProceedingTask === false) {
        proceedHandler();
      }

      if (buttonId === 'startProcessing' && reducerState.isProcessingTask === false) {
        startProcessingHandler();
      }

      if (buttonId === 'takeFromPerformer' && reducerState.isTakingFromPerformer === false) {
        takeFromPerformer();
      }

      if (!buttonId) {
        Sentry.captureException({
          info: 'TaskPage - Task button id is unknown',
        });
      }
    },
    [
      proceedHandler,
      reducerState.isProceedingTask,
      reducerState.isProcessingTask,
      reducerState.isTakingFromPerformer,
      startProcessingHandler,
      takeFromPerformer,
    ],
  );

  const isButtonDisabled = useCallback(
    (buttonId: string) => {
      if (buttonId && buttonId === 'proceedButton' && !isFormValid) {
        return true;
      }

      return false;
    },
    [isFormValid],
  );

  const getButtonPendingState = useCallback(
    (buttonId: string) => {
      if (buttonId) {
        if (buttonId === 'proceedButton') {
          return reducerState.isProceedingTask;
        }

        if (buttonId === 'takeFromPerformer') {
          return reducerState.isTakingFromPerformer;
        }

        if (buttonId === 'startProcessing') {
          return reducerState.isProcessingTask;
        }
      }

      return false;
    },
    [
      reducerState.isProceedingTask,
      reducerState.isProcessingTask,
      reducerState.isTakingFromPerformer,
    ],
  );

  const formContext = useMemo<FormContextValue>(
    () => ({
      state,
      target: FormTarget.inline,
      isShipment,
      selectedConditions,
      shipmentId: shipmentId ?? undefined,
      setDisplayModal,
      onUpdateField,
      clearConditions,
      isButtonDisabled,
      getButtonPendingState,
      terminalButtonHandler,
      getMinutesLeft: () => getMinutesLeft(state.expires),
    }),
    [
      state,
      isShipment,
      selectedConditions,
      shipmentId,
      setDisplayModal,
      onUpdateField,
      clearConditions,
      isButtonDisabled,
      getButtonPendingState,
      terminalButtonHandler,
    ],
  );

  if (isPending) {
    return <Preloader />;
  }

  if (failedStatus.header || failedStatus.chronology || failedStatus.task) {
    return (
      <ErrorReloadableComponent
        theme="white"
        width="s"
        margin={30}
        title="The task can't be loaded"
        status={failedStatus.task || failedStatus.chronology || failedStatus.header}
      />
    );
  }

  if (!state.task) {
    return null;
  }

  return (
    <>
      <InfoBar isShipment={isShipment} state={state} />
      <section className={styles.container}>
        <ChronologyContainer
          setDisplayModal={setDisplayModal}
          data={state.chronology as ChronologyResponse[]}
        />
        <section className={styles.rightContainer}>
          <TaskFormTemplate formContext={formContext} />
        </section>
      </section>
      {displayModal && (
        <ImagePreview
          files={displayModal.files}
          index={displayModal.index}
          onClose={() => {
            setDisplayModal(null);
          }}
        />
      )}
    </>
  );
};
