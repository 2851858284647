import React from 'react';

import XlsIcon from '@src/shared/components/DocDownload/assets/icon-file-xls.svg';

import styles from './ReportBlock.scss';

type ReportBlockProps = {
  title: string;
  fileName: string;
  displayPlaceholder?: boolean;
  dateLabel?: string;
  isUploading: boolean;
  placeholder: string;
  children?: React.ReactNode;
  uploadFailed?: React.ReactNode;
  uploadFailedHeader?: string;
};

export const ReportBlock = ({
  title,
  fileName,
  displayPlaceholder,
  dateLabel,
  placeholder,
  isUploading,
  children,
  uploadFailed,
  uploadFailedHeader,
}: ReportBlockProps) => {
  const renderFile = () => {
    return (
      <div className={styles.file}>
        <img src={XlsIcon} alt="xls-icon" />
        <span className={styles.fileLabel}>{fileName}</span>
      </div>
    );
  };

  return (
    <div className={styles.reportBlock}>
      <div className={styles.reportBlockTitle}>
        {title}
        <div className={styles.divider} />
      </div>
      <div className={styles.blockContainer}>
        <div className={styles.blockInfo}>
          <div className={styles.blockFileDescription}>
            {!displayPlaceholder && renderFile()}
            {displayPlaceholder && <div className={styles.placeholder}>{placeholder}</div>}
            {dateLabel && !isUploading && <div className={styles.dateLabel}>{dateLabel}</div>}
          </div>
          <div className={styles.blockContainerControls}>{children}</div>
        </div>
        {(uploadFailed || uploadFailedHeader) && dateLabel && !isUploading && (
          <div className={styles.blockFileUploadFailed}>
            {uploadFailedHeader && (
              <div className={styles.blockFileUploadFailedHeader}>{uploadFailedHeader}</div>
            )}
            {uploadFailed && <div>{uploadFailed}</div>}
          </div>
        )}
      </div>
    </div>
  );
};
