import { LatestSTSubReports, STSubReportsTransformed } from '../../types';
import { toEntity } from '../ShipmentsTrackingPage/domain/shipmentTracking/shipmentTracking';

export const prepareForwarderReportsData = (
  reports: LatestSTSubReports,
): STSubReportsTransformed => ({
  ...toEntity(reports),
  lastProcessing: [],
  lastRejected: [],
  lastSuccess: [],
  ...(reports && {
    lastN: {
      truck: {
        ...reports.truck[0],
        downloadFiles: reports.truck,
        lastSuccessfulUpdated: reports.lastSuccessTruck?.created,
      },
      container: {
        ...reports.container[0],
        downloadFiles: reports.container,
        lastSuccessfulUpdated: reports.lastSuccessContainer?.created,
      },
    },
  }),
});
