import React from 'react';
import { styled } from 'linaria/lib/react';
import { CheckIcon } from '@fronton/icons-react';

export const Menu = styled.div`
  position: absolute;
  margin-left: -24px;
  padding: var(--space-100) 0;
  border-radius: var(--rounding-m);
  background: var(--background-primary);
  box-shadow: var(--box-shadow-m);
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  padding: var(--space-25) var(--space-200) var(--space-25) 0;

  &:hover {
    background: var(--background-secondary);
  }
`;

const SelectedIcon = styled(CheckIcon)`
  margin: 0px var(--space-50);
`;

type MenuItemProps = {
  value: string;
  isSelected: boolean;
  onSelect: (value: string) => void;
};

export const MenuItem = ({ value, isSelected, onSelect }: MenuItemProps) => (
  <Item onClick={() => onSelect(value)}>
    <SelectedIcon visibility={isSelected ? 'visible' : 'hidden'} size="space-200" />
    {value}
  </Item>
);
