import dayjs from 'dayjs';

import { INPUT_DATE_FORMAT } from '@src/modules/documents/components/DatePickerRangeInput';

import { FormState } from './types';

const validateMissingFields = (form: FormState, areDatesValid: boolean) => {
  const departmentsFilled = form.departments.length > 0;

  if (departmentsFilled && areDatesValid) {
    return;
  }

  if (areDatesValid) {
    return `Choose departments`;
  }

  if (departmentsFilled) {
    return `Choose dates`;
  }

  return `Choose departments and dates`;
};

const validateDates = (form: FormState) => {
  const currStartOfDayMoscow = dayjs.utc().add(3, 'hours').startOf('day'); // yes, we're using Moscow time

  if (
    !form.startDate.isValid() ||
    !form.endDate.isValid() ||
    form.endDate.startOf('day').diff(form.startDate.startOf('day')) < 0 || // End date should be bigger than or equal to start date
    form.endDate.startOf('day').diff(currStartOfDayMoscow) > 0 || // End date should be today or less
    form.startDate.startOf('day').diff(form.minDate.startOf('day')) < 0 // Start date should be bigger than or equal to min date
  ) {
    return `Min. ${form.minDate.format(INPUT_DATE_FORMAT)}, max. ${currStartOfDayMoscow.format(
      INPUT_DATE_FORMAT,
    )}`;
  }
};

export const validateForm = (form: FormState) => {
  const wrongDatesError = validateDates(form);
  const missingFieldsError = validateMissingFields(form, !wrongDatesError);

  return {
    missingFieldsError,
    wrongDatesError,
    hasErrors: Boolean(missingFieldsError || wrongDatesError),
  };
};
