import { TableColumn, TableColumnFilterType } from '../../../../types';
import { InputState } from './types';

const REGEXP_MULTIVALUE = /[^\n\r ,;_]+/g;

export const splitByMultivalueSeparators = (value: string) => [
  ...new Set(value.match(REGEXP_MULTIVALUE) ?? ['']).values(),
]; // Set removes duplicates

export const getIsAutoFetchMode = (input: InputState) =>
  !input.hasMultipleItems && !input.errors.inputLength;

export const getIsDictionarySearch = (field: TableColumn) =>
  field.filterType === TableColumnFilterType.dictionary;
