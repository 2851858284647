import React, { PropsWithChildren } from 'react';

import * as Sentry from '@sentry/react';
import { ThemeProvider, CssBaseline } from 'fronton-react';
import { ErrorTitle } from 'mp-ui-components';
import { QueryClientProvider } from 'react-query';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { AuthService } from '@src/shared/services/auth';
import { queryClient } from '@src/shared/services/query';

import { App } from './components/App';
import { TechnicalErrorPage } from './components/AppContent/basePages/TechnicalErrorPage';
import { APP_ENV } from './env';

const CssBaselineTypecasted = CssBaseline as React.FC<PropsWithChildren>; // TODO: some problem with TS without typecasting. Probably inside fronton-react lib

const userToken = AuthService.getUserIdToken();

if (APP_ENV.sentryDsn) {
  Sentry.init({
    dsn: APP_ENV.sentryDsn,
    release: APP_ENV.version,
    environment: APP_ENV.buildNamespace,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1,
    sendClientReports: false,
    initialScope: {
      user: {
        id: userToken?.sub,
        username: userToken?.fullName,
        email: userToken?.email,
      },
    },
  });
}

const router = createBrowserRouter([
  {
    path: '*',
    element: <App />,
    errorElement: <TechnicalErrorPage />,
  },
]);

export const AppRoot: React.FC = () => {
  return (
    <Sentry.ErrorBoundary fallback={<ErrorTitle />}>
      <QueryClientProvider client={queryClient}>
        <CssBaselineTypecasted>
          <ThemeProvider>
            <RouterProvider router={router} />
          </ThemeProvider>
        </CssBaselineTypecasted>
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  );
};
