// @flow strict
import React from 'react';

export default function TimeRelated({ relateTo }: { relateTo: () => React$Element<'span'> }) {
    const [relatedResponse, setRelatedResponse] = React.useState(relateTo());

    const timerRef = React.useRef(null);
    const isUnmounted = React.useRef(false);

    React.useEffect(() => {
        setRelatedResponse(relateTo());

        timerRef.current = setInterval(() => {
            const response = relateTo();
            if (relatedResponse !== response && isUnmounted.current === false) {
                setRelatedResponse(relateTo());
            }
        }, 1000);

        return () => {
            clearInterval(timerRef.current);
            isUnmounted.current = true;
        };
    }, [relateTo]);

    return <div>{relatedResponse}</div>;
}
