import React from 'react';

import dayjs from 'dayjs';
import { Button } from 'mp-ui-components';

import { AuthService, UserRole } from '@src/shared/services/auth';

import { getReportFile } from '../../api/shipmentsTracking/getReportFile';
import { serializeDownloadOption } from '../../pages/ShipmentsTrackingPage/domain/downloadReport/downloadReportDto';
import { getEtaDateOptions } from '../../pages/ShipmentsTrackingPage/domain/shipmentReports/shipmentReportsDto';
import { DateFilter } from './DateFilter';
import {
  allDataId,
  ATAFilterOptions,
  defaultATAOptionIndex,
  defaultETAOptionIndex,
  ETAFilterOptions,
} from './enums';

import styles from './styles.scss';

type Props = {
  latestDateOfPlanEtaPodInFu?: string;
  colorScheme?: string;
  downloadButtonTheme?: string;
};

export const STReportDownloadAction = ({
  latestDateOfPlanEtaPodInFu,
  colorScheme,
  downloadButtonTheme,
}: Props) => {
  const isAllDataOptionByDefault = AuthService.getIsUserRoleMatches([
    UserRole.CustomsBroker,
    UserRole.Forwarder,
  ]);

  const [etaDownloadOptions, setEtaDownloadOptions] = React.useState<TDownloadOptions>({
    ...getEtaDateOptions(latestDateOfPlanEtaPodInFu),
    typeId: isAllDataOptionByDefault ? allDataId : ETAFilterOptions[defaultETAOptionIndex].id,
  });

  const [ataDownloadOptions, setAtaDownloadOptions] = React.useState<TDownloadOptions>({
    from: new Date(),
    to: new Date(),
    typeId: isAllDataOptionByDefault ? allDataId : ATAFilterOptions[defaultATAOptionIndex].id,
  });
  const [isDownloading, setIsDownloading] = React.useState(false);

  const onDownloadClick = () => {
    const downloadOptions = {
      planningEstimationTimeOfArrivalOnPortOfDischargeInFollowUp:
        serializeDownloadOption(etaDownloadOptions),
      factActualTimeOfArrivalOfWarehouse: serializeDownloadOption(ataDownloadOptions),
    };

    setIsDownloading(true);
    getReportFile(downloadOptions)
      .then((response) => {
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        const headersResponse = response.headers;
        const contentDisposition = headersResponse['content-disposition'];
        const fileName = contentDisposition.split('=')[1];

        link.href = url;
        link.setAttribute('download', fileName);
        document.body.append(link);
        link.click();
        URL.revokeObjectURL(link.href);
      })
      .finally(() => setIsDownloading(false));
  };

  return (
    <div className={styles.actionPart}>
      <div className={styles.filters}>
        <div className={styles.filterWrap}>
          ETA POD
          <DateFilter
            options={etaDownloadOptions}
            variants={ETAFilterOptions}
            rightBorder={dayjs(latestDateOfPlanEtaPodInFu).add(1, 'd')}
            colorScheme={colorScheme}
            onChange={setEtaDownloadOptions}
          />
        </div>
        <div className={styles.filterWrap}>
          ATA WH
          <DateFilter
            options={ataDownloadOptions}
            variants={ATAFilterOptions}
            rightBorder={dayjs().add(1, 'd')}
            colorScheme={colorScheme}
            onChange={setAtaDownloadOptions}
          />
        </div>
      </div>
      <div className={styles.prompt} />
      <div>
        <Button theme={downloadButtonTheme} onClick={onDownloadClick} isPending={isDownloading}>
          Download
        </Button>
      </div>
    </div>
  );
};
