import React from 'react';

import cn from 'classnames';

import { pluralizeNoun, pluralizeWord } from '@src/shared/utils/transform';

import { SEARCH_RESULTS_LIMIT } from '../../api/constants';
import { InputState } from '../../hooks/types';
import { SearchResults } from '../../hooks/useFilter';

import styles from './styles.module.scss';

type BuildMessageParams = {
  searchResults: NonNullable<SearchResults>;
  input: InputState;
};

export const buildSearchLimitMessage = ({ searchResults, input }: BuildMessageParams) => {
  const { items, missingItems } = searchResults;

  const foundItemsAmount = items.length;
  const missingItemsAmount = missingItems.length;

  if (
    foundItemsAmount === 0 ||
    missingItemsAmount === 0 ||
    input.items.length <= SEARCH_RESULTS_LIMIT
  ) {
    return null;
  }

  const message = `The first ${SEARCH_RESULTS_LIMIT} values were searched`;

  return (
    <div className={styles.message} title={message}>
      {message}
    </div>
  );
};

export const buildMissingItemsMessage = ({ searchResults }: BuildMessageParams) => {
  const { items, missingItems } = searchResults;

  const foundItemsAmount = items.length;
  const missingItemsAmount = missingItems.length;

  if (foundItemsAmount === 0 || missingItemsAmount === 0) {
    return null;
  }

  const elements = pluralizeNoun('element', missingItemsAmount);

  const are = pluralizeWord(['are', 'is', 'are'], missingItemsAmount);

  const missingItemsList = missingItems.join(', ');

  const message = `${missingItemsAmount} ${elements} ${are} missing: ${missingItemsList}`;

  return (
    <div className={cn(styles.message, styles.clamped)} title={message}>
      {message}
    </div>
  );
};
