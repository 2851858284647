import { TableFieldType } from '../_deprecated/Table/types';
import { createLatestFilesColumn } from './latestFiles/create';
import { createParsingDetailsColumn } from './parsingDetails/create';
import { createParsingStateColumn } from './parsingState/create';
import { createTextColumn } from './text/create';

export const CreateColumn = {
  [TableFieldType.latestFiles]: createLatestFilesColumn,
  [TableFieldType.parsingState]: createParsingStateColumn,
  [TableFieldType.parsingDetails]: createParsingDetailsColumn,
  [TableFieldType.text]: createTextColumn,
};
