import React, { useMemo } from 'react';

import cn from 'classnames';

import { Body } from './components/Body';
import { Header } from './components/Header/Header';
import { TableContext } from './context/TableContext';
import { CommonTable, TableProps } from './types';
import { getIsSelectRowMode } from './utils';

import styles from './styles.module.scss';

const getContainerCN = (tableData: CommonTable, propClassName: string | undefined) => {
  return cn(
    styles.container,
    {
      [styles.selectRowsMode]: getIsSelectRowMode(tableData),
    },
    propClassName,
  );
};

/**
 * @deprecated use ReactTable Adapters instead of full component
 */
export const Table: React.FC<TableProps> = ({
  className: propClassName,
  tableName,
  tableData,
  allEnabledTableFilters = {},
  noResultsMessage,
  actionButtonsNode,
  paginatorNode,
  onTableEvent,
}) => {
  const isTableEmpty = tableData.rows.length === 0;

  const containerCN = getContainerCN(tableData, propClassName);

  const tableContextValue = useMemo(
    () => ({ tableName, tableData, allEnabledTableFilters, onTableEvent }),
    [tableName, tableData, allEnabledTableFilters, onTableEvent],
  );

  return (
    <TableContext.Provider value={tableContextValue}>
      <div className={containerCN}>
        <table className={styles.table}>
          <Header
            tableData={tableData}
            allEnabledTableFilters={allEnabledTableFilters}
            onTableEvent={onTableEvent}
          />

          <Body tableData={tableData} isTableEmpty={isTableEmpty} onTableEvent={onTableEvent} />
        </table>

        {isTableEmpty && <div className={styles.noResultsMessage}>{noResultsMessage}</div>}

        {!isTableEmpty && (
          <>
            <div className={styles.footerIndent} />

            {actionButtonsNode && <div className={styles.actionButtons}>{actionButtonsNode}</div>}

            {paginatorNode && <div className={styles.paginator}>{paginatorNode}</div>}
          </>
        )}
      </div>
    </TableContext.Provider>
  );
};
