import React from 'react';

import HTMLReactParser, { domToReact } from 'html-react-parser';

import { isDOMTag } from '@src/shared/utils/html/isDOMTag';

import { ParsingDetail } from './types';

export const getTextNode = (details: ParsingDetail[]) => {
  const htmlText = details.reduce((acc, item, ind) => {
    const messages = Array.isArray(item.info) ? item.info : [item.info];

    const messagesHtml = messages.join('<br>');

    if (ind === 0) {
      return `${messagesHtml}<br>`;
    }

    return `${acc}<br><span class="tableDetailsItemTitle">${item.title}</span><br>${messagesHtml}<br>`;
  }, '');

  return HTMLReactParser(htmlText, {
    replace: (node) => {
      if (!isDOMTag(node)) {
        return;
      }

      if (node.tagName === 'a') {
        return <>{domToReact(node.children)}</>;
      }
    },
  });
};
