import React, { useCallback, useMemo } from 'react';

import { useSnackbar } from 'fronton-react';
import { useMutation } from 'react-query';

import { makeDefaultErrorSnack } from '@src/app/components/AppSnackbar';
import { useFormConditions } from '@src/modules/tasks/pages/TaskPage/components/TaskFormTemplate/hooks/useFormConditions';
import {
  FormContextValue,
  FormTarget,
} from '@src/modules/tasks/pages/TaskPage/components/TaskFormTemplate/hooks/useFormContext';
import { useOnUpdateField } from '@src/modules/tasks/pages/TaskPage/components/TaskFormTemplate/hooks/useFormFields';
import { useFormSubmit } from '@src/modules/tasks/pages/TaskPage/components/TaskFormTemplate/hooks/useFormSubmit';
import { useFormValidation } from '@src/modules/tasks/pages/TaskPage/components/TaskFormTemplate/hooks/useFormValidation';
import { mapSections } from '@src/modules/tasks/pages/TaskPage/mapper';
import { TaskState } from '@src/modules/tasks/pages/TaskPage/types';
import { TaskFormResolutionField } from '@src/shared/types';

import { createShipmentTaskName, makeCreateShipmentTaskTemplate } from './createShipmentTemplate';
import { addShipment } from '../../services/shipments';

const initializeState = (): TaskState => {
  const template = makeCreateShipmentTaskTemplate();

  return {
    ...template,
    fields: mapSections(template.sections, 'me'),
  };
};

export const useTaskForm = (onClose: () => void) => {
  const { addSnack } = useSnackbar();
  const [state, setState] = React.useState<TaskState>(initializeState);
  const conditions = useFormConditions();
  const { isFormValid } = useFormValidation(state, conditions.selectedConditions);
  const onUpdateField = useOnUpdateField(
    setState,
    conditions.updateConditions,
    conditions.updateCheckboxConditions,
  );
  const onSubmit = useFormSubmit(state, conditions.selectedConditions);

  const { isLoading, mutate } = useMutation({
    mutationFn: (resolution: TaskFormResolutionField[]) => addShipment(resolution),
    onSuccess: () => {
      onClose();
    },
    onError: () => {
      addSnack(makeDefaultErrorSnack());
    },
  });

  const terminalButtonHandler = useCallback(() => {
    const { isValid, resolution } = onSubmit();

    if (!isValid) {
      addSnack(makeDefaultErrorSnack());

      return;
    }

    mutate(resolution);
  }, [mutate, onSubmit, addSnack]);

  const formContext = useMemo<FormContextValue>(
    () => ({
      state,
      target: FormTarget.modal,
      header: createShipmentTaskName,
      isShipment: true,
      selectedConditions: conditions.selectedConditions,
      onClose,
      onUpdateField,
      clearConditions: conditions.clearConditions,
      isButtonDisabled: () => !isFormValid,
      getButtonPendingState: () => isLoading,
      terminalButtonHandler,
    }),
    [
      state,
      conditions.selectedConditions,
      conditions.clearConditions,
      onClose,
      onUpdateField,
      terminalButtonHandler,
      isFormValid,
      isLoading,
    ],
  );

  return formContext;
};
