import React, { useCallback } from 'react';

import { TaskState } from '../../../types';

export const useOnUpdateField = (
  setState: React.Dispatch<React.SetStateAction<TaskState>>,
  updateConditions: (prevCondition: any, nextCondition: any) => void,
  updateCheckboxConditions: (prevConditions: any, nextConditions: any) => void,
) => {
  return useCallback(
    (field) => {
      setState((old) => {
        if (old) {
          const update = {
            ...old,
            fields: old.fields.map((container) => {
              if (container.caption === field.container) {
                return {
                  ...container,
                  fields: container.fields.map((fieldOptions) => {
                    const containerField = fieldOptions;

                    if (
                      containerField.label !== null &&
                      field.label !== null &&
                      containerField.label === field.label
                    ) {
                      if (containerField.type === 'select' && field.value) {
                        const prevCondition = field.value.selected || null;
                        const nextCondition = field.update.selected || null;

                        updateConditions(prevCondition, nextCondition);
                      }

                      if (containerField.type === 'checkbox' && field.update.selected) {
                        updateCheckboxConditions(
                          field.value.selected.map((item) => item.id),
                          field.update.selected.map((item) => item.id),
                        );
                      }

                      for (const fieldKey in field.update) {
                        containerField[fieldKey] = field.update[fieldKey];
                      }
                    }

                    return containerField;
                  }),
                };
              }

              return container;
            }),
          };

          return update;
        }

        return old;
      });
    },
    [setState, updateCheckboxConditions, updateConditions],
  );
};
