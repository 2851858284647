import React from 'react';

import { uploadFiles } from '../../../api/shipmentsTracking/uploadFiles';
import { currentActivityEnum, FileStatus } from '../../../enums/enums';

export const useUploadFiles = (dispatch, options, isActive) =>
  React.useEffect(() => {
    if (isActive) {
      uploadFiles(options)
        .then(() => {
          dispatch({
            type: 'uploadFiles',
            payload: {
              nextUploadFiles: {
                files: [],
                error: '',
              },
            },
          });
          dispatch({
            type: 'changeCurrentActivity',
            payload: {
              nextActivity: currentActivityEnum.checkFileStatus,
            },
          });
        })
        .catch((error) => {
          dispatch({
            type: 'changeUpload',
            payload: {
              nextStatus: FileStatus.UploadingError,
              nextError: error,
            },
          });
          dispatch({
            type: 'changeCurrentActivity',
            payload: {
              nextActivity: currentActivityEnum.idle,
            },
          });
        });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);
