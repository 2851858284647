import React, { CSSProperties } from 'react';

import { flexRender, Table } from '@tanstack/react-table';

import { TableRow } from './_deprecated/Table';
import { TableMeta } from './_deprecated/Table/types';
import { NoDataFoundMsg } from './styled/NoDataFoundMsg';
import { TBody } from './styled/TBody';
import { TableStyled } from './styled/TableStyled';

type Props = {
  table: Table<TableRow>;
  noDataFoundMsgText?: string;
  isBodyHidden?: boolean;
  className?: string;
  style?: CSSProperties;
};

export const TableTemplate = ({
  table,
  className,
  style,
  noDataFoundMsgText = 'No data found',
  isBodyHidden,
}: Props) => {
  const tableMeta = table.options.meta as TableMeta | undefined;
  const paginatorNode = tableMeta?.paginatorNode;

  const headerGroups = table.getHeaderGroups();

  const { rows } = table.getRowModel();

  return (
    <>
      <TableStyled style={style} className={className} data-table-name={tableMeta?.tableName}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <React.Fragment key={header.id}>
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </React.Fragment>
              ))}
            </tr>
          ))}
        </thead>
        {!isBodyHidden && (
          <TBody>
            {rows.length > 0 ? (
              rows.map((row) => (
                <tr
                  key={row.original?.id}
                  data-updated={tableMeta?.uploadedRowsIds?.includes(row.original?.id ?? '')}
                >
                  {row.getVisibleCells().map((cell) => (
                    <React.Fragment key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </React.Fragment>
                  ))}
                </tr>
              ))
            ) : (
              <NoDataFoundMsg>{noDataFoundMsgText}</NoDataFoundMsg>
            )}
          </TBody>
        )}
      </TableStyled>
      {!isBodyHidden && paginatorNode}
    </>
  );
};
