import { FileService } from '@src/shared/services/files';

export const uploadFilesForShipment = async (
  files: FileList | File[],
  shipmentId: string,
  docType: string,
) => {
  const url = `/api/shipments/${shipmentId}/files`;

  return FileService.uploadFilesTaskApi({ url, docType, files });
};
