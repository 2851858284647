import { useQuery } from 'react-query';

import { RTCellContext } from '../../types';
import { LatestFilesColumn } from './types';

export const MAX_HISTORY_SIZE = 100;

export const useHistory = ({ column, row }: RTCellContext) => {
  const entityId: string = row.original?.entityId ?? '';
  const fileType: string = row.original?.type ?? '';
  const { getLatestVersions } = column.columnDef.meta as Required<LatestFilesColumn>;

  const lastUploadInfo = row.original ?? {};

  const query = useQuery({
    queryKey: getLatestVersions.getQueryKey({
      entityId,
      fileType,
      pageNumber: 1,
      pageSize: MAX_HISTORY_SIZE,
    }),
    queryFn: getLatestVersions.queryFn,
    initialData: {
      data: [lastUploadInfo],
      hasNext: false,
    },
    retry: false,
    refetchOnMount: true,
    enabled: !!entityId,
    cacheTime: 0,
  });

  return {
    query,
    uploadsList: query.data?.data ?? [],
  };
};
