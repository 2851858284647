import React, { HTMLAttributes, PropsWithChildren } from 'react';

import { cx } from 'linaria';
import { styled } from 'linaria/react';

import { TypographyTag, TypographyType } from './enums';
import { typographyStyles } from './styles';

type Props = PropsWithChildren &
  HTMLAttributes<HTMLParagraphElement> & {
    tag?: TypographyTag;
    type?: TypographyType;
    className?: string;
  };

const Tag = styled.p``;

export const LegacyTypography = ({
  className,
  tag = TypographyTag.p,
  type = TypographyType.paragraphM,
  children,
  ...props
}: Props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Tag as={tag} className={cx(typographyStyles[type], className)} {...props}>
    {children}
  </Tag>
);
