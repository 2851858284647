import React, { useCallback, useState } from 'react';

import dayjs from 'dayjs';
import { Link, Dropdown } from 'mp-ui-components';

import { FileService } from '@src/shared/services/files';

import { IconContainer, StyledDropdown, Time, TriggerContent } from './Styled';
import { UploadsList } from './UploadsList';
import { LatestFilesColumn } from './types';
import { RTCellContext } from '../../types';
import { Td } from '../Td';

export const LatestFilesCell = (rtContext: RTCellContext) => {
  const { getValue, column, row } = rtContext;
  const { showOnlyOne = true } = column.columnDef.meta as LatestFilesColumn;
  const fileUrl = row.original?.url;
  const fileName = row.original?.fileName ?? row.original?.name;

  const [isOpened, setIsOpened] = useState(false);

  const handleClick = useCallback(
    (openState: boolean) =>
      showOnlyOne ? FileService.downloadFile(fileUrl) : setIsOpened(openState),
    [showOnlyOne, fileUrl],
  );

  const date = dayjs(getValue());

  const day = date.format('DD.MM.YYYY');
  const time = date.format('HH:mm');

  const FileExtIcon = FileService.getIconComponentByFileName(fileName, true);

  return (
    <Td rtContext={rtContext}>
      <StyledDropdown isOpen={isOpened} onChange={handleClick}>
        <Dropdown.Trigger>
          <TriggerContent>
            <IconContainer size="s">
              <FileExtIcon bold />
            </IconContainer>
            <Link tag="span" isDotted={!showOnlyOne}>
              {day}
            </Link>
            <Time>{time}</Time>
          </TriggerContent>
        </Dropdown.Trigger>

        <Dropdown.Content ariseDirection="bottom-start">
          {showOnlyOne ? null : <UploadsList rtContext={rtContext} />}
        </Dropdown.Content>
      </StyledDropdown>
    </Td>
  );
};
