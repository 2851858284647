import React from 'react';

import { XIcon } from '@fronton/icons-react';
import { Divider } from 'fronton-react';
import { styled } from 'linaria/lib/react';
import { noop } from 'lodash';

import { useFormContext } from './hooks/useFormContext';

const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px;
  padding-bottom: 23px;
  font-size: 24px;
`;

export const Header = () => {
  const { header, onClose = noop } = useFormContext();

  return (
    <>
      <HeaderWrap>
        <div>{header}</div>
        <XIcon
          style={{ cursor: 'pointer' }}
          type="outline"
          size="l"
          color="text-primary"
          onClick={onClose}
        />
      </HeaderWrap>
      <Divider />
    </>
  );
};
