import { useState } from 'react';

import type { TOrderArticleId } from '../entities/orderArticle';

import { blockOrderArticles } from '../../../api/methods/articles';

type TUseOrderArticlesBlocking = {
  blockArticles: (articleIds: TOrderArticleId[], onSuccess: () => void) => void;
  changeMessage: (message: string) => void;
  message: string;
  isPending: boolean;
  error: string;
};

export function useOrderArticlesBlocking(orderId: string): TUseOrderArticlesBlocking {
  const [isPending, setIsPending] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const blockArticles = (articleIds: TOrderArticleId[], onSuccess: () => void) => {
    setIsPending(true);
    blockOrderArticles(orderId, articleIds, message)
      .then(() => {
        setMessage('');
        setError('');
        onSuccess();
      })
      .catch(() =>
        setError('The SKUs were not blocked due to a server error. Please try again later.'),
      )
      .finally(() => setIsPending(false));
  };

  const changeMessage = (nextMessage: string) => setMessage(nextMessage);

  return {
    blockArticles,
    changeMessage,
    message,
    isPending,
    error,
  };
}
