import React from 'react';

import XlsIcon from '@src/shared/components/DocDownload/assets/icon-file-xls.svg';

import styles from './ReportBlock.scss';

type ReportBlockProps = {
  title: string;
  fileName: string;
  dateLabel?: string;
  children?: React.ReactNode;
};

export const ReportBlockWithAction = ({
  title,
  fileName,
  dateLabel,
  children,
}: ReportBlockProps) => {
  return (
    <div className={styles.reportBlockAction}>
      <div className={styles.reportHeader}>
        <div className={styles.reportBlockTitle}>
          {title}
          <div className={styles.divider} />
        </div>
        <div className={styles.blockContainer}>
          <div className={styles.blockInfo}>
            <div className={styles.blockFileDescription}>
              <div className={styles.file}>
                <img src={XlsIcon} alt="xls-icon" />
                <span className={styles.fileLabel}>{fileName},&nbsp;</span>
              </div>
              <div className={styles.dateLabel}>last update {dateLabel}</div>
            </div>
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};
