// @flow strict
import React from 'react';
import styles from './index.css';
import { Button } from 'mp-ui-components';
import cn from 'classnames';

import { ErrorReloadableComponent } from '../../../../../../shared/components/Error';
import Preloader from '@src/shared/components/Preloader';
import { requestShipmentDocuments } from '../../../ShipmentsListPage/services/shipments';

function ShipmentDocuments({ shipmentId }: { shipmentId: string | null | typeof undefined }) {
    const [isPending, setPending] = React.useState(true);
    const [failedStatus, setFailedStatus] = React.useState(null);
    const [status, setStatus] = React.useState(null);
    const [urlToDownload, setUrlToDownload] = React.useState('');

    const isUnmounted = React.useRef(false);

    React.useEffect(() => {
        if (shipmentId) {
            requestShipmentDocuments(shipmentId)
                .then(response => {
                    if (!isUnmounted.current) {
                        setStatus(response.status);
                        setPending(false);
                        setUrlToDownload(response.url);
                    }
                })
                .catch(err => {
                    if (!isUnmounted.current) {
                        setFailedStatus(err.response ? err.response.status : 'network');
                        setPending(false);
                        console.error(err);
                    }
                });
        }
        return () => {
            isUnmounted.current = true;
        };
    }, []);

    if (failedStatus)
        return (
            <ErrorReloadableComponent
                theme="white"
                width="s"
                margin="30px 0"
                title="Shipment documents can't be loaded"
                status={failedStatus}
            />
        );

    if (isPending) return <Preloader />;

    const mapStatus = (status: string | null) => {
        if (status === 'in progress') return 'in progress...';
        return status;
    };

    return (
        <div>
            <div className={styles.shippingDocumentsLabel}>
                Shipping documents:{' '}
                <span
                    className={cn(
                        styles.defaultDocumentsLabel,
                        status === 'ready' && styles.shippingDocumentsLabelGreen
                    )}
                >
                    {mapStatus(status) || ''}
                </span>
            </div>
            {status === 'ready' && urlToDownload !== '' && (
                <div className={styles.downloadBtn}>
                    <a href={urlToDownload}>
                        <Button theme="secondary">Download</Button>
                    </a>
                </div>
            )}
        </div>
    );
}

export default ShipmentDocuments;
