import React from 'react';

import { AuthService, UserRole } from '@src/shared/services/auth';

import { ChangePriorityActions } from './ChangePriorityActions';

type UseActionButtonsParams = {
  onSuccess: () => void;
};

export const useActionButtons = ({ onSuccess }: UseActionButtonsParams) => {
  const isApprovisionSpecialistUser = AuthService.getIsUserRoleMatches([
    UserRole.ApprovisionSpecialist,
  ]);

  if (!isApprovisionSpecialistUser) {
    return null;
  }

  return <ChangePriorityActions onSuccess={onSuccess} />;
};
