import React from 'react';

import dayjs from 'dayjs';

import XlsIcon from '@src/shared/components/DocDownload/assets/icon-file-xls.svg';

import { STReportDownloadAction } from './STReportDownloadAction';

import styles from './styles.scss';

type Props = {
  latestDateOfPlanEtaPodInFu?: string;
  latestDateUpdateShipmentTracking?: string;
};

export const STReportDownloadWidget = ({
  latestDateOfPlanEtaPodInFu,
  latestDateUpdateShipmentTracking,
}: Props) => {
  return (
    <div className={styles.body}>
      <div className={styles.title}>Shipments tracking</div>
      <div className={styles.fileInfo}>
        <div className={styles.file}>
          <img src={XlsIcon} className={styles.icon} alt="xls-icon" />
          <div className={styles.description}>
            <b>Shipments tracking.xlsx,&nbsp;</b>
            <br />
            <span className={styles.timeUpdate}>
              last update {dayjs(latestDateUpdateShipmentTracking).format('D MMM. YYYY [at] HH:mm')}
            </span>
          </div>
        </div>
        <div className={styles.placeholder}>
          The report is generated based on the files which are uploaded to the system by the
          Transport group specialist of Leroy Merlin Russia.
          <br />
          The filters are independent of each other
        </div>
      </div>
      <STReportDownloadAction
        latestDateOfPlanEtaPodInFu={latestDateOfPlanEtaPodInFu}
        downloadButtonTheme="primary"
      />
    </div>
  );
};
