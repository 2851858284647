/* eslint-disable @typescript-eslint/default-param-last */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

// Legacy File
// TODO: refactor and fix errors
import { useCallback, useEffect, useState } from 'react';

import { CommonTable } from '@src/shared/components/Table/_deprecated/Table';
import { mapSelectedFilters } from '@src/shared/utils/mappers/mapTableDataResponseDto';

import type { TOrderArticle, TOrderArticleId } from '../entities/orderArticle';

import { useEditGKLinking } from './useEditGKLinking/useEditGKLinking';
import { useFilters } from './useFilters';

import { getOrderArticles } from '../../../api/methods/articles';

export type TUseOrderArticles = {
  articles: TOrderArticle[];
  fields: CommonTable['fields'];
  editGKLinking: ReturnType<typeof useEditGKLinking>;
  fetchArticles: (
    filters: unknown | undefined,
    options: {
      isSilent: boolean;
    },
  ) => void;
  checkArticle: (articleId: TOrderArticleId) => void;
  checkAllArticles: () => void;
  uncheckArticle: (articleId: TOrderArticleId) => void;
  uncheckAllArticles: () => void;
  isPending: boolean;
  error: string;
  filtersHook: ReturnType<typeof useFilters>;
};

function checkArticleByIndex(
  articles: TOrderArticle[],
  index: number,
  isChecked: boolean,
): TOrderArticle[] {
  return [
    ...articles.slice(0, index),
    { ...articles[index], canBlock: { ...articles[index].canBlock, isChecked } },
    ...articles.slice(index + 1),
  ];
}

export function useOrderArticles(
  orderId: string,
  ocNumber: string,
  refetchChronology: () => Promise<void>,
): TUseOrderArticles {
  const [articles, setArticles] = useState([]);
  const [fields, setFields] = useState([]);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState('');
  const filtersHook = useFilters('orderArticlesFilters');

  const fetchArticles = (
    filters = {},
    {
      isSilent,
    }: {
      isSilent: boolean;
    },
  ) => {
    setIsPending(!isSilent);
    getOrderArticles(orderId, mapSelectedFilters(filters))
      .then((response) => {
        setFields(response.fields);
        setArticles(response.data);
      })
      .catch(() => setError('Server does not respond. Please reload this page.'))
      .finally(() => setIsPending(false));
  };

  useEffect(() => {
    fetchArticles(filtersHook.filters, {
      isSilent: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersHook.filters]);

  const checkArticle = (articleIndex: number) => {
    setArticles(checkArticleByIndex(articles, articleIndex, true));
  };

  const uncheckArticle = (articleIndex: number) => {
    setArticles(checkArticleByIndex(articles, articleIndex, false));
  };

  const checkAllArticles = () => {
    setArticles(
      articles.map((article) => ({
        ...article,
        canBlock: article.canBlock.isDisabled
          ? article.canBlock
          : { ...article.canBlock, isChecked: true },
      })),
    );
  };

  const uncheckAllArticles = () => {
    setArticles(
      articles.map((article) => ({
        ...article,
        canBlock: article.canBlock.isDisabled
          ? article.canBlock
          : { ...article.canBlock, isChecked: false },
      })),
    );
  };

  const refetchPageData = useCallback(
    () =>
      Promise.all[(refetchChronology(), fetchArticles(filtersHook.filters, { isSilent: true }))],
    [refetchChronology, fetchArticles, filtersHook.filters],
  );

  const editGKLinking = useEditGKLinking(ocNumber, articles, refetchPageData);

  return {
    articles,
    fields,
    fetchArticles,
    checkArticle,
    checkAllArticles,
    uncheckArticle,
    uncheckAllArticles,
    editGKLinking,
    isPending,
    error,
    filtersHook,
  };
}
