// @flow strict
import * as React from 'react';
import dayjs from 'dayjs';

import Icon from './Icon';

import cls from 'classnames';
import s from './Head.css';

type Props = {|
    showedDate: Date,
    onChange: (date: Date) => void,
    isLookingInPast: boolean
|};

function CalendarHead(props: Props) {
    function handlePrevMonth() {
        props.onChange(
            dayjs(props.showedDate)
                .subtract(1, 'month')
                .toDate()
        );
    }

    function handleNextMonth() {
        props.onChange(
            dayjs(props.showedDate)
                .add(1, 'month')
                .toDate()
        );
    }

    function handlePrevYear() {
        props.onChange(
            dayjs(props.showedDate)
                .subtract(1, 'year')
                .toDate()
        );
    }

    function handleNextYear() {
        props.onChange(
            dayjs(props.showedDate)
                .add(1, 'year')
                .toDate()
        );
    }

    const prevMonth = dayjs(props.showedDate).subtract(1, 'month');
    const nextMonth = dayjs(props.showedDate).add(1, 'month');

    return (
        <div className={s.wrap}>
            <div className={s.col}>
                <button
                    className={cls(s.control, s.prev)}
                    type="button"
                    onClick={handlePrevMonth}
                    data-testid="PrevMonth"
                >
                    <Icon className={s.icon} />
                </button>
                <span className={s.month}>
                    {props.isLookingInPast ? prevMonth.format('MMMM') : dayjs(props.showedDate).format('MMMM')}
                </span>
                <button
                    className={cls(s.control, s.next)}
                    type="button"
                    onClick={handleNextMonth}
                    data-testid="NextMonth"
                >
                    <Icon className={s.icon} />
                </button>
            </div>
            <div className={cls(s.col, s.colYear)}>
                <button
                    className={cls(s.control, s.prev)}
                    type="button"
                    onClick={handlePrevYear}
                    data-testid="PrevYear"
                >
                    <Icon className={s.icon} />
                </button>
                <span className={s.year}>{dayjs(props.showedDate).format('YYYY')}</span>
                <button
                    className={cls(s.control, s.next)}
                    type="button"
                    onClick={handleNextYear}
                    data-testid="NextYear"
                >
                    <Icon className={s.icon} />
                </button>
            </div>
            <div className={cls(s.col, s.month, s.colNow)}>
                {`${
                    props.isLookingInPast ? dayjs(props.showedDate).format('MMMM') : nextMonth.format('MMMM')
                } ${nextMonth.format('YYYY')}`}
            </div>
        </div>
    );
}

export default CalendarHead;
