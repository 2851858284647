import React from 'react';

import { styled } from 'linaria/lib/react';

type PageSwitcherProps = {
  hasBorderBottom?: boolean;
  isHiglighted?: boolean;
};

export const PageSwitcher = styled.div<PageSwitcherProps>`
  margin-right: 7px;
  padding: 0;
  border-bottom: 1px solid transparent;

  border-bottom-color: ${({ hasBorderBottom, isHiglighted }) => {
    if (!hasBorderBottom) {
      return 'transparent';
    }

    if (isHiglighted) {
      return 'rgb(0 138 82 / 38%)';
    }

    return 'rgb(0 0 0 / 20%)';
  }};

  color: ${({ isHiglighted }) => (isHiglighted ? '#008a52' : 'rgb(0 0 0 / 38%)')};
  cursor: pointer;
  pointer-events: ${({ hasBorderBottom }) => (hasBorderBottom ? 'auto' : 'none')};
  user-select: none;

  &:hover {
    border-bottom-color: ${({ isHiglighted }) => {
      if (isHiglighted) {
        return '#008a52';
      }

      return 'rgb(0 0 0 / 50%)';
    }};
  }
`;

export const ActionsSplitter = styled.div`
  width: 1px;
  height: 16px;
  margin-right: 18px;
  margin-left: 12px;
  background: rgb(0 0 0 / 20%);
`;

type WrapProps = {
  isPending: boolean | undefined;
};

export const Wrap = styled.div<WrapProps>`
  display: flex;
  align-items: center;
  margin-top: 24px;

  opacity: ${({ isPending }) => (isPending ? 0.6 : 1)};
  font-size: 14px;
`;

const Dot = styled.div`
  margin-right: 9px;
  color: rgb(0 0 0 / 20%);
`;

export const DotSplitter = () => <Dot>·</Dot>;
