import React from 'react';

const AttachmentDocument = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24">
      <path
        fill="none"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M21.44 11.05l-9.19 9.19a6.003 6.003 0 11-8.49-8.49l9.19-9.19a4.002 4.002 0 015.66 5.66l-9.2 9.19a2.001 2.001 0 01-2.83-2.83l8.49-8.48"
      ></path>
    </svg>
  );
};

const AttachmentImage = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24">
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(3 3)"
      >
        <rect width="18" height="18" rx="2"></rect>
        <circle cx="5.5" cy="5.5" r="1.5"></circle>
        <path d="M18 12L13 7 2 18"></path>
      </g>
    </svg>
  );
};

function TickSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <defs>
        <path id="aTick" d="M0 0h14.773v15.994H0z"></path>
        <path id="cTick" d="M0 10.146L4.766 16 14.773 3.169V0L4.776 12.915 0 7.1v3.047"></path>
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="bTick" fill="#fff">
          <use xlinkHref="#aTick"></use>
        </mask>
        <g mask="url(#bTick)">
          <g transform="translate(1)">
            <mask id="dTick" fill="#fff">
              <use xlinkHref="#cTick"></use>
            </mask>
            <use fill="#1A1919" xlinkHref="#cTick"></use>
            <g mask="url(#dTick)">
              <path fill="#008A52" fillRule="nonzero" d="M-1 0h16v16H-1z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export { AttachmentDocument, AttachmentImage, TickSVG };
