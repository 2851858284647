import { httpClient } from '@src/shared/services/httpClient';
import { RequestFilterFromInputDTO } from '@src/shared/types/domain';

import { SearchResults } from './types';

export const requestFiltersLegacyMethod = (
  fieldName: string,
  searchText: string,
  signal: AbortSignal,
): Promise<SearchResults> =>
  httpClient
    .request<RequestFilterFromInputDTO[]>({
      method: 'get',
      url: `/api/filters/${fieldName}/search?text=${encodeURIComponent(searchText)}`,
      signal,
    })
    .then(({ data }) => ({ items: data, missingItems: [] })); // converting to modern request Api
