import { httpClient } from '@src/shared/services/httpClient';
import { BaseQueryDef, createQueryDef } from '@src/shared/services/query';

import { LatestSTSubReports } from '../../types';

export const getLatestSTReportsRequest = ({ take }) =>
  httpClient.get<LatestSTSubReports>('api/shipmentsTracking/files/grouped', {
    params: {
      take,
    },
  });

type QueryDef = BaseQueryDef<
  LatestSTSubReports,
  {
    take: number; // amount of last uploaded files
  }
>;

export const getLatestReports = createQueryDef<QueryDef>(
  'api/shipmentsTracking/files/grouped',
  async ({ queryKey }) => {
    const [url, { take }] = queryKey;

    const response = await getLatestSTReportsRequest({ take });

    return response.data;
  },
);
