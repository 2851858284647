import { httpClient } from '../httpClient';

export type DocTypeInfo = {
  extensions?: string[];
  maxUploadSizeInBytes?: number;
  fileNameRegex?: string;
  fileNameExample?: string;
};

export type DocTypesDictionary = Partial<Record<string, DocTypeInfo>>;

// eslint-disable-next-line import/no-mutable-exports
export let docTypes: DocTypesDictionary = {}; // is set dynamically on page load

export const requestDocTypes = () =>
  httpClient.get<DocTypesDictionary>('/api/enums/doctypes').then((response) => {
    docTypes = response.data;

    return docTypes;
  });
