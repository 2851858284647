import { useCallback, useMemo, useState } from 'react';

import { PaginationState } from '@tanstack/react-table';

export const usePagination = (initPageIndex: number, initPageSize: number) => {
  const [state, setState] = useState<PaginationState>({
    pageIndex: initPageIndex,
    pageSize: initPageSize,
  });

  const reset = useCallback(() => {
    setState({
      pageIndex: initPageIndex,
      pageSize: initPageSize,
    });
  }, [initPageIndex, initPageSize]);

  // Memo used for React Table inner optimizations
  return useMemo(
    () => ({
      state,
      setState,
      reset,
    }),
    [state, setState, reset],
  );
};
