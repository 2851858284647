/* eslint-disable no-prototype-builtins */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

// Legacy File
// TODO: refactor and fix errors
import { useState } from 'react';

const getFiltersFromLocalStorage = (key: string) => {
  let filtersFromLocalStorage = {};
  const savedFiltersString = localStorage.getItem(key);

  if (savedFiltersString) {
    filtersFromLocalStorage = JSON.parse(savedFiltersString);

    for (const filter in filtersFromLocalStorage) {
      if (filtersFromLocalStorage[filter].type === 'date') {
        filtersFromLocalStorage[filter].from = new Date(filtersFromLocalStorage[filter].from);
        filtersFromLocalStorage[filter].to = new Date(filtersFromLocalStorage[filter].to);
      }
    }

    if (Object.keys(filtersFromLocalStorage).length > 0) {
      return filtersFromLocalStorage;
    }
  }

  return {};
};

const isValuable = (raw) => {
  if (!raw) {
    return false;
  }

  if (Array.isArray(raw)) {
    return raw.length > 0;
  }

  return raw.type && raw.from && raw.to;
};

export function useFilters(key: string) {
  const [filters, setFilters] = useState(() => getFiltersFromLocalStorage(key));

  const updateFilters = (updates) => {
    const nextFilters = {};

    for (const filter in updates) {
      if (updates.hasOwnProperty(filter) && isValuable(updates[filter])) {
        nextFilters[filter] = updates[filter];
      }
    }

    setFilters(nextFilters);
    localStorage.setItem(key, JSON.stringify(nextFilters));
  };

  return {
    filters,
    updateFilters,
    isEmpty: Object.keys(filters).length === 0,
  };
}
