import dayjs from 'dayjs';

import type { TDownloadOptions } from './downloadReport';

import { allDataId } from '../../../../widgets/STReportDownloadWidget/enums';

export function serializeDownloadOption(option: TDownloadOptions) {
  const { from, to, typeId } = option;
  const convertedFrom = dayjs(from).format('YYYY-MM-DDT00:00:00[Z]'); // "2021-07-21T07:04:54.665Z",

  const convertedTo = dayjs(to || from).format('YYYY-MM-DDT23:59:59[Z]');

  switch (typeId) {
    case '0':
      return {
        period: {
          from: convertedFrom,
          to: convertedTo,
        },
        hasWithEmptyCells: false,
      };

    case '1':
      return {
        period: {
          from: convertedFrom,
          to: convertedTo,
        },
        hasWithEmptyCells: true,
      };

    case '2':
      return {
        period: {},
        hasWithEmptyCells: true,
      };

    default:
      return {};
  }
}

export function getDownloadPrompt(
  etaOption: TDownloadOptions,
  ataOption: TDownloadOptions,
): string {
  const etaTypeId = etaOption.typeId;
  const ataTypeId = ataOption.typeId;

  if (etaTypeId !== allDataId && ataTypeId !== allDataId) {
    return 'Data from first and second filter will be downloaded';
  }

  if (etaTypeId === allDataId && ataTypeId !== allDataId) {
    return 'Data from ATA WH filter will be downloaded';
  }

  if (etaTypeId !== allDataId && ataTypeId === allDataId) {
    return 'Data from ETD POD filter will be downloaded';
  }

  return 'All data will be downloaded';
}
