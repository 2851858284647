export enum FieldType {
  text = 'text',
  linkList = 'linkList',
  docList = 'docList',
  select = 'select',
  checkbox = 'checkbox',
  attachmentDoc = 'attachmentDoc',
  attachmentScreenshot = 'attachmentScreenshot',
  comment = 'comment',
  textInput = 'textInput',
  datePicker = 'datePicker',
  dateLabel = 'dateLabel',
  taskTable = 'taskTable',
  decisionTable = 'decisionTable',
}
