import React from 'react';

import { Navigate, useRoutes } from 'react-router-dom';

import { CreateRequestForm } from '../../components/CreateRequestForm';
import { RequestCreatedInfo } from '../../components/RequestCreatedInfo';
import { SupportSidebar } from '../../components/SupportSidebar';

import styles from './HelpAndSupport.scss';

export const HelpAndSupportPage = () => {
  const routes = useRoutes([
    {
      index: true,
      element: <CreateRequestForm />,
    },
    {
      path: 'done',
      element: <RequestCreatedInfo />,
    },
    {
      path: '*',
      element: <Navigate to="." replace />,
    },
  ]);

  return (
    <main className={styles.container}>
      <section className={styles.sidebarContainer}>
        <SupportSidebar />
      </section>
      <section className={styles.formContainer}>{routes}</section>
    </main>
  );
};
