import React from 'react';

import { styled } from 'linaria/lib/react';

import { PrivateRoutes } from './routes/PrivateRoutes';
import { PublicRoutes } from './routes/PublicRoutes';
import { useAppHeaderHeight } from '../AppHeader/useAppHeaderHeight';

const Wrap = styled.main<{ appHeaderHeight: number }>`
  height: calc(100vh - ${({ appHeaderHeight }) => appHeaderHeight}px);
  margin-top: ${({ appHeaderHeight }) => appHeaderHeight}px;
  overflow: auto;
`;

type Props = {
  isSignedIn: boolean;
};

export const AppContent = ({ isSignedIn }: Props) => {
  const appHeaderHeight = useAppHeaderHeight();

  return (
    <Wrap appHeaderHeight={appHeaderHeight}>
      {isSignedIn ? <PrivateRoutes /> : <PublicRoutes />}
    </Wrap>
  );
};
