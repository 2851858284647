import React from 'react';

import { getDateAsFormattedText } from '../../../../../../../pages/ShipmentsTrackingPage/domain/trackingFile/trackingFileDto';

import styles from './styles.css';

type Props = { fileName: string; created: string; performer?: string };

export const FileDescription = ({ created, fileName, performer }: Props) => (
  <>
    <span className={styles.fileName}>{fileName}, </span>
    {created ? (
      <span className={styles.timeUpdate}>{`uploaded ${getDateAsFormattedText(created)}${
        performer ? ` by ${performer}` : ''
      }`}</span>
    ) : null}
  </>
);
