import React from 'react';

import { NavLink } from 'react-router-dom';

import { UserIdToken } from '@src/shared/services/auth';
import { RoutingService } from '@src/shared/services/routing';

import { Nav, NavTab } from './Styled';

type NavBarProps = {
  userToken: UserIdToken | undefined;
};

export const NavBar = ({ userToken }: NavBarProps) => {
  const userRole = userToken?.vedRole;
  const navTabs = RoutingService.getNavTabsForRole(userRole);

  return (
    <Nav>
      {navTabs.map(({ path, label }) => (
        <NavLink to={path} key={path}>
          <NavTab tabIndex={-1}>{label}</NavTab>
        </NavLink>
      ))}
    </Nav>
  );
};
