import { groupBy } from 'lodash';

import { RequestFilterFromInputDTO } from '@src/shared/types';

export const makeTableFilterItems = (
  originalData: RequestFilterFromInputDTO[],
  itemTextMapper: any,
) => {
  const itemsWithRemappedText = originalData.map((item) => ({
    ...item,
    text: itemTextMapper?.[item.text] ?? item.text,
  }));

  const aliasGroups = Object.values(groupBy(itemsWithRemappedText, 'text'));

  const items = aliasGroups.map((group) =>
    group.reduce(
      (acc, item) => ({
        ...acc,
        aliasIds: [...(acc.aliasIds ?? []), item.id],
      }),
      group[0],
    ),
  );

  return items;
};
