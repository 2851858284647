// @flow strict

import React from 'react';
import dayjs from 'dayjs';

import { Calendar } from 'mp-ui-components';

import styles from './CalendarRangeBody.scss';

import { TPeriod } from '../types';

export default function CalendarRangeBody({
    showedDate,
    period,
    disabledPeriods = [],
    isLookingInPast = true,
    onChange
}: {
    showedDate: Date,
    period: TPeriod,
    disabledPeriods?: Array<TPeriod>,
    isLookingInPast?: boolean,
    onChange: () => void
}) {
    const [potentialDateTo, setPotentialDateTo] = React.useState<Date | null>(null);
    const [isNeedSetNewPeriod, setIsNeedSetNewPeriod] = React.useState<Boolean>(true);

    const nextMonth = dayjs(showedDate)
        .add(1, 'month')
        .toDate();

    const prevMonth = dayjs(showedDate)
        .subtract(1, 'month')
        .toDate();

    function onDayClick(date: Date) {
        if (isNeedSetNewPeriod) {
            onChange({ from: date, to: null });
            setIsNeedSetNewPeriod(false);
        } else {
            const { from } = period;

            const newPeriod = {
                from: from && date <= from ? date : from,
                to: from && date >= from ? date : from
            };

            const periodWithSeconds = {
                from: dayjs(newPeriod.from)
                    .set('hour', 0)
                    .set('minute', 0)
                    .set('second', 0)
                    .toDate(),
                to: dayjs(newPeriod.to)
                    .set('hour', 23)
                    .set('minute', 59)
                    .set('second', 59)
                    .toDate()
            };
            onChange(periodWithSeconds);
            setIsNeedSetNewPeriod(true);
        }
    }
    return (
        <div className={styles.calendarsWrapper}>
            <Calendar
                showedDate={isLookingInPast ? prevMonth : showedDate}
                from={period.from}
                to={period.to || potentialDateTo}
                onHover={setPotentialDateTo}
                onClick={onDayClick}
                disabledPeriods={disabledPeriods}
            />

            <Calendar
                showedDate={isLookingInPast ? showedDate : nextMonth}
                from={period.from}
                to={period.to || potentialDateTo}
                onHover={setPotentialDateTo}
                onClick={onDayClick}
                disabledPeriods={disabledPeriods}
            />
        </div>
    );
}
