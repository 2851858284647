import { AuthService } from '../../../../shared/services/auth';
import { FileService } from '../../../../shared/services/files';

export const uploadFilesForOrder = async (
  id: string,
  orderNumber: string,
  files: FileList | File[],
  docType: string,
  templateId?: string,
) => {
  const formData = new FormData();

  if (templateId) {
    formData.append('taskTemplateId', templateId);
  }

  const performer = AuthService.getUserIdToken()?.fullName;
  const url = `/api/orders/${id}/files?prefix=${orderNumber}&performer=${performer}`;

  return FileService.uploadFilesTaskApi({ url, docType, files, formData });
};
