// @flow strict

const data = {
    orderView: ({ orderId }: { orderId: string }) => {
        return `/orders/${orderId}`;
    },
    orderViewDocuments: ({ orderId }: { orderId: string }) => {
        return `/orders/${orderId}/documents`;
    },
    orderViewCertTracking: ({ orderId }: { orderId: string }) => {
        return `/orders/${orderId}/certtrack`;
    },
    shipmentView: ({ shipmentId }: { shipmentId: string }) => {
        return `/shipments/${shipmentId}`;
    },
    shipmentViewDocuments: ({ shipmentId }: { shipmentId: string }) => {
        return `/shipments/${shipmentId}/documents`;
    },
    shipmentViewActiveTasks: ({ shipmentId }: { shipmentId: string }) => {
        return `/shipments/${shipmentId}/tasks`;
    }
};

export default function mapUrl(url: string, orderId?: string, shipmentId?: string) {
    const mappedUrlFunction = data[url];
    if (mappedUrlFunction) {
        return mappedUrlFunction({ orderId, shipmentId });
    }
}
