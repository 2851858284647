import React, { PropsWithChildren } from 'react';

import { TableColumn } from '../_deprecated/Table/types';
import { RTCellContext } from '../types';

type Props = PropsWithChildren & {
  rtContext: RTCellContext;
  title?: string;
};

export const Td = ({ rtContext: { column }, title, children }: Props) => {
  return (
    <td data-field-id={(column.columnDef.meta as TableColumn).name} title={title}>
      {children}
    </td>
  );
};
