declare const BUILD_ENV: string;
declare const BUILD_NAMESPACE: string;
declare const SENTRY_DSN: string;
declare const APP_VERSION: string;

export const APP_ENV = {
  buildEnv: BUILD_ENV,
  buildNamespace: BUILD_NAMESPACE,
  isLocalDev: BUILD_ENV === 'local',
  sentryDsn: SENTRY_DSN,
  version: APP_VERSION,
};
