import React from 'react';

import cookie from 'react-cookies';

import { AuthService } from '@src/shared/services/auth';
import { RoutingService } from '@src/shared/services/routing';
import { sanitizeObject } from '@src/app/utilities/sanitizeObject';

import { requestToken } from '../../services/token';

import styles from './code-validation.css';

type Props = {
  code: string;
};

export const CodeValidationPage = ({ code }: Props) => {
  const [isFailed, setFailed] = React.useState(false);

  React.useEffect(() => {
    requestToken(code)
      .then((res) => {
        cookie.save('auth', res, {
          path: '/',
        });

        const defaultRoute = RoutingService.getDefaultRouteForRole(
          AuthService.getUserIdToken()?.vedRole,
        );

        window.location.href = defaultRoute;
      })
      .catch(() => {
        setFailed(true);
      });
  }, []);

  if (isFailed) {
    return (
      <div className={styles.container}>
        <p>
          Oops, something went wrong, please{' '}
          <a className={styles.link} href={sanitizeObject(window.location.href)}>
            try again
          </a>{' '}
          or{' '}
          <a className={styles.link} href="/logout">
            log out
          </a>
        </p>
      </div>
    );
  }

  return null;
};
