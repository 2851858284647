import { UserRole } from '@src/shared/services/auth';

export const userRoleDisplayMapping: Record<UserRole, string> = {
  ADEOSupplier: 'Supplier',
  ImportSpecialist: 'Import Specialist',
  CustomsDocs: 'Customs Docs',
  CustomsGroupSpecialist: 'Customs Group Specialist',
  TransportGroupSpecialist: 'Transport Group Specialist',
  CertBody: 'Certification Body',
  CertificationSpecialist: 'Certification Specialist',
  ApprovisionSpecialist: 'Approvision Specialist',
  ADEOTransport: 'Supplier Transport',
  Forwarder: 'Forwarder',
  CustomsBroker: 'Customs Broker',
  SampleCoordinator: 'Sample Coordinator',
  ADEOVietnam: 'ADEO Vietnam',
};
