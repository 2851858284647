import { CommonTable } from '@src/shared/components/Table/_deprecated/Table';
import { AuthService, UserRole } from '@src/shared/services/auth';

import { addChangePriority } from './addChangePriority';

export const calculateTableData = (tableData: CommonTable): CommonTable => {
  const isApprovisionSpecialistUser = AuthService.getIsUserRoleMatches([
    UserRole.ApprovisionSpecialist,
  ]);

  if (isApprovisionSpecialistUser) {
    return addChangePriority(tableData);
  }

  return tableData;
};
