import axios from 'axios';

import type { TokenRequest } from '../types';

function requestToken(code: string): Promise<TokenRequest> {
  return new Promise((resolve, reject) => {
    axios
      .post('/api/auth/token', {
        code,
        redirectURL: `${window.location.origin}/login`,
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export { requestToken };
