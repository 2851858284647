import React, { Fragment, useState } from 'react';

import { Button, Dropdown, TextareaField } from 'mp-ui-components';

import { TOrderArticle } from '../../entities/orderArticle';
import { TUseOrderArticles } from '../../hooks/useOrderArticles';
import { useOrderArticlesBlocking } from '../../hooks/useOrderArticlesBlocking';
import { useOrderArticlesUnblocking } from '../../hooks/useOrderArticlesUnblocking';

import styles from './styles.css';

// TODO: add proper typings
type Props = {
  orderId: string;
  articles: TOrderArticle[];
  filters: unknown;
  fetchArticles: TUseOrderArticles['fetch'];
};

export const BlockUnblockActions = ({ orderId, articles, filters, fetchArticles }: Props) => {
  const {
    blockArticles,
    message: blockingMessage,
    changeMessage: changeBlockingMessage,
    isPending: isBlocking,
    error: blockingError,
  } = useOrderArticlesBlocking(orderId);

  const {
    unblockArticles,
    message: unblockingMessage,
    changeMessage: changeUnblockingMessage,
    isPending: isUnblocking,
    error: unblockingError,
  } = useOrderArticlesUnblocking(orderId);

  const [isBlockingFormOpen, setIsBlockingFormOpen] = useState(false);

  const [isUnblockingFormOpen, setIsUnblockingFormOpen] = useState(false);

  const checkedArticles = articles.filter((article) => article.canBlock.isChecked);
  const checkedArticleIds = checkedArticles.map((article) => article.id);
  const checkedArticleAdeoCodes = checkedArticles.map((article) => article.adeoCode);

  const handleBlockingFormOpen = (isOpen) => setIsBlockingFormOpen(isOpen);

  const handleUnblockingFormOpen = (isOpen) => setIsUnblockingFormOpen(isOpen);

  const handleBlock = () =>
    blockArticles(checkedArticleIds, () => {
      fetchArticles(filters, {
        isSilent: true,
      });
      setIsBlockingFormOpen(false);
    });

  const handleUnblock = () =>
    unblockArticles(checkedArticleIds, () => {
      fetchArticles(filters, {
        isSilent: true,
      });
      setIsUnblockingFormOpen(false);
    });

  const adeoCodes = (
    <>
      {checkedArticleAdeoCodes.map((code, codeIndex) => (
        <Fragment key={`adeo-code-${code}`}>
          <strong>{code}</strong>
          {codeIndex < checkedArticleAdeoCodes.length - 1 ? ', ' : ''}
        </Fragment>
      ))}
    </>
  );

  return (
    <footer className={styles.footer}>
      <Dropdown
        className={styles.trigger}
        isOpen={isBlockingFormOpen}
        onChange={handleBlockingFormOpen}
      >
        <Dropdown.Trigger>
          <Button theme="danger" isDisabled={checkedArticleIds.length === 0} isPending={isBlocking}>
            Block
          </Button>
        </Dropdown.Trigger>
        <Dropdown.Content className={styles.form} ariseDirection="top-start">
          <p className={styles.description}>
            You are about to update the state of following SKUs: {adeoCodes}. These SKUs will be
            blocked in all orders.
          </p>
          <TextareaField
            className={styles.textArea}
            inputClassName={styles.message}
            placeholder="Keep here blocking details"
            value={blockingMessage}
            isPending={isBlocking}
            onChange={(event) => changeBlockingMessage(event.target.value)}
          />
          <footer className={styles.formFooter}>
            <Button
              theme="secondary"
              isPending={isBlocking}
              isDisabled={blockingMessage.length === 0}
              onClick={handleBlock}
            >
              Block
            </Button>
            {blockingError.length > 0 && <div className={styles.formError}>{blockingError}</div>}
          </footer>
        </Dropdown.Content>
      </Dropdown>
      <Dropdown
        className={styles.trigger}
        isOpen={isUnblockingFormOpen}
        onChange={handleUnblockingFormOpen}
      >
        <Dropdown.Trigger>
          <Button
            theme="primary"
            isDisabled={checkedArticleIds.length === 0}
            isPending={isUnblocking}
          >
            Unblock
          </Button>
        </Dropdown.Trigger>
        <Dropdown.Content className={styles.form} ariseDirection="top-start">
          <p className={styles.description}>
            You are about to update the state of following SKUs: {adeoCodes}. These SKUs will be
            unblocked in all orders.
          </p>
          <TextareaField
            className={styles.textArea}
            inputClassName={styles.message}
            placeholder="Keep here unblocking details"
            value={unblockingMessage}
            isPending={isUnblocking}
            onChange={(event) => changeUnblockingMessage(event.target.value)}
          />
          <footer className={styles.formFooter}>
            <Button
              theme="secondary"
              isPending={isUnblocking}
              isDisabled={unblockingMessage.length === 0}
              onClick={handleUnblock}
            >
              Unblock
            </Button>
            {unblockingError.length > 0 && (
              <div className={styles.formError}>{unblockingError}</div>
            )}
          </footer>
        </Dropdown.Content>
      </Dropdown>
    </footer>
  );
};
