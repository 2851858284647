import React from 'react';

import { Dropdown, List, ListDivider, ListItem } from 'fronton-react';

import { Wrap, InputLengthMeter } from './Styled';
import { useMultiPickDropdown } from './useMultiPickDropdown';
import { ITEM_FOR_ALL_ITEMS, SelectionState } from './utils';

type Props = {
  label: string;
  name: string;
  placeholder: string;
  items: { id: string; text: string }[];
  onSelect: (filterName: string, values: string[]) => void;
  selectedValues?: string[];
  className?: string;
};

export const MultiPickDropdown = ({
  items,
  label,
  name,
  selectedValues = [],
  placeholder,
  className,
  onSelect,
}: Props) => {
  const { wrapRef, inputText, handleSelect, selectionState, inputLengthMeterRef } =
    useMultiPickDropdown({ name, items, selectedValues, onSelect });

  const allItemsIcon =
    selectionState === SelectionState.partial ? 'checkbox-indeterminate' : 'checkbox';

  const isAllItemsIconActive = [SelectionState.partial, SelectionState.all].includes(
    selectionState,
  );

  return (
    <Wrap ref={wrapRef as any} className={className}>
      <Dropdown label={label} size="s" placeholder={placeholder} value={inputText}>
        <List
          size="m"
          selectionMode="multiple"
          selectedValues={selectedValues}
          onSelect={handleSelect}
        >
          <ListItem
            text={ITEM_FOR_ALL_ITEMS.text}
            value={ITEM_FOR_ALL_ITEMS.id}
            iconLeft={allItemsIcon}
            selected={isAllItemsIconActive}
          />
          <ListDivider />
          {items.map(({ id, text }) => (
            <ListItem key={id} value={id} text={text} />
          ))}
        </List>
      </Dropdown>
      <InputLengthMeter ref={inputLengthMeterRef} />
    </Wrap>
  );
};
