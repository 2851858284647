import { IUserTaskTemplate } from '@lmru-bpms-ved/core';

import { AuthService } from '@src/shared/services/auth';

import type { TaskComponentContainerType } from '../../../../shared/types/domain';

export type TerminalButton = {
  id: string;
  type: 'primary';
  caption: string;
};

export function mapPerformer(performer: string) {
  const me = AuthService.getUserIdToken()?.fullName;

  if (me === performer) {
    return 'me';
  }

  return performer;
}

export function mapButtons(buttons: TerminalButton[], performer: string): TerminalButton[] {
  return buttons.map((button) => {
    if (button.id === 'submitButton') {
      if (performer === 'me') {
        return { ...button, id: 'proceedButton' };
      }

      if (performer === '') {
        return { ...button, id: 'startProcessing', caption: 'Start processing' };
      }

      return { ...button, id: 'takeFromPerformer', caption: 'Take from performer' };
    }

    return button;
  });
}

// TODO: докрутить типизацию (TaskComponentContainerType должен опираться на общие типы из core)
export function mapSections(
  sections: IUserTaskTemplate['sections'],
  performer: string,
): TaskComponentContainerType[] {
  if (performer !== 'me') {
    const taskSection = sections.find((section) => section.caption === 'Task');

    if (taskSection) {
      return [taskSection];
    }

    return [];
  }

  return sections.map((section) => {
    if (section.caption === 'Decision') {
      return {
        ...section,
        fields: section.fields.map((field) => {
          if (field.type === 'decisionTable') {
            return { ...field, label: field.caption, isRequired: true };
          }

          if (field.type === 'select' && field.value) {
            return { ...field, selected: field.value };
          }

          if (field.type === 'datePicker' && !field.value) {
            return { ...field, value: new Date() };
          }

          if (field.type === 'checkbox' && !field.selected) {
            return { ...field, selected: [] };
          }

          return field;
        }),
      };
    }

    return section;
  });
}
