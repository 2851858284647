import { useLocalStorage } from 'react-use';

type UseTitleParams = {
  text: string;
  docType: string;
  isFoldable?: boolean;
};

export const useTitle = ({ text, docType, isFoldable = false }: UseTitleParams) => {
  const [isFolded, setIsFolded] = useLocalStorage<boolean>(
    `documentWidget_${docType}_isFolded`,
    true,
  );

  const onFold = () => setIsFolded(!isFolded);

  return {
    text,
    isFoldable,
    isFolded: isFolded ?? false,
    onFold,
  };
};
