import {
  GetIdcEditingReportFiltersResponse,
  GetIdcEditingReportResponse,
} from '@lmru-bpms-ved/contract-bff';

import { FileService } from '@src/shared/services/files';
import { httpClient } from '@src/shared/services/httpClient';
import { BaseQueryDef, createQueryDef } from '@src/shared/services/query';

import { FormState } from './types';

export const getEditingReportFilters = createQueryDef<
  BaseQueryDef<GetIdcEditingReportFiltersResponse>
>(`/api/idc/reports/editing/filters`, async ({ queryKey }) => {
  const [url] = queryKey;
  const response = await httpClient.get<GetIdcEditingReportFiltersResponse>(url);

  return response.data;
});

export const downloadEditingReport = async (params: FormState) => {
  const response = await httpClient.post<GetIdcEditingReportResponse>(`/api/idc/reports/editing`, {
    departments: params.departments,
    // converting back to UTC+0 from Moscow UTC+3:
    startDate: params.startDate.subtract(3, 'hours').toISOString(),
    endDate: params.endDate.subtract(3, 'hours').add(1, 'millisecond').toISOString(), // for proper rounding adding 1 msec
  });

  const fileUrl = response.data.s3SignedUri;

  if (fileUrl) {
    FileService.downloadFile(fileUrl);
  }
};
