import React from 'react';

import { ActionsSplitter, DotSplitter, PageSwitcher, Wrap } from './Styled';

type PaginationControllerProps = {
  page: number;
  isPending?: boolean;
  className?: string;
  isNextDisabled?: boolean;
  zeroPageLabel?: string;
  onChange: (nextPage: number) => void;
};

export const PaginationController = ({
  className,
  page,
  isNextDisabled,
  isPending,
  zeroPageLabel,
  onChange,
}: PaginationControllerProps) => {
  const setPage = (nextNumber: number) => {
    if (!isPending) {
      onChange(page + nextNumber);
    }
  };

  const increasePage = () => (!isNextDisabled ? setPage(0) : setPage(1));
  const decreasePage = () => (page > 0 ? setPage(-1) : setPage(0));

  const recentPage = () => setPage(-page);

  if (page === 0 && !isNextDisabled) {
    return null;
  }

  return (
    <Wrap className={className} isPending={isPending}>
      <PageSwitcher hasBorderBottom={page !== 0} onClick={recentPage}>
        {zeroPageLabel || 'Recent'}
      </PageSwitcher>

      <ActionsSplitter />

      {page !== 0 && (
        <PageSwitcher hasBorderBottom onClick={decreasePage}>
          Previous
        </PageSwitcher>
      )}

      {page !== 0 && isNextDisabled && <DotSplitter />}

      {isNextDisabled && (
        <PageSwitcher hasBorderBottom isHiglighted onClick={increasePage}>
          Next
        </PageSwitcher>
      )}
    </Wrap>
  );
};
