import React from 'react';

import { TShipmentReport } from '../../../../../../../types';
import { FileDownloadOnlyOne } from '../base/FileDownloadOnlyOne';
import { FileStatus } from '../base/FileStatus';

import styles from './styles.css';

export const ReportRejected = ({
  url,
  filePath,
  fileName,
  convertedErrors,
  created,
  status,
  placeholder,
}: TShipmentReport) => {
  return (
    <div className={styles.body}>
      <div className={styles.fileInfo}>
        <FileDownloadOnlyOne url={url} filePath={filePath} fileName={fileName} created={created} />

        <FileStatus status={status} convertedErrors={convertedErrors} placeholder={placeholder} />
      </div>
    </div>
  );
};
