import { makeGetAllUploadsHistory } from './makeGetAllUploadsHistory';
import { makeGetTemplateUrl } from './makeGetTemplateUrl';
import { makeGetUploadsHistoryByEntityId } from './makeGetUploadsHistoryByEntityId';
import { makeUpload } from './makeUpload';
import { makeRequestFilters } from '../../../../../shared/api/makeRequestFilters';

export const makeDocumentWidgetApi = (baseUrl: string) => ({
  upload: makeUpload(baseUrl),
  getAllUploadsHistory: makeGetAllUploadsHistory(baseUrl),
  requestFilters: makeRequestFilters(`${baseUrl}/history`),
  getUploadsHistoryByEntityId: makeGetUploadsHistoryByEntityId(baseUrl),
  getTemplateUrl: makeGetTemplateUrl(baseUrl),
});
